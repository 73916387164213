export default class UI {
    NOTIFICATION_TYPE_INFO = 0;
    NOTIFICATION_TYPE_ERROR = 1;

    static ShowNotification(message, type=UI.NOTIFICATION_TYPE_INFO) {
        $(".ui-notification").remove();
        $("body").append('<div class="ui-notification">' + message + '</div>');
        $(".ui-notification").css({
            top: -$("header").height(),
            opacity: 1,
            height: $("header").height()
        });
        
        setTimeout(() => {
            $(".ui-notification").css({
                transitionDuration: "300ms",
                transitionTimingFunction: "ease-out",
                top: 0
            });
            setTimeout(() => {
                $(".ui-notification").css({
                    transitionDuration: "100ms",
                    transitionTimingFunction: "ease-in",
                    top: -$("header").height()
                });
                setTimeout(() => {
                    $(".ui-notification").remove();
                }, 100);
            }, Math.max(3000, 100 * message?.length));
        });
    }
}