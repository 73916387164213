import { useState, useEffect } from "react";

import Field from "./Field";

export default function ExtrasConfigurator(props) {
    const [ data, setData ] = useState([]);
    const extrasJSON = typeof props.data === "string" ? JSON.parse(props.data) : props.data;

    useEffect(() => {
        if (props.onChange) props.onChange(data);
    }, [data]);

    if (!extrasJSON) return null;

    return <div className="extras-configurator">
        {Object.keys(extrasJSON).map((categoryName, idx) => <div className="category" key={"category-" + idx}>
            {extrasJSON[categoryName].items.map((item, idx) => <div className="item" key={"item-" + idx}>
                <div>
                    <div>{item.name}</div>
                    <Field type="number" defaultValue={0} min={0} onChange={value => {
                        const n = [...data];
                        const existingIndex = data.findIndex(i => i.name == item.name);
                        if (existingIndex != -1) {
                            n[existingIndex].quantity = value;
                        } else {
                            n.push({
                                name: item.name,
                                quantity: value,
                                price: item.price
                            });
                        }
                        setData(n);
                    }}/>
                </div>
            </div>)}
        </div>)}
    </div>;
}