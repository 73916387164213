import React, { useEffect, useRef } from "react";

export default function LoadingIndicator(props) {
	return (
		<div className={"loading-indicator" + (props.contained ? " contained" : "") + (props.withBackground ? " with-background" : "")} style={props.style || {}}>
			{props.message?.length > 0 && <div className="message">{props.message}</div>}
			<div className="progress-dots">
				<span style={{ backgroundColor: props.dotColor ? props.dotColor : "" }} className="progress-dot-1" />
				<span style={{ backgroundColor: props.dotColor ? props.dotColor : "" }} className="progress-dot-2" />
				<span style={{ backgroundColor: props.dotColor ? props.dotColor : "" }} className="progress-dot-3" />
			</div>
		</div>
	);
}
