import { useEffect, useState, useContext, useCallback } from "react";
import Bloque from "./AdminBloque";
import Field from "./Field";

import { DialogContext } from "../context/DialogContext";

import AdminService from "../services/Admin";
import AdminBar from "./AdminBar";
import FormToggle from "./FormToggle";
import LoadingIndicator from "./LoadingIndicator";
import Session from "../services/Session";
import L10n from "../services/Locale";

export default function AdminIdiomas(props) {
    const [ finishedLoading, setFinishedLoading ] = useState(false);
    const [ supportsLocalization, setSupportsLocalization ] = useState(false);
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
        Promise.all([
            AdminService.GetSettings("SITE_SUPPORTS_LOCALIZATION")
        ]).then(([supportsLocalization]) => {
            setSupportsLocalization(supportsLocalization == 1);
            setFinishedLoading(true);
        });

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			Promise.all([
                AdminService.SetSettings("SITE_SUPPORTS_LOCALIZATION", supportsLocalization ? 1 : 0, true)
            ]).then(() => {
				Session.AlterBootstrapData("supports_localization", supportsLocalization);
                notifySaved(true);
            });
		});
	}, [ supportsLocalization ]);

    if (!finishedLoading) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-idiomas">
			<AdminBar onSave={save} />
			<Bloque className="columnas">
				<div className="columna">
                    <div className="fila"><FormToggle defaultValue={supportsLocalization} onChange={value => setSupportsLocalization(value)} /> {L10n.__("Este sitio es multilingüe.")}</div>
				</div>
			</Bloque>
		</div>
	);
}
