import react, { useState, useEffect, useRef } from "react";

export default function ConfirmationDialog(props) {
	return (
		<div className="confirmation-dialog-overlay">
			<div className="confirmation-dialog">
				<div className="prompt">{props.prompt}</div>
				<div className="buttons">
					<div
						className="cancel-button"
						onClick={(e) => {
							e.preventDefault();
							if (props.onRejected) props.onRejected();
						}}>
						{props.noButtonText}
					</div>
					<div
						className="ok-button"
						onClick={(e) => {
							e.preventDefault();
							if (props.onAccepted) props.onAccepted();
						}}>
						{props.yesButtonText}
					</div>
				</div>
			</div>
		</div>
	);
}
