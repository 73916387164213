import { useEffect, useState } from "react";
import L10n from '../services/Locale';

export default function TextEditor(props) {
	const [elementID, setElementID] = useState();
	const [containerID, setContainerID] = useState();

	const maxLength = props.maxLength;

	useEffect(() => {
		setElementID("editor-" + Math.floor(Math.random() * 8999 + 1000));
		setContainerID("editor-container-" + Math.floor(Math.random() * 8999 + 1000));
	}, []);

	return <div className={["text-editor-container", props.className].filter(v => v).join(" ")} id={containerID}>
		<textarea className="text-editor" id={elementID} defaultValue={props.initialValue || ""} onChange={e => {
			if (props.onChange) {
				props.onChange(e.target.value);
			}
		}} />
	</div>;
}
