import L10n from '../services/Locale';

export default function AdminKPIEmpleadorSuscripciones({kpis}) {
    return <div className="kpi-container">
        <div className="item">
			<div className="value">{kpis.totalActiveSubscriptions}</div>
            <div className="caption">{L10n.__("Suscripciones activas")}</div>
        </div>
        <div className="item">
            <div className="value">
                {kpis.totalVisits.visits} / {kpis.totalVisits.totalVisits}
            </div>
            <div className="caption">{L10n.__("Visitas")}</div>
        </div>
        <div className="item">
            <div className="value">{(Math.floor(kpis.ordersWithExtrasRatio * 10000) / 100).toLocaleString("es")} %</div>
            <div className="caption">{L10n.__("Reservas con extras")}</div>
        </div>
        <div className="item">
            <div className="value">{(Math.floor(kpis.ordersOverPaxRatio * 10000) / 100).toLocaleString("es")} %</div>
            <div className="caption">{L10n.__("Reservas con más personas")}</div>
        </div>
    </div>;
}
