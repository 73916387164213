import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import L10n from '../services/Locale';

export default class ReservaFallida extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        $('header').addClass('simple');
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        return (
            <div className="reserva-completada">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{L10n.__("No se ha podido realizar la reserva")}</h1>
                            <h2>{L10n.__("Por favor inténtalo de nuevo o")} <Link to={BASENAME + "/contacto"}>{L10n.__("contacta con nosotros")}</Link>.</h2>
                            <Link to={BASENAME + "/pago"} className="btn btn-brown">{L10n.__("Volver")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
