require("../../css/admin-reservas-disponibilidad.css");

import { useEffect, useState, useRef, useContext } from 'react';

import AdminService from '../services/Admin';
import AdminBar from "./AdminBar";
import ComboSelect from "./ComboSelect";
import Session from "../services/Session";
import AdminReservas from './AdminReservas';
import AdminDisponibilidad from './AdminDisponibilidad';
import L10n from "../services/Locale";
import { PermissionsContext } from "../context/PermissionsContext";

export default function AdminReservasDisponibilidad(props) {
	const [ negocios, setNegocios ] = useState([]);
	const [ selectedNegocio, setSelectedNegocio ] = useState(null);
	const [ activeTab, setActiveTab ] = useState("reservas");
	const permissionsContext = useContext(PermissionsContext);
	const [ loading, setLoading ] = useState(false);
	const selectNegocio = useRef(null);

	function getClassForNegocioCombo(negocio) {
		if (activeTab == "reservas") {
			if (negocio.future_order_count == 0) return "empty";
		} else if (activeTab == "disponibilidad") {
			if (negocio.future_availability_count == 0) return "empty";
		}

		return "";
	}

	function getNegociosSorted() {
		return negocios.sort((a, b) => {
			if ((activeTab == "reservas" ? a.future_order_count : a.future_availability_count) > 0 && (activeTab == "reservas" ? b.future_order_count : b.future_availability_count) == 0) {
				return -1;
			}

			if ((activeTab == "reservas" ? a.future_order_count : a.future_availability_count) == 0 && (activeTab == "reservas" ? b.future_order_count : b.future_availability_count) > 0) {
				return 1;
			}
			return a.name > b.name ? 1 : -1;
		});
	}

	useEffect(() => {
        Session.GetAccountEvents().then(negocios => {
        	setNegocios(negocios.map(negocio => { negocio.value = negocio.id = parseInt(negocio.id); return negocio; }));
		});

		document.body.classList.add("has-header");

		return () => {
			document.body.classList.remove("has-header");
		};
	}, []);

	useEffect(() => {
		if (selectNegocio.current) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				selectNegocio.current(getNegociosSorted()[0]);
			}, 100);
		}
	}, [activeTab]);

	useEffect(() => {
		setSelectedNegocio(getNegociosSorted()[0]);
	}, [negocios]);

	if (loading) return null;

	return <div className="admin-page admin-reservas">
			{<AdminBar renderElements={() => null} />}
			<div className="header">
				<div className="left">
					<button className={"btn" + (activeTab == "reservas" ? " active" : "")} onClick={e => setActiveTab("reservas")}>{L10n.__("Reservas")}</button>
					<button className={"btn" + (activeTab == "disponibilidad" ? " active" : "")} onClick={e => setActiveTab("disponibilidad")}>{L10n.__("Disponibilidad")}</button>
				</div>
				<div className="right">
					{negocios.length > 0 && selectedNegocio && <ComboSelect
						selectOptionRef={ref => { selectNegocio.current = ref; }}
						defaultValue={selectedNegocio}
						onChange={negocio => setSelectedNegocio(negocio)}
						placeholder="Selecciona un negocio"
						options={getNegociosSorted()}
						renderOption={option => {
							return <span className={getClassForNegocioCombo(option)}>{option.name}</span>;
						}}
					/>}
				</div>
			</div>
			{activeTab == "reservas" && selectedNegocio && <div className="reservas">
				<AdminReservas negocio={selectedNegocio.id} />
			</div>}
			{activeTab == "disponibilidad" && selectedNegocio && <div className="disponibilidad">
				<AdminDisponibilidad negocio={selectedNegocio.id} slug={selectedNegocio.slug} />
			</div>}
	</div>;
}
