import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './js/components/App';
import './style.css';

const container = document.createElement("div");
container.id = "spotnow";
document.body.appendChild(container);

const root = createRoot(container);
root.render(<BrowserRouter><App /></BrowserRouter>);
