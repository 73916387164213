import { useEffect, useState, useCallback, useContext } from "react";

import EditorSlider from "./EditorSlider";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";
import LoadingIndicator from "./LoadingIndicator";
import AdminBar from "./AdminBar";
import Field from "./Field";

import L10n from "../services/Locale";
import ContentHelper from "../services/ContentHelper";
import Bloque from "./AdminBloque";
import AdminService from "../services/Admin";
import { DialogContext } from "../context/DialogContext";
import FormToggle from "./FormToggle";
import WidgetAttachmentLimits from "./WidgetAttachmentLimits";
import Settings from "../services/Settings";

export default function AdminHome(props) {
	const [sliderData, setSliderData] = useState({});
	const [imagenHome1, setImagenHome1] = useState(null);
	const [subtituloHome1, setSubtituloHome1] = useState(null);
	const [textoHome1, setTextoHome1] = useState(null);
	const [logosHome1, setLogosHome1] = useState(null);

	const [imagenHome2, setImagenHome2] = useState(null);
	const [subtituloHome2, setSubtituloHome2] = useState(null);
	const [textoHome2, setTextoHome2] = useState(null);
	const [logosHome2, setLogosHome2] = useState(null);

	const [imagenHome3, setImagenHome3] = useState(null);
	const [subtituloHome3, setSubtituloHome3] = useState(null);
	const [textoHome3, setTextoHome3] = useState(null);
	const [logosHome3, setLogosHome3] = useState(null);

	const [videoPosterFrame, setVideoPosterFrame] = useState(null);
	const [videoFile, setVideoFile] = useState(null);

	const [ videoEnabled, setVideoEnabled ] = useState(null);
	const [ informationSectionEnabled, setInformationSectionEnabled ] = useState(null);

	const [loadingInProgress, setLoadingInProgress] = useState(true);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		setTimeout(() => {
			resizeVideoUpload();
		}, 500);
	}, [ videoFile, videoPosterFrame ]);

	useEffect(() => {
		ContentHelper.GetString("home-imagen-paso-1").then((url) => setImagenHome1(url));
		ContentHelper.GetString("home-subtitulo-paso-1").then((url) => setSubtituloHome1(url));
		ContentHelper.GetString("home-texto-paso-1").then((url) => setTextoHome1(url));

		Promise.all([ContentHelper.GetString("home-paso-1-logo-1"), ContentHelper.GetString("home-paso-1-logo-2"), ContentHelper.GetString("home-paso-1-logo-3")]).then(([a, b, c]) => {
			setLogosHome1([a, b, c]);
		});

		ContentHelper.GetString("home-imagen-paso-2").then((url) => setImagenHome2(url));
		ContentHelper.GetString("home-titulo-paso-2").then((url) => setSubtituloHome2(url));
		ContentHelper.GetString("home-texto-paso-2").then((url) => setTextoHome2(url));

		Promise.all([ContentHelper.GetString("home-paso-2-logo-1"), ContentHelper.GetString("home-paso-2-logo-2"), ContentHelper.GetString("home-paso-2-logo-3"), ContentHelper.GetString("home-paso-2-logo-4"), ContentHelper.GetString("home-paso-2-logo-5"), ContentHelper.GetString("home-paso-2-logo-6")]).then(([a, b, c, d, e, f]) => {
			setLogosHome2([a, b, c, d, e, f]);
		});

		ContentHelper.GetString("home-imagen-paso-3").then((url) => setImagenHome3(url));
		ContentHelper.GetString("home-titulo-paso-3").then((url) => setSubtituloHome3(url));
		ContentHelper.GetString("home-texto-paso-3").then((url) => setTextoHome3(url));

		Promise.all([ContentHelper.GetString("home-paso-3-logo-1"), ContentHelper.GetString("home-paso-3-logo-2"), ContentHelper.GetString("home-paso-3-logo-3")]).then(([a, b, c]) => {
			setLogosHome3([a, b, c]);
		});

		Promise.all([ContentHelper.GetString("video-home-poster-frame"), ContentHelper.GetString("video-home-file")]).then(([poster, video]) => {
			setVideoPosterFrame(poster);
			setVideoFile(video);
		});

		Settings.Get("SHOW_HOME_VIDEO", false).then(show => {
			setVideoEnabled(show == 1 ? true : false);
		});

		Settings.Get("SHOW_HOME_INFORMATION_SECTION", true).then(show => {
			setInformationSectionEnabled(show == 1 ? true : false);
		});

		resizeVideoUpload();

		document.body.classList.add("bg-grey");
		window.addEventListener("resize", resizeVideoUpload);

		return () => {
			document.body.classList.remove("bg-grey");
			window.removeEventListener("resize", resizeVideoUpload);
		};
	}, []);

	function resizeVideoUpload() {
		$(".video-columna").css("height", "");
		$(".video-poster-columna").css("height", "");

		if ($(".video-poster-columna").height() > $(".video-columna").height()) {
			$(".video-columna").height($(".video-poster-columna").height());
		} else {
			$(".video-poster-columna").height($(".video-columna").height());
		}
	}

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), async (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

            for (let i=0; i<sliderData.length; i++) {
                const response = await AdminService.SaveUploadedImage(sliderData[i].background);
                if (response.data) {
                    sliderData[i].background = response.data;
                }
            }
			
			Promise.all([
				AdminService.SaveContentMultiple({
					"configuracion-slides": JSON.stringify(sliderData),
					"home-subtitulo-paso-1": subtituloHome1,
					"home-texto-paso-1": textoHome1,
					"home-titulo-paso-2": subtituloHome2,
					"home-texto-paso-2": textoHome2,
					"home-titulo-paso-3": subtituloHome3,
					"home-texto-paso-3": textoHome3,
				}),
				AdminService.SaveFileContent("home-paso-1-logo-1", logosHome1[0]),
				AdminService.SaveFileContent("home-paso-1-logo-2", logosHome1[1]),
				AdminService.SaveFileContent("home-paso-1-logo-3", logosHome1[2]),
				AdminService.SaveFileContent("home-paso-2-logo-1", logosHome2[0]),
				AdminService.SaveFileContent("home-paso-2-logo-2", logosHome2[1]),
				AdminService.SaveFileContent("home-paso-2-logo-3", logosHome2[2]),
				AdminService.SaveFileContent("home-paso-2-logo-4", logosHome2[3]),
				AdminService.SaveFileContent("home-paso-2-logo-5", logosHome2[4]),
				AdminService.SaveFileContent("home-paso-2-logo-6", logosHome2[5]),
				AdminService.SaveFileContent("home-paso-3-logo-1", logosHome3[0]),
				AdminService.SaveFileContent("home-paso-3-logo-2", logosHome3[1]),
				AdminService.SaveFileContent("home-paso-3-logo-3", logosHome3[2]),
				AdminService.SaveFileContent("home-imagen-paso-1", imagenHome1),
				AdminService.SaveFileContent("home-imagen-paso-2", imagenHome2),
				AdminService.SaveFileContent("home-imagen-paso-3", imagenHome3),
				AdminService.SaveFileContent("video-home-poster-frame", videoPosterFrame),
				AdminService.SaveFileContent("video-home-file", videoFile),
				AdminService.SetSettings("SHOW_HOME_VIDEO", videoEnabled ? 1 : 0, true),
				AdminService.SetSettings("SHOW_HOME_INFORMATION_SECTION", informationSectionEnabled ? 1 : 0, true)
			]).then(() => {
				notifySaved(true);
			});
		});
	}, [sliderData, imagenHome1, subtituloHome1, textoHome1, logosHome1, imagenHome2, subtituloHome2, textoHome2, logosHome2, imagenHome3, subtituloHome3, textoHome3, logosHome3, videoPosterFrame, videoFile, videoEnabled, informationSectionEnabled]);

	return (
		<div className="admin-page admin-home">
			<AdminBar
				onSave={save}
			/>
			<Bloque>
				<EditorSlider slug="configuracion-slides" onChange={(sliderData) => setSliderData(sliderData)} />
			</Bloque>
			<div className="information-section-toggle-container">
				<div>
					{informationSectionEnabled !== null && <FormToggle defaultValue={informationSectionEnabled} onChange={value => { setInformationSectionEnabled(value) }} />}
					<div className="icon-tooltip">{informationSectionEnabled ? L10n.__("Desactivar sección") : L10n.__("Activar sección")}</div>
				</div>
			</div>
			<Bloque className="columnas" style={{opacity: informationSectionEnabled ? 1 : 0.5, pointerEvents: informationSectionEnabled ? "auto" : "none"}}>
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{imagenHome1 !== null && <ImageUpload
								recommendedWidth={500}
								recommendedHeight={500}
								maxFileSize={600 * 1024}
								initialValue={imagenHome1} onChange={(value) => setImagenHome1(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{subtituloHome1 !== null && <Field maxLength={25} type="string" defaultValue={subtituloHome1} onChange={(value) => setSubtituloHome1(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome1 !== null && <Field maxLength={250} type="text" defaultValue={textoHome1} onChange={(value) => setTextoHome1(value)} />}</div>
						</div>
						{logosHome1 && (
							<div className="row logos logos-home-1">
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome1[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[0] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome1[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[1] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome1[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[2] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{subtituloHome2 !== null && <Field maxLength={25} type="string" defaultValue={subtituloHome2} onChange={(value) => setSubtituloHome2(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome2 !== null && <Field maxLength={250} type="text" defaultValue={textoHome2} onChange={(value) => setTextoHome2(value)} />}</div>
						</div>
						<WidgetAttachmentLimits compact={true} style={{position: "relative", top: 0, left: 0, marginTop: 10 }} recommendedWidth={100} recommendedHeight={100} fileFormats={["svg", "png"]} maxFileSize={100 * 1024} />
						{logosHome2 && (
							<div className="row logos logos-home-2">
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[0] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[1] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[2] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
						{logosHome2 && (
							<div className="row logos logos-home-2">
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[3]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[3] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[4]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[4] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome2[5]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											newLogosHome[5] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col">{imagenHome2 !== null && <ImageUpload
								recommendedWidth={500}
								recommendedHeight={500}
								maxFileSize={600 * 1024}
								initialValue={imagenHome2} onChange={(value) => setImagenHome2(value)} />}</div>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{imagenHome3 !== null && <ImageUpload
								recommendedWidth={500}
								recommendedHeight={500}
								maxFileSize={600 * 1024}
								initialValue={imagenHome3} onChange={(value) => setImagenHome3(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{subtituloHome3 !== null && <Field maxLength={25} type="string" defaultValue={subtituloHome3} onChange={(value) => setSubtituloHome3(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome3 !== null && <Field maxLength={250} type="text" defaultValue={textoHome3} onChange={(value) => setTextoHome3(value)} />}</div>
						</div>
						{logosHome3 && (
							<div className="row logos logos-home-3">
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome3[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[0] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome3[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[1] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										showClearButton={true}
										displayWidth={50}
										displayHeight={50}
										initialValue={logosHome3[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[2] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</Bloque>
			{videoEnabled !== null && <>
				<div className="video-toggle-container">
					<div>
						<FormToggle defaultValue={videoEnabled} onChange={value => { setVideoEnabled(value) }} />
						<div className="icon-tooltip">{videoEnabled ? L10n.__("Desactivar vídeo") : L10n.__("Activar vídeo")}</div>
					</div>
				</div>
				<Bloque className={"columnas video-bloque" + (videoEnabled ? "" : " disabled")}>
					<div className="columna video-poster-columna">
						{videoPosterFrame !== null && <ImageUpload recommendedWidth={1050} recommendedHeight={600} maxFileSize={600 * 1024} sizeMode="cover" initialValue={videoPosterFrame} onChange={(value) => setVideoPosterFrame(value)} />}
					</div>
					<div className="columna video-columna">
						{videoFile !== null && <VideoUpload fileFormats={["mp4"]} recommendedWidth={1050} recommendedHeight={600} initialValue={videoFile} onChange={(value) => setVideoFile(value)} />}
					</div>
				</Bloque>
			</>}
		</div>
	);
}
