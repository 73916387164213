import { useState, useEffect, useCallback, useContext } from 'react';
import LoadingIndicator from './LoadingIndicator';
import Table from './Table';
import Helper from '../services/Helper';
import FormToggle from './FormToggle';
import Field from '../components/Field';
import MaterialIcon from './MaterialIcon';
import { APIAdminRequest } from '../services/API';
import L10n from "../services/Locale";
import ImageUpload from './ImageUpload';
import AdminBar from './AdminBar';
import AdminService from '../services/Admin';
import {DialogContext} from "../context/DialogContext";
import UI from '../services/UI';

export default function AdminGruposSuscripcion(_props) {
	const [types, setTypes] = useState([]);
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		APIAdminRequest("get-subscription-types").then(response => {
			setTypes(applyMenuOrder(response.data));
		});

		document.body.classList.add("has-header");

		return () => {
			document.body.classList.remove("has-header");
		};
	}, []);

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog(L10n.__("¿Seguro que quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), confirmed => {
			if (confirmed) {
				AdminService.SaveUploadedImagesRecursive(types).then(newTypes => {
					APIAdminRequest("save-subscription-types", { types: newTypes }).then(result => {
						if (result.status) {
							notifySaved(true);
						} else {
							UI.ShowNotification(result.error, UI.NOTIFICATION_TYPE_ERROR);
							notifySaved(false);
						}
					});
				});
			} else notifySaved(false);
		});
	}, [types]);

	function applyMenuOrder(types) {
		return[...types].sort((a, b) => a.menu_order < b.menu_order ? -1 : a.menu_order > b.menu_order ? 1 : 0);
	}

	function renderColumnOptions(type_id, section, column, section_idx, column_idx) {
		return <div className="options">
			<select defaultValue={column.type}><option value="image">{L10n.__("Imagen")}</option><option value="text">{L10n.__("Texto")}</option><option value="faq">{L10n.__("FAQ")}</option></select>
			<div className="buttons">
				<MaterialIcon name="delete" tooltip={section.length == 2 ? "Borrar columna" : "Borrar fila"} onClick={e => {
					e.preventDefault();
					const newTypes = [...types];

					for (let i=0; i<types.length; i++) {
						if (types[i].id == type_id) {
							if (section.length == 2) {
								// Borrar columna
								types[i].page_content.sections[section_idx].splice(column_idx, 1);
							} else {
								// Borrar fila
								newTypes[i].page_content.sections.splice(section_idx, 1);
							}
						}
					}

					$(".floating-tooltip").hide();
					setTypes(newTypes);
				}} />
				{section.length == 1 && <MaterialIcon name="add" tooltip="Añadir columna" onClick={e => {
					e.preventDefault();
					const newTypes = [...types];
					for (let i=0; i<types.length; i++) {
						if (types[i].id == type_id) {
							types[i].page_content.sections[section_idx].push({ type: "text" });
						}
					}
					setTypes(newTypes);
				}} />}
			</div>
		</div>;
	}

	function alterTypes(data) {
		const newTypes = [...types];

		data.forEach(t => {
			for (let i=0; i<newTypes.length; i++) {
				if (newTypes[i].id == t.id) {
					Object.keys(t).forEach(key => {
						newTypes[i][key] = t[key];
					});
				}
			}
		});

		setTypes(applyMenuOrder(newTypes));
	}

	function alterTypeContent(typeID, sectionIndex, columnIndex, data) {
		const newTypes = [...types];
		for (let i=0; i<newTypes.length; i++) {
			if (newTypes[i].id == typeID) {
				Object.keys(data).forEach(key => {
					newTypes[i].page_content.sections[sectionIndex][columnIndex][key] = data[key];
				});
			}
		}

		setTypes(newTypes);
	}

	if (!types) {
		return <LoadingIndicator />;
	}

	return <div className="admin-page admin-subscription-types">
		<AdminBar onSave={save} />
		<div className="header">
			<h2 style={{ marginRight: "auto" }}>{L10n.__("Grupos de suscripción")}</h2>
			<a href="#" className="new-type btn" onClick={e => {
				e.preventDefault();
				const newTypes = [...types];
				newTypes.push({
					id: 0,
					active: 1,
					menu_order: types.reduce((acc, t) => t.menu_order > acc ? t.menu_order : acc, 0),
					name: "Sin nombre",
					slug: "sin-nombre",
					price: 0,
					image_url: "",
					short_description: "",
					short_description_subscribers: "",
					page_content: {sections: [], subscriptionInfo: { title: "", text: "", buttonText: "" }, subscriptionInfoSubscribers: { title: "", text: "", buttonText: "" }},
					pack: 0,
					visits: 1,
					pax: -1,
					stock: -1,
					allow_extra_pax: 0,
					combinable_visits: 0,
					interval: 1,
					interval_type: "month"
				});
				setTypes(newTypes);
			}}>{L10n.__("Añadir grupo")}</a>
		</div>
		<Table
			showDragHandle={true}
			onOrderChanged={indexes => {
				let newTypes = [];
				indexes.forEach(idx => {
					newTypes.push(types[idx]);
				});

				newTypes.forEach((t, i) => t.menu_order = i);
				setTypes(newTypes);
			}}
			submenu={[
				{
					caption: L10n.__("Clonar grupo"),
					action: (type) => {
						const newTypes = [...types];
						newTypes.push({ ...type, id: 0, menu_order: types.reduce((acc, t) => t.menu_order > acc ? t.menu_order : acc, 0) + 1 });
						setTypes(newTypes);
					}
				},
				{
					caption: L10n.__("Eliminar grupo"),
					action: (type) => {
						APIAdminRequest('delete-subscription-type', { id: type.id }).then(response => {
							if (response.status) {
								const newTypes = [...types];
								newTypes.splice(newTypes.indexOf(type), 1);
								setTypes(newTypes);
							} else if (response.error) {
								UI.ShowNotification(response.error, UI.NOTIFICATION_TYPE_ERROR);
							} else {
								UI.ShowNotification(L10n.__("No se pudo eliminar el grupo de suscripción"), UI.NOTIFICATION_TYPE_ERROR);
							}
						});
					}
				}
			]}
			header={["", "", L10n.__("Nombre"), L10n.__("Precio"), L10n.__("Visitas por período"), L10n.__("Personas por reserva"), L10n.__("Combinable"), L10n.__("Permitir exceso"), L10n.__("Período"), L10n.__("Stock"), L10n.__("Renovable"), L10n.__("Pack")]}
			widths={[1, 1, 2, 1, 2, 2, 1, 1, 1, 2, 1, 1]}
			responsiveState={[true, true, true, false, false, false, false, true, false, false, false, false]}
			responsiveWidths={[1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
			alignment={["center", "center", "left", "center", "center", "center", "center", "center", "center", "center", "center", "right"]}
			items={types.map((type, type_idx) => ({
				data: type, columns: [
					<FormToggle className="enable-type" defaultValue={type.active} onChange={(value) => setTypes(types.map(t => (t.id === type.id ? { ...t, active: value } : t)))} />,
					
					<img className="icono-grupo" src={type.image_url} />,
					
					<input disabled={!type.active} value={type.name} onChange={e => alterTypes([{id: type.id,name: e.target.value}])} />,
					
					<Field disabled={!type.pack || !type.active} type="currency" defaultValue={type.price} onChange={value => alterTypes([{id: type.id,price: value}])}/>,

					<Field disabled={!type.pack || !type.active} min={1} type="number" defaultValue={type.visits} onChange={value => alterTypes([{id: type.id, visits:value}])} />,
					
					<div className="control-group"><FormToggle disabled={!type.pack || !type.active} defaultValue={type.pax != -1} onChange={value => alterTypes([{id:type.id,pax:value?1:-1}])} /><Field disabled={!type.pack || type.pax == -1 || !type.active} min={1} type="number" defaultValue={type.pax} onChange={value => alterTypes([{id: type.id, pax:value}])} /></div>,

					<FormToggle disabled={!type.pack || !type.active} defaultValue={type.combinable_visits == 1} onChange={value => alterTypes([{id:type.id,combinable_visits:(value?1:0)}])} />,

					<FormToggle disabled={!type.pack || !type.active} defaultValue={type.allow_extra_pax == 1} onChange={value => alterTypes([{id:type.id,allow_extra_pax:(value?1:0)}])} />,
					
					<select
						disabled={!type.pack || !type.active}
						defaultValue={type.interval_type+":"+type.interval}
						onChange={(e) => {
							alterTypes([{id:type.id,interval_type:e.target.value.split(":")[0],interval:e.target.value.split(":")[1]}]);
						}}>
						<option value={"month:12"}>{L10n.__("Anual")}</option>
						<option value={"month:6"}>{L10n.__("Semestral")}</option>
						<option value={"month:3"}>{L10n.__("Trimestral")}</option>
						<option value={"month:1"}>{L10n.__("Mensual")}</option>
						<option value={"day:1"}>{L10n.__("Diario")}</option>
					</select>,
					
					<div className="control-group"><FormToggle disabled={!type.pack || !type.active} defaultValue={type.stock != -1} onChange={value => alterTypes([{id:type.id,stock:value?1:-1}])} /><Field disabled={!type.pack || type.stock == -1 || !type.active} min={0} type="number" defaultValue={type.stock >= 0 ? type.stock : ""} onChange={value => alterTypes([{id:type.id,stock:value}])} /></div>,

					<FormToggle disabled={!type.pack || !type.active} defaultValue={type.renewable == 1} onChange={value => alterTypes([{id:type.id,renewable:(value?1:0)}])} />,
					
					<FormToggle tooltip={type.has_active_subscriptions && L10n.__("Hay suscripciones activas")} disabled={!type.active || type.has_active_subscriptions} defaultValue={type.pack == 1} onChange={value => alterTypes([{id:type.id,pack:(value?1:0)}])} />
				]
			}))}
			renderDetails={(type, type_idx) => <div className="type-container">
				<MaterialIcon name="delete" className="delete-type" tooltip={L10n.__("Borrar grupo")} onClick={e => {
					e.preventDefault();
					const newTypes = [...types];
					newTypes.splice(type_idx, 1);
					setTypes(newTypes);
				}} />
				<div className="subscription-info">
					<ImageUpload width={64} height={64} className="icono-grupo" onChange={value => alterTypes([{id:type.id,image_url:value}])} defaultValue={type.image_url} />
					<div>
						<label>{L10n.__("Título")}</label><input defaultValue={type.page_content.subscriptionInfo.title} onChange={e => {
							const newTypes = [...types];
							newTypes[type_idx].page_content.subscriptionInfo.title = e.target.value;
							setTypes(types);
						}} />
						<label>{L10n.__("Información sobre la suscripción")}</label><textarea defaultValue={type.page_content.subscriptionInfo.text} onChange={e => {
							const newTypes = [...types];
							newTypes[type_idx].page_content.subscriptionInfo.text = e.target.value;
							newTypes[type_idx].short_description = e.target.value;
							setTypes(types);
						}} />
						<label>{L10n.__("Información para suscriptores")}</label><textarea placeholder={L10n.__("Dejar en blanco para utilizar la misma.")} defaultValue={type.page_content.subscriptionInfoSubscribers?.text} onChange={e => {
							const newTypes = [...types];
							if (!newTypes[type_idx].page_content.subscriptionInfoSubscribers) {
								newTypes[type_idx].page_content.subscriptionInfoSubscribers = { title: "", text: "", buttonText: ""};
							}
							newTypes[type_idx].page_content.subscriptionInfoSubscribers.text = e.target.value;
							newTypes[type_idx].short_description_subscribers = e.target.value;
							setTypes(types);
						}} />
					</div>
				</div>
				<div className="sections">
					{type.page_content.sections.map((section, idx) => {
						return <div className="columns" key={"sections-" + type.id + "-" + idx }>
							{section.map((column, column_idx) => {
								switch (column.type) {
									default: return null;
									case "image":
										return <div className="section" key={"page-section-" + idx + "-column-" + column_idx}>
											{renderColumnOptions(type.id, section, column, idx, column_idx)}
											<ImageUpload onChange={value => alterTypeContent(type.id, idx, column_idx, {image_url:value})} defaultValue={column.image_url} />
										</div>;
									case "text":
										return <div className="section" key={"page-section-" + idx}>
											{renderColumnOptions(type.id, section, column, idx, column_idx)}
											<label>{L10n.__("Supertítulo")}</label><input value={column.supertitle || ""} onChange={e => alterTypeContent(type.id, idx, column_idx, {supertitle:e.target.value})} />
											<label>{L10n.__("Título")}</label><input value={column.title || ""} onChange={e => alterTypeContent(type.id, idx, column_idx, {title:e.target.value})} />
											<label>{L10n.__("Contenido")}</label><textarea value={column.content || ""} onChange={e => alterTypeContent(type.id, idx, column_idx, {content:e.target.value})} />
											<div style={{ position: "relative " }}>
												<label>{L10n.__("Iconos")}</label>
												<div className="inner-actions">
													<MaterialIcon name="add" tooltip="Añadir icono" onClick={e => {
														e.preventDefault();
														const newIcons = [...column.icons || []];
														newIcons.push("");
														alterTypeContent(type.id, idx, column_idx, {icons:newIcons});
													}} />
												</div>
											</div>
											<div className="icons-container">
												{!column.icons?.length && <span>{L10n.__("No hay ningún icono")}</span>}
												{column.icons?.map((url, icon_idx) => {
													return <div key={"icon-" + column_idx + "-" + url }><a href="#" className="delete-icon" onClick={e => {
														e.preventDefault();
														const newIcons = [...column.icons];
														newIcons.splice(icon_idx, 1);
														alterTypeContent(type.id, idx, column_idx, {icons:newIcons});
													}}>&times;</a><ImageUpload width={64} height={64} value={url} onChange={value => {
														const newIcons = [...column.icons];
														newIcons[icon_idx] = value;
														alterTypeContent(type.id, idx, column_idx, {icons:newIcons});
													}} /></div>;
												})}
											</div>
											<label className="show-cta"><FormToggle defaultValue={column.showButton} onChange={value => alterTypeContent(type.id, idx, column_idx, {showButton:value})} /> Mostrar CTA</label>
										</div>;
									case "faq":
										return <div className="section" key={"page-section-" + idx}>
											{renderColumnOptions(type.id, section, column, idx, column_idx)}
											<label>{L10n.__("Título")}</label><input value={column.title || ""} onChange={e => alterTypeContent(type.id, idx, column_idx, {title:e.target.value})} />
											<br />
											<div style={{ position: "relative " }}>
												<label>{L10n.__("Preguntas y respuestas")}</label>
												<div className="inner-actions">
													<MaterialIcon name="add" tooltip="Añadir pregunta" onClick={e => {
														e.preventDefault();
														const newFaq = [...column.faq];
														newFaq.push({question:"",answer:""});
														alterTypeContent(type.id, idx, column_idx, {faq:newFaq});
													}} />
												</div>
											</div>
											<div className="section-faq">
												{!column.faq?.length && <span>{L10n.__("No hay ninguna pregunta")}</span>}
												{column.faq.map((item, item_idx) => {
													return <div className="faq-item" key={"faq-item-" + item_idx + "-" + column.faq.length}>
														<a href="#" className="delete-faq" onClick={e => {
															e.preventDefault();
															const newFaq = [...column.faq];
															newFaq.splice(item_idx, 1);
															alterTypeContent(type.id, idx, column_idx, {faq:newFaq});
														}}>&times;</a>
														<div className="question"><input placeholder="Pregunta" value={item.question} onChange={e => {
															const newFaq = [...column.faq];
															newFaq[item_idx].question = e.target.value;
															alterTypeContent(type.id, idx, column_idx, {faq:newFaq});
														}} /></div>
														<div className="answer"><textarea placeholder="Respuesta" value={item.answer} onChange={e => {
															const newFaq = [...column.faq];
															newFaq[item_idx].answer = e.target.value;
															alterTypeContent(type.id, idx, column_idx, {faq:newFaq});
														}}/></div>
													</div>;
												})}
											</div>
										</div>;
								}
							})}
						</div>;
					})}
				</div>
				<a href="#" className="new-section" onClick={e => {
					e.preventDefault();
					const newTypes = [...types];
					newTypes[type_idx].page_content.sections.push([{ type: "text" }]);
					setTypes(newTypes);
				}}><MaterialIcon name="add" /></a>
			</div>}
		/>
	</div >;
}
