import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Session from '../services/Session';

export default function AuthGuard(props) {
    const [ loaded, setLoaded ] = useState(false);
    
    const navigate: any = useNavigate();

    useEffect(() => {
        Session.CheckIsLoggedIn().then(loggedIn => {
            if (loggedIn) {
                setLoaded(true);
            } else {
                Session.RequireAccount(location.pathname, navigate);
            }
        });
    }, []);

    if (!loaded) return;
    return props.children;
}