import "../../css/home.css";

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Footer from "./Footer.tsx";
import LoadingIndicator from "./LoadingIndicator.tsx";
import L10n from "../services/Locale";
import Helper from "../services/Helper.tsx";
import { useParams, useNavigate, Link } from "react-router-dom";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import { APIRequest } from "../services/API.ts";
import SubscriptionTypesBar from "./SubscriptionTypesBar";
import WidgetFiltro from "./WidgetFiltro";
import ContentHelper from "../services/ContentHelper.tsx";
import Settings from "../services/Settings.ts";

import SubscriptionsService from "../services/Subscriptions.tsx";

export default function HomeSuscripcion() {
	const [ciudades, setCiudades] = useState([]);
	const [loading, setLoading] = useState(true);
	const [negocios, setNegocios] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [filteredNegocios, setFilteredNegocios] = useState([]);
	const [subscriptionTypes, setSubscriptionTypes] = useState([]);
	const [ciudad, setCiudad] = useState(null);
	const [priceIndex, setPriceIndex] = useState(-1);
	const [subscriptionInfoBoxes, setSubscriptionInfoBoxes] = useState(null);
	const [filtersActive, setFiltersActive] = useState(false);
	const [fadeOutTypesBar, setFadeOutTypesBar] = useState(true);

	const typesBarTransitionInProgress = useRef(false);
	const params = useParams();
	const navigate = useNavigate();
	const widgetFiltroResetRef = useRef();
	const widgetFiltroFilterRef = useRef();

	const subscriptionsContext = useContext(SubscriptionsContext);
	const [activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex] = useState(-1);

	if (!subscriptionsContext?.subscriptionsEnabled) return null;

	const setSelectedSubscriptionType = (selectedSubscription) => {
		if (!selectedSubscription) return;
		
		SubscriptionsService.SetCartData({
			typeName: selectedSubscription.name,
			typeSlug: selectedSubscription.slug,
			typeID: selectedSubscription.id,
			typeMode: selectedSubscription.pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO
		});
	};

	const changeSubscriptionTypesWithTransition = (newTypes) => {
		return new Promise((resolve, _reject) => {

			let same = true;

			for (let i = 0; i < newTypes.length; i++) {
				if (subscriptionTypes.length > i && newTypes[i].id != subscriptionTypes[i].id) {
					same = false;
					break;
				}
			}

			if (same) {
				setFadeOutTypesBar(false);
				setSubscriptionTypes(newTypes);
				return resolve();
			}

			clearTimeout(typesBarTransitionInProgress.current);
			setFadeOutTypesBar(true);
			typesBarTransitionInProgress.current = setTimeout(() => {
				setSubscriptionTypes(newTypes);
				setTimeout(() => {
					setFadeOutTypesBar(false);
					setTimeout(() => {
						typesBarTransitionInProgress.current = false;
						resolve();
					}, 300);
				}, 50);
			}, 300);
		});
	};

	useEffect(() => {
		let newSubscriptionTypes = [...subscriptionsContext?.subscriptionTypes];
		newSubscriptionTypes.sort((a, b) => {
			let hasA, hasB;

			hasA = subscriptionsContext.userSubscriptions?.filter((s) => s.subscription_type_id == a.id).length > 0;
			hasB = subscriptionsContext.userSubscriptions?.filter((s) => s.subscription_type_id == b.id).length > 0;

			if (hasA && hasB) {
				if (a.menu_order < b.menu_order) return -1;
				if (a.menu_order > b.menu_order) return 1;
				return 0;
			}

			if (hasA && !hasB) return -1;
			if (!hasA && hasB) return 1;

			return 0;
		});

		let same = true;
		for (let i = 0; i < newSubscriptionTypes.length; ++i) {
			if (i >= subscriptionTypes.length || newSubscriptionTypes[i]?.id != subscriptionTypes[i]?.id) same = false;
		}

		if (!same || loading) {
			APIRequest("subscriptions/get-events-for-types", { subscription_type_ids: newSubscriptionTypes.map((t) => t.id).join(",") }).then((response) => {
				Object.keys(response.data).forEach((typeID) => {
					response.data[typeID].forEach((negocio) => {
						if (!negocio.subscription_type_ids) negocio.subscription_type_ids = [];
						negocio.subscription_type_ids.push(parseInt(typeID));
						negocio.iconos = JSON.parse(negocio.iconos);
						negocio.iconos_detalle = JSON.parse(negocio.iconos_detalle);

						const type = newSubscriptionTypes.find((t) => t.id == typeID);

						if (type) {
							if (type.pack == 1) {
								negocio.min_price = type.price;
							} else {
								negocio.min_price = Infinity;
								type.events.filter(ev => ev.negocio_id == negocio.id).forEach(event => {
									negocio.min_price = Math.min(negocio.min_price, event.event_subscription_data.price);
								});
							}
						}
					});
				});

				newSubscriptionTypes.forEach((type, idx) => {
					type.original_order = idx;
					type.available = true;
				});

				/**** */

				const filteredNegocios = Object.keys(response.data).reduce((elements, typeID) => elements.concat(response.data[typeID]), []);

				newSubscriptionTypes.forEach((subscriptionType) => {
					subscriptionType.available = false;
					subscriptionType.events.forEach((event) => {
						filteredNegocios.forEach((negocio) => {
							if (event.negocio_id == negocio.id && negocio.subscription_type_ids.includes(subscriptionType.id)) subscriptionType.available = true;
						});
					});
				});
		
				if (ciudad) newSubscriptionTypes.forEach((subscriptionType) => {
					if (!subscriptionType.available) {
						return;
					}
		
					subscriptionType.available = false;
					filteredNegocios.forEach((negocio) => {
						if (negocio.city == ciudad && negocio.subscription_type_ids.includes(subscriptionType.id)) subscriptionType.available = true;
					});
				});
		
				newSubscriptionTypes.sort((a, b) => {
					if (a.available && !b.available) return -1;
					if (!a.available && b.available) return 1;
					if (filtersActive) {
						const a_min_price = filteredNegocios.filter(negocio => a.events.filter(evento => evento.negocio_id == negocio.id).length > 0).reduce((acc, negocio) => Math.min(acc, negocio.min_price), Infinity);
						const b_min_price = filteredNegocios.filter(negocio => b.events.filter(evento => evento.negocio_id == negocio.id).length > 0).reduce((acc, negocio) => Math.min(acc, negocio.min_price), Infinity);
						if (a_min_price < b_min_price) return -1;
						if (a_min_price > b_min_price) return 1;
					}
					if (a.menu_order < b.menu_order) return -1;
					if (a.menu_order > b.menu_order) return 1;
					return 0;
				});

				/**** */

				changeSubscriptionTypesWithTransition(newSubscriptionTypes.filter(t => {
					if (subscriptionsContext.userSubscriptions.filter(us => us.subscription_type_id == t.id).length > 0) {
						return true;
					}
					if (!t.pack && t.events.filter(ev => ev.event_subscription_data).length == 0) {
						return false;
					}
					if ((t.pack && t.stock == 0) || (!t.pack && t.events.filter(ev => ev.event_subscription_data?.stock != 0).length == 0)) {
						return false;
					}
					return true;
				})).then(() => {
					let negocios = response.data;
					for (let typeID of Object.keys(negocios)) {
						negocios[typeID] = negocios[typeID].filter((negocio) => {
							return negocio.events?.filter(ev => ev.availability?.length > 0 || ev.no_calendar).length > 0;
						});
					}
					setNegocios(negocios);
					setTimeout(() => {
						setFilteredNegocios(Object.keys(response.data).reduce((elements, typeID) => elements.concat(response.data[typeID]), []));
					}, 100);

					setCiudades(Object.keys(response.data).reduce((ciudades, typeID) => ciudades.concat(response.data[typeID].map(negocio => negocio.ciudad)), []));

					setLoading(false);
				});
			});
		}

		if (params?.subscription_type_slug && newSubscriptionTypes.find((t) => t.slug == params.subscription_type_slug)) {
			setActiveSubscriptionTypeIndex(newSubscriptionTypes.findIndex((t) => t.slug == params.subscription_type_slug));
			setSelectedSubscriptionType(newSubscriptionTypes.find((t) => t.slug == params.subscription_type_slug));
		} else {
			setActiveSubscriptionTypeIndex(0);
			setSelectedSubscriptionType(newSubscriptionTypes[0]);
		}
	}, [subscriptionsContext]);

	useEffect(() => {
		subscriptionsContext?.reload();
		
		ContentHelper.GetJSON("subscription-home-info-boxes").then((content) => {
			setSubscriptionInfoBoxes(content);
		});

		Settings.Get('SHOW_FILTER_HOME', 1).then((value) => {
			setShowFilter(value == 1);
		});
	}, []);

	useEffect(() => {
		let newSubscriptionTypes = [...subscriptionTypes];

		newSubscriptionTypes.forEach((subscriptionType) => {
			subscriptionType.available = false;
			subscriptionType.events.forEach((event) => {
				filteredNegocios.forEach((negocio) => {
					if (event.negocio_id == negocio.id && negocio.subscription_type_ids.includes(subscriptionType.id)) subscriptionType.available = true;
				});
			});
		});

		if (ciudad) newSubscriptionTypes.forEach((subscriptionType) => {
			if (!subscriptionType.available) {
				return;
			}

			subscriptionType.available = false;
			filteredNegocios.forEach((negocio) => {
				if (negocio.city == ciudad && negocio.subscription_type_ids.includes(subscriptionType.id)) subscriptionType.available = true;
			});
		});

		newSubscriptionTypes.sort((a, b) => {
			if (a.available && !b.available) return -1;
			if (!a.available && b.available) return 1;
			if (filtersActive) {
				const a_min_price = filteredNegocios.filter(negocio => a.events.filter(evento => evento.negocio_id == negocio.id).length > 0).reduce((acc, negocio) => Math.min(acc, negocio.min_price), Infinity);
				const b_min_price = filteredNegocios.filter(negocio => b.events.filter(evento => evento.negocio_id == negocio.id).length > 0).reduce((acc, negocio) => Math.min(acc, negocio.min_price), Infinity);
				if (a_min_price < b_min_price) return -1;
				if (a_min_price > b_min_price) return 1;
			}
			if (a.menu_order < b.menu_order) return -1;
			if (a.menu_order > b.menu_order) return 1;
			return 0;
		});

		changeSubscriptionTypesWithTransition(newSubscriptionTypes);
	}, [ filteredNegocios, filtersActive ]);

	useEffect(() => {
		if (activeSubscriptionTypeIndex < 0 || activeSubscriptionTypeIndex >= subscriptionTypes.length) return;
		setSelectedSubscriptionType(subscriptionTypes[activeSubscriptionTypeIndex]);
	}, [ activeSubscriptionTypeIndex ]);

	if (loading) return <LoadingIndicator />;

	const noAvailability = (ciudad || priceIndex != -1) && filteredNegocios.length == 0;
	const filteredNegociosSuscripcion = filteredNegocios.filter(negocio => negocio.subscription_type_ids.includes(subscriptionTypes[activeSubscriptionTypeIndex]?.id));
	const isSubscribedPack = subscriptionTypes[activeSubscriptionTypeIndex]?.pack && subscriptionsContext?.userSubscriptions?.find(us => us.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex]?.id);

	return (
		<div className="home home-suscripcion">
			{showFilter && <WidgetFiltro
				ciudades={ciudades}
				elements={Object.keys(negocios).reduce((elements, typeID) => elements.concat(negocios[typeID]), [])}
				onFiltered={(filtered, filtersActive, ciudad, fecha, pax, priceIndex) => {
					setFilteredNegocios(filtered);
					setCiudad(ciudad);
					setPriceIndex(priceIndex);
					setFiltersActive(filtersActive);
				}}
				resetRef={(ref) => (widgetFiltroResetRef.current = ref)}
				filterRef={(ref) => (widgetFiltroFilterRef.current = ref)}
				disablePriceFilter={false}
				disablePaxFilter={true}
				disableDateFilter={true}
			/>}

			{subscriptionTypes?.length > 0 && (
				<SubscriptionTypesBar
					style={{opacity: fadeOutTypesBar ? 0 : 1}}
					className={showFilter ? 'with-filter': ''}
					onChange={(idx) => {
						setActiveSubscriptionTypeIndex(idx);
					}}
					initialValue={activeSubscriptionTypeIndex}
					subscriptionTypes={subscriptionTypes}
				/>
			)}

			{!noAvailability && subscriptionTypes[activeSubscriptionTypeIndex] && (
				<div style={{minHeight: 133,opacity:0,animation:"1s fadeinslidedown 550ms forwards"}}>
					<div style={{ opacity: fadeOutTypesBar ? 0 : 1 }} className={"subscription-description has-button"}>
						<Link to={"/" + (isSubscribedPack ? "reserva" : "suscribete") + "/t/" + subscriptionTypes[activeSubscriptionTypeIndex]?.slug} className="cta btn btn-small">
							{L10n.__("+ Info")}
						</Link>
						<div>{subscriptionTypes[activeSubscriptionTypeIndex]?.short_description_subscribers || subscriptionTypes[activeSubscriptionTypeIndex]?.short_description}</div>
					</div>
					<h2 className="selected-subscription-name">{subscriptionTypes[activeSubscriptionTypeIndex]?.name}</h2>
				</div>
			)}

			{noAvailability && (
				<div style={{ opacity: fadeOutTypesBar ? 0 : 1 }} className="subscription-description no-subscriptions">
					<div>
						<div>{L10n.__("No se ha encontrado disponibilidad con esta combinación.")}</div>
					</div>
				</div>
			)}

			<div className="negocios-container">
				<section className={"top-list bg-offwhite" + (window.innerWidth < 992 ? " responsive" : "")} style={{ width: window.innerWidth < 992 && filteredNegociosSuscripcion.filter(negocio => negocio.events.length > 0).length > 1 ? filteredNegociosSuscripcion.filter(negocio => negocio.events.length > 0).length * window.innerWidth * 0.9 : null }}>
					{filteredNegociosSuscripcion.filter(negocio => negocio.events.length > 0).map((negocio, negocioIdx) => {

						let remainingVisits = 0;
						let totalVisits = 0;

						subscriptionsContext.userSubscriptions?.forEach((userSubscription) => {
							if (userSubscription.events?.filter(ev => ev.no_calendar != 1).length == 0) return;
							
							if (subscriptionTypes[activeSubscriptionTypeIndex]?.pack) {
								if (userSubscription.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex]?.id) {
									if (userSubscription.remaining_visits > remainingVisits) {
										remainingVisits = userSubscription.remaining_visits * userSubscription.pax;
									}
									totalVisits = userSubscription.visits * userSubscription.pax;
								}
							} else {
								if (userSubscription.events?.filter((ev) => ev.negocio_id == negocio.id).length) {
									if (userSubscription.remaining_visits > remainingVisits) {
										remainingVisits = userSubscription.remaining_visits * userSubscription.pax;
									}
									totalVisits = userSubscription.visits * userSubscription.pax;
								}
							}
						});

						let intervalLabel = "mes";
						
						if (subscriptionTypes[activeSubscriptionTypeIndex]?.pack) {
							intervalLabel = Helper.GetIntervalLabel(subscriptionTypes[activeSubscriptionTypeIndex]?.interval, subscriptionTypes[activeSubscriptionTypeIndex]?.interval_type);
						} else {
							subscriptionTypes[activeSubscriptionTypeIndex]?.events?.forEach(ev => {
								if (ev.negocio_id == negocio.id) {
									intervalLabel = Helper.GetIntervalLabel(ev.event_subscription_data.interval, ev.event_subscription_data.interval_type);
								}
							});
						}

						return (
							<div
								className="negocio cover-zoom-listener"
								style={{ width: window.innerWidth < 992 && filteredNegociosSuscripcion.length > 1 ? window.innerWidth * 0.9 : null }}
								key={negocio.id}
								onClick={(e) => {
									e.preventDefault();
									SubscriptionsService.SetCartData({
										typeName: subscriptionTypes[activeSubscriptionTypeIndex]?.name,
										typeSlug: subscriptionTypes[activeSubscriptionTypeIndex]?.slug,
										typeID: subscriptionTypes[activeSubscriptionTypeIndex]?.id,
										typeMode: subscriptionTypes[activeSubscriptionTypeIndex]?.pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO
									});
									navigate(BASENAME + "/suscripcion/" + subscriptionTypes[activeSubscriptionTypeIndex]?.slug + "/" + negocio.slug);
								}}>
								<div className="image cover-zoom" style={{ backgroundImage: "url(" + negocio.image_url + ")" }}>
									{totalVisits > 0 && (
										<div className="tag-visitas has-tooltip inhibit-clicks" data-tooltip={(remainingVisits == 1 ? L10n.__("Te queda") : L10n.__("Te quedan")) + " " + remainingVisits + " " + (remainingVisits == 1 ? L10n.__("visita") : L10n.__("visitas"))}>
											{remainingVisits}/{totalVisits} visitas
										</div>
									)}
									{totalVisits > 0 && remainingVisits == 0 && (
										<div className="tag-out-of-visits inhibit-clicks has-tooltip" data-tooltip={L10n.__("No te quedan visitas en tu suscripción.")}>
											{L10n.__("Límite superado")}
										</div>
									)}
									<div className="iconos">
										{negocio.iconos.map((icono, iconoIdx) => {
											return <img key={icono.image_url} src={icono.image_url} className="has-tooltip icono inhibit-clicks" data-tooltip={icono.description} />;
										})}
									</div>
								</div>
								<div className="name">{negocio.name}</div>
								<div className="city">{negocio.city}</div>
								<div className="iconos-detalle">
									{negocio.iconos_detalle.map((icono, iconoIdx) => {
										return <img src={icono.image_url} key={icono.image_url} className="has-tooltip icono inhibit-clicks" data-tooltip={icono.titulo} />;
									})}
								</div>
								{negocio.min_price != -1 && negocio.min_price != Infinity && !subscriptionsContext.isSubscribedToNegocio(subscriptionTypes[activeSubscriptionTypeIndex], negocio) && 
								<div className="lower">
										<div className="minimum-price">
											<div>
												{L10n.__(subscriptionTypes[activeSubscriptionTypeIndex]?.pack == 1 ? "Suscripción pack" : "Suscríbete desde")} <span dangerouslySetInnerHTML={{ __html: Helper.FormatAmount(negocio.min_price / 100, false, true, true) }} />{!!subscriptionTypes[activeSubscriptionTypeIndex].renewable && ("/" + intervalLabel)}
											</div>
										</div>
								</div>}
							</div>
						);
					})}
				</section>
			</div>

			{subscriptionInfoBoxes !== null && (
				<div className="subscription-info-boxes">
					<div className="image" style={{ backgroundImage: "url(" + subscriptionInfoBoxes[0] + ")" }} />
					<div>
						<h2>{subscriptionInfoBoxes[1].title}</h2>
						<div dangerouslySetInnerHTML={{ __html: subscriptionInfoBoxes[1].text }} />
					</div>
				</div>
			)}

			<Footer />
		</div>
	);
}
