import { useEffect, useState, useContext, useCallback } from "react";
import Bloque from "./AdminBloque";
import Field from "./Field";
import L10n from "../services/Locale";

import { DialogContext } from "../context/DialogContext";

import AdminService from "../services/Admin";
import AdminBar from "./AdminBar";

export default function AdminEmails(props) {
	const [welcomeText, setWelcomeText] = useState(null);
	const [emailChangeText, setEmailChangeText] = useState(null);
	const [passwordChangeText, setPasswordChangeText] = useState(null);
	const [orderBeforeDetails, setOrderBeforeDetails] = useState(null);
	const [orderAfterDetails, setOrderAfterDetails] = useState(null);
	const [orderBeforeDetailsNegocio, setOrderBeforeDetailsNegocio] = useState(null);
	const [orderAfterDetailsNegocio, setOrderAfterDetailsNegocio] = useState(null);
	const [orderBeforeDetailsAdmin, setOrderBeforeDetailsAdmin] = useState(null);
	const [orderAfterDetailsAdmin, setOrderAfterDetailsAdmin] = useState(null);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		AdminService.GetContentMultiple(["email-bienvenida", "email-cambio-email", "email-cambio-contrasena", "email-detalles-antes", "email-detalles-despues", "email-detalles-antes-negocio", "email-detalles-despues-negocio", "email-detalles-antes-admin", "email-detalles-despues-admin"], "").then(response => {
			setWelcomeText(response["email-bienvenida"] || "");
			setEmailChangeText(response["email-cambio-email"] || "");
			setPasswordChangeText(response["email-cambio-contrasena"] || "");
			setOrderBeforeDetails(response["email-detalles-antes"] || "");
			setOrderAfterDetails(response["email-detalles-despues"] || "");
			setOrderBeforeDetailsNegocio(response["email-detalles-antes-negocio"] || "");
			setOrderAfterDetailsNegocio(response["email-detalles-despues-negocio"] || "");
			setOrderBeforeDetailsAdmin(response["email-detalles-antes-admin"] || "");
			setOrderAfterDetailsAdmin(response["email-detalles-despues-admin"] || "");
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			AdminService.SaveContentMultiple({
				"email-bienvenida": welcomeText,
				"email-cambio-email": emailChangeText,
				"email-cambio-contrasena": passwordChangeText,
				"email-detalles-antes": orderBeforeDetails,
				"email-detalles-despues": orderAfterDetails,
				"email-detalles-antes-negocio": orderBeforeDetailsNegocio,
				"email-detalles-despues-negocio": orderAfterDetailsNegocio,
				"email-detalles-antes-admin": orderBeforeDetailsAdmin,
				"email-detalles-despues-admin": orderAfterDetailsAdmin
			}).then(() => {
				notifySaved(true);
			});
		});
	}, [ welcomeText, emailChangeText, passwordChangeText, orderBeforeDetails, orderAfterDetails, orderBeforeDetailsNegocio, orderAfterDetailsNegocio, orderBeforeDetailsAdmin, orderAfterDetailsAdmin ]);

	return (
		<div className="admin-page admin-emails">
			<AdminBar onSave={save} />
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Email de bienvenida")}</h2>
					{welcomeText !== null && <Field type="text" onChange={(value) => setWelcomeText(value)} defaultValue={welcomeText} />}
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Cambio de contraseña")}</h2>
					{passwordChangeText !== null && <Field type="text" onChange={(value) => setPasswordChangeText(value)} defaultValue={passwordChangeText} />}
				</div>
				<div className="columna">
					<h2>{L10n.__("Cambio de email")}</h2>
					{emailChangeText !== null && <Field type="text" onChange={(value) => setEmailChangeText(value)} defaultValue={emailChangeText} />}
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Introducción al email de nuevo pedido (cliente)")}</h2>
					{orderBeforeDetails !== null && <Field type="text" onChange={(value) => setOrderBeforeDetails(value)} defaultValue={orderBeforeDetails} />}
				</div>
				<div className="columna">
					<h2>{L10n.__("Texto en el email de nuevo pedido (cliente)")}</h2>
					{orderAfterDetails !== null && <Field type="text" onChange={(value) => setOrderAfterDetails(value)} defaultValue={orderAfterDetails} />}
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Introducción al email de nuevo pedido (negocio)")}</h2>
					{orderBeforeDetailsNegocio !== null && <Field type="text" onChange={(value) => setOrderBeforeDetailsNegocio(value)} defaultValue={orderBeforeDetailsNegocio} />}
				</div>
				<div className="columna">
					<h2>{L10n.__("Texto en el email de nuevo pedido (negocio)")}</h2>
					{orderAfterDetailsNegocio !== null && <Field type="text" onChange={(value) => setOrderAfterDetailsNegocio(value)} defaultValue={orderAfterDetailsNegocio} />}
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>{L10n.__("Introducción al email de nuevo pedido (administrador)")}</h2>
					{orderBeforeDetailsAdmin !== null && <Field type="text" onChange={(value) => setOrderBeforeDetailsAdmin(value)} defaultValue={orderBeforeDetailsAdmin} />}
				</div>
				<div className="columna">
					<h2>{L10n.__("Texto en el email de nuevo pedido (administrador)")}</h2>
					{orderAfterDetailsAdmin !== null && <Field type="text" onChange={(value) => setOrderAfterDetailsAdmin(value)} defaultValue={orderAfterDetailsAdmin} />}
				</div>
			</Bloque>
		</div>
	);
}
