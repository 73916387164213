import "../../css/home.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "./Footer";
import ImagenFondo from "./ImagenFondo";
import { Content, ContentImage, GetJSON } from "./Content";
import Slider from "./Slider";
import Events from "../services/Events";
import Settings from "../services/Settings";
import LoadingIndicator from "./LoadingIndicator";
import WidgetFiltro from "./WidgetFiltro";
import SearchResultsProvider, { ISearchResult } from "../services/SearchResultsProvider";
import Video from "./Video";
import L10n from "../services/Locale";
import { SettingsContext } from "../context/SettingsContext";
import Viviendas from "../services/Viviendas";
import Trabajo from "../services/Trabajo";

export default function Home({contentMode}) {
	const [homeInformationFirst, setHomeInformationFirst] = useState();
	const [slidesConfig, setSlidesConfig] = useState([]);
	const [filteredResults, setFilteredResults] = useState<ISearchResult[]>([]);
	const [venues, setVenues] = useState<ISearchResult[]>([]);
	const [ciudades, setCiudades] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	const [videoPlayed, setVideoPlayed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [eventsLoading, setEventsLoading] = useState(true);
	const [showVideo, setShowVideo] = useState(null);
	const [showSlider, setShowSlider] = useState(true);
	const [showFilter, setShowFilter] = useState(false);
	const [showInformationSection, setShowInformationSection] = useState(true);

	const searchResultsProvider = useRef<SearchResultsProvider>(new SearchResultsProvider(venues, 3));

	const settingsContext = useContext(SettingsContext);

	function toggleVideoPlay() {
		const $video = $(".step-video video");
		if ($video.hasClass("playing")) {
			pauseVideo();
		} else {
			playVideo();
		}
	}

	async function playVideo() {
		const $video = $(".step-video video");
		const video: HTMLVideoElement = $video[0] as HTMLVideoElement;

		if (!video) return;

		$video.off("click").on("click", function (e) {
			if ($video.hasClass("playing")) {
				$video.addClass("forced-pause");
			}
			toggleVideoPlay();
		});

		if (!$video.hasClass("playing")) {
			try {
				await video.play();
				$video.addClass("playing").removeClass("forced-pause");
				setVideoPlayed(true);
			} catch (error) {
				$video.removeClass("playing");
				setVideoPlayed(false);
			}
		}
	}

	function pauseVideo() {
		const video: HTMLVideoElement = $(".step-video video")[0] as HTMLVideoElement;

		if (!video) return;

		video.pause();
		video.classList.remove("playing");
	}

	function onScroll(_e: any) {
		const $video = $(".step-video video");

		if (!$(".step-video").length) {
			$(window).off("scroll", onScroll);
			return;
		}

		const videoTop = $(".step-video").offset()?.top || 0;
		const videoBottom = videoTop + ($(".step-video").height() || 0);
		const windowTop = $(window).scrollTop() || 0;
		const windowBottom = windowTop + window.innerHeight;

		if (windowBottom > videoTop && videoBottom > windowTop) {
			if (!$video.hasClass("forced-pause")) {
				playVideo();
			}
		} else {
			pauseVideo();
		}
	}

	function renderInformationSection() {
		if (!showInformationSection) return <section className="information" style={{ margin: 0 }} />;

		return (
			<section className="information">
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-1" />
					<div className="celda">
						<Content slug="home-paso-1-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-subtitulo-paso-1" />
						</h2>
						<Content slug="home-texto-paso-1" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-1-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-3" />
							</div>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="celda">
						<Content slug="home-paso-2-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-2" />
						</h2>
						<Content slug="home-subtitulo-paso-2" containerClassName="p" />
						<Content slug="home-texto-paso-2" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-2-logo-1" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-2" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-3" containerClassName="steps-3-logo" />
							</div>
						</div>
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-2-logo-4" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-5" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-6" containerClassName="steps-3-logo" />
							</div>
						</div>
					</div>
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-2" />
				</div>
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-3" />
					<div className="celda">
						<Content slug="home-paso-3-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-3" />
						</h2>
						<Content slug="home-subtitulo-paso-3" containerClassName="p" />
						<Content slug="home-texto-paso-3" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-3-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-3" />
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	useEffect(() => {
		searchResultsProvider.current?.setElementList(venues);
		searchResultsProvider.current.search("", null, 1);
		setFilteredResults(venues);
	}, [venues]);

	useEffect(() => {
		if (!loading) setEventsLoading(false);
	}, [filteredResults, loading])

	useEffect(() => {
		setLoading(true);

		Promise.all([Settings.Get("SHOW_FILTER_HOME", true), Settings.Get("SHOW_HOME_VIDEO", false), Settings.Get("SHOW_HOME_INFORMATION_SECTION", true), Settings.Get("HOME_INFORMATION_FIRST", 0), GetJSON("configuracion-slides"), Events.GetCities()]).then(([showFilter, showVideo, showInformationSection, homeInformationFirst, slidesJSON, cities]) => {
			setShowFilter(showFilter == 1);
			setShowVideo(showVideo == 1);
			setShowInformationSection(showInformationSection == 1);
			setHomeInformationFirst(homeInformationFirst == 1);
			setSlidesConfig(slidesJSON);
			setCiudades(cities);

			Promise.all([
				Events.GetAll(),
				Viviendas.GetAll(),
				Trabajo.GetAll()
			]).then(async ([negocios, vendedores, empleadores]) => {
				let venues = [];

				if (contentMode == "experiencias") {
					venues = [...negocios];
				} else {
					venues = [...vendedores, ...empleadores].map(x => {
						if (x.ofertas_trabajo) x.events = x.ofertas_trabajo;
						if (x.viviendas) x.events = x.viviendas;
						return x;
					});
				}

				for (let i = 0; i < venues.length; i++) {
					for (let j = 0; j < venues[i].events.length; j++) {
						venues[i].events[j].availability = [];
						venues[i].events[j].calendar = {};
						venues[i].events[j].loadAlways = false;

						venues[i].events[j].oferta_trabajo = !!venues[i].ofertas_trabajo;
						venues[i].events[j].vivienda = !!venues[i].viviendas;

						if (venues[i].ofertas_trabajo || venues[i].viviendas) {
							venues[i].events[j].no_calendar = true;
						} else {
							const response = await Events.GetAvailability(venues[i].events[j].id);
							venues[i].events[j].availability = response.availability;
							venues[i].events[j].calendar = response.calendar;
						}
					}
				}

				setVenues(venues);

				searchResultsProvider.current?.setShowAveragePrice(true);

				setLoading(false);
			});
		});

		jQuery(function ($) {
			$(window).on("scroll", onScroll);
		});

		$("body").removeClass("modal-open, scroll-lock");

		return () => {
			$(window).off("scroll", onScroll);
			$("body").removeClass("modal-open, scroll-lock");
		};
	}, [contentMode]);

	if (loading) return <LoadingIndicator />;

	searchResultsProvider.current?.resetRenderingOffsets();
	searchResultsProvider.current?.resetTitleRendering();

	return (
		<div className={"home" + (homeInformationFirst ? " information-first" : "")}>
			{!!showSlider && (
				<Slider
					style={{
						opacity: slidesConfig.length ? 1 : 0
					}}
					effect="fade"
					delay={5000}
					slides={slidesConfig}
				/>
			)}

			{!!showFilter && !homeInformationFirst && venues?.length > 0 && (
				<WidgetFiltro
					ciudades={ciudades}
					elements={venues}
					resultsProvider={searchResultsProvider.current}
					onFiltered={(filtered: ISearchResult[], filtersActive: boolean) => {
						setFilteredResults(filtered);
						setFiltersActive(filtersActive);
					}}
					onFilterChanged={(_ciudad, _fecha, _pax, _priceIndex, _kind) => { }}
					disableKindFilter={contentMode != "vivienda-y-empleo"}
					disableDateFilter={contentMode == "vivienda-y-empleo" || settingsContext?.date_filter_disabled}
					disablePaxFilter={contentMode == "vivienda-y-empleo" || settingsContext?.pax_filter_disabled}
					disablePriceFilter={contentMode == "vivienda-y-empleo" || !settingsContext?.price_filter_disabled}
				/>
			)}

			{!!homeInformationFirst && filtersActive == false && renderInformationSection()}

			<section className="top-list bg-offwhite" style={{ opacity: eventsLoading ? 0 : 1 }}>
				{searchResultsProvider.current?.getResults().length == 0 && !eventsLoading && <div className="no-results">{L10n.__("No se ha encontrado disponibilidad con esta combinación.")}</div>}
				{!!filtersActive && searchResultsProvider.current?.renderDefaultRows(true, false, 100)}
				{searchResultsProvider.current?.renderDefaultRows(!filtersActive, false, 2)}
			</section>

			{!homeInformationFirst && filtersActive == false && renderInformationSection()}

			<section className="top-list bg-offwhite">{searchResultsProvider.current?.resetTitleRendering() && searchResultsProvider.current?.renderDefaultRows(!filtersActive)}</section>

			{!!showVideo && (
				<div className="row step bg-offgrey step-video">
					<div className="steps-inner">
						<Video slug="video-home" />
						{!videoPlayed && (
							<div
								className="play-button"
								onClick={(e) => {
									e.preventDefault();
									playVideo();
								}}
							/>
						)}
					</div>
				</div>
			)}

			<Footer />
		</div>
	);
}
