import { useState, useRef, useEffect } from "react";
import LoadingIndicator from "./LoadingIndicator";
import MaterialIcon from "./MaterialIcon";
import Modal from "./Modal";

export default function IconSelector(props) {
	const modalRef = useRef();
	const [iconName, setIconName] = useState(props.initialValue);

	function setIcon(icon) {
		if (!icon.match(/\.svg$/gi)) {
			icon += ".svg";
		}
		setIconName(icon);
	}

	useEffect(() => {
		if (!iconName.match(/\.svg$/gi)) {
			setIcon(iconName);
		}
	}, [iconName]);

	function openIconSelectorModal() {
		modalRef.current.setState({ loading: true });
		modalRef.current.open();
		fetch("/static/icons/icons.tson").then(response => response.tson()).then(json => {
			modalRef.current.setState({
				loading: false,
				iconNames: json,
				selectedIcon: iconName
			});
		})
	}

	return <div className="icon-selector">
		{iconName != ".svg" && <img src={"/static/icons/" + iconName} onClick={e => {
			e.preventDefault();
			openIconSelectorModal();
		}} />}
		{iconName == ".svg" && <div className="placeholder" onClick={e => {
			e.preventDefault();
			openIconSelectorModal();
		}}><MaterialIcon name="library_add" /></div>}
		<Modal
			title="Selecciona un icono"
			ref={ref => modalRef.current = ref}
			renderContent={modal => {
				if (modal.state.loading) return <LoadingIndicator />;
				return <div className="icon-grid">
					{modal.state.iconNames.map((name, idx) => {
						return <img className="icon" key={idx} src={"/static/icons/" + name} onClick={e => {
							e.preventDefault();
							setIcon(name);
							modal.close();
						}} />
					})}
				</div>
			}}
		/>
	</div>;
}
