import { useState, useEffect } from "react";

import MaterialIcon from './MaterialIcon';
import FormToggle from './FormToggle';
import InputSelect from './InputSelect';

import Helper from '../services/Helper';
import L10n from "../services/Locale";

import "../../css/admin-widget-horario.css";

export function DaySelect(props) {
    const [selectedDays, setSelectedDays] = useState([0]);

    useEffect(() => {
        if (props.onChange) props.onChange(selectedDays);
    }, [selectedDays]);

    return <div className={"day-select" + (props.className ? " " + props.className : "")}>
        {[L10n.__("L"), L10n.__("M"), L10n.__("X"), L10n.__("J"), L10n.__("V"), L10n.__("S"), L10n.__("D")].map((label, idx) => {
            return <a href="#" className={(selectedDays.indexOf(idx) != -1 ? "active" : "")} key={idx+"-"+label} onClick={e => {
                e.preventDefault();
                
                if (props.exclusiveMode) {
                    if (selectedDays[0] != idx) {
                        setSelectedDays([idx]);
                    }
                } else {
                    const n = [...selectedDays];
                    const index = n.indexOf(idx);

                    if (index != -1) {
                        if (n.length > 1) n.splice(index, 1);
                    } else {
                        n.push(idx);
                    }

                    setSelectedDays(n);
                }
            }}>{label}</a>;
        })}
    </div>;
}

function AdminWidgetHorarioModal(props) {
    const [selectedDays, setSelectedDays] = useState([0]);
    const [composite, setComposite] = useState(props.composite || []);
    const [secondEntryEnabled, setSecondEntryEnabled] = useState(false);
    const [fullDay, setFullday] = useState(false);
    const [cerrado, setCerrado] = useState(false);

    const defaultValues = [
        selectedDays.reduce((a, v) => a == composite[v][0] ? a : "", composite[selectedDays[0]][0]),
        selectedDays.reduce((a, v) => a == composite[v][1] ? a : "", composite[selectedDays[0]][1]),
        selectedDays.reduce((a, v) => a == composite[v][2] ? a : "", composite[selectedDays[0]][2]),
        selectedDays.reduce((a, v) => a == composite[v][3] ? a : "", composite[selectedDays[0]][3])
    ];

    useEffect(() => {
        if (props.onChange) props.onChange({ selectedDays, composite });
    }, [ selectedDays, composite ]);

    useEffect(() => {
        let fullday = true;
        let cerrado = true;
        let secondentry = false;

        selectedDays.forEach(day => {
            if (composite[day][2] != "00:00" || composite[day][3] != "00:00") secondentry = true;
            if (composite[day][0] != "00:00" || composite[day][1] != "23:59") fullday = false;
            if (composite[day][0] != "00:00" || composite[day][1] != "00:00" || composite[day][2] != "00:00" || composite[day][3] != "00:00") cerrado = false;
        });

        setSecondEntryEnabled(secondentry);
        setFullday(fullday);
        setCerrado(cerrado);
    }, [ selectedDays, composite, secondEntryEnabled ]);

    return <div className="widget-horario-modal-container" onClick={e => {
        if (e.target.classList.contains("widget-horario-modal-container")) props.close();
    }}>
        <div className="widget-horario-modal">
            <div className="title">{L10n.__("Editar horario")}</div>
            <DaySelect onChange={selectedDays => setSelectedDays(selectedDays)} />
            <div className="toggles">
                <div><FormToggle checked={fullDay} onChange={value => {
                    const n = [...composite];
                    selectedDays.forEach(day => {
                        n[day][0] = value ? "00:00" : "09:00";
                        n[day][1] = value ? "23:59" : "14:00";
                        n[day][2] = value ? "00:00" : "00:00";
                        n[day][3] = value ? "00:00" : "00:00";
                    });
                    setComposite(n);
                }}/> {L10n.__("24 horas")}</div>
                <div><FormToggle checked={cerrado} onChange={value => {
                    const n = [...composite];
                    selectedDays.forEach(day => {
                        n[day][0] = value ? "00:00" : "09:00";
                        n[day][1] = value ? "00:00" : "14:00";
                        n[day][2] = value ? "00:00" : "00:00";
                        n[day][3] = value ? "00:00" : "00:00";
                    });
                    setComposite(n);
                }}/> {L10n.__("Cerrado")}</div>
            </div>
            {!cerrado && !fullDay && <>
                <div className="time-entry" key={"entry-first-" + selectedDays.join(".")}>
                    <InputSelect
                        comboMode={true}
                        options={Helper.GenTimeOptions()}
                        defaultValue={defaultValues[0] != defaultValues[1] ? defaultValues[0] : ""}
                        onChange={(value) => {
                            const n = [...composite];
                            selectedDays.forEach(selectedDay => {
                                n[selectedDay][0] = value;
                            });
                            setComposite(n);
                        }}
                    />
                    <InputSelect
                        comboMode={true}
                        options={Helper.GenTimeOptions()}
                        defaultValue={defaultValues[0] != defaultValues[1] ? defaultValues[1] : ""}
                        onChange={(value) => {
                            const n = [...composite];
                            selectedDays.forEach(selectedDay => {
                                n[selectedDay][1] = value;
                            });
                            setComposite(n);
                        }}
                    />
                </div>
                <label className={!secondEntryEnabled ? "disabled" : ""} >{L10n.__("Horario partido")}</label>
                <div className="time-entry" key={"entry-second-" + selectedDays.join(".")}>
                    <InputSelect
                        disabled={!secondEntryEnabled}
                        comboMode={true}
                        options={Helper.GenTimeOptions()}
                        defaultValue={defaultValues[2] != defaultValues[3] ? defaultValues[2] : ""}
                        onChange={(value) => {
                            const n = [...composite];
                            selectedDays.forEach(selectedDay => {
                                n[selectedDay][2] = value;
                            });
                            setComposite(n);
                        }}
                    />
                    <InputSelect
                        disabled={!secondEntryEnabled}
                        comboMode={true}
                        options={Helper.GenTimeOptions()}
                        defaultValue={defaultValues[2] != defaultValues[3] ? defaultValues[3] : ""}
                        onChange={(value) => {
                            const n = [...composite];
                            selectedDays.forEach(selectedDay => {
                                n[selectedDay][3] = value;
                            });
                            setComposite(n);
                        }}
                    />
                </div>
                <MaterialIcon tooltip={secondEntryEnabled ? L10n.__("Desactivar horario de tardes") : L10n.__("Activar horario de tardes")} className="second-entry-toggle" name={secondEntryEnabled ? "schedule" : "history_toggle_off"} onClick={e => {
                    e.preventDefault();
                    const n = [...composite];
                    selectedDays.forEach(day => {
                        n[day][2] = secondEntryEnabled ? "00:00" : "16:00";
                        n[day][3] = secondEntryEnabled ? "00:00" : "20:00";
                    });
                    setComposite(n);
                    setSecondEntryEnabled(!secondEntryEnabled);
                }} />
            </>}
            <div className="actions">
                <a href="#" className="btn" onClick={e => {
                    e.preventDefault();
                    props.close();
                }}>{L10n.__("Cancelar")}</a>
                <a href="#" className="btn btn-brown" onClick={e => {
                    e.preventDefault();
                    props.onSave(composite);
                    props.close();
                }}>{L10n.__("Guardar cambios")}</a>
            </div>
        </div>
    </div>;
}

export default function AdminWidgetHorario(props) {
    const [showModal, setShowModal] = useState(false);
	const [initialized, setInitialized] = useState(props.composite !== null);
    const [composite, setComposite] = useState(props.composite || [
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
        ["00:00","00:00","00:00","00:00"],
    ]);
    const [ compositeString, setCompositeString ] = useState();
    const [ compositeComponents, setCompositeComponents ] = useState();

    useEffect(() => {
        if (composite.length != 7 || composite.reduce((totalLength, item) => totalLength + item.length, 0) != 7 * 4) {
            return;
        }

        const weekDays = [ L10n.__("lunes"), L10n.__("martes"), L10n.__("miércoles"), L10n.__("jueves"), L10n.__("viernes"), L10n.__("sábado"), L10n.__("domingo") ];

        const groups = [];
        let start = 0;
        let end = 0;
        while (end <= 6) {
            if (composite[end][0] != composite[start][0] || composite[end][1] != composite[start][1] || composite[end][2] != composite[start][2] || composite[end][3] != composite[start][3]) {
                groups.push({ start: start, end: end - 1 });
                start = end;
            }
            end++;
        }
        
        groups.push({ start, end: end - 1 });

        let compositeStringComponents = [];
        let compositeComponents = [];

        groups.forEach(group => {
            if (group.start == group.end) {

                if (composite[group.start][0] == composite[group.start][1]) {
                    compositeComponents.push({ date: [weekDays[group.start]], time: [] });
                } else {
                    compositeComponents.push({ date: [weekDays[group.start]], time: [composite[group.start][0], composite[group.start][1], composite[group.start][2], composite[group.start][3]] });

                    compositeStringComponents.push(weekDays[group.start] + " " + L10n.__("de") + " " + composite[group.start][0] + " " + L10n.__("a") + " " + composite[group.start][1] + (composite[group.start][2] != composite[group.start][3] ? (" " + L10n.__("y de") + " " + composite[group.start][2] + " " + L10n.__("a") + " " + composite[group.start][3]) : ""));
                }
            } else {                
                if (composite[group.start][0] == composite[group.start][1]) {
                    compositeComponents.push({ date: [weekDays[group.start], weekDays[group.end]], time: [] });
                } else {
                    compositeComponents.push({ date: [weekDays[group.start], weekDays[group.end]], time: [composite[group.start][0], composite[group.start][1], composite[group.start][2], composite[group.start][3]] });
                    
                    compositeStringComponents.push(L10n.__("de") + " " + weekDays[group.start] + " " + L10n.__("a") + " " + weekDays[group.end] + " " + L10n.__("de") + " " + composite[group.start][0] + " " + L10n.__("a") + " " + composite[group.start][1] + (composite[group.start][2] != composite[group.start][3] ? (" " + L10n.__("y de") + " " + composite[group.start][2] + " " + L10n.__("a") + " " + composite[group.start][3]) : ""));
                }
            }
        });

        setCompositeComponents(compositeComponents);

        const str = compositeStringComponents.join(", ");
        setCompositeString(str.substring(0, 1).toUpperCase() + str.substring(1));
    }, [composite]);

    const closedDayComponents = compositeComponents ? compositeComponents.filter(c => c.time.length == 0) : [];
    let closedDaysStringComponents = [];
    closedDayComponents.forEach(component => {
        closedDaysStringComponents.push(component.date.length > 1 ? component.date[0] + " " + L10n.__("a") + " " + component.date[1] : component.date[0]);
    });

    return <div className="widget-horario-container">
        {showModal && <AdminWidgetHorarioModal composite={composite} onSave={composite => {
            setComposite(composite);
			setInitialized(true);
            if (props.onSave) props.onSave(composite);
        }} close={() => setShowModal(false) }
        onChange={() => props.onChange ? props.onChange({ composite }) : () => {}}
        />}
        {<div className={"widget-horario" + (props.validationError ? " error" : "") + (initialized ? "" : " uninitialized")}>
			{!initialized && <div><MaterialIcon name="schedule" style={{float: "left", marginRight: "0.25em"}} />{L10n.__("Horario de apertura")}</div>}
			{initialized && <>
            {compositeComponents && compositeComponents.filter(c => c.time.length > 0).map(component => {
                return <div className="composite-component" key={"component" + Helper.Hash(JSON.stringify(component))}>
                    <div><MaterialIcon style={{marginRight:"0.25em"}} name="calendar_month" /> {Helper.UCFirst(component.date[0])}{component.date.length > 1 && " " + L10n.__("a") + " " + component.date[1]}</div>
                    <div><MaterialIcon style={{marginRight:"0.25em"}} name="schedule" /> {
                        component.time[0] == "00:00" && component.time[1] == "23:59" ? L10n.__("Abierto las 24 horas") :
                        component.time[0] == "00:00" && component.time[1] == "00:00" && component.time[2] == "00:00" && component.time[3] == "00:00" ? L10n.__("Cerrado") :
                        component.time[0] + " a " + component.time[1] + ((component.time.length > 2 && component.time[2] != component.time[3]) ? (" | " + component.time[2] + " " + L10n.__("a") + " " + component.time[3]) : "")
                    }</div>
                </div>
            })}

            {closedDaysStringComponents.length > 0 && <div className="composite-component" key={"component" + Helper.Hash(closedDaysStringComponents[0])}>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="calendar_month" /> {Helper.UCFirst(closedDaysStringComponents.slice(0, closedDaysStringComponents.length > 2 ? closedDaysStringComponents.length - 1 : 1).join(", ")) + (closedDaysStringComponents.length > 1 ? " " + L10n.__("y") + " " + closedDaysStringComponents.slice(-1) : "")}</div>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="schedule" /> {L10n.__("Cerrado")}</div>
            </div>}
			</>}
            
            <MaterialIcon className="edit-button" name="edit_square" onClick={e => {
                e.preventDefault();
                setShowModal(true);
            }}/>
        </div>}
    </div>;
}
