import { APIRequest, APIAdminRequest } from "./API";
import React from "react";
import SubscriptionsService from "./Subscriptions";

export default class Viviendas {
	static CachedPlaceholder = null;

	static PeriodCutoffHour() {
		return 17;
	}

	static async GetViviendaNames() {
		const response = await APIRequest("vivienda/get-event-names");
		return response.data;
	}

	static async CreatePlaceholder(allowCached = true) {
		if (Viviendas.CachedPlaceholder && allowCached) {
			return { ...Viviendas.CachedPlaceholder };
		}

		const response = await APIAdminRequest("get-event-placeholder");
		Viviendas.CachedPlaceholder = response.data;
		return { ...response.data };
	}

	static GetMealTimeForSlot(restaurant, slot) {
		if (!restaurant.uses_limited_slots) {
			return 0;
		}

		const startTime = slot.substr(0, 5);
		const startHour = startTime.substr(0, 2);

		if (startHour < Viviendas.PeriodCutoffHour()) {
			return parseInt(restaurant.mean_lunch_time);
		} else {
			return parseInt(restaurant.mean_dining_time);
		}
	}

	static async Search(search_parameters) {
		const response = await APIRequest("vivienda/search", { parameters: JSON.stringify(search_parameters) });
		return response.data;
	}

	static async GetAllWithAvailability() {
		const response = await APIRequest("vivienda/get-all-with-availability");
		const venues = response.data;
		const types = await Viviendas.GetTypes();

		if (types?.length) {
			venues.forEach((venue) => {
				const type = types.find((t) => t.id == venue.venue_type_id);
				if (type) {
					venue.tipo = type;
				}
			});
		}

		for (let i = 0; i < venues.length; i++) {
			if (venues[i].iconos) {
				try {
					venues[i].iconos = JSON.parse(venues[i].iconos);
				} catch (e) {
					venues[i].iconos = [];
				}
			} else {
				venues[i].iconos = [];
			}
		}

		return venues;
	}

	static async GetAll() {
		const response = await APIRequest("vivienda/get-all");
		const venues = response.data;
		const types = await Viviendas.GetTypes();

		if (types?.length) {
			venues.forEach((venue) => {
				const type = types.find((t) => t.id == venue.vendedor_type_id);
				if (type) {
					venue.tipo = type;
				}
			});
		}

		for (let i = 0; i < venues.length; i++) {
			if (venues[i].iconos) {
				try {
					venues[i].iconos = JSON.parse(venues[i].iconos);
				} catch (e) {
					venues[i].iconos = [];
				}
			} else {
				venues[i].iconos = [];
			}
		}

		return venues;
	}

	static async GetSubscriptionViviendas() {
		return SubscriptionsService.GetAllViviendas();
	}

	static async GetAvailability(eventID) {
		const response = await APIRequest("vivienda/availability", { event_id: eventID });
		return response.data;
	}

	static async GetAvailabilityBulk(eventIDs) {
		const response = await APIRequest("vivienda/availability-bulk", { event_ids: eventIDs.join(",") });
		return response.data;
	}

	static async GetVendedor(id, slot = "", includeDisabled = false) {
		const response = await APIRequest("vivienda/get-vendedor", { id, slot, include_disabled: includeDisabled ? 1 : 0 });
		return response.data;
	}

	static async GetViviendaSlot(availabilityID) {
		const response = await APIRequest("vivienda/get-event-slot", { availability_id: availabilityID });
		return response.data;
	}

	static async GetSimilar(id) {
		const similar = await APIRequest("vivienda/similar", { id });
		return similar.data;
	}

	static async GetFeaturedTags() {
		const result = await APIRequest("vivienda/featured-tags");
		return result.data;
	}

	static async GetBySlug(slug) {
		const event = await APIRequest("vivienda/get-by-slug", { slug });
		if (event.data.slider) {
			event.data.slider = JSON.parse(event.data.slider);
		}
		return event.data;
	}

	static async GetCities() {
		const cities = await APIRequest("vivienda/get-cities");
		return cities.data;
	}

	static renderTagLinks(tags, onclick) {
		if (!tags) return "";
		return (
			<span className="tag-links">
				{JSON.parse(tags).map((t, k) => (
					<a
						onClick={(e) => {
							e.preventDefault();
							onclick.call(e.currentTarget);
						}}
						key={k}
						href="#"
						data-tag={t}>
						{t.toUpperCase()}
					</a>
				))}
			</span>
		);
	}

	static RenderAvailabilityTag(pax) {
		if (pax == 0) {
			return <span>Completo</span>;
		}

		return (
			<span>
				{pax} {pax == 1 ? "plaza" : "plazas"}
			</span>
		);
	}

	static async GetAvailabilityForPax(vendedorID, pax) {
		const availability = await APIRequest("vivienda/availability-for-pax", { vendedorID, pax });
		return availability.data;
	}

	static async GetAvailabilityFromName(eventID, name) {
		const availability = await APIRequest("vivienda/availability-from-name", { event_id: eventID, name });
		return availability.data;
	}

	static async GetOtherTypes(currentType) {
		const vivienda = await APIRequest("vivienda/get-other-types", { current_type: currentType });
		return vivienda.data;
	}

	static async getAvailableSlots(restaurant, date, time, pax) {
		const availability = await APIRequest("vivienda/time-availability", { restaurant, date, time, pax });
		return availability.data;
	}

	static async getByTag(tag, n) {
		const vivienda = await APIRequest("vivienda/by-tag", { tag, count: n });
		return vivienda;
	}

	static async SetFavorite(restaurant_id, favorite = true) {
		await APIRequest("vivienda/set-favorite", { restaurant_id, favorite: favorite ? 1 : 0 });
	}

	static async IsFavorite(restaurant_id) {
		const response = await APIRequest("vivienda/is-favorite", { restaurant_id });
		return response.data;
	}

	static async GetFavorites() {
		const vivienda = await APIRequest("vivienda/get-favorites");
		return vivienda.data;
	}

	static RenderButtonCaption(event) {
		return <React.Fragment>RESERVAR AHORA</React.Fragment>;
		// if (parseInt(event.accepts_preorder)) {
		//     return (<React.Fragment>RESERVAR AHORA</React.Fragment>);
		// } else {
		//     return (<React.Fragment>Ticket {(event.price * 0.01).toLocaleString('es', {minimumFractionDigits: 2})} € | RESERVAR</React.Fragment>);
		// }
	}

	static async GetDates(eventID, date = "") {
		const response = await APIRequest("vivienda/get-dates", { event_id: eventID, date });
		return response.data;
	}

	static async GetTypes() {
		const response = await APIRequest("vivienda/get-types");
		return response.data;
	}

	// event, slot, duration, pax, full_booking, price_alt, address_alt, city_alt, description_alt, availability_description
	static async PartnerCreateAvailability(data) {
		const response = await APIRequest("partners/create-availability", data);
		return response.status;
	}

	static async PartnerAlterAvailability(data) {
		const response = await APIRequest("partners/alter-availability", data);
		return response.status;
	}

	static async PartnerDeleteAvailability(id) {
		const response = await APIRequest("partners/delete-availability", { id });
		return response.status;
	}

	static async PartnerDeleteAvailabilities(availabilities) {
		let status = true;

		availabilities.forEach(async (availability) => {
			status = status ? await PartnerDeleteAvailability(availability.id) : status;
		});

		return status;
	}
}
