import { useEffect, useRef, useState } from "react";
import MaterialIcon from "./MaterialIcon";
import AdminService from "../services/Admin";
import WidgetAttachmentLimits from "./WidgetAttachmentLimits";

export default function VideoUpload(props) {
	const [videoURL, setVideoURL] = useState(props.initialValue || "");
	const [empty, setEmpty] = useState(videoURL == "");
	const [ uploadedFileSize, setUploadedFileSize ] = useState(0);
	const [maxUploadSize, setmaxUploadSize] = useState(0);

	const elementID = useRef("video-upload-" + Math.floor(Math.random() * 1000) + 1000);

	useEffect(() => {
		setEmpty(videoURL == "");

		if (location.hostname == "localhost" && videoURL[0] == "/") {
			setVideoURL("http://localhost:8081" + videoURL);
		}
	}, [ videoURL ]);

	if (maxUploadSize == -1) return null;

	return (
		<div
			className={"video-upload" + (props.className ? " " + props.className : "") + (empty ? " empty" : "")}
			id={elementID.current}
			style={{ height: props.height || "" }}
			onClick={e => {
				if (!empty || !e.target.classList.contains("video-upload")) return;
				e.preventDefault();
				$("#" + elementID.current).find("input[type=file]").trigger("click");
			}}
		>
			<WidgetAttachmentLimits positionOffset={props.limitsInfoPositionOffset} position={props.limitsInfoPosition} fileFormats={props.fileFormats || ["png", "jpg"]} maxFileSize={props.maxFileSize} recommendedWidth={props.recommendedWidth} recommendedHeight={props.recommendedHeight} uploadedFileSize={uploadedFileSize} />
			{empty && <>
				<div className="overlay" />
				<MaterialIcon name="library_add" className="add-icon" />
			</>}
			{!empty && <video controls>
				<source src={videoURL} type="video/mp4" />
			</video>}
			{!empty && <div className="upload-controls">
				<a href="#" onClick={e => {
					e.preventDefault();
					$("#" + elementID.current).find("input[type=file]").trigger("click");
				}} className="add-icon"><MaterialIcon name="library_add" /></a>
			</div>}
			<input
				style={{ display: "none" }}
				type="file"
				id="file-input"
				accept="video/mp4"
				onChange={(e) => {
					setUploadedFileSize(e.target.files[0].size);
					AdminService.ValidateUploadSize(e.target.files[0].size).then(() => {
						if (props.onChange) {
							AdminService.UploadFile(e.target.files[0]).then(url => {
								props.onChange(url);
								setVideoURL(url);
							});
						}
					});
				}}
			/>
		</div>
	);
}
