import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import SubscriptionsService from "../services/Subscriptions";
import { APIRequest } from "../services/API";
import L10n from "../services/Locale";
import Session from "../services/Session";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import React from "react";
import { SessionContext } from "../context/SessionContext";
import Helper from "../services/Helper";

export default function Vivienda(props) {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState({ sections: [], subscriptionInfo: {}, subscriptionInfoSubscribers: {} });
    const [negocios, setNegocios] = useState([]);
    const [activeQuestions, setActiveQuestions] = useState({});
    const [vendedor, setVendedor] = useState(null);
    const [vivienda, setVivienda] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    const sessionContext = useContext(SessionContext);

    useEffect(() => {
        setLoading(true);

        APIRequest("vivienda/get-by-slug", { slug: params.slug }).then(async (response) => {
            setVendedor(response.data);
            setVivienda(response.data.viviendas.find(o => o.id == params.id_vivienda));
            setLoading(false);
        });

        APIRequest("users/get-data").then(response => {
            setUserData(response.data);
        });

        sessionStorage.setItem("login_redirect", location.href);
    }, []);

    useEffect(() => {
        if (vivienda?.page_data) {
            setContents(JSON.parse(vivienda.page_data));
        }
    }, [vivienda]);

    useEffect(() => {
        setInterval(() => {
            $(".block.faq:not(.loaded)").each(function () {
                $(this).find(".answer").each(function () {
                    $(this)[0].originalHeight = $(this).outerHeight(true);
                    $(this).css({ height: 0, overflow: "hidden" });
                });

                $(this).addClass("loaded");
            });

            $(".faq-container").each(function () {
                if ($(this).hasClass("active")) {
                    $(this).find(".answer").css({ height: $(this).find(".answer")[0].originalHeight, padding: "" });
                } else {
                    $(this).find(".answer").css({ height: 0, padding: 0 });
                }
            });
        }, 1000);

        $("header").addClass("simple");

        return () => {
            $("header").removeClass("simple");
        };
    }); // On render.

    function renderSectionBlock(block, blockIdx) {
        switch (block.type) {
            case "image":
                return <div className="block image" key={"block-" + blockIdx} style={{ backgroundImage: "url(" + block.image_url + ")" }} />;

            case "faq":
                return <div className="block faq" key={"block-" + blockIdx}>
                    <h2>{block.title}</h2>
                    {block.faq.map((faq, faqIdx) => {
                        return <div className={"faq-container" + (activeQuestions[blockIdx] && activeQuestions[blockIdx][faqIdx] ? " active" : "")} key={blockIdx + "faq" + faqIdx}>
                            <div className="question" onClick={e => {
                                const newActiveQuestions = { ...activeQuestions };
                                if (!newActiveQuestions[blockIdx]) newActiveQuestions[blockIdx] = {};
                                newActiveQuestions[blockIdx][faqIdx] = !newActiveQuestions[blockIdx][faqIdx];
                                setActiveQuestions(newActiveQuestions);
                            }}>{faq.question} <img src="/static/icons/desplegable-preguntas.png" /></div>
                            <div className="answer">{faq.answer}</div>
                        </div>;
                    })}
                </div>;

            case "text":
                return <div className="block text" key={"block-" + blockIdx}>
                    {block.supertitle?.length > 0 && <div className="supertitle">{block.supertitle}</div>}
                    {block.title?.length > 0 && <h2>{block.title}</h2>}
                    {block.content?.length > 0 && <div dangerouslySetInnerHTML={{ __html: block.content.replaceAll("\n", "<br/>") }}></div>}
                    {block.icons?.length > 0 && <div className="icons">
                        {block.icons.map((icon, iconIdx) => {
                            return <div key={"icon-" + blockIdx + "-" + iconIdx} style={{ backgroundImage: "url(" + icon + ")" }} />;
                        })}
                    </div>}
                    {block.showButton && <a className="btn btn-brown" onClick={e => {
                        e.preventDefault();
                        navigate(BASENAME + "/vivienda/" + params.slug + "/" + params.id_vivienda + "/solicitud");
                    }}>{contents.subscriptionInfoSubscribers?.buttonText || contents.subscriptionInfo?.buttonText || L10n.__("Solicitar")}</a>}
                </div>;

            case "file-upload":
                return <div className="block text" key={"block-" + blockIdx}>
                    {block.supertitle?.length > 0 && <div className="supertitle">{block.supertitle}</div>}
                    {block.title?.length > 0 && <h2>{block.title}</h2>}
                    {!sessionContext.session && <div>
                        Para realizar esta solicitud, primero debes <Link to={BASENAME + "/login"} style={{textDecoration:"underline", fontWeight:600}}>iniciar sesión</Link> o <Link to={BASENAME + "/registro"} style={{textDecoration:"underline", fontWeight:600}}>crear una cuenta</Link>.
                    </div>}
                    {!!sessionContext.session && <>
                        <div style={{ marginBottom: 0 }}>
                        <div style={{marginBottom: "1em"}}>{block.content}</div>
                            {!!userData && <p>
                                <strong>{userData.name} {userData.surname}</strong><br />
                                {userData.login}<br />
                                {Helper.FormatPhone(userData.phone)}
                            </p>}
                        </div>
                        {block.icons?.length > 0 && <div className="icons">
                            {block.icons.map((icon, iconIdx) => {
                                return <div key={"icon-" + blockIdx + "-" + iconIdx} style={{ backgroundImage: "url(" + icon + ")" }} />;
                            })}
                        </div>}
                        {block.file_id && block.file_label && <div className="file-upload">
                            <h3>{block.file_label}</h3>
                            {!!block.file_description && <h4>{block.file_description}</h4>}
                            <div className="upload-area"
                                onDragOver={e => {
                                    e.preventDefault();
                                    e.target.classList.add("over");
                                    $(e.currentTarget).css({ backgroundColor: "white" });
                                }}
                                onDragLeave={e => {
                                    e.target.classList.remove("over");
                                }}
                                onDrop={e => {
                                    e.preventDefault();
                                    setSelectedFile(e.dataTransfer.files[0]);
                                    $(e.currentTarget).css({ backgroundColor: "" });
                                }}>
                                <input type="file" onClick={e => {
                                    setSelectedFile(null);
                                }} onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setSelectedFile(file);
                                    } else {
                                        setSelectedFile(null);
                                    }
                                }} style={{ position: "absolute", width: "100%", height: "100%", opacity: 0, cursor: "pointer" }} />
                                {selectedFile ? selectedFile.name : <span style={{ opacity: 0.5 }}>{L10n.__("Subir archivo (1 MB máx.)")}</span>}
                            </div>
                        </div>}
                        {!!error && <div style={{ color: "crimson", margin: 0, padding: 0 }}>{error}</div>}
                        {block.showButton && <a className={"btn btn-brown" + (selectedFile ? "" : " disabled")} disabled={!selectedFile} onClick={e => {
                            e.preventDefault();
                            if (!selectedFile) return;

                            const request = new Request(API_URL + "api/users/solicitud-vivienda", {
                                credentials: "include",
                            });
                            const formData = new FormData();
                            formData.append("file", selectedFile);
                            formData.append("id_vivienda", params.id_vivienda);
                            formData.append("locale", L10n.GetLocale());
                            formData.append("name", userData.name);
                            formData.append("surname", userData.surname);
                            formData.append("phone", userData.phone);
                            formData.append("email", userData.login);

                            setError(null);

                            fetch(request, {
                                method: "POST",
                                mode: "cors",
                                cache: "default",
                                body: formData,
                            })
                                .then(response => {
                                    response.text().then(text => {
                                        try {
                                            try {
                                                const json = JSON.parse(text);
                                                if (json.status) {
                                                    navigate(BASENAME + "/vivienda/gracias");
                                                } else {
                                                    setError(json.data);
                                                }
                                            } catch (e) {
                                                console.error(text);
                                            }
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    })
                                        .catch(error => console.error(error));
                                });

                        }}>{contents.subscriptionInfoSubscribers?.buttonText || contents.subscriptionInfo?.buttonText || L10n.__("Solicitar")}</a>}
                    </>}
                </div>;

            default: return null;
        }
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return <div className="suscribete">
        {contents.sections?.map((section, idx) => {
            return <div className={"section" + (section.length == 1 ? " single-block" : "")} key={"section-" + idx}>
                {section.map((block, blockIdx) => {
                    if (block.type == "offer" && negocios.length > 0 && !isTypeSubscriptionCart) return <div className="block offer" key={"block-" + blockIdx}>
                        <h2>Maecenas dapibus</h2>
                        <div className="negocios">
                            <div className="negocios-inner">
                                {negocios.map((negocio, negocioIdx) => {
                                    return <div className="negocio cover-zoom-listener" key={"negocio" + negocioIdx}>
                                        <div className="image-container cover-zoom" style={{ height: 235, backgroundImage: "url(" + (negocio.image_url[0] == "/" ? negocio.image_url : "/static/images/eventos/" + negocio.image_url) + ")" }}>
                                            <div className="icons">
                                                {negocio.iconos.map((icono, iconoIdx) => {
                                                    return <div
                                                        style={{ position: "relative" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            return false;
                                                        }}
                                                        key={"icono" + negocioIdx + "-" + iconoIdx}
                                                        className="has-tooltip"
                                                        data-tooltip={icono.description}>
                                                        <img src={icono.image_url} className="icon-image" />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="details">
                                            <div className="negocio-name">{negocio.name}</div>
                                            <div className="negocio-description" dangerouslySetInnerHTML={{ __html: negocio.description }} />
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                    </div>;
                    else return renderSectionBlock(block, blockIdx);
                })}
            </div>;
        })}
    </div>;
}
