import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    ArcElement,
    Legend,
    Tooltip
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';
import Helper from '../../services/Helper';
import L10n from '../../services/Locale';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

export default function HorizontalBarChart(props) {
    const colors = ['#36a2eb', '#4bc0c0', '#ffcd56', '#ff9f40', '#ff6384'];

    function getOrCreateTooltip(chart) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
        
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.classList.add("tooltip");	
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .3s';
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
        
        return tooltipEl;
    };
    
    return <div className="chart-container horizontal-bar-chart">
                <div>
                    <Bar
                        data={{
                            labels: [L10n.__('País')],
                            datasets: props.types.map((type, idx) => { return {
                                label: Helper.UCFirst(type.plural),
                                data: [Math.round((props.ratios.find(ratio => ratio.venue_type_id == type.id)?.ratio || 0) * 10000) / 100],
                                borderWidth: 1,
                                backgroundColor: colors[idx],
                            }})
                        }}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            indexAxis: "y",
                            scales: {
                                x: {
                                    display: false,
                                    stacked: true
                                },
                                y: {
                                    display: false,
                                    stacked: true
                                }
                            },
                            plugins: {
                                tooltip: {
                                    enabled: false,
                                    position: 'nearest',
                                    external: (context) => {
                                        // Tooltip Element
                                        const {chart, tooltip} = context;
                                        const tooltipEl = getOrCreateTooltip(chart);
                                        
                                        // Hide if no tooltip
                                        if (tooltip.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }
                                            
                                        while (tooltipEl.firstChild) {
                                            tooltipEl.removeChild(tooltipEl.firstChild);
                                        }
                                        
                                        // Set Text
                                        if (tooltip.body) {
                                            tooltipEl.innerHTML = '<span>' + tooltip.dataPoints[0].dataset.label + '</span><span>|</span><span>' + tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex] + '%</span>';
                                        }
                                        
                                        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
                                        
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        const rect = tooltipEl.getBoundingClientRect();
                                        const initialPosition = positionX + tooltip.caretX;
                                        const error = (tooltipEl.parentElement.getBoundingClientRect().left + initialPosition + rect.width) - window.innerWidth + 15;
                                        tooltipEl.style.left = (error > 0 ? initialPosition - error : initialPosition) + 'px';
                                        tooltipEl.style.top = positionY + tooltip.caretY - 50 + 'px';
                                    }
                                }
                            }
                        }}
                        plugins={[ Tooltip ]}
                    />
                </div>
            </div>;
}
