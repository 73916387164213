import { APIRequest } from "../services/API";
import Session from "./Session";
import Helper from "./Helper";
import { GetCached, SetCached } from "./ContentHelper";

export default class SubscriptionsService {
	static SUBSCRIPTION_MODE_TIPO = "tipo";
	static SUBSCRIPTION_MODE_EVENTO = "evento";

	static async IsEnabled() {
		const cached = GetCached("subscriptions-enabled");
		if (cached !== null) return cached;
		const response = await APIRequest("subscriptions/enabled");
		SetCached("subscriptions-enabled", response.data);
		return response.data;
	}

	static async GetTypes() {
		const response = await APIRequest("subscriptions/get-types");
		return response.data;
	}

	static async GetAllEvents(includeSingle = false) {
		const response = await APIRequest("subscriptions/get-all-events", { include_single: includeSingle });
		return response.data;
	}

	static async GetEventsForType(typeID, includeSingle = false) {
		const response = await APIRequest("subscriptions/get-events-for-type", { subscription_type_id: typeID, include_single: includeSingle });
		return response.data;
	}

	static async GetPageContents(eventID, typeSlug, mode) {
		let response;
		if (eventID) response = await APIRequest("subscriptions/get-page-contents", { event_id: eventID, type_slug: typeSlug, mode });
		else response = await APIRequest("subscriptions/get-page-contents", { type_slug: typeSlug, mode });
		return JSON.parse(response.data || '{"sections":[]}') || { sections: [] };
	}

	static async RequireSubscriptionForEvent(eventID) {
		APIRequest("events/get-negocio-for-event", { id: eventID }).then((response) => {
			sessionStorage.setItem("subscribe_redirect", location.href);
			location.href = BASENAME + "/suscribete/n/" + response.data.slug + "/" + eventID;
		});
	}

	static async RequireSubscriptionForAvailability(availabilityID, redirectURL = null) {
		APIRequest("events/get-event-slot", { availability_id: availabilityID }).then((response) => {
			sessionStorage.setItem("subscribe_redirect", redirectURL || location.href);
			location.href = BASENAME + "/suscribete/n/" + response.data.negocio.slug + "/" + response.data.id;
		});
	}

	static async RequireSubscriptionForType(slug, redirectURL = null) {
		sessionStorage.setItem("subscribe_redirect", redirectURL || location.href);
		location.href = BASENAME + "/suscribete/t/" + slug;
	}

	static async RedirectAfterSubscribed() {
		const url = sessionStorage.getItem("subscribe_redirect");
		sessionStorage.removeItem("subscribe_redirect");
		location.href = url || (BASENAME + "/");
	}

	static SetCartData(data) {
		const cartData = SubscriptionsService.GetCartData();

		for (let key of Object.keys(data)) {
			cartData[key] = data[key];
		}

		sessionStorage.setItem("subscription_cart_data", JSON.stringify(cartData || {}));
	}

	static GetCartData() {
		return JSON.parse(sessionStorage.getItem("subscription_cart_data") || "{}");
	}

	static ClearCartData() {
		sessionStorage.setItem("subscription_cart_data", JSON.stringify({}));
	}

	static FilterCalendar(calendar, eventID, userSubscriptions) {
		let filtered = {};
		if (Array.isArray(userSubscriptions)) for (let userSubscription of userSubscriptions.filter((s) => s.events?.filter((e) => e.id == eventID).length > 0)) {
			let interval_type = userSubscription.event_subscription_data ? userSubscription.event_subscription_data.interval_type : userSubscription.subscription_type.interval_type;
			let interval = userSubscription.event_subscription_data ? userSubscription.event_subscription_data.interval : userSubscription.subscription_type.interval;
			const startDateObj = new Date();
			const endDateObj = new Date();
			startDateObj.setTime(userSubscription.current_period_start * 1000);
			endDateObj.setTime(userSubscription.current_period_start * 1000);

			if (interval_type == 'day') {
				endDateObj.setDate(endDateObj.getDate() + interval);
			} else if (interval_type == 'month') {
				endDateObj.setMonth(endDateObj.getMonth() + interval);
			}

			for (let date of Object.keys(calendar)) {
				for (let time of Object.keys(calendar[date])) {
					const dateObj = new Date(date.split("-")[0], date.split("-")[1] - 1, date.split("-")[2], time.split(":")[0], time.split(":")[1]);
					if (dateObj >= startDateObj && dateObj < endDateObj) {
						filtered[date] = calendar[date];
					}
				}
			}
		}

		return filtered;
	}

	static IsSubscribedToType(context, subscriptionType) {
		if (context.subscriptionsEnabled) {
			if (subscriptionType.pack == 1) {
				if (context.userSubscriptions.findIndex((t) => t.id == subscriptionType.id) != -1) {
					return true;
				}
			} else {
				if (context.userSubscriptions.filter((us) => us.events?.filter((usev) => subscriptionType.events.findIndex((sev) => sev.id == usev.id) != -1).length > 0).length > 0) {
					return true;
				}
			}
		}

		return false;
	}

	static IsSubscribedToEvent(context, eventID) {
		if (context.subscriptionsEnabled) {
			if (context.userSubscriptions.filter((us) => us.events?.filter((usev) => usev.id == eventID).length > 0).length > 0) {
				return true;
			}
		}

		return false;
	}

	static GetEventoMaxPax(userSubscriptions, eventID) {
		let max = 0;

		userSubscriptions.forEach((subscription) => {
			if (subscription.events?.filter((ev) => ev.id == eventID).length == 0) return;
			max = Math.max(subscription.remaining_pax ?? subscription.pax, max);
		});

		return max;
	}

	static IsOverPaxAny(userSubscriptions, pax) {
		return (pax == -1 ? 1 : pax) > SubscriptionsService.GetMaxPax(userSubscriptions);
	}

	static GetMaxPax(userSubscriptions, negocioID) {
		if (negocioID) {
			userSubscriptions = userSubscriptions.filter((s) => s?.events?.filter((ev) => ev.negocio_id == negocioID).length > 0);
			if (userSubscriptions.length == 0) return 0;
		}

		let minPax = Infinity;
		userSubscriptions?.forEach((subscription) => {
			if (subscription.visits == -1) return;
			minPax = Math.min(subscription.remaining_pax || subscription.remaining_visits, minPax);
		});

		return minPax;
	}

	static IsCombinedVisits(pax, userSubscriptions, eventID) {
		return pax > SubscriptionsService.GetEventoMaxPax(userSubscriptions, eventID);
	}
}
