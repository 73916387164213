import { useState, useEffect, useRef } from "react";

import { APIAdminRequest } from "../services/API";
import Bloque from "./AdminBloque";
import Helper from "../services/Helper";
import { StarMeter } from "./Encuesta";
import DoughnutChart from "./estadisticas/DoughnutChart";
import BarChart from "./estadisticas/BarChart";
import HorizontalBarChart from "./estadisticas/HorizontalBarChart";
import LoadingIndicator from "./LoadingIndicator";
import L10n from "../services/Locale";
import MaterialIcon from "./MaterialIcon";
import { CalendarFilter } from "./AdminEstadisticas";
import Settings from "../services/Settings";

export const Colors = ["#36a2eb", "#4bc0c0", "#ffcd56", "#ff9f40", "#ff6384"];

export default function AdminEstadisticasSuscripcion(props) {
	const [types, setTypes] = useState([]);
	const [ratiosByType, setRatiosByType] = useState([]);
	const [kpis, setKpis] = useState();
	const [ratiosByDay, setRatiosByDay] = useState([]);
	const [ratiosByCountry, setRatiosByCountry] = useState([]);
	const [surveyData, setSurveyData] = useState(null);
	const [loadingInProgress, setLoadingInProgress] = useState(true);
	const [showCalendarFilter, setShowCalendarFilter] = useState(false);
	const [calendarFilterDates, setCalendarFilterDates] = useState({ from: null, to: null });
	const [prevCalendarFilterDates, setPrevCalendarFilterDates] = useState({ from: null, to: null });
	const [dateFilterChanging, setDateFilterChanging] = useState(false);
	const [brandName, setBrandName] = useState("");

	const calendarFilterRef = useRef();
	const dateChangeRef = useRef();

	useEffect(() => {
		if (!types || !types.length) return;
		APIAdminRequest("surveys-get-venue-types-data", { venue_type_ids: types.map((t) => t.id).join(","), date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }).then((response) => {
			setSurveyData(response.data);
		});
	}, [types, calendarFilterDates]);

	useEffect(() => {
		if (!types.length || (prevCalendarFilterDates.from != calendarFilterDates.from && prevCalendarFilterDates.to != calendarFilterDates.to && calendarFilterDates.from == null && calendarFilterDates.to == null) || (calendarFilterDates.from != null && calendarFilterDates.to != null)) {
			setDateFilterChanging(true);
			setPrevCalendarFilterDates({ ...calendarFilterDates });

			Promise.all([APIAdminRequest("stats-get-types", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }), APIAdminRequest("stats-get-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }), APIAdminRequest("stats-get-kpi", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }), APIAdminRequest("stats-get-day-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }), APIAdminRequest("stats-get-country-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" })]).then(([types, ratioByType, KPI, dayRatios, countryRatios]) => {
				setTypes(types.data);
				setRatiosByType(ratioByType.data);
				setKpis(KPI.data);
				setRatiosByDay(dayRatios.data);
				setRatiosByCountry(countryRatios.data);
				setLoadingInProgress(false);
				setDateFilterChanging(false);
			});
		}
	}, [calendarFilterDates]);

	useEffect(() => {
		Settings.Get("BRAND_NAME").then((name) => setBrandName(name));

		document.body.classList.add("bg-grey");

		const onClick = (e) => {
			if ($(e.target).closest(".calendar-filter, .calendar-filter-toggle").length == 0) {
				setShowCalendarFilter(false);
			}
		};

		document.body.addEventListener("click", onClick);

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.removeEventListener("click", onClick);
		};
	}, []);

	let mediaEncuestas = [];
	if (surveyData && types) {
		types.forEach((type, typeIndex) => {
			let itemCount = 0;
			mediaEncuestas[typeIndex] = 0;
			if (surveyData[type.id] && surveyData[type.id].length) {
				surveyData[type.id].forEach((item) => {
					if (item.value > 0) {
						mediaEncuestas[typeIndex] += item.value;
						itemCount++;
					}
				});
			}
			mediaEncuestas[typeIndex] /= itemCount;

			if (isNaN(mediaEncuestas[typeIndex])) mediaEncuestas[typeIndex] = 0;
		});
	}

	if (loadingInProgress) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-estadisticas">
			{dateFilterChanging && <LoadingIndicator style={{ marginLeft: $(".sidebar").outerWidth(true) / 2 }} />}
			<div className="header">
				<CalendarFilter
					visible={showCalendarFilter}
					onChange={(dates) => {
						clearTimeout(dateChangeRef.current);
						dateChangeRef.current = setTimeout(() => {
							setCalendarFilterDates(dates);
						}, 1000);
					}}
					resetRef={(reset) => {
						calendarFilterRef.current = { reset };
					}}
					defaultvalue={calendarFilterDates}
				/>
				<MaterialIcon
					name="calendar_month"
					tooltip={L10n.__("Filtar por fechas")}
					className="calendar-filter-toggle"
					onClick={(e) => {
						setShowCalendarFilter(!showCalendarFilter);
					}}
				/>
				{calendarFilterDates.from != null && (
					<div className="dates-display">
						<span>{Helper.FormatISODate(calendarFilterDates.from, Helper.DATE_VERBOSE_SHORT)}&nbsp;</span>
						{calendarFilterDates.from != calendarFilterDates.to && <span>- {Helper.FormatISODate(calendarFilterDates.to, Helper.DATE_VERBOSE_SHORT)}</span>}{" "}
						<MaterialIcon
							className="reset-dates"
							name="restart_alt"
							onClick={(e) => {
								calendarFilterRef.current.reset();
								setCalendarFilterDates({ from: null, to: null });
							}}
						/>
					</div>
				)}
			</div>
			<Bloque className="columnas" style={{ opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms" }}>
				<div className="columna grafica">
					<h2>{L10n.__("Suscripciones")}</h2>
					{kpis && !(kpis.renewedSubscriptionsRatio == 0 && kpis.cancelledSubscriptionsRatio == 0 && kpis.newSubscriptionsRatio == 0) && (
						<DoughnutChart
							id="estado-de-suscripcion"
							typesLabel="Estado de suscripción"
							types={[
								{ id: 0, plural: "Renovadas" },
								{ id: 1, plural: "Canceladas" },
								{ id: 2, plural: "Nuevas" }
							]}
							ratios={[
								{ id: 0, ratio: kpis.renewedSubscriptionsRatio },
								{ id: 1, ratio: kpis.cancelledSubscriptionsRatio },
								{ id: 2, ratio: kpis.newSubscriptionsRatio }
							]}
						/>
					)}
					{kpis.renewedSubscriptionsRatio == 0 && kpis.cancelledSubscriptionsRatio == 0 && kpis.newSubscriptionsRatio == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>

				<div className="columna">
					<h2>{L10n.__("Reservas por día de la semana")}</h2>
					<BarChart types={types} ratios={ratiosByDay} displayCount={true} />
					{Object.values(ratiosByDay).filter((d) => d.ratios.length > 0).length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>
			</Bloque>
			<Bloque className="columnas" style={{ opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms" }}>
				<div className="columna">
					<h2>{L10n.__("Indicadores de rendimiento")}</h2>
					{kpis && (
						<div className="kpi-container">
							<div className="item">
								<div className="value">{Helper.FormatAmount(Math.floor(kpis.averageSubscriptionAmount / 100))}</div>
								<div className="caption">{L10n.__("Suscripción media")}</div>
							</div>
							<div className="item">
								<div className="value">{Helper.FormatAmount(kpis.totalFees / 100)}</div>
								<div className="caption">
									{L10n.__("Comisiones")} {brandName}
								</div>
							</div>
							<div className="item">
								<div className="value">{kpis.totalActiveSubscriptions}</div>
								<div className="caption">{L10n.__("Suscripciones activas")}</div>
							</div>
							<div className="item">
								<div className="value">
									{kpis.totalVisits.visits || 0} / {kpis.totalVisits.totalVisits}
								</div>
								<div className="caption">{L10n.__("Visitas")}</div>
							</div>
							<div className="item">
								<div className="value">{(Math.floor(kpis.ordersWithExtrasRatio * 10000) / 100).toLocaleString("es")} %</div>
								<div className="caption">{L10n.__("Reservas con extras")}</div>
							</div>
							<div className="item">
								<div className="value">{(Math.floor(kpis.ordersOverPaxRatio * 10000) / 100).toLocaleString("es")} %</div>
								<div className="caption">{L10n.__("Reservas con más personas")}</div>
							</div>
						</div>
					)}
				</div>

				<div className="columna grafica">
					<h2>{L10n.__("Reservas por tipo de negocio")}</h2>
					<DoughnutChart id="reservas-por-tipo" types={types} ratios={ratiosByType} />
					{ratiosByType.length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>
			</Bloque>
			{surveyData && types && (
				<Bloque className="encuestas columnas" columnCount={1} style={{ paddingTop: 75, position: "relative" }}>
					<h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Valoración global")}</h2>
					{types.map((type, typeIndex) => {
						if (!surveyData[type.id] || !surveyData[type.id].length) return null;
						return (
							<div className="columna" key={"type-reviews-" + typeIndex}>
								<h3>{Helper.UCFirst(type.plural)}</h3>
								{surveyData[type.id].map((item, index) => {
									return (
										<div className="category" key={"survey-data-category" + index}>
											<h3>{item.text}</h3>
											<StarMeter defaultValue={item.value} />
											<div className="numeric-value">{parseFloat(item.value.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
										</div>
									);
								})}
								<div className="category mean">
									<h3>{L10n.__("Media")}</h3>
									<StarMeter defaultValue={mediaEncuestas[typeIndex]} />
									<div className="numeric-value">{parseFloat(mediaEncuestas[typeIndex].toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
								</div>
							</div>
						);
					})}
					{types.length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</Bloque>
			)}
		</div>
	);
}
