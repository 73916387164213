import { useState, useEffect } from "react";
import ImageUpload from "./ImageUpload";

export default function ImageUploadList(props) {
	const [imageList, setImageList] = useState(props.defaultValue || []);

	return <div className={"image-upload-list" + (props.className ? " " + props.className : "")}>
		{imageList.map((url, idx) => {
			return <div className="image-upload-container" key={url}><ImageUpload initialValue={url} onChange={value => {
				const imageList = [...imageList];
				imageList[idx] = value;
				setImageList(imageList);
				if (props.onChange) props.onChange(imageList);
			}} /></div>;
		})}
	</div>;
}
