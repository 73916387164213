import React, { Component } from 'react';
import FormInput from './FormInput.tsx';
import Helper from './../services/Helper';
import Session from './../services/Session';
import L10n from './../services/Locale';
import $ from 'jquery';

import AsyncButton from './AsyncButton.tsx';

class CambiarContrasena extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_message: null,
            token: null
        };

        const m = window.location.search.match(/\?token=(.+)/i);
        if(m && m[1]) {
            this.state.token = m[1];
        }
    }

    render() {
        return (
            <div className="cambiar-contrasena">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 form-container">
                            { this.state.token && this.renderChange() }
                            { !this.state.token && this.renderRequest() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderChange() {
        return (
            <React.Fragment>
                <h1>{L10n.__("Cambiar contraseña")}</h1>
                { this.state.success_message && <div>{this.state.success_message}</div>}
                { !this.state.success_message && (
                    <React.Fragment>
                    <form autoComplete="off"
                    onSubmit={this.onSubmit.bind(this)}>
                        <input type="hidden" autoComplete="off" />
                        <FormInput required
                            type="password"
                            id="password"
                            label={L10n.__("Contraseña")}
                            onValidate={value => {
                                if(value.length < 8) return L10n.__("La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.");
                            }}
                            onChange={value => {
                                this.setState({ password: value })
                            }}
                        />
                        <FormInput required
                            type="password"
                            id="password2"
                            label={L10n.__("Repetir contraseña")}
                            onValidate={value => {
                                if(value.length && this.state.password !== value) return L10n.__("Las contraseñas no coinciden.");
                            }}
                        />

                        { this.state.error_message && <div className="error-message">{this.state.error_message}</div> }

                        <AsyncButton style={{ gridColumn: 'span 2' }} ref={ref => this.buttonRef = ref} className="btn btn-brown" onClick={this.onSubmitChange.bind(this)} caption={L10n.__("Cambiar contraseña")} />
                    </form>
                </React.Fragment>
                ) }
            </React.Fragment>
        );
    }

    renderRequest() {
        return (
            <React.Fragment>
                <h1>{L10n.__("Cambiar contraseña")}</h1>
                { this.state.success_message && <div>{this.state.success_message}</div>}
                { !this.state.success_message && (
                    <React.Fragment>
                        <div>{L10n.__("Introduce tu e-mail a continuación y enviaremos un enlace de verificación para que puedas cambiar tu contraseña:")}</div>
                            <input type="hidden" autoComplete="off" />
                            <FormInput
                                type="email"
                                id="email"
                                label={L10n.__("Email")}
                                onValidate={value => {
                                    if(!Helper.ValidateEmail(value)) return L10n.__("Por favor introduce una dirección de email válida.");
                                }}
                                onChange={value => {
                                    this.setState({ login: value });
                                }}
                            />

                            {
                                this.state.error_message ? (
                                    <div className="error-message">{this.state.error_message}</div>
                                ) : null
                            }
                            <AsyncButton className="btn btn-brown" ref={ref => this.buttonRef = ref} onClick={this.onSubmit.bind(this)} caption={L10n.__("Enviar")} />
                    </React.Fragment>
                ) }
            </React.Fragment>
        );
    }

    onSubmit(e) {
        if(e && e.preventDefault) e.preventDefault();
        if($('.form-input.not-validated').length) return false;
        
        Session.RequestPasswordChange(this.state.login).then(result => {
            if(result) {
                this.setState({
                    success_message: L10n.__("Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.")
                });
            } else {
                this.setState({
                    error_message: L10n.__("No se ha podido enviar tu enlace de recuperación. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.")
                });
            }
        });

        return false;
    }
    
    onSubmitChange(e) {
        if(e && e.preventDefault) e.preventDefault();
        if($('.form-input.not-validated').length) return;

        Session.PerformPasswordChange(this.state.password, this.state.token).then(result => {
            if(!result) {
                this.setState({
                    error_message: L10n.__("No se ha podido cambiar tu contraseña. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.")
                });
                return;
            }
            this.setState({
                success_message: L10n.__("Tu contraseña se ha cambiado con éxito. Ya puedes utilizarla para entrar.")
            });
        });
    }
}

export default CambiarContrasena;
