import { useContext, useEffect, useRef } from "react";
import { HeaderContext } from "../services/HeaderContext";
import UI from "../services/UI";
import AsyncButton from "./AsyncButton";
import L10n from "../services/Locale";

export default function AdminBar(props) {
	const headerContext = useContext(HeaderContext);
	const saveButtonRef = useRef();
	const onSave = props.onSave;
	const disabled = props.disabled;

	function renderAdminBarContents() {
		return <AsyncButton
			onClick={(e) => {
				if (disabled) return;
				if (onSave) {
					onSave((result) => {
						if (result === true) {
							UI.ShowNotification(L10n.__("Cambios guardados con éxito"));
							saveButtonRef.current?.reset();
						} else {
							if (typeof result === "string") {
								console.error(result);
							}
						}
						saveButtonRef.current?.reset();
					});
				} else {
					saveButtonRef.current?.reset();
				}
			}}
			className={"save-button" + (disabled ? " disabled" : "")}
			onRef={(ref) => (saveButtonRef.current = ref)}
			caption={L10n.__("Guardar cambios")}
		/>;
	}

	useEffect(() => {
		headerContext.setHeaderButtons(
			<div className="admin-bar">
				{onSave && renderAdminBarContents()}

				{props.renderElements && <div className="bar-elements">{props.renderElements()}</div>}
			</div>
		);

		return () => {
			headerContext.setHeaderButtons(null);
		}
	}, [onSave, disabled]);

	if (window.innerWidth <= 992) return renderAdminBarContents();
	
	return null;
}
