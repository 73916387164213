import React, { Component, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Events from '../services/Events';
import Session from '../services/Session';
import L10n from '../services/Locale';
import $ from 'jquery';
import { SubscriptionsContext } from '../context/SubscriptionsContext';

export default function ReservaCompletada(props) {
    const [ restaurant, setRestaurant ] = useState(JSON.parse(Session.GetBookingData("selected_restaurant") || "null"));
    const [ favorited, setFavorited ] = useState(false);
    const [ alreadyFavorite, setAlreadyFavorite ] = useState(true);
    const [ userData, setUserData ] = useState(null);

    const subscriptionsContext = useContext(SubscriptionsContext);

    useEffect(() => {
        if (restaurant) Events.IsFavorite(restaurant.id).then(alreadyFavorite => {
            setAlreadyFavorite(alreadyFavorite);
        });

        Session.GetSession().then(session => {
            setUserData(session);
        });

        const user_data = sessionStorage.getItem('user_data');
        if(user_data) {
            setUserData(JSON.parse(user_data));
        }
        
        $('header').addClass('simple');
        Session.ClearBookingData();

        return () => {
            $('header').removeClass('simple');
        };
    }, []);

    return <div className="reserva-completada">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>{L10n.__("Tu reserva se ha enviado con éxito")}</h1>
                    {userData && <h2>{L10n.__("Tan pronto la confirmemos recibirás un correo de confirmación en")} <span className="user-email">{userData.login}</span> {L10n.__("y en tu WhatsApp.")}<br/>{L10n.__("Recuerda comprobar también la carpeta de spam.")}</h2>}

                    <div className="buttons">
                        {restaurant && !alreadyFavorite && <div className="add-favorite" onClick={e => {
                            this.setState({ favorited: !favorited });
                            Events.SetFavorite(restaurant.id, !favorited);
                        }}><div className="heart-container"><i className={favorited ? "fas fa-heart" : "far fa-heart"} /></div>{L10n.__("Añadir")} {restaurant.name} {L10n.__("a mis favoritos")}</div>}
                        <Link to={subscriptionsContext.subscriptionsEnabled ? "/mis-suscripciones" : "/"} className="btn btn-brown">{L10n.__("Volver al inicio")}</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
