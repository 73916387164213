import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer.tsx';
import ImagenPaso from './ImagenPaso';
import ContentHelper from '../services/ContentHelper.tsx';
import $ from 'jquery';

import { Content } from './Content.tsx';

export default class PlanearEvento extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			imageURL1: null,
			imageURL2: null,
			imageURL3: null,
			ctaText: "",
			eventosImagenesBanda: [],
			eventosImagenesBandaInferior: []
		};

		Promise.all([
			ContentHelper.GetString("eventos-imagen-1"),
			ContentHelper.GetString("eventos-imagen-2"),
			ContentHelper.GetString("eventos-imagen-3"),
			ContentHelper.GetString("eventos-cta"),
			ContentHelper.GetString("eventos-imagenes-banda"),
			ContentHelper.GetString("eventos-imagenes-banda-inferior")
		]).then(([imageURL1, imageURL2, imageURL3, ctaText, eventosImagenesBanda, eventosImagenesBandaInferior]) => {
			this.setState({ imageURL1, imageURL2, imageURL3, ctaText, eventosImagenesBanda: JSON.parse(eventosImagenesBanda || "[]"), eventosImagenesBandaInferior: JSON.parse(eventosImagenesBandaInferior || "[]") });
		});
	}

    componentDidMount() {
        $('header').addClass('bg-offwhite');
    }

    componentWillUnmount() {
        $('header').removeClass('bg-offwhite');
    }

    render() {
        return (
            <div className="planear-evento bg-offwhite">
                <div className="container full-width">
                    <div className="row">
						{this.state.imageURL1 !== null && <ImagenPaso className="col-md-6" imageUrl={"/static/images/" + this.state.imageURL1} />}
                        <div className="col-md-6">
                            <div className="text-block">
                                <div className="allcaps eventos-supertitulo"><Content slug="eventos-supertitulo" /></div>
                                <h1><Content slug="eventos-titulo" /></h1>
                                <p><Content slug="eventos-subtitulo" /></p>
								<Link to={BASENAME + "/contratar-un-evento"} className="btn btn-brown">{this.state.ctaText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container full-width">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-band bg-offgrey">
                                <h2><Content slug="eventos-banda-titulo" /></h2>
                                <p><Content slug="eventos-banda-texto" /></p>
                                <div className="row logo-row pl-sm-3 pl-lg-2 justify-content-center">
									{this.state.eventosImagenesBanda.map((url, idx) => {
										return <div key={idx} className="border logo-box">
											<img src={"static/images/" + url} className="w-100 px-2 px-lg-0" />
										</div>;
									})}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container eventos-destacado eventos-destacado-1 full-width">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-block">
                                <h2><Content slug="eventos-destacado-1-titulo" /></h2>
                                <p><Content slug="eventos-destacado-1-texto" /></p>
                                <Link to={BASENAME + "/contratar-un-evento"} className="btn btn-brown">{this.state.ctaText}</Link>
                            </div>
                        </div>
						{this.state.imageURL2 !== null && <ImagenPaso className="col-md-6" imageUrl={"/static/images/" + this.state.imageURL2} />}
                    </div>
                </div>
                <div className="container eventos-destacado eventos-destacado-2 full-width">
                    <div className="row">
						{this.state.imageURL3 !== null && <ImagenPaso className="col-md-6" imageUrl={"/static/images/" + this.state.imageURL3} />}
                        <div className="col-md-6">
                            <div className="text-block">
                                <h2><Content slug="eventos-destacado-2-titulo" /></h2>
                                <p><Content slug="eventos-destacado-2-texto" /></p>
                                <Link to={BASENAME + "/contratar-un-evento"} className="btn btn-brown">{this.state.ctaText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container eventos-banda-inferior full-width">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-band bg-offgrey">
                                <h2><Content slug="eventos-banda-inferior-titulo" /></h2>
                                <div className="logos-clientes row pl-sm-3 pl-lg-2 justify-content-center pb-5">
									{this.state.eventosImagenesBandaInferior.map((url, idx) => {
										return <div className="col-4 col-sm-3" key={idx}>
											<img src={"static/images/" + url} width="80%" />
										</div>;
									})}
                                </div>
                                <Link to={BASENAME + "/contratar-un-evento"} className="btn btn-brown">{this.state.ctaText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
