import React, { Component } from 'react';
import { Content } from './Content';
import Footer from './Footer';
import $ from 'jquery';

export default class PoliticaDePrivacidad extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        let user_data = sessionStorage.getItem('user_data');
        if(user_data) {
            this.state.user_data = JSON.parse(user_data);
        }
    }

    componentDidMount() {
        $('header').addClass('simple');
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        return (
            <div className="texto-legal">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Content slug="politica-de-privacidad" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
