import { useState, useEffect } from "react";
import Helper from "../services/Helper";
import MaterialIcon from "./MaterialIcon";
import { APIAdminRequest } from "../services/API";
import AdminService from "../services/Admin";
import WidgetAttachmentLimits from "./WidgetAttachmentLimits";

export default function ImageUpload(props) {
	const [imageURL, setImageURL] = useState((props.initialValue && props.initialValue?.replace("/static/images/", "")) || "");
	const [ empty, setEmpty ] = useState(imageURL == "");
	const [ uploadedFileSize, setUploadedFileSize ] = useState(0);

	const showClearButton = props.showClearButton || false;
	const sizeMode = props.sizeMode || "contain";

    useEffect(() => {
		setEmpty(imageURL == "");
        if (!imageURL.length) return;

        const img = document.createElement("img");
        img.onerror = () => {
            console.error(imageURL + " not found.");
            setImageURL("");
        };
        img.src = imageURL;
    }, [imageURL]);

	return (
		<div
			className={"image-upload" + (props.className ? " " + props.className : "") + (empty ? " empty" : "")}
			style={{ height: props.height || props.displayHeight || "", width: props.width || props.displayWidth || "", minHeight: props.displayHeight || "", backgroundSize: sizeMode, backgroundImage: "url(" + imageURL + ")" }}
			onClick={(e) => {
				$(e.target.parentNode).find("input[type=file]").trigger("click");
			}}
		>
			{showClearButton && !empty && <MaterialIcon className="clear-image" name="close" onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				setImageURL("");
				if (props.onChange) props.onChange("");
			}} />}
			<WidgetAttachmentLimits alwaysShow={props.alwaysShowLimitsInfo} positionOffset={props.limitsInfoPositionOffset} position={props.limitsInfoPosition} fileFormats={props.fileFormats || ["png", "jpg"]} maxFileSize={props.maxFileSize} recommendedWidth={props.recommendedWidth} recommendedHeight={props.recommendedHeight} uploadedFileSize={uploadedFileSize} />
			<div className="overlay"></div>
			<img src={imageURL} />
			<div className="add-icon">
				<MaterialIcon name="library_add" />
			</div>
			<input
				type="file"
				id="file-input"
				accept="image/png,image/x-png,image/gif,image/jpeg,image/svg+xml"
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						AdminService.ValidateUploadSize(e.target.files[0].size).then(() => {
							APIAdminRequest("upload-image", { file: e.target.files[0] }, false).then(response => {
                                if (response.status) {
                                    setImageURL(response.data);
                                    if (props.onChange) {
                                        props.onChange(response.data);
                                    }
                                }
							});
						});
					}
				}}
			/>
		</div>
	);
}
