import React, { useEffect, useState, useRef, useCallback } from 'react';

import Helper from '../services/Helper';
import L10n from '../services/Locale';

export default function Calendar(props) {
	const firstAvailable = props.availabilityCalendar ? Object.keys(props.availabilityCalendar).sort().filter(date => date >= Helper.GetISODate(new Date()))[0] : "";
	const selectionLength = props.selectionLength;

	const [ daysData, setDaysData ] = useState([]);
	const [ selectedDate, setSelectedDate ] = useState(props.defaultValue);
	const [ startDate, setStartDate ] = useState(selectedDate ? new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1) : (props.startDate || (firstAvailable && new Date(firstAvailable.split("-")[0], firstAvailable.split("-")[1] - 1, 1)) || new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
	const [multipleSelection, setMultipleSelection] = useState([]);
	const [shiftKeyDown, setShiftKeyDown] = useState(false);
	const [controlKeyDown, setControlKeyDown] = useState(false);

	const visibleMonths = props.months || (Helper.IsResponsive() ? 1 : 2);
	const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

	const changeDate = useRef(newDate => {
		changeSelectedDate(newDate);
	});

	const changeStartDate = useRef(newDate => {
		setStartDate(newDate);
	});

	if (props.changeDateRef) {
		props.changeDateRef(changeDate.current);
	}

	if (props.changeStartDateRef) {
		props.changeStartDateRef(changeStartDate.current);
	}

	function changeSelectedDate(date) {
		const dates = [];

		if (date) {
			dates.push(date);

			daysData.forEach(days => {
				days.forEach(date => {
					for (let i = 1; i < selectionLength; i++) {
						const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + i);
						if (Helper.GetISODate(date) == Helper.GetISODate(newDate)) {
							dates.push(newDate);
							break;
						}
					}
				});
			});
		}

		if (props.onSelectionChanged) {
			props.onSelectionChanged(dates);
		}

		setSelectedDate(date);
	}

	const allowMultipleSelection = props.allowMultipleSelection;
	const onSelectionChanged = props.onSelectionChanged;

	useEffect(() => {
		if (allowMultipleSelection && onSelectionChanged) {
			onSelectionChanged(multipleSelection);
		}
	}, [allowMultipleSelection, onSelectionChanged, multipleSelection]);

	const onKeyDown = useCallback(e => {
		if (!shiftKeyDown && e.shiftKey) setShiftKeyDown(true);
		if (!controlKeyDown && (e.ctrlKey || e.metaKey)) setControlKeyDown(true);
	}, [shiftKeyDown, controlKeyDown]);

	const onKeyUp = useCallback(e => {
		if (!e.shiftKey) setShiftKeyDown(false);
		if (!e.ctrlKey && !e.metaKey) setControlKeyDown(false);
	}, []);

	useEffect(() => {
		$(window).on("keydown", onKeyDown);
		$(window).on("keyup", onKeyUp);

		return () => {
			$(window).off("keydown", onKeyDown);
			$(window).off("keyup", onKeyUp);
		}
	}, [onKeyUp, onKeyDown]);

	useEffect(() => {
		const newDaysData = [];
		let currentYear = startDate.getFullYear();
		for (let monthOffset = 0; monthOffset < visibleMonths; monthOffset++) {
			let dayOffset = 0;
			let currentMonth = startDate.getMonth() + monthOffset;
			if (currentMonth >= 12) {
				currentMonth -= 12;
				currentYear++;
			}
			let currentDate = new Date(currentYear, currentMonth, startDate.getDate() + dayOffset);
			const days = [];

			while (currentDate.getMonth() == currentMonth) {
				days.push(currentDate);
				dayOffset += 1;
				currentDate = new Date(currentYear, currentMonth, startDate.getDate() + dayOffset);
			}

			newDaysData.push(days);
		}
		setDaysData(newDaysData);
	}, [startDate, visibleMonths]);

	return <div className={"calendar " + (props.className || "") + (props.allowPast ? " allow-past" : "") + (props.allowUnavailableSelection ? " allow-selection" : "")} style={props.style || {}}>
		{(props.allowPast || startDate > new Date(today.getFullYear(), today.getMonth(), 1)) && <div onClick={e => {
			setStartDate(new Date(startDate.getFullYear(), startDate.getMonth() - visibleMonths, 1));
		}} className="calendar-arrow calendar-previous"><img src="/static/icons/desplegable-preguntas.png" /></div>}
		<div onClick={e => {
			setStartDate(new Date(startDate.getFullYear(), startDate.getMonth() + visibleMonths, 1));
		}} className="calendar-arrow calendar-next"><img src="/static/icons/desplegable-preguntas.png" /></div>
		{daysData.map((days, idx) => {
			if (!days.length) return null;

			return <div className="month" key={idx}>
				<h4>{[L10n.__("Enero"), L10n.__("Febrero"), L10n.__("Marzo"), L10n.__("Abril"), L10n.__("Mayo"), L10n.__("Junio"), L10n.__("Julio"), L10n.__("Agosto"), L10n.__("Septiembre"), L10n.__("Octubre"), L10n.__("Noviembre"), L10n.__("Diciembre")][days[0].getMonth()] + " " + days[0].getFullYear()}</h4>
                <div className="weekday-names">
                    <div>{L10n.__("Lu")}</div>
                    <div>{L10n.__("Ma")}</div>
                    <div>{L10n.__("Mi")}</div>
                    <div>{L10n.__("Ju")}</div>
                    <div>{L10n.__("Vi")}</div>
                    <div>{L10n.__("Sa")}</div>
                    <div>{L10n.__("Do")}</div>
                </div>
				<div>
					{days.map((date, idx) => {
						const dow = date.getDay();
						const offset = dow == 0 ? 6 : dow - 1;

						let available = true;
						if (props.availabilityCalendar) {
							available = props.availabilityCalendar[Helper.GetISODate(date)];
						} else if (props.fullAvailability) {
							available = true;
						}

						let selected = false;

						if (props.allowMultipleSelection) {
							selected = typeof multipleSelection.find(d => d.valueOf() == date.valueOf())  !== "undefined";
						} else {
							selected = selectedDate && selectedDate.valueOf() == date.valueOf();
						}

						let secondary = false;

						if (selectedDate) {
							for (let i = 1; i < props.selectionLength; i++) {
								if (Helper.GetISODate(date) == Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + i))) {
									selected = true;
									secondary = true;
									break;
								}
							}
						}

						return <React.Fragment key={idx}>
							{idx == 0 && [...Array(offset).keys()].map((_, idx) => {
								return <div key={idx}></div>;
							})}
							<div
							onClick={(e) => {
								e.preventDefault();
								if ((date >= today && props.allowUnavailableSelection) || (date < today && props.allowPast) || date >= today) {
									if (selectedDate && props.allowMultipleSelection && shiftKeyDown) {
										const firstDate = date > selectedDate ? selectedDate : date;
										const lastDate = date > selectedDate ? date : selectedDate;
										let currentDate = new Date(firstDate.valueOf());
										const newMultipleSelection = [];
										while (currentDate <= lastDate) {
											newMultipleSelection.push(new Date(currentDate.valueOf()));
											currentDate.setDate(currentDate.getDate() + 1);
										}
										setMultipleSelection(newMultipleSelection);
									} else if (props.allowMultipleSelection && controlKeyDown) {
										const newMultipleSelection = [...multipleSelection];
										const index = newMultipleSelection.findIndex(d => d.valueOf() == date.valueOf());
										if (index == -1) {
											newMultipleSelection.push(new Date(date.valueOf()));
										} else {
											newMultipleSelection.splice(index, 1);
										}
										setMultipleSelection(newMultipleSelection);
									} else {
										setMultipleSelection([date]);
										changeSelectedDate(date);
									}
								}
							}}
								className={"day dow dow-" + dow + " " + (date > today ? "future" : (date < today ? (props.allowPast ? "" : "past") : "today")) + (selected ? " active" : "") + (secondary ? " secondary" : "") + (available ? " availability" : " no-availability")}>{date.getDate()}</div>
						</React.Fragment>;
					})}
				</div>
			</div>;
		})}
	</div>;
}
