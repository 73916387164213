import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import ContentHelper from '../services/ContentHelper';

export default function ImagenPaso({imageUrl, slug, className}) {
    const mountedRef = useRef(false);
    const [URL, setURL] = useState();

    useEffect(() => {
		mountedRef.current = true;

		if(slug) {
			ContentHelper.GetString(slug).then(response => {
				if(mountedRef.current) {
                    setURL(response);
				}
			});
		} else if(imageUrl) {
			if(mountedRef.current) {
                setURL(imageUrl);
			}
		}

        return () => {
            mountedRef.current = false;
        }
	}, [slug, imageUrl]);
    
    if(!URL) return null;
    return (
        <div className={ className + ' imagen-fondo' } style={{
            background: "url(" + (URL[0] == "/" ? URL : "/static/images/" + URL) + ") no-repeat center/cover"
        }}>
        </div>
    );
}
