import React from "react";
import Helper from "../services/Helper";
import $ from 'jquery';

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOptionIndex: -1,
            dropDownOpen: false
        };

        this.element = null;
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        $('body').off('click', this.onBodyClick.bind(this)).on('click', this.onBodyClick.bind(this));
    }

    componentWillUnmount() {
        this.mounted = false;
        $('body').off('click', this.onBodyClick.bind(this));
    }

    onBodyClick(e) {
        if(!$(e.target).closest('.custom-dropdown').length) {
            if(!this.mounted) return;
            this.setState({
                dropDownOpen: false
            });
        }
    }

    render() {
        return (
            <div className={["custom-dropdown", this.state.dropDownOpen ? "open" : ""].join(" ")}>
                <div onClick={e => {
                    this.setState({ dropDownOpen: true });
                }} className="dropdown-option dropdown-default selected">{this.state.selectedOptionIndex === -1 ? this.props.defaultOption.caption : this.props.options[this.state.selectedOptionIndex].caption}</div>
                <div className="dropdown-options">
                    {
                        this.state.selectedOptionIndex !== -1 && (
                            <div onClick={e => {
                                if(this.props.onChange) {
                                    this.props.onChange("")
                                }
                                this.setState({
                                    selectedOptionIndex: -1,
                                    dropDownOpen: false
                                });
                            }} className="dropdown-option dropdown-default">{this.props.defaultOption.caption}</div>
                        )
                    }
                    {
                        this.props.options.map((option, idx) => {
                            if(idx === this.state.selectedOptionIndex) return null;

                            return (
                                <div onClick={e => {
                                    e.stopPropagation();
                                    if(this.props.onChange) {
                                        this.props.onChange(option.value);
                                    }
                                    this.setState({
                                        selectedOptionIndex: idx,
                                        dropDownOpen: false
                                    });
                                }} className="dropdown-option" data-caption={Helper.SngNormalize(option.caption)} key={idx}>{option.caption}</div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}