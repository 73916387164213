import React, { Component } from 'react';
import Footer from './Footer.tsx';
import FormInput from './FormInput.tsx';
import { Content } from './Content.tsx';
import $ from 'jquery';
import L10n from '../services/Locale.tsx';

class FormularioEventos extends Component {
	componentDidMount() {
		$('body').addClass('bg-offwhite');
	}

	componentWillUnmount() {
		$('body').removeClass('bg-offwhite');
	}

	render() {
		return (
			<div className="formulario-eventos bg-offwhite">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<Content slug="contacto-empresas-titulo" containerClassName="contacto-titulo" />
							<Content slug="contacto-empresas-subtitulo" containerClassName="contacto-subtitulo" />

							<h2>Datos</h2>
							<FormInput required label={L10n.__("Nombre*")} id="nombre" />
							<FormInput required type="email" label={L10n.__("Email*")} id="email"
								onValidate={value => {
									// eslint-disable-next-line no-control-regex
									if (!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return L10n.__("Por favor introduce una dirección de email válida.");
								}}
							/>
							<FormInput label={L10n.__("Teléfono")} id="telefono" />
							<FormInput label={L10n.__("Nombre de la empresa")} id="nombre-empresa" />

							<h2>{L10n.__("Detalles del Evento")}</h2>
							<FormInput required label={L10n.__("Día/mes/hora estimado *")} id="dia" />
							<FormInput required label={L10n.__("Número de personas estimado *")} id="pax" />
							<FormInput required label={L10n.__("Ciudad *")} id="ciudad" />

							<h2>{L10n.__("Cosas más concretas")}</h2>
							<FormInput label="" id="detalles" type="textarea" />
						</div>
					</div>
					<button className="btn btn-brown" onClick={() => {

					}}>{L10n.__("Enviar")}</button>
				</div>
				<Footer />
			</div>
		);
	}
}

export default FormularioEventos;
