import { useEffect, useState } from "react";
import ContentHelper from "../services/ContentHelper.tsx";
import HTMLReactParser from "html-react-parser";

export default function PopupCookies(props) {
	const [content, setContent] = useState(null);
	const [accepted, setAccepted] = useState(localStorage.getItem("cookies_accepted") === "1");

	useEffect(() => {
		ContentHelper.GetString("popup-cookies").then((content) => setContent(content));
	}, []);

	if (content === null) return null;

	return (
		<div className={"popup-cookies" + (accepted ? " accepted" : "")}>
			<span>
				{HTMLReactParser(content)}
			</span>

			<button
				className="btn btn-brown"
				onClick={() => {
					localStorage.setItem("cookies_accepted", "1");
					setAccepted(true);
				}}
			>
				Entendido
			</button>
		</div>
	);
}
