import React, { useEffect, useState } from 'react';
import Helper from '../services/Helper';
import L10n from '../services/Locale';

export default function Carousel(props) {
    const [ numItems, _setNumItems ] = useState(props.items || 4);
    const [ gutter, _setGutter ] = useState(props.gutter || 20);
    const [ currentSlide, setCurrentSlide ] = useState(0);

    function goDirection(dir) {
        let newCurrentSlide = (currentSlide + dir) % (props.elements.length - numItems + 1);
        if (newCurrentSlide < 0) {
            newCurrentSlide += props.elements.length - numItems + 1;
        }
        setCurrentSlide(newCurrentSlide);
    }

    const innerRatio = Math.max(1, props.elements.length/numItems);

    if (Helper.IsResponsive()) return (
        <div className={[ 'container', 'carousel', 'carousel-mobile', props.className ].join(' ')}>
            <div className="horizontal-scroll-container" style={{ width: "calc(" + (100 * props.elements.length) + "vw - " + (60 * props.elements.length) + "px)" }}>
                {props.elements.map((element, index) => props.renderElement(element, index))}
            </div>
        </div>
    );
    else {
        return <section className={['container', 'carousel', props.className, props.elements.length <= numItems ? 'hide-nav' : '' ].join(' ')}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="carousel-container">
                        <div className="carousel-inner" style={{ left: -currentSlide * (100/numItems) + "%", width: "calc(" + Math.ceil(innerRatio * 100) + "%)" }}>
                            {props.elements.map((element, idx) =>
                                <div key={idx} className="carousel-element" style={{ marginRight: idx >= numItems - 1 && idx == props.elements.length - 1 ? 0 : gutter }}>
                                    {props.renderElement(element, idx)}
                                </div>
                            )}
                            {numItems - props.elements.length > 0 && Array(numItems - props.elements.length).fill().map((_element, idx) => 
                                <div key={"dummy-" + idx} className="carousel-element" style={{ marginRight: idx == (numItems - props.elements.length) - 1 ? 0 : gutter }}></div>
                            )}
                        </div>
                    </div>
                    <div className="carousel-nav-button carousel-nav-button-left" onClick={() => goDirection(-1)}><img alt={L10n.__("Anterior")} src="/static/images/chevron-left-black.png" /></div>
                    <div className="carousel-nav-button carousel-nav-button-right" onClick={() =>  goDirection(1)}><img alt={L10n.__("Siguiente")} src="/static/images/chevron-right-black.png" /></div>
                </div>
            </div>
        </section>;
    }
}
