import { useEffect, useState } from "react";
import ContentHelper from "../services/ContentHelper.tsx";

export default function Video(props) {
	const [videoPosterFrameFilename, setVideoPosterFrameFilename] = useState();
	const [videoFilename, setVideoFilename] = useState();

	useEffect(() => {
		if (videoPosterFrameFilename && location.hostname == "localhost" && videoPosterFrameFilename[0] == "/") {
			setVideoPosterFrameFilename("http://localhost:8081" + videoPosterFrameFilename);
		}
	}, [ videoPosterFrameFilename ]);

	useEffect(() => {
		if (videoFilename && location.hostname == "localhost" && videoFilename[0] == "/") {
			setVideoFilename("http://localhost:8081" + videoFilename);
		}
	}, [ videoFilename ]);

	useEffect(() => {
		Promise.all([
			ContentHelper.GetString(props.slug + "-poster-frame"),
			ContentHelper.GetString(props.slug + "-file"),
		]).then(([posterFrame, videoFile]) => {
			setVideoPosterFrameFilename(posterFrame);
			setVideoFilename(videoFile);
		});
	}, []);

	if (!videoPosterFrameFilename || !videoFilename) return null;

	return (
		<video autoPlay={false} controls={false} loop={true} poster={videoPosterFrameFilename} playsInline={true}>
			<source src={videoFilename} type="video/mp4" />
		</video>
	);
}
