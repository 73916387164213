import { useState } from 'react';
import L10n from '../services/Locale';

export default function LanguageSelector(props) {
    const [ open, setOpen ] = useState(false);

	function positionOptions() {
		$(".language-selector .language:not(.active)").each(function(index) {
			$(this).css({ top: document.querySelector(".language-selector").getBoundingClientRect().top + 60 + 60 * index });
		});
	}

    let topOffset = window.innerWidth <= 992 ? -1 : 11;
    return <div onMouseOver={positionOptions} className={"language-selector" + (open ? " force-open" : "")}>{L10n.GetAvailableLanguages().map((language, idx) => {
        return <div className={"language" + (L10n.GetLanguage() == language ? " active" : "")} key={language} onClick={e => {
            e.preventDefault();
            if (L10n.GetLanguage() == language) {
                setOpen(!open);
                return;
            }

            L10n.SetLanguage(language).then(response => {
                location.reload();
            });
        }} >{language}</div>;
    })}</div>;
}
