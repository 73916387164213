import { useEffect, useState, useCallback, useContext } from "react";

import ImageUpload from "./ImageUpload";
import AdminBar from "./AdminBar";
import Field from "./Field";

import L10n from "../services/Locale";
import ContentHelper from "../services/ContentHelper";
import Bloque from "./AdminBloque";
import AdminService from "../services/Admin";
import { DialogContext } from "../context/DialogContext";

export default function AdminHome(props) {
    const [ infoBox, setInfoBox ] = useState(["", {title:"",text:""}]);
	const [loadingInProgress, setLoadingInProgress] = useState(true);
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
        Promise.all([
            ContentHelper.GetJSON("subscription-home-info-boxes")
        ]).then(([infoBox]) => {
            setInfoBox(infoBox);

            setLoadingInProgress(false);
        });

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), async (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

            AdminService.SaveUploadedImage(infoBox[0]).then(response => {
                infoBox[0] = response.data;

                AdminService.SaveContentMultiple({
                    "subscription-home-info-boxes": JSON.stringify(infoBox),
                }).then(() => {
                    setInfoBox(infoBox);
                    notifySaved(true);
                });
            });
		});
	}, [infoBox]);

	return (
		<div className="admin-page admin-home">
			<AdminBar
				onSave={save}
			/>
			<Bloque className="columnas">
				<div className="columna">
                    <ImageUpload defaultValue={infoBox[0]} onChange={value => setInfoBox([value, ...infoBox.slice(1)])} />
				</div>
				<div className="columna">
                    <Field type="string" defaultValue={infoBox[1].title} onChange={value => setInfoBox([infoBox[0], { ...infoBox[1], title: value }, ...infoBox.slice(2)])} />
                    <Field type="text" defaultValue={infoBox[1].text} onChange={value => setInfoBox([infoBox[0], { ...infoBox[1], text: value }, ...infoBox.slice(2)])} />
				</div>
			</Bloque>
		</div>
	);
}

