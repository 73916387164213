import { APIRequest, APIAdminRequest } from "./API";
import React from "react";
import SubscriptionsService from "./Subscriptions";

export default class Trabajo {
	static CachedPlaceholder = null;

	static PeriodCutoffHour() {
		return 17;
	}

	static async GetOfertaTrabajoNames() {
		const response = await APIRequest("trabajo/get-oferta-trabajo-names");
		return response.data;
	}

	static async CreatePlaceholder(allowCached = true) {
		if (Trabajo.CachedPlaceholder && allowCached) {
			return { ...Trabajo.CachedPlaceholder };
		}

		const response = await APIAdminRequest("get-oferta-trabajo-placeholder");
		Trabajo.CachedPlaceholder = response.data;
		return { ...response.data };
	}

	static GetMealTimeForSlot(restaurant, slot) {
		if (!restaurant.uses_limited_slots) {
			return 0;
		}

		const startTime = slot.substr(0, 5);
		const startHour = startTime.substr(0, 2);

		if (startHour < Trabajo.PeriodCutoffHour()) {
			return parseInt(restaurant.mean_lunch_time);
		} else {
			return parseInt(restaurant.mean_dining_time);
		}
	}

	static async Search(search_parameters) {
		const response = await APIRequest("trabajo/search", { parameters: JSON.stringify(search_parameters) });
		return response.data;
	}

	static async GetAllWithAvailability() {
		const response = await APIRequest("trabajo/get-all-with-availability");
		const venues = response.data;
		const types = await Trabajo.GetTypes();

		if (types?.length) {
			venues.forEach((venue) => {
				const type = types.find((t) => t.id == venue.venue_type_id);
				if (type) {
					venue.tipo = type;
				}
			});
		}

		for (let i = 0; i < venues.length; i++) {
			if (venues[i].iconos) {
				try {
					venues[i].iconos = JSON.parse(venues[i].iconos);
				} catch (e) {
					venues[i].iconos = [];
				}
			} else {
				venues[i].iconos = [];
			}
		}

		return venues;
	}

	static async GetAll() {
		const response = await APIRequest("trabajo/get-all");
		const venues = response.data;
		const types = await Trabajo.GetTypes();

		if (types?.length) {
			venues.forEach((venue) => {
				const type = types.find((t) => t.id == venue.empleador_type_id);
				if (type) {
					venue.tipo = type;
				}
			});
		}

		for (let i = 0; i < venues.length; i++) {
			if (venues[i].iconos) {
				try {
					venues[i].iconos = JSON.parse(venues[i].iconos);
				} catch (e) {
					venues[i].iconos = [];
				}
			} else {
				venues[i].iconos = [];
			}
		}

		return venues;
	}

	static async GetSubscriptionTrabajo() {
		return SubscriptionsService.GetAllTrabajo();
	}

	static async GetAvailability(ofertaTrabajoID) {
		const response = await APIRequest("trabajo/availability", { oferta_trabajo_id: ofertaTrabajoID });
		return response.data;
	}

	static async GetAvailabilityBulk(ofertaTrabajoIDs) {
		const response = await APIRequest("trabajo/availability-bulk", { oferta_trabajo_ids: ofertaTrabajoIDs.join(",") });
		return response.data;
	}

	static async GetEmpleador(id, slot = "", includeDisabled = false) {
		const response = await APIRequest("trabajo/get-empleador", { id, slot, include_disabled: includeDisabled ? 1 : 0 });
		return response.data;
	}

	static async GetViviendaSlot(availabilityID) {
		const response = await APIRequest("trabajo/get-oferta-trabajo-slot", { availability_id: availabilityID });
		return response.data;
	}

	static async GetSimilar(id) {
		const similar = await APIRequest("trabajo/similar", { id });
		return similar.data;
	}

	static async GetFeaturedTags() {
		const result = await APIRequest("trabajo/featured-tags");
		return result.data;
	}

	static async GetBySlug(slug) {
		const empleador = await APIRequest("trabajo/get-by-slug", { slug });
		if (empleador.data.slider) {
			empleador.data.slider = JSON.parse(empleador.data.slider);
		}
		return empleador.data;
	}

	static async GetCities() {
		const cities = await APIRequest("trabajo/get-cities");
		return cities.data;
	}

	static renderTagLinks(tags: string, onclick: (e: any) => void) {
		if (!tags) return "";
		const tagsO = JSON.parse(tags);

		return <span className="tag-links" >
			{
				tagsO.map((t, k) => (
					<a onClick={(e) => {
						e.preventDefault();
						onclick.call(e.currentTarget);
					}}
						key={k}
						href="#"
						data-tag={t} >
						{t.toUpperCase()}
					</a>
				))
			}
		</span>
	}

	static RenderAvailabilityTag(pax) {
		if (pax == 0) {
			return <span>Completo </span>;
		}

		return (
			<span>
				{pax} {pax == 1 ? "plaza" : "plazas"}
			</span>
		);
	}

	static async GetAvailabilityForPax(negocioID, pax) {
		const availability = await APIRequest("trabajo/availability-for-pax", { negocioID, pax });
		return availability.data;
	}

	static async GetAvailabilityFromName(ofertaTrabajoID, name) {
		const availability = await APIRequest("trabajo/availability-from-name", { ofertaTrabajo_id: ofertaTrabajoID, name });
		return availability.data;
	}

	static async GetOtherTypes(currentType) {
		const ofertasTrabajo = await APIRequest("trabajo/get-other-types", { current_type: currentType });
		return ofertasTrabajo.data;
	}

	static async getAvailableSlots(restaurant, date, time, pax) {
		const availability = await APIRequest("trabajo/time-availability", { restaurant, date, time, pax });
		return availability.data;
	}

	static async getByTag(tag, n) {
		const ofertasTrabajo = await APIRequest("trabajo/by-tag", { tag, count: n });
		return ofertasTrabajo;
	}

	static async SetFavorite(restaurant_id, favorite = true) {
		await APIRequest("trabajo/set-favorite", { restaurant_id, favorite: favorite ? 1 : 0 });
	}

	static async IsFavorite(restaurant_id) {
		const response = await APIRequest("trabajo/is-favorite", { restaurant_id });
		return response.data;
	}

	static async GetFavorites() {
		const ofertasTrabajo = await APIRequest("trabajo/get-favorites");
		return ofertasTrabajo.data;
	}

	static RenderButtonCaption(ofertaTrabajo) {
		return <React.Fragment>RESERVAR AHORA </React.Fragment>;
		// if (parseInt(ofertaTrabajo.accepts_preorder)) {
		//     return (<React.Fragment>RESERVAR AHORA</React.Fragment>);
		// } else {
		//     return (<React.Fragment>Ticket {(ofertaTrabajo.price * 0.01).toLocaleString('es', {minimumFractionDigits: 2})} € | RESERVAR</React.Fragment>);
		// }
	}

	static async GetDates(ofertaTrabajoID, date = "") {
		const response = await APIRequest("trabajo/get-dates", { oferta_trabajo_id: ofertaTrabajoID, date });
		return response.data;
	}

	static async GetTypes() {
		const response = await APIRequest("trabajo/get-types");
		return response.data;
	}

	// ofertaTrabajo, slot, duration, pax, full_booking, price_alt, address_alt, city_alt, description_alt, availability_description
	static async PartnerCreateAvailability(data) {
		const response = await APIRequest("partners/create-availability", data);
		return response.status;
	}

	static async PartnerAlterAvailability(data) {
		const response = await APIRequest("partners/alter-availability", data);
		return response.status;
	}

	static async PartnerDeleteAvailability(id) {
		const response = await APIRequest("partners/delete-availability", { id });
		return response.status;
	}

	static async PartnerDeleteAvailabilities(availabilities) {
		let status = true;

		availabilities.forEach(async (availability) => {
			status = status ? await PartnerDeleteAvailability(availability.id) : status;
		});

		return status;
	}
}
