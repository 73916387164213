import L10n from "../services/Locale";
import Helper from "../services/Helper";

export default function AdminKPINegocio({kpis}) {
    return <div className="kpi-container">
        <div className="item">
            <div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerUser / 100))}</div>
            <div className="caption">{L10n.__("Tícket medio por persona")}</div>
        </div>
        <div className="item">
            <div className="value">{Math.round(((kpis.recurrencia * 100 + Number.EPSILON) * 100) / 100)}%</div>
            <div className="caption">{L10n.__("Recurrencia")}</div>
        </div>
        <div className="item">
            <div className="value">{Math.round(((kpis.upsellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
            <div className="caption">{L10n.__("Conversión upselling")}</div>
        </div>
        <div className="item">
            <div className="value">{Math.round(((kpis.crossSellingRatio * 100 + Number.EPSILON) * 100) / 100)}%</div>
            <div className="caption">{L10n.__("Conversión cross-selling")}</div>
        </div>
    </div>;
}
