import { useState, useEffect, useContext, useCallback } from "react";
import LoadingIndicator from "./LoadingIndicator";
import ContentHelper from "../services/ContentHelper";
import TextEditor from "./TextEditor";
import AdminBar from "./AdminBar";
import AdminService from "../services/Admin";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";

export default function AdminPaginaHTML(props) {
    const [content, setContent] = useState(null);
    const dialogContext = useContext(DialogContext);

    const contentSlug = props.contentSlug;
    useEffect(() => {
        if (!contentSlug) return;
        ContentHelper.GetString(contentSlug).then(content => setContent(content));
    }, [contentSlug]);

    const save = useCallback(notifySaved => {
        dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
            if (!confirmed) {
                notifySaved(false);
                return;
            }
            
            AdminService.SaveContent(contentSlug, content).then(() => {
                notifySaved(true);
            });
        });
    }, [ contentSlug, content ]);

    if (content === null) {
        return <LoadingIndicator />;
    }

    return <div className={"admin-page admin-pagina-html " + props.contentSlug}>
        <AdminBar onSave={save} />
        <TextEditor initialValue={content} onChange={value => { setContent(value) }} />
    </div>;
}
