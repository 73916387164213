import FormInput from "./FormInput.tsx";
import MaterialIcon from "./MaterialIcon.tsx";
import L10n from "../services/Locale.tsx";
import Helper from "../services/Helper.tsx";

export default function EventInfo(props) {
    let info;
    
    if (props.negocio) {
        info = props.negocio.info;
    } else if (props.info) {
        info = JSON.parse(props.info);
    }

    if (!info) return null;

    if (info.composite) {
        const composite = info.composite;
        const weekDays = [ L10n.__("lunes"), L10n.__("martes"), L10n.__("miércoles"), L10n.__("jueves"), L10n.__("viernes"), L10n.__("sábado"), L10n.__("domingo") ];

        const groups = [];
        let start = 0;
        let end = 0;
        while (end <= 6) {
            if (composite[end][0] != composite[start][0] || composite[end][1] != composite[start][1] || composite[end][2] != composite[start][2] || composite[end][3] != composite[start][3]) {
                groups.push({ start: start, end: end - 1 });
                start = end;
            }
            end++;
        }
        
        groups.push({ start, end: end - 1 });

        let compositeStringComponents = [];
        let compositeComponents = [];

        groups.forEach(group => {
            if (group.start == group.end) {

                if (composite[group.start][0] == composite[group.start][1]) {
                    compositeComponents.push({ date: [weekDays[group.start]], time: [] });
                } else {
                    compositeComponents.push({ date: [weekDays[group.start]], time: [composite[group.start][0], composite[group.start][1], composite[group.start][2], composite[group.start][3]] });

                    compositeStringComponents.push(weekDays[group.start] + " " + L10n.__("de") + " " + composite[group.start][0] + " " + L10n.__("a") + " " + composite[group.start][1] + (composite[group.start][2] != composite[group.start][3] ? (" " + L10n.__("y de") + " " + composite[group.start][2] + " " + L10n.__("a") + " " + composite[group.start][3]) : ""));
                }
            } else {                
                if (composite[group.start][0] == composite[group.start][1]) {
                    compositeComponents.push({ date: [weekDays[group.start], weekDays[group.end]], time: [] });
                } else {
                    compositeComponents.push({ date: [weekDays[group.start], weekDays[group.end]], time: [composite[group.start][0], composite[group.start][1], composite[group.start][2], composite[group.start][3]] });
                    
                    compositeStringComponents.push(L10n.__("de") + " " + weekDays[group.start] + " " + L10n.__("a") + " " + weekDays[group.end] + " " + L10n.__("de") + " " + composite[group.start][0] + " " + L10n.__("a") + " " + composite[group.start][1] + (composite[group.start][2] != composite[group.start][3] ? (" " + L10n.__("y de") + " " + composite[group.start][2] + " " + L10n.__("a") + " " + composite[group.start][3]) : ""));
                }
            }
        });

        let closedDaysString;

        const closedDayComponents = compositeComponents ? compositeComponents.filter(c => c.time.length == 0) : [];
        let closedDaysStringComponents = [];
        closedDayComponents.forEach(component => {
            closedDaysStringComponents.push(component.date.length > 1 ? component.date[0] + " a " + component.date[1] : component.date[0]);
        });

        return <div className="event-text event-info widget-horario">{compositeComponents && compositeComponents.filter(c => c.time.length > 0).map(component => {
            return <div className="composite-component" key={"component" + Helper.Hash(JSON.stringify(component))}>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="calendar_month" /> {Helper.UCFirst(component.date[0])}{component.date.length > 1 && " a " + component.date[1]}</div>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="schedule" /> {
                    component.time[0] == "00:00" && component.time[1] == "23:59" ? L10n.__("Abierto las 24 horas") :
                    component.time[0] == "00:00" && component.time[1] == "00:00" && component.time[2] == "00:00" && component.time[3] == "00:00" ? L10n.__("Cerrado") :
                    component.time[0] + " " + L10n.__("a") + " " + component.time[1] + ((component.time.length > 2 && component.time[2] != component.time[3]) ? (" | " + component.time[2] + " " + L10n.__("a") + " " + component.time[3]) : "")
                }</div>
            </div>
        })}
            
            {closedDaysStringComponents.length > 0 && <div className="composite-component" key={"component" + Helper.Hash(closedDaysStringComponents[0])}>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="calendar_month" /> {Helper.UCFirst(closedDaysStringComponents.slice(0, closedDaysStringComponents.length > 2 ? closedDaysStringComponents.length - 1 : 1).join(", ")) + (closedDaysStringComponents.length > 1 ? " " + L10n.__("y") + " " + closedDaysStringComponents.slice(-1) : "")}</div>
                <div><MaterialIcon style={{marginRight:"0.25em"}} name="schedule" /> {L10n.__("Cerrado")}</div>
            </div>}
        </div>;
    }

    if (props.editable) {
        return <div className="event-text event-info">
            <div><MaterialIcon name="calendar_month" style={{ marginRight: "0.25em" }} /> <FormInput initialValue={info.dias} /></div>
            <div><MaterialIcon name="schedule" style={{ marginRight: "0.25em" }} /> <FormInput initialValue={info.horario} /></div>
        </div>;
    } else {
        return <div className="event-text event-info">
            <div><MaterialIcon name="calendar_month" style={{ marginRight: "0.25em" }} /> {info.dias}</div>
            <div><MaterialIcon name="schedule" style={{ marginRight: "0.25em" }} /> {info.horario}</div>
        </div>;
    }
}