import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogContext } from '../context/DialogContext';
import { APIAdminRequest } from '../services/API';
import LoadingIndicator from './LoadingIndicator';
import AdminBar from './AdminBar';
import Bloque from './AdminBloque';
import L10n from '../services/Locale';
import MaterialIcon from './MaterialIcon';
import ImageUpload from './ImageUpload';
import Field from './Field';
import UI from '../services/UI';
import Admin from './Admin';
import AdminService from '../services/Admin';

export default function AdminEditarCiudad(props) {
    const [ finishedLoading, setFinishedLoading ] = useState(false);
    const [ city, setCity ] = useState(null);
    const [ validationErrors, setValidationErrors ] = useState({});

	const dialogContext = useContext(DialogContext);

	const navigate = useNavigate();

	useEffect(() => {
        if (props.ciudadID == 0) {
            setCity({
                id: 0,
                name: '',
                slug: '',
                image_url: null
            });
            setFinishedLoading(true);
        } else {
            APIAdminRequest("get-city", { id: props.ciudadID }).then(response => {
                setCity(response.data);
                setFinishedLoading(true);
            });
        }

		document.body.classList.add("bg-grey", "has-header");

		return () => {
			document.body.classList.remove("bg-grey", "has-header");
		};
	}, []);

	const save = useCallback(notifySaved => {
		let validated = true;
		const n = {...validationErrors};
		for (let key of ["name", "image_url"]) {
			if (!city[key]) {
				validated = false;
				n[key] = true;
			}
		}

		setValidationErrors(n);

		if (!validated) {
			UI.ShowNotification("Por favor rellena todos los campos obligatorios.");
			notifySaved(false);
			return;
		}
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

            const n = {...city};
            AdminService.SaveUploadedFile(n.image_url).then(response => {
                if (response.data) n.image_url = response.data;
                APIAdminRequest("save-city", n).then(response => {
                    notifySaved(true);
                    if (props.ciudadID == 0) {
                        navigate(BASENAME + "/admin/ciudades/" + response.data.id);
                    } else {
                        setCity(response.data);
                    }
                });
            });
		});
	}, [ city ]);

    if (!finishedLoading) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-ciudades">
			<AdminBar onSave={save} />
			<div className="header">
				<h2>{city.name ? city.name : L10n.__("Nueva ciudad")}</h2>
				{props.ciudadID != 0 && <div style={{marginLeft:"1em"}} className="delete-ciudad-container">
					<MaterialIcon onClick={e => {
						dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar esta ciudad?"), L10n.__("Sí"), L10n.__("No"), confirmed => {
							if (confirmed) {
								APIAdminRequest("delete-city", { id: props.ciudadID }).then(() => {
									navigate(BASENAME + "/admin/ciudades");
								});
							}
						});
					}} name="delete" tooltip={L10n.__("Borrar ciudad")} />
				</div>}
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload
						className={validationErrors["image_url"] ? "error" : ""}
						recommendedWidth={1000}
						recommendedHeight={400}
						maxFileSize={600}
						initialValue={city.image_url}
						onChange={(value) => {
							const n = { ...city };
							n.image_url = value;
							setCity(n);
						}}
					/>
				</div>
				<div className="columna">
					<div className="columnas">
						<Field
							placeholder={L10n.__("Nombre")}
							className={"expand" + (validationErrors["name"] ? " error" : "")}
							type="string"
							defaultValue={city.name}
							maxLength={25}
							onChange={(value) => {
								const n = { ...city };
								n.name = value;
								setCity(n);
							}}
						/>
					</div>
				</div>
			</Bloque>
		</div>
	);
}
