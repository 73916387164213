import { useEffect, useState, useContext, useCallback} from "react";
import AdminService from "../services/Admin";
import ContentHelper from "../services/ContentHelper";
import AdminBar from "./AdminBar";
import Field from "./Field";
import ImageUpload from "./ImageUpload";
import ImageUploadList from "./ImageUploadList";
import Bloque from "./AdminBloque";
import LoadingIndicator from "./LoadingIndicator";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";
import FormToggle from "./FormToggle";

export default function AdminPaginaEstatica(props) {
	const [strings, setStrings] = useState(null);
	const [ enabled, setEnabled ] = useState(null);
    const dialogContext = useContext(DialogContext);

	useEffect(() => {
		ContentHelper.GetStrings(["eventos-imagenes-banda", "eventos-imagenes-banda-inferior", "eventos-imagen-1", "eventos-imagen-2", "eventos-imagen-3", "eventos-supertitulo", "eventos-titulo", "eventos-subtitulo", "eventos-banda-titulo", "eventos-banda-texto", "eventos-destacado-1-titulo", "eventos-destacado-1-texto", "eventos-destacado-2-titulo", "eventos-destacado-2-texto", "eventos-banda-html", "eventos-banda-inferior-html", "eventos-banda-inferior-titulo", "eventos-cta"]).then((strings) => {
			setStrings(strings);
		});

		AdminService.GetSettings("SHOW_PAGINA_ESTATICA", true).then(response =>  {
			setEnabled(response);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			Promise.all([
				AdminService.SaveUploadedImage(strings["eventos-imagen-1"]),
				AdminService.SaveUploadedImage(strings["eventos-imagen-2"]),
				AdminService.SaveUploadedImage(strings["eventos-imagen-3"]),
				AdminService.SaveUploadedImageList(JSON.parse(strings["eventos-imagenes-banda"])),
				AdminService.SaveUploadedImageList(JSON.parse(strings["eventos-imagenes-banda-inferior"])),
			]).then(([imagen1, imagen2, imagen3, banda, bandaInferior]) => { 
				const n = {...strings};

				if (imagen1.data) n["eventos-imagen-1"] = imagen1.data;
				if (imagen2.data) n["eventos-imagen-2"] = imagen2.data;
				if (imagen3.data) n["eventos-imagen-3"] = imagen3.data;
				if (banda) n["eventos-imagenes-banda"] = JSON.stringify(banda);
				if (bandaInferior) n["eventos-imagenes-banda-inferior"] = JSON.stringify(bandaInferior);

				AdminService.SaveContentMultiple(n).then(() => {
					AdminService.SetSettings("SHOW_PAGINA_ESTATICA", enabled ? 1 : 0, true).then(() => {
						setStrings(n);
						notifySaved(true);
					});
				});
			});
		});
	}, [strings, enabled]);

	if (!strings || enabled === null) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-pagina-estatica">
			<AdminBar onSave={save} />
			<Bloque>
				<FormToggle className="has-tooltip" tooltip={L10n.__("Mostrar en el menú")} defaultValue={enabled} onChange={value => {
					setEnabled(value);
				}} />
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-1"]} onChange={url => {
						const n = {...strings};
						n["eventos-imagen-1"] = url;
						setStrings(n);
					}} />
				</div>
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-supertitulo"]} onChange={value => {
								const n = {...strings};
								n["eventos-supertitulo"] = value;
								setStrings(n);
							}}/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-titulo"]} onChange={value => {
								const n = {...strings};
								n["eventos-titulo"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-subtitulo"]} onChange={value => {
								const n = {...strings};
								n["eventos-subtitulo"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-cta"]} onChange={value => {
								const n = {...strings};
								n["eventos-cta"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-banda-titulo"]} onChange={(value) => {
								const n = {...strings};
								n["eventos-banda-titulo"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-banda-texto"]} onChange={(value) => {
								const n = {...strings};
								n["eventos-banda-texto"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ImageUploadList defaultValue={JSON.parse(strings["eventos-imagenes-banda"] || "[]")} onChange={(value) => {
								const n = {...strings};
								n["eventos-imagenes-banda"] = JSON.stringify(value);
								setStrings(n);
							}} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-destacado-1-titulo"]} onChange={value => {
								const n = {...strings};
								n["eventos-destacado-1-titulo"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-destacado-1-texto"]} onChange={value => {
								const n = {...strings};
								n["eventos-destacado-1-texto"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
				</div>
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-2"]} onChange={(value) => {
						const n = {...strings};
						n["eventos-imagen-2"] = value;
						setStrings(n);
					}} />
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-3"]} onChange={(value) => {
						const n = {...strings};
						n["eventos-imagen-3"] = value;
						setStrings(n);
					}} />
				</div>
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-destacado-2-titulo"]} onChange={value => {
								const n = {...strings};
								n["eventos-destacado-2-titulo"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-destacado-2-texto"]} onChange={value => {
								const n = {...strings};
								n["eventos-destacado-2-texto"] = value;
								setStrings(n);
							}} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="filas">
				<div className="fila">
					<Field type="string" defaultValue={strings["eventos-banda-inferior-titulo"]} onChange={(value) => {
						const n = {...strings};
						n["eventos-banda-inferior-titulo"] = value;
						setStrings(n);
					}} />
				</div>
				<div className="fila">
					<ImageUploadList className="inferior" defaultValue={JSON.parse(strings["eventos-imagenes-banda-inferior"] || "[]")} onChange={(value) => {
						const n = {...strings};
						n["eventos-imagenes-banda-inferior"] = JSON.stringify(value);
						n(strings);
					}} />
				</div>
			</Bloque>
		</div>
	);
}
