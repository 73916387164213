import { useState, useEffect, useCallback, useContext } from "react";
import ImageUpload from "./ImageUpload";

import Bloque from "./AdminBloque";
import ContentHelper from "../services/ContentHelper";
import LoadingIndicator from "./LoadingIndicator";
import Field from "./Field";
import TextEditor from "./TextEditor";
import MaterialIcon from "./MaterialIcon";
import AdminBar from "./AdminBar";
import L10n from "../services/Locale";

import { DialogContext } from "../context/DialogContext";
import AdminService from "../services/Admin";

export default function AdminFAQ(props) {
	const [faq, setFaq] = useState(false);
	const [headerImage, setHeaderImage] = useState(null);
	const [openFAQIndex, setOpenFAQIndex] = useState(-1);
	const [dragging, setDragging] = useState(-1);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		ContentHelper.GetString("faq-url-fondo-cabecera").then((url) => setHeaderImage(url));

		ContentHelper.GetFAQAll().then((faq) => {
			setFaq(faq);
		});

		function onMouseUp(e) {
			setDragging(-1);
		}

		$(window).on("mouseup", onMouseUp);

		return () => {
			$(window).off("mouseup", onMouseUp);
		};
	}, []);

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			Promise.all([AdminService.SaveContent("faq-url-fondo-cabecera", headerImage, "url"), AdminService.SaveFAQAll(faq)]).then(() => {
				notifySaved(true);
			});
		});
	}, [ headerImage, faq ]);

	if (!faq) return <LoadingIndicator />;

	return (
		<div className="admin-faq">
			<AdminBar
				onSave={save}
			/>
			<Bloque title={L10n.__("Imagen de encabezado")}>{headerImage !== null && <ImageUpload sizeMode="cover" initialValue={headerImage} onChange={(value) => setHeaderImage(value)} />}</Bloque>
			<Bloque title={L10n.__("Preguntas")}>
				<div className={"faq-list" + (dragging != -1 ? " dragging" : "")}>
					{faq &&
						faq.map((item, idx) => {
							return (
								<div key={item.key || idx} className="faq-item">
									<div
										className={"faq-header" + (openFAQIndex == idx ? " expanded" : "") + (dragging == idx ? " dragging" : "")}
										onClick={(e) => {
											if (openFAQIndex == idx) setOpenFAQIndex(-1);
											else setOpenFAQIndex(idx);
										}}
										onMouseUp={(e) => {
											if (dragging == -1 || dragging == idx) {
												return;
											}

											const draggedItem = { ...faq[dragging] };
											const newItems = [...faq];
											newItems.splice(dragging, 1);
											newItems.splice(
												newItems.findIndex((v) => v.titulo == item.titulo && v.contenido == item.contenido),
												0,
												draggedItem
											);
											setFaq(newItems);
										}}>
										{dragging == -1 && (
											<div
												className="drag-button"
												onMouseDown={(e) => {
													e.preventDefault();
													setDragging(idx);
												}}>
												<MaterialIcon name="drag_indicator" />
											</div>
										)}
										<span>{item.titulo}</span>
										<div className="expand-button">
											<MaterialIcon name={openFAQIndex == idx ? "expand_less" : "expand_more"} />
										</div>
									</div>
									{openFAQIndex == idx && (
										<div className="faq-content">
											<label>{L10n.__("Pregunta")}</label>
											<Field
												type="string"
												defaultValue={item.titulo}
												onChange={(value) => {
													const newItems = [...faq];
													newItems[idx].titulo = value;
													setFaq(newItems);
												}}
											/>
											<label>{L10n.__("Respuesta")}</label>
											<Field
												type="text"
												defaultValue={item.contenido}
												onChange={(value) => {
													const newItems = [...faq];
													newItems[idx].contenido = value;
													setFaq(newItems);
												}}
											/>

											<a
												href="#"
												className="delete-button btn"
												onClick={(e) => {
													e.preventDefault();
													const newItems = [...faq];
													newItems.splice(idx, 1);
													setFaq(newItems);
													setOpenFAQIndex(-1);
												}}>
												{L10n.__("Borrar pregunta")}
											</a>
										</div>
									)}
								</div>
							);
						})}
					<div
						className="add-new faq-header"
						onClick={(e) => {
							const newItems = [...faq];
							newItems.push({ titulo: L10n.__("Pregunta sin título"), contenido: "" });
							setFaq(newItems);
						}}
						onMouseUp={(e) => {
							if (dragging == -1) {
								return;
							}

							const newItems = [...faq];
							newItems.splice(dragging, 1);
							newItems.push([...faq][dragging]);
							setFaq(newItems);
						}}>
						<MaterialIcon name="add_circle" />
					</div>
				</div>
			</Bloque>
		</div>
	);
}
