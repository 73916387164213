import { useState, useEffect } from "react";
import Helper from "../services/Helper";

export default function DotMenu(props) {
	const [showMenu, setShowMenu] = useState(false);
	const [elementID, _] = useState(Helper.GetRandomID("dots-menu"));

	const onClick = (e) => {
		if (!$(e.target).attr("id") != elementID && $(e.target).closest("#" + elementID).length == 0) setShowMenu(false);
	};

	const positionMenu = () => {
		let leftOffset = $("#" + elementID)[0].getBoundingClientRect().left + 15;

		if (leftOffset + 182 > window.innerWidth - 15) {
			leftOffset = leftOffset + 182 - (window.innerWidth - 15);
		}

		$("#" + elementID + " .menu").css({
			left: leftOffset,
			top: $("#" + elementID)[0].getBoundingClientRect().top + 15
		});
	};

	useEffect(() => {
		if (showMenu) {
			if (props.closeMenuRef) {
				props.closeMenuRef(function () {
					setShowMenu(false);
				});
			}
			document.body.addEventListener("click", onClick);
		} else {
			document.body.removeEventListener("click", onClick);
		}
	}, [showMenu]);

	useEffect(() => {
		positionMenu();

		const onScroll = (e) => {
			setShowMenu(false);
		};
		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	return (
		<div className={"dot-menu" + (showMenu ? " active" : "") + (props.align == "left" ? " left" : "")} id={elementID}>
			<div
				className="dots"
				onClick={(e) => {
					positionMenu();
					setShowMenu(!showMenu);
				}}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div className={"menu" + (showMenu ? " active" : "")}>
				{props.options
					?.filter((v) => v)
					.map((option, idx) => {
						return (
							<a
								key={"dot-menu-option-" + idx}
								href="#"
								onClick={(e) => {
									e.preventDefault();
									setShowMenu(false);
									option.action();
								}}>
								{option.caption}
							</a>
						);
					})}
			</div>
		</div>
	);
}
