import { useEffect, useState, useCallback } from 'react';
import MaterialIcon from "./MaterialIcon";

export default function InputSelect(props) {
    const style = props.style || {};
    const allOptions = props.options || [];
    const defaultValue = props.defaultValue || "";
    const onChange = props.onChange || (() => {});

    const [ options, setOptions ] = useState([...allOptions]);
    const [ showOptionsList, setShowOptionsList ] = useState();
    const [ previousValue, setPreviousValue ] = useState(defaultValue);
    const [ value, setValue ] = useState(defaultValue);
    const [ selectedOptionIndex, setSelectedOptionIndex ] = useState(-1);
    const [ elementID, setElementID ] = useState("inputselect-" + Math.ceil(Math.random() * 10000));

    const filterOptions = useCallback(partialTerm => {
        setOptions(allOptions.filter(option => option.toLowerCase().indexOf(partialTerm.toLowerCase()) != -1));
    }, []);

    useEffect(() => {
        if (props.comboMode) {
            const onBodyClick = e => {
                if ((e.target.parentNode?.id != elementID || !e.target.classList.contains("combo-mode-button")) && (!e.target.classList.contains("option") || !e.target.parentNode?.classList.contains("combo-mode-button"))) {
                    setShowOptionsList(false);
                }
            }

            document.body.addEventListener("click", onBodyClick);

            return () => {
                document.body.removeEventListener("click", onBodyClick);
            }
        }
    }, []);

    useEffect(() => {
        if (value != previousValue && onChange) onChange(value);
        setPreviousValue(value);
    }, [value]);

    useEffect(() => {
        const onScroll = () => {
            $(".options-list").each(function() {
                $(this).css({
                    position: "fixed",
                    top: this.parentNode.getBoundingClientRect().bottom,
                    zIndex: 3,
                    width: $(this).prev().outerWidth()
                });
            });
        };

        if (showOptionsList) {
            $(".options-list").each(function() {
                const rect = (this.parentNode.querySelector("input") || this.parentNode.querySelector(".combo-mode-button")).getBoundingClientRect();
                $(this).css({
                    position: "fixed",
                    top: rect.bottom,
                    left: props.comboMode ? "" : rect.left,
                    width: rect.width,
                    zIndex: 3
                });
            });

            window.addEventListener("scroll", onScroll);
        }

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [ showOptionsList ]);

    if (props.comboMode) {
        style.cursor = "pointer";
    }

    return <div id={elementID} className={"input-select " + (props.disabled ? " disabled" : "") + (props.comboMode ? " combo-mode" : "") + (props.className || "") + (props.placeholder ? " has-placeholder" : "")} style={style} onClick={props.comboMode && ((e) => {
        setShowOptionsList(true);
    })}>
        {props.comboMode && <MaterialIcon name="expand_more" />}
		{props.placeholder != "" && <div className="placeholder">{props.placeholder}</div>}
        {!props.comboMode && <input
            value={value}
            onFocus={e => {
                setShowOptionsList(true);
                filterOptions(value);
            }}
            onBlur={e => {
                setTimeout(() => {
                    setShowOptionsList(false);
                    setSelectedOptionIndex(-1);
                }, 150);
            }}
            onKeyDown={e => {
                if (e.key == "ArrowDown") {
                    setSelectedOptionIndex((selectedOptionIndex + 1) % options.length);
                } else if (e.key == "ArrowUp") {
                    let newIndex = selectedOptionIndex - 1;
                    if (newIndex < 0) newIndex = options.length - 1;
                    setSelectedOptionIndex(newIndex);
                } else if (e.key == "Enter") {
                    setValue(options[selectedOptionIndex]);
                    setShowOptionsList(false);
                    setSelectedOptionIndex(-1);
                }
            }}
            onChange={e => {
                filterOptions(e.target.value);
                setValue(e.target.value);
                setShowOptionsList(true);
            }}
        />}
        {props.comboMode && <div className="combo-mode-button">{value}</div>}
        {showOptionsList && <div className="options-list">
            {options.map((option, idx) => {
                return <div key={idx} className={["option", (selectedOptionIndex == idx ? "selected" : "")].join(" ")} onClick={e => {
                    if (props.disabled) return;
                    setShowOptionsList(false);
                    setSelectedOptionIndex(-1);
                    setValue(option);
                }}>{option}</div>;
            })}    
        </div>}
    </div>;
}
