import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Trabajo from "../services/Trabajo";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import TextEditor from "./TextEditor";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";
import FormToggle from "./FormToggle";
import { StarMeter } from "./Encuesta.tsx";
import BarChart from "./estadisticas/BarChart";
import DotMenu from "./DotMenu";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";
import L10n from "../services/Locale";
import UI from "../services/UI";

import "../../css/admin-editar-evento.css";
import { DaySelect } from "./AdminWidgetHorario";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import React from "react";

export default function AdminEditarVivienda(props) {
    const [vivienda, setVivienda] = useState(null);
    const [vendedor, setVendedor] = useState(null);
    const dialogContext = useContext(DialogContext);

    const [selectedDays, setSelectedDays] = useState([0]);
    const [timeTable, setTimeTable] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [programarEnabled, setProgramarEnabled] = useState(null);
    const [surveyData, setSurveyData] = useState(null);

    const [statTypes, setStatsTypes] = useState();
    const [kpis, setKpis] = useState();
    const [ordersPerDay, setOrdersPerDay] = useState();

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [viviendaSubscriptionTypes, setViviendaSubscriptionTypes] = useState([]);
    const [newViviendaSubscriptionType, setNewViviendaSubscriptionType] = useState(null);
    const [availableSubscriptionTypes, setAvailableSubscriptionTypes] = useState([]);
    const [individualSubscription, setIndividualSubscription] = useState(false);
    const [viviendaSubscriptionData, setViviendaSubscriptionData] = useState({
        pax: 1,
        price: 0,
        stock: -1,
        combinable_visits: 0,
        visits: 1,
        page_data: {},
        renewable: 1
    });
    const [priceChanged, setPriceChanged] = useState(false);
    const [loading, setLoading] = useState(true);

    const [noSubscriptionTypeAlert, setNoSubscriptionTypeAlert] = useState(false);

    const viviendaID = props.viviendaID || 0;
    const vendedorID = props.vendedorID || 0;

    const navigate = useNavigate();

    const subscriptionsContext = useContext(SubscriptionsContext);

    useEffect(() => {
        if (!vendedor) return () => { };

        if (viviendaID == 0) {
            setVivienda({
                image_url: "",
                full_booking: 0,
                disponibilidad_multiple: 0,
                offers_upselling: 0,
                offers_preorder: 0,
                nombre: "",
                price: vendedor.price,
                duration: 0,
                address: null,
                description: "",
                extras_menu: {},
                vendedor_id: vendedorID,
                enabled: true,
                no_calendar: 0,
                has_active_subscriptions: 0,
                interval: 1,
                interval_type: "month"
            });

            setProgramarEnabled(true);
            setTimeTable([[], [], [], [], [], [], []]);
        } else {
            APIAdminRequest("get-vivienda", { id: viviendaID, locale: L10n.GetLanguage() }).then((response) => {
                response.data.page_data = JSON.parse(response.data.page_data || "{\"sections\":[]}");
                response.data.page_data.sections?.forEach(section => section.forEach(column => {
                    if (column.type == "faq") {
                        column.faq?.forEach(item => {
                            item.key = Helper.RandomKey();
                        });
                    }
                }));
                setVivienda(response.data);
            });

            if (viviendaID > 0) {
                Promise.all([APIAdminRequest("stats-get-types"), APIAdminRequest("stats-get-vendedor-kpi", { vivienda_id: viviendaID }), APIAdminRequest("stats-get-vendedoro-orders-per-day", { vivienda_id: viviendaID })]).then(([types, KPI, ordersPerDay, surveys]) => {
                    setStatsTypes(types.data);
                    setKpis(KPI.data);
                    setOrdersPerDay(ordersPerDay.data);
                });
            }
        }

        document.body.classList.add("bg-grey");

        return () => {
            document.body.classList.remove("bg-grey");
        };
    }, [vendedor]);

    useEffect(() => {
        if (!programarEnabled) {
            const n = { ...validationErrors };
            n["programar-disponibilidad"] = false;
            setValidationErrors(n);
        }
    }, [programarEnabled]);

    useEffect(() => {
        setNewViviendaSubscriptionType(0);
    }, [availableSubscriptionTypes]);

    useEffect(() => {
        setAvailableSubscriptionTypes(subscriptionTypes.filter((t) => viviendaSubscriptionTypes.findIndex((et) => et.id == t.id) == -1 && (individualSubscription ? t.pack != 1 : t.pack == 1)));
    }, [individualSubscription, subscriptionTypes, viviendaSubscriptionTypes]);

    useEffect(() => {
        if (vendedorID) {
            APIAdminRequest("get-vendedor", { id: vendedorID, locale: L10n.GetLanguage() }).then((response) => {
                setVendedor(response.data);
            });
        } else {
            navigate(BASENAME + "/admin/venta-vivienda/" + vendedorID);
        }

        setLoading(false);

        document.body.classList.add("has-header");

        return () => {
            document.body.classList.remove("has-header");
        };
    }, []);

    function renderColumnOptions(section, column, section_idx, column_idx) {
        return (
            <div className="options">
                <select defaultValue={column.type} onChange={e => {
                    const n = { ...vivienda };
                    n.page_data.sections[section_idx][column_idx].type = e.target.value;
                    setVivienda(n);
                }}>
                    <option value="image">{L10n.__("Imagen")}</option>
                    <option value="text">{L10n.__("Texto")}</option>
                    <option value="faq">{L10n.__("FAQ")}</option>
                    <option value="file-upload">{L10n.__("Subida de archivo")}</option>
                </select>
                <div className="buttons">
                    <MaterialIcon
                        name="delete"
                        tooltip={section.length == 2 ? "Borrar columna" : "Borrar fila"}
                        onClick={(e) => {
                            e.preventDefault();
                            const n = { ...vivienda };

                            if (section.length == 2) {
                                // Borrar columna
                                n.page_data.sections[section_idx].splice(column_idx, 1);
                            } else {
                                // Borrar fila
                                n.page_data.sections.splice(section_idx, 1);
                            }

                            $(".floating-tooltip").hide();
                            setVivienda(n);
                        }}
                    />
                    {section.length == 1 && (
                        <MaterialIcon
                            name="add"
                            tooltip="Añadir columna"
                            onClick={(e) => {
                                e.preventDefault();
                                const n = { ...vivienda };
                                n.page_data.sections[section_idx].push({ type: "text" });
                                setVivienda(n);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    function alterContent(sectionIndex, columnIndex, data) {
        const n = { ...vivienda };
        for (let key of Object.keys(data)) {
            n.page_data.sections[sectionIndex][columnIndex][key] = data[key];
        }
        setVivienda(n);
    }

    const save = useCallback(
        (notifySaved) => {
            const doSave = (confirmationMessage) => {
                let validated = true;
                const n = { ...validationErrors };
                for (let key of ["image_url", "nombre", "description"]) {
                    if (!vivienda[key]) {
                        validated = false;
                        n[key] = true;
                    }
                }

                setValidationErrors(n);

                if (!validated) {
                    UI.ShowNotification(L10n.__("Por favor rellena todos los campos obligatorios."));
                    notifySaved(false);
                    return;
                }

                if (individualSubscription && viviendaSubscriptionData.visits != -1 && viviendaSubscriptionData.visits < vivienda.visit_cost) {
                    UI.ShowNotification(L10n.__("El número de visitas permitidas no puede ser inferior al consumo por visita."));
                    notifySaved(false);
                    return;
                }

                dialogContext.openDialog(confirmationMessage ?? L10n.__("¿Seguro que quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
                    if (confirmed) {
                        const n = { ...vivienda };
                        const promises = [];

                        Object.values(n.extras_menu).forEach((category) => {
                            if (category.items)
                                category.items.forEach((item) => {
                                    if (item.image_url) {
                                        promises.push(AdminService.SaveUploadedImage(item.image_url));
                                    }
                                });
                        });

                        Promise.all(promises).then((responses) => {
                            let index = 0;
                            for (let i = 0; i < Object.values(n.extras_menu).length; i++) {
                                const category = Object.values(n.extras_menu)[i];
                                if (category.items)
                                    for (let j = 0; j < category.items.length; j++) {
                                        if (category.items[j].image_url) {
                                            if (responses[index].data) n.extras_menu[Object.keys(n.extras_menu)[i]].items[j].image_url = responses[index].data;
                                            index++;
                                        }
                                    }
                            }

                            AdminService.SaveUploadedImage(vivienda.image_url).then((response) => {
                                const url = response.data;
                                const n = { ...vivienda };
                                if (url) n.image_url = url;
                                n.automatic_availability = programarEnabled ? 1 : 0;
                                n.enabled = n.enabled ? 1 : 0;

                                if (subscriptionsContext?.subscriptionsEnabled) {
                                    if (individualSubscription) {
                                        n.vivienda_subscription_data = viviendaSubscriptionData;
                                    }
                                    n.vivienda_subscription_types = viviendaSubscriptionTypes.map((type) => {
                                        return { id: type.id };
                                    });
                                }

                                APIAdminRequest("save-vivienda", n).then((response) => {
                                    if (!response.status) {
                                        UI.ShowNotification(response.error);
                                        notifySaved(false);
                                        return;
                                    }

                                    let redirect = false;
                                    if (!n.id) {
                                        n.id = response.data.id;
                                        redirect = true;
                                    }

                                    notifySaved(true);
                                    if (redirect) {
                                        navigate(BASENAME + "/admin/venta-vivienda/" + vendedorID + "/" + n.id);
                                    } else {
                                        setVivienda(n);
                                    }
                                });
                            });
                        });
                    } else {
                        notifySaved(false);
                    }
                });
            };

            if (parseFloat(vivienda.price) == 0 && !priceChanged) {
                doSave(L10n.__(subscriptionsContext.subscriptionsEnabled ? "El precio fuera de suscripción es de 0 €. ¿Seguro que deseas continuar?" : "El precio de esta vivienda es de 0 €. ¿Seguro que deseas continuar?"));
            } else {
                doSave();
            }
        },
        [vivienda, programarEnabled, timeTable, individualSubscription, viviendaSubscriptionData, viviendaSubscriptionTypes]
    );

    if (!vivienda || !vendedor || loading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="editar-evento edit-page">
            <AdminBar onSave={save} />
            <div className="header">
                <h2>{vivienda.nombre ? vivienda.nombre : L10n.__("Nueva vivienda")}{!!(vendedor?.name) && " | " + vendedor.name}</h2>
                {viviendaID != 0 && (
                    <FormToggle
                        tooltip={L10n.__((vivienda.enabled ? "Desactivar" : "Activar") + " vivienda")}
                        large={true}
                        defaultValue={vivienda.enabled}
                        onChange={(value) => {
                            const n = { ...vivienda };
                            n.enabled = value;
                            setVivienda(n);
                        }}
                    />
                )}
                {viviendaID != 0 && (
                    <div style={{ marginLeft: "1em" }} className="delete-evento-container">
                        <MaterialIcon
                            onClick={(e) => {
                                dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar esta vivienda?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
                                    if (confirmed) {
                                        APIAdminRequest("delete-vendedor", { id: viviendaID }).then(() => {
                                            navigate(BASENAME + "/admin/venta-vivienda/" + vendedorID);
                                        });
                                    }
                                });
                            }}
                            name="delete"
                            tooltip={L10n.__("Borrar vivienda")}
                        />
                    </div>
                )}
            </div>
            <Bloque className="columnas">
                <div className="columna">
                    <ImageUpload
                        className={validationErrors["image_url"] ? "error" : ""}
                        recommendedWidth={600}
                        recommendedHeight={300}
                        maxFileSize={600}
                        initialValue={vivienda.image_url}
                        onChange={(value) => {
                            const newVivienda = { ...vivienda };
                            newVivienda.image_url = value;
                            setVivienda(newVivienda);
                        }}
                    />
                </div>
                <div className="columna">
                    <div className="columnas">
                        <Field
                            placeholder={L10n.__("Nombre")}
                            className={"expand" + (validationErrors["nombre"] ? " error" : "")}
                            type="string"
                            defaultValue={vivienda.nombre}
                            maxLength={25}
                            onChange={(value) => {
                                const newVivienda = { ...vivienda };
                                newVivienda.nombre = value;
                                setVivienda(newVivienda);
                            }}
                        />
                        <Field
                            className="precio"
                            type="currency"
                            defaultValue={vivienda.price > 0 ? vivienda.price : ""}
                            onChange={(value) => {
                                const newVivienda = { ...vivienda };
                                if (value != newVivienda.price) setPriceChanged(true);
                                newVivienda.price = value;
                                setVivienda(newVivienda);
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}><Field
                        placeholder={vendedor.address || L10n.__("Dirección")}
                        className="expand"
                        id="campo-direccion"
                        disabled={vivienda.address === null}
                        type="string"
                        style={{ flex: 1 }}
                        defaultValue={vivienda.address}
                        onChange={(value) => {
                            const newVivienda = { ...vivienda };
                            newVivienda.address = value;
                            setVivienda(newVivienda);
                        }}
                    /><FormToggle defaultValue={!!vivienda.address} onChange={value => {
                        const newVivienda = { ...vivienda };
                        if (value) {
                            newVivienda.address = "";
                            setTimeout(() => {
                                $("#campo-direccion").focus();
                            }, 100);
                        } else newVivienda.address = null;
                        setVivienda(newVivienda);
                    }} /></div>
                    <Field
                        placeholder={L10n.__("Descripción de la vivienda.")}
                        className={"descripcion expand" + (validationErrors["description"] ? " error" : "")}
                        type="text"
                        maxLength={500}
                        defaultValue={vivienda.description}
                        onChange={(value) => {
                            const newVivienda = { ...vivienda };
                            newVivienda.description = value;
                            setVivienda(newVivienda);

                            const n = { ...validationErrors };
                            n["description"] = false;
                            setValidationErrors(n);
                        }}
                    />
                </div>
            </Bloque>
            <Bloque className={"columnas page-content-editor" + (vivienda.page_data?.sections?.length ? "" : " empty")} columnCount={1}>
                <h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Información detallada sobre la vivienda")}</h2>
                {vivienda.page_data?.sections && (
                    <div className="columna sections">
                        {vivienda.page_data?.sections?.map((section, idx) => {
                            return (
                                <div className="columns">
                                    {section.map((column, column_idx) => {
                                        switch (column.type) {
                                            default:
                                                return null;
                                            case "image":
                                                return (
                                                    <div className="section" key={"page-section-" + idx}>
                                                        {renderColumnOptions(section, column, idx, column_idx)}
                                                        <ImageUpload onChange={(value) => alterContent(idx, column_idx, { image_url: value })} initialValue={column.image_url} />
                                                    </div>
                                                );
                                            case "text":
                                            case "file-upload":
                                                return (
                                                    <div className="section" key={"page-section-" + idx}>
                                                        {renderColumnOptions(section, column, idx, column_idx)}
                                                        <label>{L10n.__("Supertítulo")}</label>
                                                        <input maxLength={50} value={column.supertitle || ""} onChange={(e) => alterContent(idx, column_idx, { supertitle: e.target.value })} />
                                                        <label>{L10n.__("Título")}</label>
                                                        <input maxLength={50} value={column.title || ""} onChange={(e) => alterContent(idx, column_idx, { title: e.target.value })} />
                                                        {column.type !== "file-upload" && <label>{L10n.__("Contenido")}</label>}
                                                        {column.type == "file-upload" && <><label>{L10n.__("Título del archivo")}</label>
                                                            <input maxlength={50} value={column.file_label || ""} onChange={(e) => alterContent(idx, column_idx, { file_label: e.target.value, file_id: Helper.MakeSlug(e.target.value) })} /></>}
                                                        {column.type == "file-upload" && <><label>{L10n.__("Descripción del archivo")}</label>
                                                            <input maxlength={50} value={column.file_description || ""} onChange={(e) => alterContent(idx, column_idx, { file_description: e.target.value })} /></>}
                                                        <textarea maxLength={column.type == "file-upload" ? 200 : 450} value={column.content || ""} onChange={(e) => alterContent(idx, column_idx, { content: e.target.value })} />
                                                        <div style={{ position: "relative " }}>
                                                            <label>{L10n.__("Iconos")}</label>
                                                            <div className="inner-actions">
                                                                <MaterialIcon
                                                                    name="add"
                                                                    tooltip="Añadir icono"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        const newIcons = [...(column.icons || [])];
                                                                        newIcons.push("");
                                                                        alterContent(idx, column_idx, { icons: newIcons });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="icons-container">
                                                            {!column.icons?.length && <span>{L10n.__("No hay ningún icono")}</span>}
                                                            {column.icons?.map((url, icon_idx) => {
                                                                return (
                                                                    <div>
                                                                        <a
                                                                            href="#"
                                                                            className="delete-icon"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                const newIcons = [...column.icons];
                                                                                newIcons.splice(icon_idx, 1);
                                                                                alterContent(idx, column_idx, { icons: newIcons });
                                                                            }}>
                                                                            &times;
                                                                        </a>
                                                                        <ImageUpload
                                                                            width={64}
                                                                            height={64}
                                                                            initialValue={url}
                                                                            onChange={(value) => {
                                                                                const newIcons = [...column.icons];
                                                                                newIcons[icon_idx] = value;
                                                                                alterContent(idx, column_idx, { icons: newIcons });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <label className="show-cta">
                                                            <FormToggle defaultValue={column.showButton} onChange={(value) => alterContent(idx, column_idx, { showButton: value })} /> Mostrar CTA
                                                        </label>
                                                    </div>
                                                );
                                            case "faq":
                                                return (
                                                    <div className="section" key={"page-section-" + idx}>
                                                        {renderColumnOptions(section, column, idx, column_idx)}
                                                        <label>{L10n.__("Título")}</label>
                                                        <input maxLength={50} value={column.title || ""} onChange={(e) => alterContent(idx, column_idx, { title: e.target.value })} />
                                                        <br />
                                                        <div style={{ position: "relative " }}>
                                                            <label>{L10n.__("Preguntas y respuestas")}</label>
                                                            <div className="inner-actions">
                                                                <MaterialIcon
                                                                    name="add"
                                                                    tooltip="Añadir pregunta"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        const newFaq = [...(column.faq || [])];
                                                                        newFaq.push({ question: "", answer: "" });
                                                                        alterContent(idx, column_idx, { faq: newFaq });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="section-faq">
                                                            {!column.faq?.length && <span>{L10n.__("No hay ninguna pregunta")}</span>}
                                                            {column.faq?.map((item, item_idx) => {
                                                                return (
                                                                    <div className="faq-item" key={"faq-item-" + item.key + "-" + item_idx}>
                                                                        <a
                                                                            href="#"
                                                                            className="delete-faq"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                const newFaq = [...column.faq];
                                                                                newFaq.splice(item_idx, 1);
                                                                                alterContent(idx, column_idx, { faq: newFaq });
                                                                            }}>
                                                                            &times;
                                                                        </a>
                                                                        <div className="question">
                                                                            <input
                                                                                maxLength={50}
                                                                                placeholder="Pregunta"
                                                                                defaultValue={item.question}
                                                                                onChange={(e) => {
                                                                                    const newFaq = [...column.faq];
                                                                                    newFaq[item_idx].question = e.target.value;
                                                                                    alterContent(idx, column_idx, { faq: newFaq });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="answer">
                                                                            <textarea
                                                                                maxLength={250}
                                                                                placeholder="Respuesta"
                                                                                value={item.answer}
                                                                                onChange={(e) => {
                                                                                    const newFaq = [...column.faq];
                                                                                    newFaq[item_idx].answer = e.target.value;
                                                                                    alterContent(idx, column_idx, { faq: newFaq });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                        }
                                    })}
                                </div>
                            );
                        })}
                    </div>
                )}
                <a
                    href="#"
                    className="new-section"
                    onClick={(e) => {
                        e.preventDefault();
                        const n = { ...vivienda };
                        if (!n.page_data) n.page_data = {};
                        if (!n.page_data?.sections) n.page_data = { sections: [] };
                        n.page_data.sections.push([{ type: "text" }]);
                        setVivienda(n);
                    }}>
                    <MaterialIcon name="add" />
                </a>
            </Bloque>
        </div>
    );
}
