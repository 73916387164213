import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Orders from "../services/Orders";
import Helper from "../services/Helper";
import $ from "jquery";

import L10n from "../services/Locale";
import Icon from "./Icon.tsx";
import MaterialIcon from "./MaterialIcon.tsx";
import Session from "../services/Session.tsx";
import { Content } from "./Content.tsx";
import ContentHelper from "../services/ContentHelper.tsx";
import { APIRequest } from "../services/API.ts";
import TPVWidget from "./TPVWidget.tsx";
import Slider from "./Slider.tsx";
import LoadingIndicator from "./LoadingIndicator.tsx";
import Settings from "../services/Settings";
import Events from "../services/Events.tsx";
import SubscriptionsService from "../services/Subscriptions";
import { SubscriptionsContext } from "../context/SubscriptionsContext.tsx";

export default function Pago(props) {
	const subscriptionsContext = useContext(SubscriptionsContext);

	const [orderDetails, setOrderDetails] = useState(null);
	const [orderError, setOrderError] = useState(null);
	const [orderCompleted, setOrderCompleted] = useState(false);
	const [orderInProgress, setOrderInProgress] = useState(true);
	const [orderData, setOrderData] = useState({ comments: [] });
	const [userName, setUserName] = useState("");
	const [userSurname, setUserSurname] = useState("");
	const [event, setEvent] = useState(null);
	const [booking, setBooking] = useState(null);
	const [placeholderCampoAlergias, setPlaceholderCampoAlergias] = useState("");
	const [codigoDescuento, setCodigoDescuento] = useState(null);
	const [codigoDescuentoInput, setCodigoDescuentoInput] = useState();
	const [errorCodigoDescuento, setErrorCodigoDescuento] = useState(null);
	const [loadingInProgress, setLoadingInProgress] = useState(false);
	const [logoHeight, setLogoHeight] = useState(65);
	const [subscriptionsEnabled, setSubscriptionsEnabled] = useState(false);
	const [acceptTermsError, setAcceptTermsError] = useState(false);
	const [subscriptionType, setSubscriptionType] = useState(null);
	const [inProgress, setInProgress] = useState(false);
	const [noCalendar, setNoCalendar] = useState(false);
	const [calculatingTotals, setCalculatingTotals] = useState(false);

	const alterPaxTimeout = useRef();

	const navigate = useNavigate();

	useEffect(() => {
		Promise.all([
			Settings.Get("LOGO_HEIGHT", 65),
			Settings.Get("LOGO_PADDING", 0)
		]).then(([height, padding]) => {
			setLogoHeight(parseInt(height) + parseInt(padding) * 2);
		});

		SubscriptionsService.IsEnabled().then(async (enabled) => {
			setSubscriptionsEnabled(enabled);
			let userSubscriptionsResponse;
			let userSubscriptions;

			if (enabled) {
				userSubscriptionsResponse = await APIRequest("subscriptions/get-user-subscriptions");
				userSubscriptions = userSubscriptionsResponse.data;
			}
			
			const session = await Session.GetSession();
			let event = Session.GetBookingData()?.selected_event;

			if (!event) {
				navigate(BASENAME + "/");
				return;
			}

			event = JSON.parse(event);

			if (session && session.id) {
				if (enabled) {
					if (userSubscriptions?.availabilityIDs?.indexOf(Session.GetBookingData()?.selected_availability_id) != -1) {
						setUserName(session["name"]);
						setUserSurname(session["surname"]);
					} else {
						SubscriptionsService.RequireSubscriptionForAvailability(Session.GetBookingData()?.selected_availability_id);
					}
				} else {
					setUserName(session["name"]);
					setUserSurname(session["surname"]);
				}
			} else {
				Session.RequireLogin();
			}
		});

		ContentHelper.GetString("placeholder-campo-alergias").then((text) => {
			setPlaceholderCampoAlergias(text);
		});

		$("header").addClass("simple");
		let booking = Session.GetBookingData();
		let event = booking.selected_event;

		if (event && booking) {
			event = JSON.parse(event);
			
			if (booking.selected_event_id) {
				setNoCalendar(true);
			} else {
				setNoCalendar(false);

				Session.SetBookingData("selected_event_id", event.id);

				if (!booking.selected_slot) {
					window.location.href = BASENAME + "/venue/" + event.negocio.slug;
					return;
				}
			}

			const bookingData = Session.GetBookingData();

			setEvent(event);
			setBooking(bookingData);
			setOrderError(null);
			setOrderInProgress(false);
		} else {
			setOrderError(null);
			setOrderInProgress(false);

			window.location.href = BASENAME + "/";
		}

		getOrderDetails();

		const onScrollEvent = (_e) => {
			if ($(".booking-sidebar").length == 0) return;

			if ($(window).scrollTop() > 14) {
				if (!$(".booking-sidebar").hasClass("floated")) {
					$(".booking-sidebar")
						.css({
							position: "fixed",
							top: $("header .logo").height() + 20 + "px",
							left: $(".booking-sidebar").offset().left + "px",
							width: $(".booking-sidebar").outerWidth()
						})
						.addClass("floated");
				}
			} else {
				if ($(".booking-sidebar").hasClass("floated")) {
					$(".booking-sidebar")
						.css({
							position: "",
							top: "",
							left: "",
							width: ""
						})
						.removeClass("floated");
				}
			}
		};

		window.addEventListener("scroll", onScrollEvent);

		return () => {
			window.removeEventListener("scroll", onScrollEvent);
			$("header").removeClass("simple");
		};
	}, []);

	let width = 0;
	$(".preorder-details-line:not(.secondary) .preorder-quantity").each(function () {
		if ($(this).width() > width) {
			width = $(this).width();
		}
	});
	$(".preorder-details-line:not(.secondary) .preorder-quantity").width(width);

	const getOrderDetails = () => {
		return new Promise((resolve, reject) => {
			const selected_event = JSON.parse(Session.GetBookingData("selected_event"));

			if (Session.GetBookingData("selected_slot")) {
				Session.SetBookingData("selected_full_slot", Helper.RenderFullTimeSlot(Session.GetBookingData("selected_slot"), selected_event.mean_lunch_time, selected_event.mean_dining_time, selected_event));
			}

			Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
				if (response.status) {
					const order_completed = response.data.order && response.data.order.payment_status === "1";
					const codigoDescuento = response.data.order ? parseInt(response.data.order.discount_id) : null;

					if (response.data.order) {
						if (!response.data.order.comments) response.data.order.comments = [];
						if (typeof response.data.order.comments === "string") response.data.order.comments = JSON.parse(response.data.order.comments || "[]") || [];
					}

					setOrderDetails(response.data);
					setNoCalendar(response.data.event.no_calendar == 1);
					setOrderCompleted(order_completed);
					setEvent(response.data.event);
					setOrderData(response.data.order);
					setCodigoDescuento(isNaN(codigoDescuento) ? null : codigoDescuento);
					setOrderInProgress(false);
					setCalculatingTotals(false);
					
					if (response.data.subscription_type) {
						setSubscriptionType(response.data.subscription_type);
					}

					if (order_completed) {
						window.location.href = "/reserva-completada";
						return;
					}

					if (!response.data.order || !parseInt(sessionStorage.getItem("current_order_id")) || sessionStorage.getItem("current_order_id") == 0) {
						APIRequest("payment/create-order", Session.GetBookingData()).then((response) => {
							sessionStorage.setItem("current_order_id", response.data);
							Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
								const codigoDescuento = parseInt(response.data.order.discount_id);
								setOrderDetails(response.data);
								setOrderData(response.data.order);
								setCodigoDescuento(isNaN(codigoDescuento) ? null : codigoDescuento);
								resolve(response.data.order);
							});
						});
					} else {
						resolve(response.data.order);
					}
				} else {
					setOrderError(response.data.error);
					setOrderInProgress(false);
					reject(response.data.error);
				}
			});
		});
	};

	const renderBookingSidebar = () => {
		if (orderError) return null;

		let slides = [];

		const url = orderDetails.event.image_url || orderDetails.event.event_image_url;
		slides.push({
			background: url[0] == "/" ? url : "eventos/" + url,
			title: orderDetails.event.negocio.name
		});
		orderDetails.order?.extra_events?.forEach && orderDetails.order?.extra_events?.forEach((item) => {
			slides.push({
				background: item.image_url[0] == "/" ? item.image_url : "eventos/" + item.image_url,
				title: item.name || item.negocio.name
			});
		});

		const image = orderDetails.event.image_url || orderDetails.event.event_image_url;

		return (
			<div className="col-md-4 booking-sidebar">
				<div className="box event-information">
					<Slider
						style={{
							overflow: "hidden",
							width: 300,
							height: 200,
							minHeight: 200
						}}
						height={200}
						slides={slides}
					/>
					<div className="booking-information">
						<div className="change-order-link">
							<Link to={subscriptionsContext.subscriptionsEnabled ? "/suscripcion/" + SubscriptionsService.GetCartData()["typeSlug"] + "/" + event.negocio.slug : "/venue/" + event.negocio.slug + "#" + event.id}>{L10n.__("¿Quieres modificar tu reserva?")}</Link>
						</div>
					</div>
				</div>

				{!!subscriptionsContext.subscriptionsEnabled && renderCajaDescuento()}
			</div>
		);
	};

	const onGotOrderId = (order_id) => {
		if (!orderDetails.order) {
			getOrderDetails();
		} else {
			setOrderDetails({ ...orderDetails, order: { ...orderDetails.order, id: order_id } });
		}
	};

	const renderCajaDescuento = () => {
		return (
			<div className="box caja-descuento">
				{codigoDescuento != null && codigoDescuento !== 0 && (
					<React.Fragment>
						<div>{L10n.__("¡Código descuento aplicado!")}</div>
						<button
							className="btn aplicar-codigo-descuento"
							onClick={(e) => {
								APIRequest("payment/unapply-discount-code", {
									order_id: orderData.id
								}).then((_response) => {
									setCodigoDescuento(null);
									setCodigoDescuentoInput(null);
									setErrorCodigoDescuento(null);
									setLoadingInProgress(true);
									getOrderDetails().then(() => {
										setLoadingInProgress(false);
									});
								});
							}}>
							Eliminar descuento
						</button>
					</React.Fragment>
				)}
				{(codigoDescuento === null || codigoDescuento === 0) && (
					<React.Fragment>
						<div>{L10n.__("¿Tienes un código de descuento?")}</div>
						<input
							className="codigo-descuento"
							onChange={(e) => {
								setCodigoDescuentoInput(e.target.value);
							}}></input>
						{errorCodigoDescuento && <div className="error-codigo-descuento">{errorCodigoDescuento}</div>}
						<button
							className="btn aplicar-codigo-descuento"
							onClick={(_e) => {
								setLoadingInProgress(true);
								APIRequest("payment/apply-discount-code", {
									code: codigoDescuentoInput,
									order_id: orderData.id
								}).then((response) => {
									if (response.status) {
										setErrorCodigoDescuento(null);
										setCodigoDescuento(codigoDescuentoInput);
										getOrderDetails().then(() => {
											setLoadingInProgress(false);
										});
									} else {
										setErrorCodigoDescuento(L10n.__("Este código no parece válido."));
										setLoadingInProgress(false);
										setCodigoDescuento(null);
									}
								});
							}}>
							{L10n.__("Aplicar")}
						</button>
					</React.Fragment>
				)}
			</div>
		);
	};

	const completarReserva = () => {
		if (!$(".accept-terms").prop("checked")) {
			setAcceptTermsError(true);
			return;
		}

		setOrderInProgress(true);
		setAcceptTermsError(false);

		Orders.UpdateOrderPreferences(orderData.id, orderData.comments || "", $("#acepta-recibir").prop("checked")).then(() => {
			if (orderDetails.total > 0) {
				$("#tpv-form").trigger("submit");
			} else {
				setInProgress(true);
				Orders.CompleteOrder(orderData.id).then((response) => {
					if (response.status) {
						location.href = "/reserva-completada";
					} else {
						location.href = "/reserva-fallida";
					}
				});
			}
		});
	};

	const alterPax = pax => {
		if (subscriptionsContext.subscriptionsEnabled) {
			const userSubscription = subscriptionsContext.userSubscriptions.find(us => us.events.filter(ev => ev.id == event.id).length > 0);
			if (userSubscription) {
				if (userSubscription.combinable_visits) {
					pax = Math.max(1, Math.min(pax, userSubscription.pax * userSubscription.visits));
				} else {
					pax = Math.max(1, Math.min(pax, userSubscription.pax));
				}
			}
		}

		if (orderDetails.event.availability?.pax) {
			pax = Math.max(1, Math.min(pax, orderDetails.event.availability.pax));
		} else {
			pax = Math.max(1, pax);
		}

		if (booking.pax != pax) {
			setCalculatingTotals(true);
			clearTimeout(alterPaxTimeout.current);
			alterPaxTimeout.current = setTimeout(() => {
				getOrderDetails();
			}, 500);
			Session.SetBookingData("pax", pax);
			setBooking({...booking, pax});
		}
	};

	const renderOrderDetails = () => {
		if (orderError) {
			return (
				<Link className="btn btn-brown" to={BASENAME + "/"}>
					Volver
				</Link>
			);
		}
		if (!orderDetails) return null;

		let eventPrice = noCalendar ? event.price : (orderDetails.event.availability?.price != -1 ? orderDetails.event.availability?.price : orderDetails.event.price);
		if (eventPrice == -1) eventPrice = 0;
		const selectedDate = Helper.CreateDateCompatible(booking.selected_date);
		const detailsAddress = orderDetails.event.availability.address_alt || orderDetails.event.address || orderData.address;

		return (
			<div className="order-details">
				<div className="order-details-preorder">
					<div className="preorder-details-line title">
						<div className="negocio-name">{orderDetails.event.negocio.name}</div>
						{subscriptionType && <div className="subscription-type-info">
							<div className="subscription-type-name">{subscriptionType?.name}</div>
							{subscriptionType.pack == 1 && <div className="subscription-type-pack">Pack</div>}
						</div>}
						{detailsAddress !== null && detailsAddress !== "null" && <div className="details-address">{orderDetails.event.address || orderDetails.event.negocio.address}</div>}
						<div className="event-name">
							<div>{orderDetails.event.nombre}</div>
							<div className="preorder-amount">{Helper.FormatAmount(((orderDetails.event.full_booking ? 1 : booking.pax) * eventPrice) / 100)}</div>
						</div>
					</div>
					<div className="preorder-details-line secondary details">
						{noCalendar && <div className="producto-tag" style={{ marginRight: "0.5em" }}>{L10n.__("Producto")}</div>}
						{<MaterialIcon name="calendar_month" style={{ marginRight: "0.25em" }} />}
						{noCalendar && Helper.FormatDate(new Date())}
						{!noCalendar && Helper.FormatISODate(booking.selected_date)}
						{!noCalendar && booking.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + booking.selection_length - 1)))}</span>} {!noCalendar && <MaterialIcon name="schedule" style={{ marginLeft: "0.5em", marginRight: "0.25em" }} />}
						{!noCalendar && orderDetails.event.availability.slot?.split(" ")[1].substring(0, 5)}
						{!noCalendar && Helper.RenderDurationEndTime(orderDetails.event.availability.slot?.split(" ")[1], orderDetails.event.availability.duration || orderDetails.event.duration, " a ")}
						{!!booking.pax && <MaterialIcon name="account_circle" style={{ marginLeft: "0.5em" }} />}
						{!!booking.pax && <span style={{width:"1.5em",textAlign:"center"}}>{booking.pax}</span>}
					</div>

					{orderDetails.order.preorder_selection &&
						orderDetails.order.preorder_selection.map((item, idx) => {
							if (item.quantity <= 0) {
								return null;
							}

							return (
								<div className="preorder-details-line secondary" key={idx}>
									<div className="preorder-quantity">{item.quantity}</div>
									<div className="preorder-name">{item.name}</div>
									<div className="preorder-amount">{Helper.FormatAmount((item.quantity * item.price) / 100)}</div>
								</div>
							);
						})}
					<div className={"box-alergia mt"}>
						<label>
							<Content slug="etiqueta-campo-alergias" />
						</label>
						<textarea
							disabled={orderCompleted}
							id="notas-pedido"
							rows={5}
							placeholder={(orderData.comments && orderData.comments[0]) || placeholderCampoAlergias}
							defaultValue={orderData?.comments ? orderData.comments[0] : ""}
							onChange={(e) => {
								const n = { ...orderData };
								n.comments[0] = e.target.value;
								setOrderData(n);
							}}></textarea>
					</div>
					{orderDetails.order.extra_events &&
						orderDetails.order.extra_events.map((item, idx) => {
							const extraEventIdx = idx;
							if (item.quantity <= 0) {
								return null;
							}

							const isoDate = item.selectedSlot.split(" ")[0];
							const slot = item.selectedSlot?.split(" ")[1]?.substring(0, 5);
							const selectedDate = Helper.CreateDateCompatible(isoDate);

							return (
								<React.Fragment key={"event-" + idx}>
									<div className="preorder-details-line title">
										<div className="preorder-name">
											<b>{item.negocio.name}</b>
											<div className="details-address">{item.address && item.address != "null" ? item.address : ""}</div>
											<div className="preorder-event-name">{item.nombre} <div className="preorder-amount">{Helper.FormatAmount(((item.full_booking ? 1 : item.quantity) * item.price) / 100)}</div></div>
										</div>
									</div>
									{!!slot && <div className="preorder-details-line secondary details">
										<MaterialIcon name="calendar_month" />
										{Helper.FormatISODate(isoDate)}
										{item.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + item.selection_length - 1)))}</span>} <MaterialIcon name="schedule" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{slot}
										{Helper.RenderDurationEndTime(slot, item.duration, " a ")} <MaterialIcon name="account_circle" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{item.quantity}
									</div>}
									{!slot && <div className="preorder-details-line secondary details">
										<div className="producto-tag">{L10n.__("Producto")}</div>
										<MaterialIcon name="calendar_month" />
										{Helper.FormatDate(new Date())}
										<MaterialIcon name="account_circle" style={{ marginLeft: "0.5em", marginRight: "0.5em" }} />
										{item.quantity}
									</div>}

									{item.selectedExtras?.map((extra, idx) => {
										return (
											<div className="preorder-details-line secondary" key={"extra-" + idx}>
												<div className="preorder-quantity">{extra.quantity}</div>
												<div className="preorder-name">{extra.name}</div>
												<div className="preorder-amount">{Helper.FormatAmount((extra.quantity * extra.price) / 100)}</div>
											</div>
										);
									})}
									<div className={"box-alergia mt"}>
										<label>
											<Content slug="etiqueta-campo-alergias" />
										</label>
										<textarea
											disabled={orderCompleted}
											id="notas-pedido"
											rows={5}
											placeholder={(orderData?.comments && orderData?.comments.length > extraEventIdx + 1 && orderData?.comments[extraEventIdx + 1]) || placeholderCampoAlergias}
											defaultValue={orderData && orderData?.comments && orderData?.comments.length > extraEventIdx + 1 ? orderData.comments[extraEventIdx + 1] : ""}
											onChange={(e) => {
												const data = { ...orderData };
												data.comments[extraEventIdx + 1] = e.target.value;
												setOrderData(data);
											}}></textarea>
									</div>
								</React.Fragment>
							);
						})}
					{event?.offers_preorder == 1 && event?.extras_menu && JSON.parse(event?.extras_menu) && (
						<div className="change-order-link">
							<Link to={"/venue/" + event.negocio.slug + "/extras"}>{L10n.__("¿Quieres modificar tu selección?")}</Link>
						</div>
					)}
				</div>

				<div className="order-details-total">
                    {subscriptionsContext?.subscriptionsEnabled && <div className="pax-selector">
                        <div className="pax">{booking.pax} {!noCalendar && (booking.pax == 1 ? L10n.__("persona") : L10n.__("personas"))}{noCalendar && (booking.pax == 1 ? L10n.__("unidad") : L10n.__("unidades"))}</div>
                        <a href="#" className="pax-button minus" onClick={e => {
							e.preventDefault();
							alterPax(booking.pax - 1);
                        }}><MaterialIcon name="remove" /></a>
                        <a href="#" className="pax-button plus" onClick={e => {
							e.preventDefault();
							alterPax(booking.pax + 1);
                        }}><MaterialIcon name="add" /></a>
                    </div>}
                    <div className="total" style={{opacity:calculatingTotals ? 0.5 : 1, transition:"opacity 0.5s"}}>
                        <div>{L10n.__("Total (IVA inc)")}</div>
                        <div>{Helper.FormatAmount(orderDetails.total / 100)}</div>
                    </div>
                </div>
			</div>
		);
	};

	if (!userName || !event || !booking || !orderDetails || !orderDetails.order || !sessionStorage.getItem("current_order_id")) return <LoadingIndicator />;

	if (inProgress) return <LoadingIndicator message={L10n.__("Confirmando reserva")} />;

	return (
		<div className="pago">
			{loadingInProgress && <LoadingIndicator />}
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-sm-12 order-container">
						<div className="box" style={{paddingBottom:0}}>
							<div className={orderError ? "hidden" : ""}>
								<h3>
									{userName}, {L10n.__("completa tu reserva")}
								</h3>
								{Helper.IsResponsive() && (
									<div className="change-order-link">
										<Link to={subscriptionsContext.subscriptionsEnabled ? "/suscripcion/" + SubscriptionsService.GetCartData()["typeSlug"] + "/" + event.negocio.slug : "/venue/" + event.negocio.slug}>{L10n.__("¿Quieres modificarla?")}</Link>
									</div>
								)}
							</div>
							{renderOrderDetails()}
						</div>
						{!subscriptionsContext.subscriptionsEnabled && Helper.IsResponsive() && renderCajaDescuento()}

						{orderDetails.total > 0 && <TPVWidget disabled={calculatingTotals} order={orderData.id} amount={orderDetails.total} />}

						<div className={"disclaimer" + (orderError ? " hidden" : "")}>
							<label>
								<input type="checkbox" required className="accept-terms"></input> {L10n.__("Confirmo que he leído y acepto los")}{" "}
								<Link rel="noreferrer" to={BASENAME + "/terminos-y-condiciones"} target="_blank">
									{L10n.__("términos de uso")}
								</Link>{" "}
								{L10n.__("y la")}{" "}
								<Link rel="noreferrer" to={BASENAME + "/politica-de-privacidad"} target="_blank">
									{L10n.__("política de privacidad")}
								</Link>
								.
							</label>
						</div>
						{acceptTermsError && <div className="accept-terms-error">{L10n.__("Debes aceptar los términos de uso y la política de privacidad para continuar.")}</div>}

						<button
							disabled={orderInProgress}
							className={"btn btn-brown btn-pagar" + (orderError || orderCompleted ? " hidden" : "") + (orderInProgress ? " disabled" : "")}
							onClick={() => {
								if (!orderInProgress) completarReserva();
							}}>
							{orderInProgress ? <LoadingIndicator contained={true} dotColor="white" /> : orderDetails.total > 0 ? L10n.__("Pagar") : L10n.__("Confirmar")}
						</button>
						{orderDetails.total > 0 && <img alt="" src="/static/images/pago.png" className="formas-pago mobile-only" />}
						{orderDetails.total > 0 && <img alt="" src="/static/images/pago.png" className="formas-pago desktop-only" />}
					</div>
					{renderBookingSidebar()}
				</div>
			</div>
		</div>
	);
}
