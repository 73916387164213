import React, { useState, useEffect, useRef } from 'react';
import { APIAdminRequest } from '../services/API';
import L10n from '../services/Locale';

export default function UserFinder(props) {
    const [ listIsVisible, setListIsVisible] = useState(false);
    const [ value, setValue ] = useState(props.defaultValue || 0);
    const [ list, setList ] = useState([]);
    const [ name, setName ] = useState("Todos");
    const [ opacity, setOpacity ] = useState(0);
    const [ position, setPosition ] = useState({ left: 0, top: 0 });

    const inputRef = useRef(null);
    const requestTimeout = useRef(null);
    
    const onBodyClick = e => {
        if (e.target.classList.contains("user-finder") || $(e.target).closest(".user-finder").length) return;
        e.preventDefault();
        setListIsVisible(false);
        $("body").off("click", onBodyClick);
	};

    const handleChange = e => {
        setName(e.target.value);

        if (e.target === document.activeElement) {
            clearTimeout(requestTimeout.current);
            requestTimeout.current = setTimeout(() => {
                APIAdminRequest("search-users", { query: e.target.value }).then(response => {
                    setList(response.data);
                });
            }, 500);
        }
    };

    const updateName = name => {
        inputRef.current.value = name;
    };

    useEffect(() => {
        if (props.onChange) props.onChange(value);

        if (value == 0) {
            updateName(L10n.__("Todos"));
        } else {
            APIAdminRequest("get-user", { id: value }).then(response => {
                updateName(response.data.name + " " + response.data.surname + " (" + response.data.login + ")");
            });
        }
    }, [value]);

    useEffect(() => {
		if (listIsVisible) {
			setTimeout(() => {
				$("body").on("click", onBodyClick);
				const rect = document.querySelector(".user-finder").getBoundingClientRect();
				let newLeft = position.left;
				let newTop = position.top;

				if (rect.left + rect.width > window.innerWidth - 30) {
					newLeft = window.innerWidth - rect.width - 30;
				}

				if (rect.top + rect.height > window.innerheight - 30) {
					newTop = window.innerHeight - rect.height - 30;
				}

				setOpacity(1);
				setPosition({ left: newLeft, top: newTop });
			}, 100);
		} else {
			setOpacity(0);
			$("body").off("click", onBodyClick);

            if (value == 0 || inputRef.current.value == "") {
                updateName(L10n.__("Todos"));
                setValue(0);
            } else {
                APIAdminRequest("get-user", { id: value }).then(response => {
                    updateName(response.data.name + " " + response.data.surname + " (" + response.data.login + ")");
                });
            }
		}
	}, [ listIsVisible ]);

	useEffect(() => {
		return () => {
			$("body").off("click", onBodyClick);
		}
	}, []);

    return <div className="user-finder">
        <input ref={inputRef} onChange={handleChange} onFocus={e => setListIsVisible(true)} onBlur={e => setTimeout(() => setListIsVisible(false), 100)} defaultValue="" />
        {listIsVisible && <div className="user-search-results">
            {props.allowAny && <div onClick={e => {
                e.preventDefault();
                setListIsVisible(false);
                updateName(L10n.__("Todos"));
                setValue(0);
            }} key={"any"} className="user-item"><b>Todos</b></div>}
            {list.length > 0 && list.map((item, index) => {
                return <div onClick={e => {
                    e.preventDefault();
                    setListIsVisible(false);
                    updateName(item.complete_name + " (" + item.login + ")");
                    setValue(item.id);
                }} key={index + "-" + item.id} className="user-item"><b>{item.complete_name}</b><br/>{item.login}</div>
            })}    
        </div>}
    </div>;
}