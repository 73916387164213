import React, { useEffect, useState, useContext, createContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import AdminService from "../services/Admin";
import AdminUsuarios from "./AdminUsuarios";
import AdminNegocios from "./AdminNegocios";
import AdminEditarNegocio from "./AdminEditarNegocio";
import AdminEditarEvento from "./AdminEditarEvento";
import AdminHome from "./AdminHome";
import AdminHomeSuscripciones from "./AdminHomeSuscripciones";
import AdminFAQ from "./AdminFAQ";
import AdminGeneral from "./AdminGeneral";
import AdminDescuentos from "./AdminDescuentos";
import AdminIdiomas from "./AdminIdiomas";
import AdminCiudades from "./AdminCiudades";
import AdminEditarCiudad from "./AdminEditarCiudad";
import AdminContacto from "./AdminContacto";
import AdminPaginaHTML from "./AdminPaginaHTML";
import AdminPaginaEstatica from "./AdminPaginaEstatica";
import MaterialIcon from "./MaterialIcon";
import LoadingIndicator from "./LoadingIndicator";
import AdminEmails from "./AdminEmails";
import AdminReservasDisponibilidad from "./AdminReservasDisponibilidad";
import AdminPermisos from "./AdminPermisos";
import AdminEstadisticas from "./AdminEstadisticas";
import AdminEstadisticasSuscripcion from "./AdminEstadisticasSuscripcion";
import Helper from "../services/Helper";
import AdminGruposSuscripcion from "./AdminGruposSuscripcion";
import AdminSuscripciones from "./AdminSuscripciones";
import AdminPagos from "./AdminPagos";
import AdminEditarVivienda from "./AdminEditarVivienda";
import AdminVendedoresVivienda from "./AdminVendedoresVivienda";
import AdminEditarOfertaTrabajo from "./AdminEditarOfertaTrabajo";
import AdminEmpleadores from "./AdminEmpleadores";
import AdminEditarEmpleador from "./AdminEditarEmpleador";
import AdminEditarVendedorVivienda from "./AdminEditarVendedorVivienda";
import AdminInformePoblacion from "./informes/AdminInformePoblacion";

import Session from "../services/Session";

import { PermissionsContext } from "../context/PermissionsContext";
import { SessionContext } from "../context/SessionContext";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import L10n from "../services/Locale";
import SubscriptionsService from "../services/Subscriptions";
import { SettingsContext } from "../context/SettingsContext";

function MenuDesplegable(props) {
	return (
		<div
			className={"menu-desplegable" + (!props.expanded ? " collapsed" : "")}
			onClick={(e) => {
				if (props.onClick) props.onClick(e);
			}}>
			<div className="title">
				<MaterialIcon name={props.icon} /> {props.title} <MaterialIcon name={props.expanded ? "remove" : "add"} className="expand-icon" />
			</div>
			<div className="content">{props.children}</div>
		</div>
	);
}

export const AdminStylesContext = createContext({ paddingTop: 0, addPaddingTop: (_) => { } });

export default function Admin(props) {
	const params = useParams();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState("home");
	const [blank, setBlank] = useState();
	const [permissions, setPermissions] = useState(null);
	const [sidebarActive, setSidebarActive] = useState(false);
	const [expandedSections, setExpandedSections] = useState({});
	const [user, setUser] = useState({});
	const [additionalBodyPaddingTop, setAdditionalBodyPaddingTop] = useState(0);
	const [sidebarHidden, setSidebarHidden] = useState(true);

	const subscriptionsContext = useContext(SubscriptionsContext);
	const sessionContext = useContext(SessionContext);
	const settingsContext = useContext(SettingsContext);

	const lengthIndicatorInterval = useRef();

	useEffect(() => {
		const headerHeight = Helper.IsResponsive() ? $("header.mobile").outerHeight(true) : $("header.desktop").outerHeight(true) + additionalBodyPaddingTop;
		const extendedHeaderHeight = headerHeight + 78;

		$(".injected-css-admin").remove();
		$("body").append(`<div class="injected-css-admin">
			<style>
				body.admin {
					padding-top: ${headerHeight}px;
				}

				body.admin:not(.has-header).scrolled {
					padding-top: ${headerHeight}px;
				}

				body.admin.has-header:not(.scrolled) {
					padding-top: ${headerHeight}px;
				}

				body.admin.has-header.scrolled {
					padding-top: ${extendedHeaderHeight}px;
				}

				.admin .sidebar {
					top: ${headerHeight}px;
				}

				.admin .sidebar-menu-backdrop {
					top: ${headerHeight}px;
				}

				body.scrolled .admin-page .header,
				body.scrolled .admin .edit-page .header {
					top: ${headerHeight}px;
				}
			</style>
		</div>`);

		return () => {
			$(".injected-css").remove();
		};
	}); // On render.

	useEffect(() => {
		AdminService.GetUserPermissions().then((data) => {
			setPermissions(data.permissions);
			setUser(data.user);
		});

		document.body.classList.add("admin");
		$("body").css("padding-top", "");

		function createLengthIndicators() {
			$("input, textarea").each(function() {
				if ($(this).closest(".custom-field").length) return;

				if ($(this).attr("maxlength")) {
					const maxLength = parseInt($(this).attr("maxlength"));
					let indicator = $(this).next(".custom-length-indicator");

					if (indicator.length) {
						indicator = indicator[0];
					} else {
						indicator = document.createElement('div');
						indicator.className = "custom-length-indicator";
						indicator.style.position = "absolute";
						$(this).after(indicator);
					}
					
					indicator.innerHTML = this.value.length + " / " + maxLength;
					
					const thisRect = this.getBoundingClientRect();
					const indicatorRect = indicator.getBoundingClientRect();
					indicator.style.top = this.offsetTop + thisRect.height - indicatorRect.height - 2 + "px";
					indicator.style.left = this.offsetLeft + thisRect.width - indicatorRect.width - 2 + "px";
				}
			});
		}

		lengthIndicatorInterval.current = setInterval(() => {
			createLengthIndicators();
		}, 300);

		$(window).on("scroll", createLengthIndicators);

		return () => {
			clearInterval(lengthIndicatorInterval.current);
			$(window).off("scroll", createLengthIndicators);
			document.body.classList.remove("admin");
		};
	}, []);

	function renderCurrentPage() {
		if (!permissions) return null;

		switch (currentPage) {
			default:
			case "home":
				if (!permissions.portada) return null;
				window.document.title = L10n.__("Contenidos de portada");
				if (subscriptionsContext?.subscriptionsEnabled) return <AdminHomeSuscripciones />;
				else return <AdminHome />;
			case "faq":
				if (!permissions.faq) return null;
				window.document.title = L10n.__("Preguntas frecuentes");
				return <AdminFAQ />;
			case "contacto":
				if (!permissions.contacto) return null;
				window.document.title = L10n.__("Formulario de contacto");
				return <AdminContacto />;
			case "terminos":
				if (!permissions["textos-legales"]) return null;
				window.document.title = L10n.__("Términos y condiciones");
				return <AdminPaginaHTML contentSlug="terminos-y-condiciones" />;
			case "privacidad":
				if (!permissions["textos-legales"]) return null;
				window.document.title = L10n.__("Política de privacidad");
				return <AdminPaginaHTML contentSlug="politica-de-privacidad" />;
			case "pagina":
				if (!permissions["pagina-estatica"]) return null;
				window.document.title = L10n.__("Página estática");
				return <AdminPaginaEstatica />;
			case "estadisticas":
				if (!permissions.estadisticas) return null;
				window.document.title = L10n.__("Estadísticas");
				return subscriptionsContext?.subscriptionsEnabled ? <AdminEstadisticasSuscripcion /> : <AdminEstadisticas />;
			case "reservas":
				if (!permissions["reservas-y-disponibilidad"]) return null;
				window.document.title = L10n.__("Reservas y disponibilidad");
				return <AdminReservasDisponibilidad />;
			case "packs":
				if (!permissions["packs"] || !subscriptionsContext?.subscriptionsEnabled) return null;
				window.document.title = L10n.__("Packs de suscripción");
				return <AdminGruposSuscripcion />;
			case "descuentos":
				if (!permissions["codigos-de-descuento"]) return null;
				window.document.title = L10n.__("Códigos de descuento");
				return <AdminDescuentos />;
			case "emails":
				if (!permissions.emails) return null;
				window.document.title = L10n.__("E-mails");
				return <AdminEmails />;
			case "usuarios":
				if (!permissions.usuarios) return null;
				window.document.title = L10n.__("Listado de usuarios");
				return <AdminUsuarios />;
			case "negocios":
				if (!permissions.negocios) return null;
				if (params.args2) {
					window.document.title = L10n.__("Editar evento");
					return <AdminEditarEvento eventID={params.args2} negocioID={params.args} />;
				}
				if (params.args) {
					window.document.title = L10n.__("Editar negocio");
					return <AdminEditarNegocio negocioID={params.args} />;
				} else {
					window.document.title = L10n.__("Listado de negocios");
					return <AdminNegocios />;
				}
			case "alquiler-vivienda":
				if (!permissions.vivienda) return null;
				if (params.args2) {
					window.document.title = L10n.__("Editar oferta de alquiler");
					return <AdminEditarEvento eventID={params.args2} negocioID={params.args} />;
				}
				if (params.args) {
					window.document.title = L10n.__("Editar inmobiliaria");
					return <AdminEditarNegocio negocioID={params.args} />;
				} else {
					window.document.title = L10n.__("Listado de inmobiliaria");
					return <AdminNegocios negocioClass={1} />;
				}
			case "venta-vivienda":
				if (!permissions.vivienda) return null;
				if (params.args2) {
					window.document.title = L10n.__("Editar vivienda");
					return <AdminEditarVivienda viviendaID={params.args2} vendedorID={params.args} />;
				}
				if (params.args) {
					window.document.title = L10n.__("Editar vendedor de vivienda");
					return <AdminEditarVendedorVivienda vendedorID={params.args} />;
				} else {
					window.document.title = L10n.__("Editar vivienda para venta");
					return <AdminVendedoresVivienda />;
				}
			case "trabajo":
				if (!permissions.trabajo) return null;
				if (params.args2) {
					window.document.title = L10n.__("Editar oferta de trabajo");
					return <AdminEditarOfertaTrabajo ofertaTrabajoID={params.args2} empleadorID={params.args} />;
				}
				if (params.args) {
					window.document.title = L10n.__("Editar empleador");
					return <AdminEditarEmpleador empleadorID={params.args} />;
				} else {
					window.document.title = L10n.__("Listado de empleadores");
					return <AdminEmpleadores />;
				}
			case "general":
				if (!permissions.general) return null;
				window.document.title = L10n.__("Ajustes generales");
				return <AdminGeneral />;
			case "permisos":
				if (!permissions.permisos) return null;
				window.document.title = L10n.__("Permisos");
				return <AdminPermisos />;
			case "ciudades":
				if (!permissions["ciudades-y-zonas"]) return null;
				window.document.title = L10n.__("Ciudades y zonas");
				if (params.args) {
					return <AdminEditarCiudad ciudadID={params.args} />;
				} else {
					return <AdminCiudades />;
				}
			case "idiomas":
				if (!permissions.idiomas) return null;
				window.document.title = L10n.__("Idiomas");
				return <AdminIdiomas />;
			case "suscripciones":
				if (!permissions.suscripciones) return null;
				window.document.title = L10n.__("Suscripciones");
				return <AdminSuscripciones />;
			case "pagos":
				if (!permissions.pagos) return null;
				window.document.title = L10n.__("Pagos");
				return <AdminPagos />;
			case "informes":
				if (!permissions.informes) return null;
				if (params.args == "poblacion") {
					return <AdminInformePoblacion />;
				} else if (params.args == "global") {
					return <AdminInformePoblacion />;
				}
				return null;
		}
	}

	function openPage(pageName) {
		setBlank(true);
		$(".sidebar a").removeClass("active");
		$(".sidebar a.link-" + pageName).addClass("active");
		setSidebarHidden(pageName.substring(0, 8) == "informes");
		setTimeout(() => {
			setCurrentPage(pageName);
			setBlank(false);
		}, 100);
	}

	if (props.drawHeaderButtons) {
		props.drawHeaderButtons(() => {
			return <div></div>;
		});
	}

	useEffect(() => {
		if (sidebarHidden) {
			$("body").addClass("sidebar-hidden");
		} else {
			$("body").removeClass("sidebar-hidden");
		}
	}, [sidebarHidden]);

	useEffect(() => {
		if (sidebarActive) {
			$("body").addClass("menu-open scroll-lock");
		} else {
			$("body").removeClass("menu-open scroll-lock");
		}

		return () => {
			$("body").removeClass("menu-open scroll-lock");
		};
	}, [sidebarActive]);

	useEffect(() => {
		if (currentPage == "terminos" || currentPage == "privacidad") {
			const ex = { ...expandedSections };
			ex["textos_legales"] = true;
			setExpandedSections(ex);
		}
		if (currentPage == "venta-vivienda" || currentPage == "alquiler-vivienda") {
			const ex = { ...expandedSections };
			ex["vivienda"] = true;
			setExpandedSections(ex);
		}

		setSidebarHidden(currentPage == "informes");
	}, [currentPage]);

	useEffect(() => {
		$("header").addClass("simple admin");

		return () => {
			$("header").removeClass("simple admin");
		};
	}, []);

	if (!sessionContext.session) {
		Session.RequireLogin();
	}

	if (!permissions) {
		return <LoadingIndicator />;
	}

	if (!blank && params.page && currentPage != params.page) {
		openPage(params.page);
	}

	let returnLocation = location.pathname.split("/").slice(0, -1).join("/");
	if (returnLocation == BASENAME + "/admin") returnLocation = BASENAME + "/admin/portada";
	if (location.pathname == BASENAME + "/admin/portada" || returnLocation == location.pathname) returnLocation = BASENAME + "/";
	return (
		<AdminStylesContext.Provider
			value={{
				paddingTop: additionalBodyPaddingTop,
				addPaddingTop: (padding) => {
					setAdditionalBodyPaddingTop(padding);
				}
			}}>
			<PermissionsContext.Provider value={{ permissions, user }}>
				<div className="admin">
					{!sidebarHidden && <div className="sidebar-menu-backdrop mobile-only" onClick={() => setSidebarActive(false)} />}
					{!sidebarHidden && <div className={"sidebar" + (sidebarActive ? " active" : "")}>
						<div
							className="mobile-only sidebar-toggle-button"
							onClick={() => {
								setSidebarActive(!sidebarActive);
							}}>
							{sidebarActive && <MaterialIcon name="close" />}
							{!sidebarActive && <img src="/static/icons/menu-icon.png" />}
						</div>
						<div className="top">
							<Link onClick={() => setSidebarActive(false)} to={returnLocation} className="link-volver">
								<MaterialIcon name="arrow_back" /> {L10n.__("Volver")}
							</Link>
						</div>
						<div className="middle">
							{permissions.portada && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/home"} className="link-home">
									<MaterialIcon name="home" /> {L10n.__("Portada")}
								</Link>
							)}
							{permissions.faq && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/faq"} className="link-faq">
									<MaterialIcon name="live_help" /> {L10n.__("FAQ")}
								</Link>
							)}
							{permissions.contacto && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/contacto"} className="link-contacto">
									<MaterialIcon name="contact_mail" /> {L10n.__("Contacto")}
								</Link>
							)}
							{permissions["textos-legales"] && (
								<MenuDesplegable
									icon="quick_reference"
									title={L10n.__("Textos legales")}
									expanded={expandedSections["textos_legales"]}
									onClick={e => {
										const ex = { ...expandedSections };
										ex["textos_legales"] = $(e.target).hasClass("title") || $(e.target).hasClass("expand-icon") ? !ex["textos_legales"] : true;
										setExpandedSections(ex);
									}}>
									<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/terminos"} className="link-terminos">
										<MaterialIcon name="policy" /> {L10n.__("Términos y condiciones")}
									</Link>
									<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/privacidad"} className="link-privacidad">
										<MaterialIcon name="admin_panel_settings" /> {L10n.__("Política de privacidad")}
									</Link>
								</MenuDesplegable>
							)}
							{!subscriptionsContext?.subscriptionsEnabled && permissions["pagina-estatica"] && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/pagina"} className="link-pagina">
									<MaterialIcon name="view_quilt" /> {L10n.__("Página estática")}
								</Link>
							)}
						</div>
						<div className="bottom">
							{permissions["reservas-y-disponibilidad"] && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/reservas"} className="link-reservas">
									<MaterialIcon name="calendar_month" /> {L10n.__("Reservas y disponibilidad")}
								</Link>
							)}
							{permissions["packs"] && subscriptionsContext?.subscriptionsEnabled && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/packs"} className="link-packs">
									<MaterialIcon name="event_repeat" /> {L10n.__("Grupos de suscripción")}
								</Link>
							)}
							{permissions.negocios && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/negocios"} className="link-negocios">
									<MaterialIcon name="local_convenience_store" /> {L10n.__("Negocios")}
								</Link>
							)}
							{permissions.trabajo && parseInt(settingsContext.ENABLE_TRABAJO_OFFERS) == 1 && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/trabajo"} className="link-trabajo">
									<MaterialIcon name="agriculture" /> {L10n.__("Empleo")}
								</Link>
							)}
							{permissions.vivienda && parseInt(settingsContext.ENABLE_VIVIENDA_OFFERS) == 1 && (
								<MenuDesplegable
									icon="cottage"
									title={L10n.__("Vivienda")}
									expanded={expandedSections["vivienda"]}
									onClick={(e) => {
										const ex = { ...expandedSections };
										ex["vivienda"] = $(e.target).hasClass("title") || $(e.target).hasClass("expand-icon") ? !ex["vivienda"] : true;
										setExpandedSections(ex);
									}}>
									<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/venta-vivienda"} className="link-vivienda">
										<MaterialIcon name="gite" /> {L10n.__("Venta")}
									</Link>
									<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/alquiler-vivienda"} className="link-alquiler">
										<MaterialIcon name="holiday_village" /> {L10n.__("Alquiler")}
									</Link>
								</MenuDesplegable>
							)}
							{subscriptionsContext?.subscriptionsEnabled && permissions.suscripciones && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/suscripciones"} className="link-suscripciones">
									<MaterialIcon name="currency_exchange" /> {L10n.__("Suscripciones")}
								</Link>
							)}
							{!subscriptionsContext?.subscriptionsEnabled && permissions.pagos && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/pagos"} className="link-pagos">
									<MaterialIcon name="currency_exchange" /> {L10n.__("Pagos")}
								</Link>
							)}
							{permissions.estadisticas && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/estadisticas"} className="link-estadisticas">
									<MaterialIcon name="insert_chart" /> {L10n.__("Estadísticas")}
								</Link>
							)}
							{permissions.usuarios && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/usuarios"} className="link-usuarios">
									<MaterialIcon name="account_box" /> {L10n.__("Usuarios")}
								</Link>
							)}
							{permissions["codigos-de-descuento"] && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/descuentos"} className="link-descuentos">
									<MaterialIcon name="heap_snapshot_large" /> {L10n.__("Códigos de descuento")}
								</Link>
							)}
							{permissions.emails && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/emails"} className="link-emails">
									<MaterialIcon name="local_post_office" /> {L10n.__("E-mails")}
								</Link>
							)}
							{permissions.permisos && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/permisos"} className="link-permisos">
									<MaterialIcon name="flag" /> {L10n.__("Permisos")}
								</Link>
							)}
							{permissions["ciudades-y-zonas"] && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/ciudades"} className="link-ciudades">
									<MaterialIcon name="location_on" /> {L10n.__("Ciudades y zonas")}
								</Link>
							)}
							{permissions.idiomas && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/idiomas"} className="link-idiomas">
									<MaterialIcon name="language" /> {L10n.__("Idiomas")}
								</Link>
							)}
							{permissions.general && (
								<Link onClick={() => setSidebarActive(false)} to={BASENAME + "/admin/general"} className="link-general">
									<MaterialIcon name="settings" /> {L10n.__("General")}
								</Link>
							)}
						</div>
					</div>}
					<div className="content">{!blank && renderCurrentPage()}</div>
				</div>
			</PermissionsContext.Provider>
		</AdminStylesContext.Provider>
	);
}
