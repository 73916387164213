import { useState, useEffect, useRef } from "react";

import { APIAdminRequest, APIRequest } from "../../services/API";
import Bloque from "../AdminBloque";
import Helper from "../../services/Helper";
import { StarMeter } from "../Encuesta";
import DoughnutChart from "../estadisticas/DoughnutChart";
import BarChart from "../estadisticas/BarChart";
import HorizontalBarChart from "../estadisticas/HorizontalBarChart";
import LoadingIndicator from "../LoadingIndicator";
import L10n from "../../services/Locale";
import MaterialIcon from "../MaterialIcon";
import Table from "../Table";
import Dropdown from "../Dropdown";

export const Colors = ["#36a2eb", "#4bc0c0", "#ffcd56", "#ff9f40", "#ff6384"];

export function CalendarFilter(props) {
    const [calendarRange, setCalendarRange] = useState({ min: props.minDate ?? null, max: props.maxDate ?? null });
    const [months, setMonths] = useState([]);
    const [dates, setDates] = useState(props.defaultValue || { from: null, to: null });
    const [scrolled, setScrolled] = useState(false);

    const elementID = "calendar-filter-" + Math.floor(Math.random() * 100000000);
    const today = new Date();

    if (props.resetRef) {
        props.resetRef(() => {
            setDates({ from: null, to: null });
        });
    }

    useEffect(() => {
        document.body.classList.add("has-header");

        return () => {
            document.body.classList.remove("has-header");
        };
    }, []);

    const { visible } = props;
    useEffect(() => {
        if (!visible && (dates.from == null || dates.to == null)) {
            if (dates.from == null) setDates({ from: dates.to, to: dates.to });
            if (dates.to == null) setDates({ from: dates.from, to: dates.from });
        }

        if (visible && !scrolled) {
            setTimeout(() => {
                $("#" + elementID).scrollTop(
                    $("#" + elementID)
                        .find("#month" + today.getFullYear() + "-" + (today.getMonth() + 1))
                        .offset()?.top - 150
                );
                setTimeout(() => {
                    setScrolled(false);
                }, 500);
            });
        }
    }, [visible]);

    useEffect(() => {
        const months = [];
        if (calendarRange.min && calendarRange.max) {
            calendarRange.max.setMonth(calendarRange.max.getMonth() + 1);
            for (let index = calendarRange.min; index <= calendarRange.max; index.setMonth(index.getMonth() + 1)) {
                months.push(new Date(index));
            }
        } else {
            let index = new Date();
            index.setFullYear("2020");
            while (index.getFullYear() < new Date().getFullYear() + 1) {
                months.push(new Date(index));
                index.setMonth(index.getMonth() + 1);
            }
        }
        setMonths(months);
    }, [calendarRange]);

    useEffect(() => {
        if (props.onChange) props.onChange(dates);
    }, [dates]);

    if (!props.visible || months.length == 0) return null;

    const dateTimeFormat = new Intl.DateTimeFormat(L10n.GetLanguage(), { weekday: "long", year: "numeric", month: "long", day: "numeric" });

    return (
        <div className="calendar-filter" id={elementID} onScroll={(e) => setScrolled(true)}>
            <div className="calendar-scroll">
                {months.map((month, idx) => {
                    const firstDate = new Date(month.getFullYear(), month.getMonth(), 1);
                    const firstDay = firstDate.getDay() == 0 ? 6 : firstDate.getDay() - 1;
                    const lastDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
                    const lastDay = 6 - (lastDate.getDay() == 0 ? 6 : lastDate.getDay() - 1);
                    const dateParts = dateTimeFormat.formatToParts(firstDate);

                    let firstSundayDate = new Date(firstDate);
                    while (firstSundayDate.getDay() != 0) {
                        firstSundayDate.setDate(firstSundayDate.getDate() + 1);
                    }
                    const firstSunday = firstSundayDate.getDate();

                    return (
                        <div key={"month_" + Helper.GetISODate(month) + "_" + idx} id={"month" + month.getFullYear() + "-" + (month.getMonth() + 1)}>
                            <div className="month-header">{Helper.UCFirst(dateParts[4].value) + " " + firstDate.getFullYear()}</div>
                            <div className="month">
                                <div className="day-header">{L10n.__("Lu")}</div>
                                <div className="day-header">{L10n.__("Ma")}</div>
                                <div className="day-header">{L10n.__("Mi")}</div>
                                <div className="day-header">{L10n.__("Ju")}</div>
                                <div className="day-header">{L10n.__("Vi")}</div>
                                <div className="day-header">{L10n.__("Sa")}</div>
                                <div className="day-header">{L10n.__("Do")}</div>
                                {Array.from(Array(firstDay), (v, i) => i).map((_) => {
                                    return <div className="dummy" key={"dummy" + _}></div>;
                                })}
                                {Array.from(Array(lastDate.getDate()), (v, i) => i + 1).map((dateNum) => {
                                    const dayISODate = `${month.getFullYear()}-${Helper.PadStart(month.getMonth() + 1, 2, "0")}-${Helper.PadStart(dateNum, 2, "0")}`;
                                    const startISODate = dates.from;
                                    const endISODate = dates.to;
                                    const date = new Date(month.getFullYear(), month.getMonth(), dateNum);

                                    return (
                                        <div
                                            key={"date_" + dayISODate}
                                            className={"day" + (dateNum <= firstSunday ? " first-week" : "") + (dateNum == lastDate.getDate() ? " last" : "") + (date.getDay() == 0 ? " sunday" : "") + (dayISODate == startISODate ? " start-date" : "") + (dayISODate == endISODate ? " end-date" : "") + (dayISODate > startISODate && dayISODate < endISODate ? " in-between-date" : "")}
                                            onClick={(e) => {
                                                const n = { ...dates };
                                                if (dates.from == null || (dates.from != null && dates.to != null && dates.from != dates.to)) {
                                                    n.from = dayISODate;
                                                    n.to = dayISODate;
                                                } else {
                                                    if (dayISODate < dates.from) {
                                                        n.to = n.from;
                                                        n.from = dayISODate;
                                                    } else {
                                                        n.to = dayISODate;
                                                    }
                                                }
                                                setDates(n);
                                            }}>
                                            {dateNum}
                                        </div>
                                    );
                                })}
                                {Array.from(Array(lastDay), (v, i) => i).map((_, idx) => {
                                    return <div key={"dummy" + idx} className="dummy end" key={"dummy" + _}></div>;
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default function AdminInformePoblacion(props) {
    const [ratiosByType, setRatiosByType] = useState([]);
    const [kpis, setKpis] = useState();
    const [ratiosByDay, setRatiosByDay] = useState([]);
    const [surveyData, setSurveyData] = useState(null);
    const [loadingInProgress, setLoadingInProgress] = useState(true);
    const [showCalendarFilter, setShowCalendarFilter] = useState(false);
    const [calendarFilterDates, setCalendarFilterDates] = useState({ from: null, to: null });
    const [prevCalendarFilterDates, setPrevCalendarFilterDates] = useState({ from: 0, to: 0 });
    const [dateFilterChanging, setDateFilterChanging] = useState(false);
    const [calendarRange, setCalendarRange] = useState({ min: null, max: null });
    const [usuarios, setUsuarios] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(-1);

    const calendarFilterRef = useRef();
    const dateChangeRef = useRef();

    function refreshData() {
        Promise.all([APIAdminRequest("solicitudes-get-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "", city_id: selectedCity || -1 }), APIAdminRequest("solicitudes-get-kpi", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "", city_id: selectedCity || -1 }), APIAdminRequest("solicitudes-get-day-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "", city_id: selectedCity || -1 })]).then(([ratioByType, KPI, dayRatios]) => {
            setRatiosByType(ratioByType.data);
            setKpis(KPI.data);
            setRatiosByDay(dayRatios.data);
            setLoadingInProgress(false);
            setDateFilterChanging(false);
        });

        APIAdminRequest("get-users-with-solicitudes-vivienda-empleo", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "", city_id: selectedCity || -1 }).then((response) => {
            setUsuarios(response.data);
        });
    }

    useEffect(() => {
        if ((prevCalendarFilterDates.from != calendarFilterDates.from && prevCalendarFilterDates.to != calendarFilterDates.to && calendarFilterDates.from == null && calendarFilterDates.to == null) || (calendarFilterDates.from != null && calendarFilterDates.to != null)) {
            setDateFilterChanging(true);
            setPrevCalendarFilterDates({ ...calendarFilterDates });

            refreshData();
        }
    }, [calendarFilterDates]);

    useEffect(() => {
        refreshData();
    }, [selectedCity]);

    useEffect(() => {
        APIAdminRequest("solicitudes-get-date-range").then((response) => {
            setCalendarRange({
                min: Helper.CreateDateCompatible(response.data.min_date),
                max: Helper.CreateDateCompatible(response.data.max_date)
            });
        });

        APIRequest("users/register-get-countries").then((response) => {
            setCountries(response.data);
        });

        APIAdminRequest("get-users-with-solicitudes-vivienda-empleo", { city_id: -1 }).then((response) => {
            setUsuarios(response.data);
        });

        APIAdminRequest("solicitudes-get-cities").then((response) => {
            setCities(response.data);
        });

        setCalendarFilterDates({ from: null, to: null });

        document.body.classList.add("bg-grey");

        const onClick = (e) => {
            if ($(e.target).closest(".calendar-filter, .calendar-filter-toggle").length == 0) {
                setShowCalendarFilter(false);
            }
        };

        document.body.addEventListener("click", onClick);

        return () => {
            document.body.classList.remove("bg-grey");
            document.body.removeEventListener("click", onClick);
        };
    }, []);

    let mediaEncuestas = [];
    if (surveyData && types) {
        types.forEach((type, typeIndex) => {
            let itemCount = 0;
            mediaEncuestas[typeIndex] = 0;
            if (surveyData[type.id] && surveyData[type.id].length) {
                surveyData[type.id].forEach((item) => {
                    if (item.value > 0) {
                        mediaEncuestas[typeIndex] += item.value;
                        itemCount++;
                    }
                });
            }
            mediaEncuestas[typeIndex] /= itemCount;

            if (isNaN(mediaEncuestas[typeIndex])) mediaEncuestas[typeIndex] = 0;
        });
    }

    if (loadingInProgress) return <LoadingIndicator />;

    return (
        <div className="admin-page admin-estadisticas informes">
            {dateFilterChanging && <LoadingIndicator style={{ marginLeft: $(".sidebar").outerWidth(true) / 2 }} />}
            <div className="header">
                {calendarRange.min != null && calendarRange.max != null && <>
                    <CalendarFilter
                        visible={showCalendarFilter}
                        onChange={(dates) => {
                            clearTimeout(dateChangeRef.current);
                            dateChangeRef.current = setTimeout(() => {
                                setCalendarFilterDates(dates);
                            }, 1000);
                        }}
                        resetRef={(reset) => {
                            calendarFilterRef.current = { reset };
                        }}
                        defaultvalue={calendarFilterDates}
                        minDate={calendarRange.min}
                        maxDate={calendarRange.max} /><MaterialIcon
                        name="calendar_month"
                        tooltip={L10n.__("Filtar por fechas")}
                        className="calendar-filter-toggle"
                        onClick={(e) => {
                            setShowCalendarFilter(!showCalendarFilter);
                        }} />
                </>}
                {calendarFilterDates.from != null && (
                    <div className="dates-display">
                        <span>{Helper.FormatISODate(calendarFilterDates.from, Helper.DATE_VERBOSE_SHORT)}&nbsp;</span>
                        {calendarFilterDates.from != calendarFilterDates.to && <span>- {Helper.FormatISODate(calendarFilterDates.to, Helper.DATE_VERBOSE_SHORT)}</span>}{" "}
                        <MaterialIcon
                            className="reset-dates"
                            name="restart_alt"
                            onClick={(e) => {
                                calendarFilterRef.current.reset();
                                setCalendarFilterDates({ from: null, to: null });
                            }}
                        />
                    </div>
                )}
                <Dropdown
                    options={cities.map(city => {
                        return { caption: city.name, value: city.id };
                    })} defaultOption={{ caption: L10n.__("Todas las poblaciones"), value: -1 }}
                    onChange={(value) => setSelectedCity(value)}
                />
            </div>
            <Bloque className="columnas" style={{ opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms" }}>
                <div className="columna grafica">
                    <h2>{L10n.__("Solicitudes por tipo")}</h2>
                    <DoughnutChart typesLabel={L10n.__("Tipo de solicitud")} types={[{ id: "vivienda", name: L10n.__("Vivienda"), plural: "viviendas" }, { id: "empleo", name: L10n.__("Empleo"), plural: "ofertas de empleo" }]} ratios={ratiosByType} />
                    {ratiosByType.filter(r => r.ratio > 0).length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
                </div>

                <div className="columna">
                    <h2>{L10n.__("Solicitudes por día de la semana")}</h2>
                    <BarChart types={[{ id: "vivienda", name: L10n.__("Vivienda"), plural: "viviendas" }, { id: "empleo", name: L10n.__("Empleo"), plural: "ofertas de empleo" }]} ratios={ratiosByDay} displayCount={true} />
                    {Object.values(ratiosByDay).filter((d) => d.total > 0).length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
                </div>
            </Bloque>
            <Bloque className="columnas" style={{ opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms" }}>
                <div className="columna">
                    <h2>{L10n.__("Indicadores de rendimiento")}</h2>
                    <div className="kpi-container">
                        <div className="item">
                            <div className="value">{kpis.userCount}</div>
                            <div className="caption">{L10n.__("Usuarios")}</div>
                        </div>
                        <div className="item">
                            <div className="value">{kpis.totalCount}</div>
                            <div className="caption">{L10n.__("Solicitudes totales")}</div>
                        </div>
                        <div className="item">
                            <div className="value">{Math.round(((kpis.recurrencia * 100 + Number.EPSILON) * 100) / 100)}%</div>
                            <div className="caption">{L10n.__("Recurrencia")}</div>
                        </div>
                    </div>
                </div>
            </Bloque>
            <Bloque className="columnas">
                <Table
                    className="usuarios"
                    header={[L10n.__("Nombre y apellidos"), L10n.__('Email'), L10n.__("Teléfono"), L10n.__("País")]}
                    widths={[3, 1, 1, 1]}
                    responsiveState={[true, true, true, true]}
                    responsiveWidths={[2, 0, 0, 0]}
                    alignment={["left", "left", "left", "right"]}
                    items={usuarios.filter(u => u.solicitudesEmpleo.length > 0 || u.solicitudesVivienda.length > 0).map((usuario) => ({ data: usuario, columns: [usuario.name + " " + usuario.surname, usuario.login, Helper.FormatPhoneNumber(usuario.phone), countries.find((c) => c.id == usuario.country_id)?.name || "Desconocido"] }))}
                    onOpenDetails={(usuario) => {
                    }}
                    renderDetails={(usuario, idx) => (
                        <>
                            {usuario.solicitudesEmpleo.length > 0 && (
                                <div className="row-reservas" key={"reservas-usuario-" + idx}>
                                    <h2>{L10n.__("Solicitudes de empleo")}</h2>
                                    {usuario.solicitudesEmpleo.map((solicitud, solicitud_index) => {
                                        return <div className={"user-order-row"} key={"reserva-usuario-" + idx + "-" + solicitud_index}>
                                            <div
                                                className="order-row auto-row"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(API_URL + "handle-download.php?type=solicitud-empleo&id=" + solicitud.id, "_blank");
                                                }}>
                                                <div className="no-auto-width date">{Helper.FormatISODate(solicitud.creation_date.split(" ")[0])}</div>
                                                <div className="negocio-name">{solicitud.nombre_empleador}</div>
                                                <div className="availability-description">{solicitud.nombre_oferta_trabajo}</div>
                                                <div className="ciudad-name">{solicitud.nombre_ciudad}</div>
                                                <div className="download-user-file align-right file">{L10n.__("Descargar CV")} <MaterialIcon name="file_download" /></div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            )}
                            {usuario.solicitudesVivienda.length > 0 && (
                                <div className="row-reservas" key={"reservas-usuario-" + idx}>
                                    <h2>{L10n.__("Solicitudes de vivienda")}</h2>
                                    {usuario.solicitudesVivienda.map((solicitud, solicitud_index) => {
                                        return <div className={"user-order-row"} key={"reserva-usuario-" + idx + "-" + solicitud_index}>
                                            <div
                                                className="order-row auto-row"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(API_URL + "handle-download.php?type=solicitud-vivienda&id=" + solicitud.id, "_blank");
                                                }}>
                                                <div className="date">{Helper.FormatISODate(solicitud.creation_date.split(" ")[0])}</div>
                                                <div className="negocio-name">{solicitud.nombre_vendedor}</div>
                                                <div className="availability-description">{solicitud.nombre_vivienda}</div>
                                                <div className="ciudad-name">{solicitud.nombre_ciudad}</div>
                                                <div className="download-user-file align-right file">{L10n.__("Descargar archivo")} <MaterialIcon name="file_download" /></div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            )}
                        </>
                    )}
                />
            </Bloque>
        </div>
    );
}
