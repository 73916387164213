require("../../css/admin-reservas.css");

import { useRef, useCallback, useState, useEffect, useContext } from 'react';
import Calendar from "./Calendar";
import InputSelect from './InputSelect';
import Helper from "../services/Helper";
import Session from '../services/Session';
import Field from './Field';
import MaterialIcon from './MaterialIcon';
import AdminService from '../services/Admin';
import ExtrasConfigurator from "./ExtrasConfigurator";
import L10n from '../services/Locale';
import { APIAdminRequest } from '../services/API';
import { DialogContext } from "../context/DialogContext";

export default function AdminReservas(props) {
	const [ draggedOrderIntent, setDraggedOrderIntent ] = useState();
	const [ draggedOrder, setDraggedOrder ] = useState();
	const [ selectedDates, setSelectedDates ] = useState([]);
	const [ openOrderDetailsIndex, setOpenOrderDetailsIndex ] = useState(-1);
	const [ openNewRowDetailsIndex, setOpenNewRowDetailsIndex ] = useState("");
	const [ orders, setOrders ] = useState([]);
	const [ orderSavingInProgress, setSavingOrderInProgress ] = useState(false);
	const [ ordersAvailabilityCalendar, setOrdersAvailabilityCalendar ] = useState({});
	const [ negocio, setNegocio ] = useState();
	const [ events, setEvents ] = useState([]);
	const [ newOrderData, setNewOrderData ] = useState({ name: "", email: "", phone: "", pax: 1, extras: [], notes: "", price: 0	});

	const dialogContext = useContext(DialogContext);

	const negocioID = props.negocio;
	const blankOrder = orders.find(order => order.event_id == -1);

	useEffect(() => {
		changeDate(null);
		Session.GetPartnerCalendar(negocioID).then(calendar => {
			setOrdersAvailabilityCalendar(calendar);
		});

		APIAdminRequest("get-negocio", { id: negocioID }).then(response => {
			setNegocio(response.data);
		});

		APIAdminRequest("get-events", { negocio_id: negocioID }).then(response => {
			setEvents(response.data);
		});
	}, [negocioID]);

	useEffect(() => {
		const firstDate = Object.keys(ordersAvailabilityCalendar).sort().find(isodate => Helper.GetISODate() <= isodate);
		if (!firstDate) return;

		let firstDateObject = Helper.CreateDateCompatible(firstDate);
		firstDateObject = new Date(firstDateObject.getFullYear(), firstDateObject.getMonth(), 1);

		changeStartDate(firstDateObject);
	}, [ ordersAvailabilityCalendar ]);

	useEffect(() => {
		function onMouseUp(e) {
			if (draggedOrder) {
				e.preventDefault();
				setDraggedOrder(null);
			}
		}

		function onMouseMove(e) {
			if (draggedOrderIntent) {
				e.preventDefault();
				setDraggedOrder(draggedOrderIntent);
				setDraggedOrderIntent(null);
			}
		}

		window.addEventListener("mouseup", onMouseUp);
		window.addEventListener("mousemove", onMouseMove);

		return () => {
			window.removeEventListener("mouseup", onMouseUp);
			window.removeEventListener("mousemove", onMouseMove);
		}
	})

	const changeDateRef = useRef(() => {});
	const changeDate = useCallback(date => {
		if (changeDateRef.current) changeDateRef.current(date);
		setOrders([]);
	}, [ changeDateRef.current ]);

	const changeStartDateRef = useRef(() => {});
	const changeStartDate = useCallback(date => {
		if (changeStartDateRef.current) changeStartDateRef.current(date);
	}, [ changeStartDateRef.current ]);

	const reloadOrders = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (selectedDates && selectedDates.length) {
				AdminService.GetOrdersDirect(negocioID, Helper.GetISODate(selectedDates[0])).then(orders => {
					setOrders(orders[Helper.GetISODate(selectedDates[0])]);
					resolve();
				});
			} else {
				resolve();
			}
		});
	}, [ negocioID, selectedDates ]);

	return <div className="inner"><div className="narrow-content">
		{ordersAvailabilityCalendar !== null && <Calendar
			months={window.innerWidth <= 992 ? 1 : 2}
			allowUnavailableSelection={true}
			changeStartDateRef={ref => changeStartDateRef.current = ref}
			changeDateRef={ref => changeDateRef.current = ref}
			allowPast={true}
			availabilityCalendar={ordersAvailabilityCalendar}
			onSelectionChanged={dates => {
				const date = dates[0];
				if (date) {
					setSelectedDates(dates);
					setOpenOrderDetailsIndex(-1);
					setOpenNewRowDetailsIndex("");
                    setOrders(ordersAvailabilityCalendar[Helper.GetISODate(dates[0])] || []);

					setTimeout(() => {
						$("body, html").animate({ scrollTop: $(".orders-list").offset().top - 170 }, 500);
					});
				}
			}}
		/>}
		</div>
		{selectedDates != null && selectedDates.length > 0 && <div className="orders-list auto-table">
			<div className="date">{Helper.UCFirst(Helper.FormatDate(selectedDates[0], Helper.DATE_VERBOSE_LONGEST))}</div>
			{events.map((event, event_idx) => {
				const price = event.price != -1 ? event.price : negocio.price;

				return <div key={"event-" + event_idx}>
					<div className="event-name">{event.nombre}</div>
					{draggedOrder != null && draggedOrder.event.nombre != event.nombre && <div className="drag-placeholder-order" onMouseUp={e => {
						if (draggedOrder) {
							e.preventDefault();
							const newOrders = [...orders];
							const movedOrder = newOrders.find(movedOrder => movedOrder.id == draggedOrder.id);
							movedOrder.details = {};
							movedOrder.details = event.details;
							movedOrder.event_id = event.id;
							setOrders(newOrders);
							setDraggedOrder(null);

							setSavingOrderInProgress(true);
							AdminService.UpdateOrder(movedOrder.id, movedOrder).then(() => {
								setSavingOrderInProgress(false);
							})
						}
					}} />}
					{orders.filter(order => order.event.nombre == event.nombre).map((order, order_idx) => {
						return <div key={order_idx}>
							<span>
								<div className={"order" + (openOrderDetailsIndex == order_idx ? " active" : "") + (draggedOrder == order ? " dragged" : "")}>
									<div className="order-row auto-row" onClick={e => {
										if (openOrderDetailsIndex == order_idx) {
											setOpenOrderDetailsIndex(-1);
										} else {
											setOpenOrderDetailsIndex(order_idx);
											setOpenNewRowDetailsIndex("");
										}
									}}>
										<div
										onMouseDown={e => {
											e.preventDefault();
											//setDraggedOrderIntent(order);
										}}

										onMouseUp={e => {
											e.preventDefault();
											if (draggedOrderIntent == order) setDraggedOrderIntent(null);
										}}

										className="drag-handle" />
										<div className="name">
											{order == blankOrder ? <Field placeholder={L10n.__("Nombre")} type="string" defaultValue={order.name} onChange={value => {
												const newOrders = [...orders];
												newOrders[order_idx].name = value;
												setOrders(newOrders);
											}} /> : order.name}
										</div>
										<div className="email">
											{order == blankOrder ? <Field placeholder={L10n.__("E-mail")} type="string" defaultValue={order.email} onChange={value => {
												const newOrders = [...orders];
												newOrders[order_idx].email = value;
												setOrders(newOrders);
											}} /> : order.email}	
										</div>
										<div className="phone">
											{order == blankOrder ? <Field placeholder={L10n.__("Teléfono")} type="string" defaultValue={order.phone} onChange={value => {
												const newOrders = [...orders];
												newOrders[order_idx].phone = value;
												setOrders(newOrders);
											}} /> : Helper.FormatPhoneNumber(order.phone)}	
										</div>
										<div className="pax">
											{order == blankOrder ?
												<Field min={1} type="number" defaultValue={order.pax} onChange={value => {
													const newOrders = [...orders];
													newOrders[order_idx].pax = value;
													setOrders(newOrders);
												}} />
											:
												order.pax + " " + (order.pax == 1 ? L10n.__("persona") : L10n.__("personas"))}
										</div>
										{order.details?.time_relevant > 0 && <div className="slot">{order.booked_slot}</div>}
										<div className="align-right amount">{Helper.FormatAmount(order.total / 100)}</div>
										<div className="validated">{order.validated ? L10n.__("Validada") : ""}</div>
										<div className="expand-button no-auto-width"><MaterialIcon name={openOrderDetailsIndex == order_idx ? "expand_less" : "expand_more"} /></div>
									</div>
									{openOrderDetailsIndex == order_idx && <div className="order-details">
										{order.preorder_selection && order.preorder_selection.length > 0 && <div className="comments">
												<span>{L10n.__("Extras")}</span>
												<div className="extras">{order.preorder_selection.map((item, item_idx) => <div className="extra-item" key={"order-" + order_idx + "-item-" + item_idx}>
												<span>{item.quantity}</span>{item.name}
											</div>)}</div>
										</div>}
										{order.comments != null && order.comments.trim() != "" && <div className="comments">
											<span>{L10n.__("Comentarios del cliente")}</span>
											<div>{order.comments}</div>
										</div>}
										<div className="comments">
											<span>{L10n.__("Notas")}</span>
											<Field type="text" defaultValue={order.notes} onChange={value => {
												const newOrders = [...orders];
												newOrders[order_idx].notes = value;
												setOrders(newOrders);
											}} />
										</div>
										<div className={"order-actions" + (orderSavingInProgress ? " disabled" : "")}>
											<button className="btn" onClick={e => {
												if (orderSavingInProgress) return;
												dialogContext.openDialog(L10n.__("¿Seguro que deseas cancelar esta reserva?"), L10n.__("Sí"), L10n.__("No"), accepted => {
													if (accepted) {
														setSavingOrderInProgress(true);
														AdminService.CancelOrder(order.id).then(status => {
															if (status) {
																const newOrders = [...orders];
																newOrders.splice(order_idx, 1);
																setOrders(newOrders);
															}
															setSavingOrderInProgress(false);
														});
													}
												});
											}}>Cancelar reserva</button>
											<button className="btn btn-brown" onClick={e => {
												if (orderSavingInProgress) return;

												dialogContext.openDialog(L10n.__("¿Seguro que deseas modificar esta reserva?"), L10n.__("Sí"), L10n.__("No"), accepted => {
													if (accepted) {
                                                        setSavingOrderInProgress(true);
                                                        AdminService.UpdateOrder(order.id, order).then(() => {
                                                            setSavingOrderInProgress(false);
                                                        });
                                                    }
                                                });
											}}>{L10n.__("Guardar reserva")}</button>
										</div>
									</div>}
								</div>
							</span>
					</div>})}
					<div className={"availability-row-header crear-disponibilidad-button" + (openNewRowDetailsIndex == event.nombre ? " active" : "")} onClick={e => {
						if (openNewRowDetailsIndex == event.nombre) {
							setOpenNewRowDetailsIndex("");
						} else {
							setOpenNewRowDetailsIndex(event.nombre);
							setOpenOrderDetailsIndex(-1);
							const n = {...newOrderData};
							n.price = price;
							setNewOrderData(n);
						}
					}}>
						<div>{L10n.__("Crear reserva")}</div>
						<div className="expand-button"><MaterialIcon name={openNewRowDetailsIndex == event.nombre ? "expand_less" : "expand_more"} /></div>
					</div>
					{openNewRowDetailsIndex == event.nombre && <div className="new-order-row-details">
						<div className="order-details-row">
							<div>
								<div>{L10n.__("Nombre")}</div>
								<Field type="string" onChange={value => {
									const n = {...newOrderData};
									n.name = value;
									setNewOrderData(n);
								}} />
							</div>
							<div>
								<div>{L10n.__("Email")}</div>
								<Field type="string" onChange={value => {
									const n = {...newOrderData};
									n.email = value;
									setNewOrderData(n);
								}} />
							</div>
							<div>
								<div>{L10n.__("Teléfono")}</div>
								<Field type="string" onChange={value => {
									const n = {...newOrderData};
									n.phone = value;
									setNewOrderData(n);
								}} />
							</div>
							<div>
								<div>{L10n.__("Hora")}</div>
								<InputSelect
									comboMode={true}
									options={Helper.GenTimeOptions()}
									defaultValue="00:00"
									onChange={(option) => {
										const n = {...newOrderData};
										n.time = option;
										setNewOrderData(n);
									}}
								/>
							</div>
							<div>
								<div>{L10n.__("Personas")}</div>
								<Field type="number" inputStyle={{backgroundColor: "var(--resaltado-claro)"}} defaultValue={1} min={1} onChange={value => {
									const n = {...newOrderData};
									n.pax = value;
									setNewOrderData(n);
								}} />
							</div>
							<div>
								<div>{L10n.__("Precio unitario")}</div>
								{negocio && <Field type="currency" defaultValue={price} onChange={value => {
									const n = {...newOrderData};
									n.price = value;
									setNewOrderData(n);
								}} />}
							</div>
						</div>	
						<div className="order-details-row">
							<div style={{flex:1}}>
								<div>{L10n.__("Extras")}</div>
								<div>
									<ExtrasConfigurator
										data={event.extras_menu || negocio.preorder_menu}
										onChange={(data) => {
											const n = {...newOrderData};
											n.extras = data;
											setNewOrderData(n);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="order-details-row">
							<div style={{flex:1}}>
								<div>{L10n.__("Notas")}</div>
								<div><Field type="text" onChange={value => {
									const n = {...newOrderData};
									n.notes = value;
									setNewOrderData(n);
								}} /></div>
							</div>
						</div>
						<div className={"order-actions" + (orderSavingInProgress ? " disabled" : "")}>
							<button className="btn btn-brown" onClick={e => {
								if (orderSavingInProgress) return;

								setSavingOrderInProgress(true);
								
								const orderData = {...newOrderData};
								orderData.negocio_id = negocio.id;
								orderData.event_id = event.id;
								orderData.slot = Helper.GetISODate(selectedDates[0]) + " " + newOrderData.time;
								orderData.extras = orderData.extras.filter(item => item.quantity > 0);

								AdminService.CreateOrder(orderData).then(() => {
									reloadOrders().then(() => {
										setSavingOrderInProgress(false);
										setOpenNewRowDetailsIndex("");
									});
								});
							}}>{L10n.__("Crear reserva")}<span>{Helper.FormatAmount((newOrderData.price * (negocio.full_booking ? 1 : newOrderData.pax) + newOrderData.extras.reduce((total, item) => total + item.quantity * item.price, 0))/100)}</span></button>
						</div>
					</div>}
				</div>
			})}
		</div>}
	</div>;
}
