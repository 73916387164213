import React, { useRef, useState, useEffect, useCallback, useContext } from "react";
import Modal from "./Modal.tsx";
import HTMLReactParser from "html-react-parser";

import MaterialIcon from "./MaterialIcon.tsx";
import { StarMeter } from "./Encuesta";
import EmpleadorInfo from "./EmpleadorInfo.tsx";
import Calendar from "./Calendar";
import Icon from "./Icon.tsx";
import Trabajo from "../services/Trabajo.tsx";
import Session from "../services/Session.tsx";
import Helper from "../services/Helper.tsx";
import LoadingIndicator from "./LoadingIndicator.tsx";
import L10n from "../services/Locale.tsx";
import Slider from "./Slider.tsx";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { APIRequest } from "../services/API.ts";

export default function FichaEmpleador(props) {
    const params = useParams();
    const navigate = useNavigate();
    const setOriginalDatesOffset = useRef();
    const [similar, setSimilar] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState(null);
    const [empleador, setEmpleador] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const datesInfoModal = useRef({});

    useEffect(() => {
        if (!empleador) return;

        APIRequest("trabajo/get-empleador-surveys", { empleador_id: empleador.id }).then((response) => {
            setSurveyData(response.data);
        });
    }, [empleador]);

    const noCalendarModalRef = useRef();
    const calendarModalRef = useRef();
    const scrollOffset = useRef(0);

    const updateData = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            $("header").addClass("simple");
            const empleador = await Trabajo.GetBySlug(params.slug);
            empleador.ofertas_trabajo = empleador.ofertas_trabajo?.filter((e) => e.enabled);
            setEmpleador(empleador);

            setLoading(false);

            if (!isNaN(window.location.hash.substring(1)) && window.location.hash.substring(1) > 0) {
                setSelectedAvailability(empleador.ofertas_trabajo.find((e) => e.id == window.location.hash.substring(1)));
            }

            Trabajo.GetSimilar(empleador.id).then(async (similar) => {
                setSimilar(similar);
            });
        });
    }, [params]);

    function openModalDeferred() {
        if (selectedAvailability && calendarModalRef.current?.open) {
            setLoading(false);
            calendarModalRef.current.open();
            history.pushState({}, "", window.location.pathname + "#" + selectedAvailability.id);
        } else {
            setTimeout(openModalDeferred, 100);
        }
    }

    function renderDates(dateBoxWidth) {
        return empleador.ofertas_trabajo.map((ofertaTrabajo, idx) => {

            let minimumPrice = -1;

            if (ofertaTrabajo.price == -1) {
                if (minimumPrice == -1 || (empleador.price != -1 && empleador.price < minimumPrice)) {
                    minimumPrice = empleador.price;
                }
            } else {
                if (minimumPrice == -1 || (ofertaTrabajo.price != -1 && ofertaTrabajo.price < minimumPrice)) {
                    minimumPrice = ofertaTrabajo.price;
                }
            }

            if (minimumPrice == -1) minimumPrice = 0;

            const image_url = location.hostname == "localhost" ? "http://localhost:8081" + (ofertaTrabajo.image_url[0] == "/" ? ofertaTrabajo.image_url : "/" + ofertaTrabajo.image_url) : ofertaTrabajo.image_url;

            return (
                <div key={"date-" + idx} style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        className={"date cover-zoom-listener"}
                        style={{
                            opacity: (ofertaTrabajo.no_calendar == 1 ? noCalendarModalRef : calendarModalRef)?.current?.open ? 1 : 0,
                            width: dateBoxWidth || "auto"
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            Session.RequireAccount("/empleo/" + empleador.slug + "/" + ofertaTrabajo.id, navigate);
                        }}>
                        <div className="event-info-image cover-zoom" style={{ backgroundImage: "url(" + (image_url[0] == "/" ? image_url : "/static/images/eventos/" + image_url) + ")" }}>
                            {ofertaTrabajo.no_calendar == 1 && <div className="event-type has-tooltip no-calendar" data-tooltip={L10n.__('Producto físico')}>{L10n.__("Producto")}</div>}
                        </div>
                        <div className="details">
                            <div className="event-info-name">{ofertaTrabajo.nombre}</div>
                            <div className="event-info-tag">
                                {ofertaTrabajo.nombre_tipo_oferta}
                            </div>
                        </div>
                    </div>
                    {Helper.IsResponsive() && (
                        <div className="mas-info date" key={idx}>
                            <div className="details">
                                <div className="event-info-name">{ofertaTrabajo.nombre}</div>
                                <div className="event-info-description">
                                    {Helper.Ellipsis(
                                        ofertaTrabajo.description ? ofertaTrabajo.description : empleador.description,
                                        100,
                                        () =>
                                        (
                                            <a
                                                href="#"
                                                className="read-more-link"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    datesInfoModal.current[ofertaTrabajo.id].open();
                                                }}>
                                                {L10n.__("+ Info")}
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    }

    function renderInlineDates() {
        return empleador.ofertas_trabajo
            .map((ofertaTrabajo, idx) => {
                return (
                    <div className="date" key={idx}>
                        <div className="details">
                            <div className="event-info-name">{ofertaTrabajo.nombre}</div>
                            <div className="event-info-description">
                                <div dangerouslySetInnerHTML={{ __html: ofertaTrabajo.description ? ofertaTrabajo.description : empleador.description }}></div>
                                <a
                                    href="#"
                                    className="read-more-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        Session.RequireAccount("/empleo/" + empleador.slug + "/" + ofertaTrabajo.id, navigate);
                                    }}>
                                    {L10n.__("+ Info")}
                                </a>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    function onScroll(e) {
        if (!$(".col-dates").is(":visible") || !$(".col-dates").length || !$(".col-dates")[0].originalOffset) {
            return;
        }

        const $ofertaTrabajoInfo = $(".container.evento > .row + .row > .event-info");

        if ($ofertaTrabajoInfo.height() < $(".col-dates").outerHeight(true)) {
            $ofertaTrabajoInfo.css({ paddingBottom: $(".col-dates").outerHeight(true) - $ofertaTrabajoInfo.height() });
        }
        $ofertaTrabajoInfo.css({ minHeight: $(".col-dates").outerHeight(true) });

        requestAnimationFrame(() => {
            const scrollTop = $(window).scrollTop();
            const scrollDelta = scrollTop - scrollOffset.current;
            scrollOffset.current = scrollTop;

            const bottomElement = $(".encuestas-clientes").length ? $(".encuestas-clientes")[0] : $(".related-events-h3")[0];

            if (scrollDelta > 0 && scrollTop + $("header").height() + 20 >= $(".col-dates")[0].originalOffset.top) {
                if ($(".col-dates")[0]?.getBoundingClientRect().bottom < window.innerHeight - 10) {
                    $(".col-dates").css({
                        top: window.innerHeight - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0].getBoundingClientRect().top
                    });
                }

                if ($(".col-dates").offset().top > $(".col-dates")[0].originalOffset.top && $(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
                    $(".col-dates").css({
                        top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
                    });
                }

                if ($(".col-dates")[0]?.getBoundingClientRect().bottom > bottomElement?.getBoundingClientRect().top) {
                    $(".col-dates").css({
                        top: bottomElement?.getBoundingClientRect().top - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
                    });
                }
            } else if (scrollDelta < 0) {
                if ($(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
                    $(".col-dates").css({
                        top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
                    });
                }

                if (scrollTop + $("header").height() + 20 < $(".col-dates")[0].originalOffset.top) {
                    $(".col-dates").css({
                        top: 0
                    });
                }
            }
        });
    }

    setOriginalDatesOffset.current = () => {
        if (!$(".col-dates").length) {
            setTimeout(setOriginalDatesOffset.current, 500);
            return;
        }

        $(".col-dates")[0].originalOffset = $(".col-dates").offset();
        $(".col-dates")[0].originalOffset.boundingClientRect = $(".col-dates")[0].getBoundingClientRect();
        $(".col-dates").parent().css("position", "relative");
        $(".col-dates")
            .addClass("floating")
            .css({
                top: 0,
                width: $(".col-dates")[0].originalOffset.boundingClientRect.width,
                right: 0
            });
    };

    useEffect(() => {
        updateData();

        setOriginalDatesOffset.current();
        $(window).on("scroll", onScroll);

        return () => {
            $(window).off("scroll", onScroll);
            $("header").removeClass("simple");
        };
    }, [updateData]);

    useEffect(() => {
        if (selectedAvailability?.id) {
            openModalDeferred();
        }
    }, [selectedAvailability]);

    useEffect(() => {
        if (empleador) Helper.SetDocumentTitle(empleador.name);
    }, [empleador]);

    if (!empleador) return <LoadingIndicator />;

    const sliderHeight = !Helper.IsResponsive() ? 456 : 375;
    const ofertasTrabajoLength = empleador.ofertas_trabajo.length;

    return (
        <React.Fragment>
            {loading && <LoadingIndicator />}
            <div className="heading-ficha-evento">
                {empleador.slider_enabled == 1 && empleador.slider && (
                    <Slider
                        style={{
                            opacity: empleador.slider.length ? 1 : 0,
                            height: sliderHeight,
                            minHeight: sliderHeight
                        }}
                        height={sliderHeight}
                        className="slider-ficha-evento"
                        effect="fade"
                        delay={5000}
                        slides={empleador.slider}
                    />
                )}
                {!(empleador.slider_enabled == 1 && empleador.slider) && empleador.header_image_url != null && (
                    <div className="row">
                        <div className="event-cover" style={{ backgroundImage: "url(" + (empleador.header_image_url[0] == "/" ? empleador.header_image_url : "/static/images/eventos/" + empleador.header_image_url) + ")" }}></div>
                    </div>
                )}
            </div>
            <div className={"evento container"}>
                <div className="row">
                    <div className="col-md-8 event-info">
                        <h1>{empleador.name}</h1>
                        <div className="event-address">
                            <div className="address-name">{empleador.city}</div>
                            <div className="address">{empleador.address}</div>
                        </div>
                        <div className="tags"></div>
                        <div className="info-negocio">
                            <EmpleadorInfo empleador={empleador} />
                        </div>
                        {empleador.description != undefined && (
                            <div className="event-description">
                                <div className="event-text">{HTMLReactParser(empleador.description)}</div>
                            </div>
                        )}
                        <div className="event-dates-responsive">
                            <div className="container" style={{ width: "calc(" + ofertasTrabajoLength * 100 + "vw - " + ofertasTrabajoLength * 75 + "px)" }}>
                                {renderDates("calc(100vw - 75px)")}
                            </div>
                        </div>
                        {empleador.ofertas_trabajo?.length > 0 && (
                            <div className="desktop-only experiencias">
                                <div className="inner">{renderInlineDates()}</div>
                            </div>
                        )}
                        {empleador.iconos_detalle != undefined && JSON.parse(empleador.iconos_detalle)?.length > 0 && (
                            <div className="facts">
                                {JSON.parse(empleador.iconos_detalle).map((detalle, idx) => {
                                    return (
                                        <div key={"detalle-" + idx}>
                                            <div className="icon-image" style={{ backgroundImage: "url(" + detalle.image_url + ")" }} />
                                            <div>
                                                <div>
                                                    <b>{detalle.titulo}</b>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: detalle.lista }} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {empleador.show_ratings == 1 && surveyData && surveyData.map && (
                            <div className="encuestas-clientes">
                                <h3>{L10n.__("Valoración")}</h3>
                                <div className="inner">
                                    <div className="columna" style={{ width: window.innerWidth > 992 ? null : surveyData.length * window.innerWidth * 0.63 - window.innerWidth + 300 }}>
                                        {surveyData.map((item, index) => {
                                            return (
                                                <div className="category" key={"survey-data-category" + index}>
                                                    <h2>{item.text}</h2>
                                                    <StarMeter defaultValue={item.value} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-3 col-dates">{renderDates()}</div>
                </div>
                {similar?.length > 0 && (
                    <div>
                        <h3 className="related-events-h3">{L10n.__("Similares")}</h3>
                        <div className="related-events desktop-only">
                            {similar?.slice(0, 3).map((ofertaTrabajo) => (
                                <div key={"event-" + ofertaTrabajo.id} onClick={() => (location.href = "/oferta-trabajo/" + ofertaTrabajo.slug)}>
                                    <div
                                        className="event-image"
                                        style={{
                                            backgroundImage: "url(" + (ofertaTrabajo.header_image_url[0] == "/" ? ofertaTrabajo.header_image_url : "/static/images/eventos/" + ofertaTrabajo.header_image_url) + ")"
                                        }}>
                                        &nbsp;
                                    </div>
                                    <div className="event-name">{ofertaTrabajo.name}</div>
                                    <div className="event-city">{ofertaTrabajo.city}</div>
                                </div>
                            ))}
                        </div>

                        <div className="related-events mobile-only">
                            <div className="horizontal-scroll-container" style={{ width: similar.length * (window.innerWidth - 75) + 130 }}>
                                {similar.map((ofertaTrabajo) => (
                                    <div key={"similar-" + ofertaTrabajo.id} className="cover-zoom-listener">
                                        <a
                                            onClick={() => (location.href = "/oferta-trabajo/" + ofertaTrabajo.slug)}
                                            className="event-image cover-zoom"
                                            style={{
                                                backgroundImage: "url(" + (ofertaTrabajo.header_image_url[0] == "/" ? ofertaTrabajo.header_image_url : "/static/images/eventos/" + ofertaTrabajo.header_image_url) + ")"
                                            }}>
                                            &nbsp;
                                        </a>
                                        <div className="event-name">{ofertaTrabajo.name}</div>
                                        <div className="event-city">{ofertaTrabajo.city}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {empleador.ofertas_trabajo
                .map((ofertaTrabajo, idx) => {
                    return (
                        <Modal
                            key={"modal-" + idx}
                            onRef={(ref) => {
                                datesInfoModal.current[ofertaTrabajo.id] = ref;
                            }}
                            title={ofertaTrabajo.nombre}
                            className="event-info-modal"
                            renderContent={(modal) => {
                                return (
                                    <>
                                        <div
                                            className="event-image"
                                            style={{
                                                backgroundImage: "url(" + (ofertaTrabajo.image_url[0] == "/" ? ofertaTrabajo.image_url : "/static/images/eventos/" + ofertaTrabajo.image_url) + ")"
                                            }}>
                                            &nbsp;
                                        </div>
                                        <p />
                                        <div dangerouslySetInnerHTML={{ __html: ofertaTrabajo.description }} />
                                    </>
                                );
                            }}
                        />
                    );
                })}
        </React.Fragment>
    );
}
