import react, { useState, useEffect, useRef, useContext } from "react";
import Icon from "./Icon";
import Modal from "./Modal";
import Calendar from "./Calendar";
import Helper from "../services/Helper";
import L10n from "../services/Locale";
import { APIRequest } from "../services/API";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import SubscriptionsService from "../services/Subscriptions";
import React from "react";
import { SettingsContext } from "../context/SettingsContext";

export default function WidgetFiltro(props) {
	const [ciudades, setCiudades] = useState([]);
	const [ciudad, setCiudad] = useState("");
	const [fecha, setFecha] = useState("");
	const [pax, setPax] = useState(-1);
	const [kind, setKind] = useState("");
	const [priceIndex, setPriceIndex] = useState(-1);
	const [finishedLoading, setFinishedLoading] = useState(false);
	const [filteredElements, setFilteredElements] = useState(props.elements);

	const ciudadesModalRef = useRef();
	const fechasModalRef = useRef();
	const resultsProvider = props.resultsProvider;
	const subscriptionsContext = useContext(SubscriptionsContext);
	const settingsContext = useContext(SettingsContext);

	function changeFilter(ciudad, fecha, pax, priceIndex, kind = "") {
		if (props.disabled) return;

		let results = filter([...props.elements], ciudad, fecha, pax, priceIndex, kind);

		if (props.onFilterChanged) {
			props.onFilterChanged(ciudad, fecha, pax, priceIndex, kind);
		}

		if (props.onFiltered) {
			props.onFiltered(results, ciudad != "" || fecha != "" || pax > 1 || priceIndex != -1 || ciudad != "" || fecha != "" || pax > 1 || priceIndex != -1 || kind != "");
		}

		setCiudad(ciudad);
		setFecha(fecha);
		setPax(pax);
		setPriceIndex(priceIndex);
		setKind(kind);

		setFilteredElements(results);
	}

	function filter(results, ciudad = "", fecha = "", pax = -1, priceIndex = -1, kind = "") {
		if (props.disabled) return;

		if (resultsProvider) {
			results = resultsProvider.search(ciudad, fecha, pax, priceIndex, kind);
		} else {
			if (subscriptionsContext?.subscriptionsEnabled) {
				const prices = results.reduce((prices, negocio) => {
					if (negocio.min_price != -1 && negocio.min_price != Infinity && negocio.min_price < prices.min) prices.min = negocio.min_price;
					if (negocio.min_price != -1 && negocio.min_price != Infinity && negocio.min_price > prices.max) prices.max = negocio.min_price;
					return prices;
				}, { min: Infinity, max: 0 });

				results = results.filter((negocio) => {
					if (priceIndex == -1) return true;
					if (priceIndex == 0 && negocio.min_price <= prices.min + (prices.max - prices.min) * 0.33) return true;
					if (priceIndex == 1 && negocio.min_price > prices.min + (prices.max - prices.min) * 0.33 && negocio.min_price <= prices.min + (prices.max - prices.min) * 0.66) return true;
					if (priceIndex == 2 && negocio.min_price > prices.min + (prices.max - prices.min) * 0.66) return true;
					return false;
				});
			}

			results = results.filter((negocio) => {
				if (subscriptionsContext?.subscriptionsEnabled) {
					for (let i = 0; i < negocio.events.length; ++i) {
						let userSubscriptionPax = 0;

						const event = negocio.events[i];
						if (event.no_calendar) continue;

						subscriptionsContext.userSubscriptions?.forEach((subscription) => {
							if (subscription.events?.filter((ev) => ev.negocio_id == event.negocio_id).length > 0) {
								userSubscriptionPax = Math.max(userSubscriptionPax, subscription.pax);
							}
						});

						if (event.subscription_type?.pack) {
							if (!event.subscription_type.allow_extra_pax && pax > (event.subscription_type.combinable_visits ? event.subscription_type.visits : 1) * userSubscriptionPax) return false;
						} else {
							if (!event.subscription_data.allow_extra_pax && pax > (event.subscription_data.combinable_visits ? event.subscription_data.visits : 1) * userSubscriptionPax) return false;
						}
					}
				}

				if (ciudad && negocio.city != ciudad) {
					return false;
				}

				if (fecha) {
					let available = false;
					for (let i = 0; i < negocio.events.length; ++i) {
						const event = negocio.events[i];
						for (let j = 0; j < event.availability.length; ++j) {
							const availability = event.availability[j];
							if (availability.slot.split(" ")[0] == Helper.GetISODate(fecha)) available = true;
						}
					}
					if (!available) return false;
				}

				if (pax != -1) {
					let available = false;
					for (let i = 0; i < negocio.events.length; ++i) {
						const event = negocio.events[i];
						if (event.no_calendar) {
							available = true;
							continue;
						}

						for (let j = 0; j < event.availability.length; ++j) {
							const availability = event.availability[j];
							if (availability.pax >= pax) available = true;
						}
					}
					if (!available) return false;
				}

				if (kind != "") {
					console.trace("Not implemented");
					console.log(negocio);
				}

				return true;
			});
		}

		return results;
	}

	const propsElements = props.elements;
	useEffect(() => {
		setFilteredElements(propsElements);
	}, [propsElements]);

	useEffect(() => {
		APIRequest("cities/get").then((response) => {
			setCiudades(response.data);
			setFinishedLoading(true);
		});
	}, []);

	if (props.resetRef) {
		props.resetRef(() => {
			changeFilter("", "", 1, -1);
		});
	}

	if (props.filterRef) {
		props.filterRef((results) => {
			return filter(results, ciudad, fecha, pax, priceIndex);
		});
	}

	const shownPax = Math.max(1, pax);
	const maxPax = props.maxPax || -1;
	if (!ciudades || !finishedLoading) return null;

	return (
		<>
			<div className={"widget-filtro " + (props.className || "") + (props.disabled ? " disabled" : "")}>
				{props.disableCityFilter !== true && (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							ciudadesModalRef.current.open();
						}}
						className={["filtro-ciudades", ciudad != "" && "active"].join(" ")}>
						{ciudad != "" ? Helper.Ellipsis(Helper.UCFirst(ciudad), 18) : L10n.__("Lugar")}
						{ciudad != "" && (
							<div
								className="reset-filter"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									changeFilter("", fecha, pax, priceIndex);
								}}>
								×
							</div>
						)}
					</a>
				)}
				{props.disablePriceFilter !== true && <div className="filtro-precio">
					<a href="#" onClick={(e) => {
						e.preventDefault();
						if (priceIndex == 0) changeFilter(ciudad, fecha, pax, -1);
						else changeFilter(ciudad, fecha, pax, 0);
					}} className={["price-filter-option", priceIndex == 0 && "active", priceIndex != -1 && priceIndex != 0 && "dimmed"].join(" ")}>€</a>
					<a href="#" onClick={(e) => {
						e.preventDefault();
						if (priceIndex == 1) changeFilter(ciudad, fecha, pax, -1);
						else changeFilter(ciudad, fecha, pax, 1);
					}} className={["price-filter-option", priceIndex == 1 && "active", priceIndex != -1 && priceIndex != 1 && "dimmed"].join(" ")}>€€</a>
					<a href="#" onClick={(e) => {
						e.preventDefault();
						if (priceIndex == 2) changeFilter(ciudad, fecha, pax, -1);
						else changeFilter(ciudad, fecha, pax, 2);
					}} className={["price-filter-option", priceIndex == 2 && "active", priceIndex != -1 && priceIndex != 2 && "dimmed"].join(" ")}>€€€</a>
				</div>}
				{props.disableDateFilter !== true && (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							fechasModalRef.current.open();
						}}
						className={["filtro-fecha", fecha != "" && "active"].join(" ")}>
						{fecha != "" ? Helper.FormatDate(fecha, Helper.DATE_VERBOSE_SHORT) : Helper.IsResponsive() ? L10n.__("Más filtros") : L10n.__("Fecha")}
						{fecha != "" && (
							<div
								className="reset-filter"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									changeFilter(ciudad, "", pax, priceIndex);
								}}>
								×
							</div>
						)}
					</a>
				)}
				{props.disablePaxFilter !== true && !Helper.IsResponsive() && (
					<a
						href="#"
						style={{ cursor: "default" }}
						onClick={(e) => {
							e.preventDefault();
						}}
						className="filtro-pax">
						<span>
							{shownPax} {shownPax == 1 ? L10n.__("persona") : L10n.__("personas")}
						</span>
						<span className="boton-cantidad">
							<div
								onClick={(_) => {
									if (shownPax - 1 < 1) return;
									changeFilter(ciudad, fecha, Math.max(1, shownPax - 1), priceIndex);
								}}>
								<Icon name="minus" pixelSize={18} />
							</div>
						</span>
						<span className={"boton-cantidad" + (maxPax > -1 && maxPax <= shownPax ? " disabled" : "")}>
							<div
								onClick={(_) => {
									changeFilter(ciudad, fecha, maxPax != -1 ? Math.min(maxPax, shownPax + 1) : shownPax + 1, priceIndex);
								}}>
								<Icon name="plus" pixelSize={18} />
							</div>
						</span>
					</a>
				)}
				{!props.disableKindFilter && (parseInt(settingsContext.ENABLE_TRABAJO_OFFERS) == 1 || parseInt(settingsContext.ENABLE_VIVIENDA_OFFERS) == 1) && !subscriptionsContext.subscriptionsEnabled && <div className={"kind-filter" + (kind != "" ? " has-active" : "")}>
					{parseInt(settingsContext.ENABLE_VIVIENDA_OFFERS) == 1 && <a href="#" className={kind == "vivienda" ? "active" : ""} onClick={e => {
						e.preventDefault();
						changeFilter(ciudad, fecha, pax, priceIndex, kind == "vivienda" ? "" : "vivienda");
					}}>{L10n.__("Vivienda")}</a>}
					{parseInt(settingsContext.ENABLE_TRABAJO_OFFERS) == 1 && <a href="#" className={kind == "empleo" ? "active" : ""} onClick={e => {
						e.preventDefault();
						changeFilter(ciudad, fecha, pax, priceIndex, kind == "empleo" ? "" : "empleo");
					}}>{L10n.__("Empleo")}</a>}
				</div>
				}
			</div>

			<Modal
				ref={(ref) => (ciudadesModalRef.current = ref)}
				title={L10n.__("Selecciona un lugar")}
				className="search-city-modal"
				onCancel={() => {
					changeFilter("", fecha, pax, priceIndex);
				}}
				renderContent={(_modal) => {
					return ciudades.map((ciudad, idx) => {
						let disabled = true;

						props.ciudades.forEach((foundCiudad) => {
							if (foundCiudad.id == ciudad.id) disabled = false;
						});

						return (
							<div
								className={["city-button", disabled ? "disabled" : ""].join(" ")}
								key={idx}
								onClick={(e) => {
									if (disabled) {
										return;
									}

									changeFilter(ciudad.name, fecha, pax, priceIndex);
									ciudadesModalRef.current.close();
								}}
								style={{
									backgroundImage: "url(" + ciudad.image_url + ")"
								}}>
								<div className="city-name-tag">{ciudad.name}</div>
							</div>
						);
					});
				}}
			/>

			<Modal
				ref={(ref) => (fechasModalRef.current = ref)}
				title={L10n.__("Selecciona una fecha")}
				className="calendar-modal"
				onCancel={() => {
					changeFilter(ciudad, "", pax, priceIndex);
				}}
				renderContent={(_modal) => {
					let combinedCalendar = {};

					if (props.combinedCalendar) {
						combinedCalendar = { ...props.combinedCalendar };
					} else {
						let eventsList = [];

						if (resultsProvider) {
							eventsList = resultsProvider.getFilteredElements(ciudad, "", pax).reduce((events, negocio) => events.concat(negocio.events), []);
						} else {
							eventsList = filteredElements.filter((negocio) => (ciudad != "" ? negocio.city == ciudad : true)).reduce((events, negocio) => events.concat(negocio.events), []);
						}

						eventsList.forEach((event) => {
							Object.keys(event.calendar).forEach((date) => {
								Object.keys(event.calendar[date]).forEach((time) => {
									if (event.calendar[date][time] < Math.max(1, shownPax)) {
										return;
									}

									if (!combinedCalendar[date]) combinedCalendar[date] = {};
									if (!combinedCalendar[date][time]) combinedCalendar[date][time] = 0;

									combinedCalendar[date][time] += parseInt(event.calendar[date][time]);
								});
							});
						});
					}

					return (
						<div className="modal-calendario">
							{Helper.IsResponsive() && (
								<div className="pax-selector">
									<div className="pax">
										{shownPax} persona{shownPax != 1 && "s"}
									</div>
									<div
										className="pax-button"
										onClick={(e) => {
											if (shownPax - 1 < 1) return;
											changeFilter(ciudad, "", Math.max(1, shownPax - 1), priceIndex);
										}}>
										<Icon name="minus" size={1.5} />
									</div>
									<div
										className="pax-button"
										onClick={(e) => {
											changeFilter(ciudad, "", shownPax + 1, priceIndex);
										}}>
										<Icon name="plus" size={1.5} />
									</div>
								</div>
							)}
							<Calendar
								defaultValue={fecha}
								months={Helper.IsResponsive() ? 1 : 2}
								availabilityCalendar={combinedCalendar}
								onSelectionChanged={(dates, data) => {
									changeFilter(ciudad, dates[0], pax, priceIndex);
									fechasModalRef.current.close();
								}}
							/>
						</div>
					);
				}}
			/>
		</>
	);
}
