import { APIRequest } from "./API";

export default class Settings {
	static async GetAll() {
		const response = await APIRequest("settings/get-all");
		return response.data;
	}

	static Get(key, defaultValue = null) {
		return new Promise((resolve, reject) => {
			const cached = (JSON.parse(sessionStorage.getItem("settings_cache") || "{}") || {})[key];

			if (typeof cached !== "undefined") {
				resolve(cached);
				return;
			}

			APIRequest("settings/get", { key }).then(response => {
				const settingsCache = (JSON.parse(sessionStorage.getItem("settings_cache") || "{}") || {});
				settingsCache[key] = response.data || defaultValue;
				sessionStorage.setItem("settings_cache", JSON.stringify(settingsCache));
				resolve(response.data || defaultValue);
			});
		});
	}
}
