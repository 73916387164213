/*
4242424242424242	Succeeds and immediately processes the payment.
4000000000003220	Requires 3D Secure 2 authentication for a successful payment.
4000000000009995	Always fails with a decline code of insufficient_funds.
*/

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import L10n from '../services/Locale';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Helper from '../services/Helper';
import { APIRequest } from '../services/API';

const StripeCheckoutForm = props => {
    const [ payButtonEnabled, setPayButtonEnabled ] = useState(false);
    const [ termsAccepted, setTermsAccepted ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ show3dsWarning, setShow3dsWarning ] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (props.hasPaymentMethod) {
            $(".pago-suscripcion").css({ opacity: 1 });
            setPayButtonEnabled(true);
        }
    }, [props]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setPayButtonEnabled(false);

        if (props.hasPaymentMethod && props.returnURL && props.userSubscription) {
            if (props.onTriggerConfirm) {
                props.onTriggerConfirm();
            }
            
            APIRequest("subscriptions/confirm-with-existing-payment-method", { user_subscription_id: props.userSubscription.id }).then(response => {
                if (!response.status) {
                    setErrorMessage(response.error);
                    setShowError(true);
                    setPayButtonEnabled(true);
                    return;
                }

                location.href = "https://" + location.hostname + props.returnURL;
            });
            return;
        }
        
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmSetup({
            elements,
            redirect: props.returnURL ? "always" : "if_required",
            confirmParams: {
                return_url: "https://" + location.hostname + props.returnURL
            }
        });
        
        if (!result.error || result.error.setup_intent.status == "succeeded") {
            if (!props.returnURL && props.onConfirmed) {
                props.onConfirmed();
            } else if (props.returnURL) {
                location.href = "https://" + location.hostname + props.returnURL + "?setup_intent=" + result.error.setup_intent.id;
            } else {
                location.reload();
            }
            return;
        }
        
        if (result.error) {
            setErrorMessage(result.error.message);
            setShowError(true);
            setPayButtonEnabled(true);
            return;
        }

        setShowError(false);
    };

    if (!elements) return null;

    return <form>
        <div className="stripe-element-container">
            {!props.hasPaymentMethod && <PaymentElement
                onReady={e => {
                    $(".pago-suscripcion").css({ opacity: 1 });
                }}
                onChange={e => {
                    setPayButtonEnabled(e.complete);
                }}
            />}
            {props.hasPaymentMethod && <div>
                <div>{L10n.__("Usaremos la tarjeta terminada en")} {props.cardLast4} {L10n.__("para cobrar tu suscripción.")}</div>
                <div>{L10n.__("Recuerda que puedes cambiarla desde tu perfil de usuario.")}</div>
            </div>}
        </div>

        <div className="disclaimer"><label><input onChange={e => {
            setTermsAccepted(e.target.checked);
            setShowError(false);
        }} type="checkbox" required="" className="accept-terms" /> {L10n.__("Confirmo que he leído y acepto los")} <Link rel="noreferrer" to={BASENAME + "/terminos-y-condiciones"} target="_blank">{L10n.__("términos de uso")}</Link> {L10n.__("y la")} <Link rel="noreferrer" to={BASENAME + "/politica-de-privacidad"} target="_blank">{L10n.__("política de privacidad")}</Link>.</label></div>

        {showError && <div className="terms-error">{errorMessage}</div>}

        <button onClick={e => {
            e.preventDefault();
            if (!termsAccepted) {
                setErrorMessage(L10n.__("Para continuar debes aceptar los términos de uso y la política de privacidad."));
                setShowError(true);
                return;
            }

            setShowError(false);
            if (!props.skip3dsWarning) setShow3dsWarning(true);
            else {
                handleSubmit(e);
            }
        }} disabled={!stripe || !payButtonEnabled} className={"btn btn-brown confirmar" + (!termsAccepted ? " disabled" : "")}>{props.buttonCaption ||L10n.__("Pagar")}</button>

        {show3dsWarning && <div className="i3ds-warning-backdrop">
            <div className="i3ds-warning">
                {!!props.subscriptionName && <>
                    <div><p>{L10n.__("Es posible que tu banco te pida autorizar la tarjeta con un coste de 0,00 € como paso previo al cobro de tu suscripción.")}</p>
                    {!!props.renewable && <p>{L10n.__("Una vez completado este paso, se procederá al cargo de") + " " + Helper.FormatAmount(props.chargedAmount/100, false, true) + L10n.__("/mes por tu suscripción a") + " " + props.subscriptionName + "."}</p>}
                    {!props.renewable && <p>{L10n.__("Esta suscripción no se renovará.")}</p>}
                    </div>
                </>}
                {!props.subscriptionName && <>
                    <div><p>{L10n.__("Es posible que tu banco te pida autorizar la tarjeta con un coste de 0,00 €.")}</p></div>
                </>}
                <a href="#" className="btn btn-brown" onClick={e => {
                    e.preventDefault();
                    handleSubmit(e);
                    setShow3dsWarning(false);
                }}>{L10n.__("Continuar")}</a>
            </div>
        </div>}
    </form>;
};

export default StripeCheckoutForm;
