import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import SubscriptionsService from "../services/Subscriptions";
import { APIRequest } from "../services/API";
import L10n from "../services/Locale";
import Session from "../services/Session";
import { SubscriptionsContext } from "../context/SubscriptionsContext";

export default function Suscribete(props) {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState({ sections: [], subscriptionInfo: {}, subscriptionInfoSubscribers: {} });
    const [negocios, setNegocios] = useState([]);
    const [activeQuestions, setActiveQuestions] = useState({});
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [subscribed, setSubscribed] = useState(false);
    const [noCalendar, setNoCalendar] = useState(true);
    const [isNonPackType, setIsNonPackType] = useState(true);
    const [isTypeSubscriptionCart, setIsTypeSubscriptionCart] = useState(false);

    const subscriptionsContext = useContext(SubscriptionsContext);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setIsNonPackType(!subscriptionType?.pack && !params.eventID);
        setIsTypeSubscriptionCart(SubscriptionsService.GetCartData().typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO || subscriptionType?.pack);
    }, [subscriptionType]);

    useEffect(() => {
        if (Array.isArray(subscriptionsContext.userSubscriptions)) subscriptionsContext.userSubscriptions.forEach(subscription => {
            if (subscribed) return;
            if (params.eventID) {
                if (Array.isArray(subscription.events)) for (let event of subscription.events) {
                    if (event.id == params.eventID) {
                        setSubscribed(true);
                        setNoCalendar(event.no_calendar == 1);
                        setSubscriptionType(subscriptionsContext.subscriptionTypes.find(st => st.id == subscription.subscription_type_id));
                        return;
                    }
                }
            } else if (params.slugTipo) {
                if (subscription.subscription_slug == params.slugTipo) {
                    setSubscribed(true);
                    setSubscriptionType(subscriptionsContext.subscriptionTypes.find(st => st.id == subscription.subscription_type_id));
                    return;
                }
            }
        });

        APIRequest("subscriptions/get-type-by-slug", { slug: params.slugTipo || SubscriptionsService.GetCartData().typeSlug }).then(async (response) => {
            setSubscriptionType(response.data);
            SubscriptionsService.SetCartData({
                typeName: response.data.name
            });
        });
    }, [subscriptionsContext]);

    useEffect(() => {
        setInterval(() => {
            $(".block.faq:not(.loaded)").each(function () {
                $(this).find(".answer").each(function () {
                    $(this)[0].originalHeight = $(this).outerHeight(true);
                    $(this).css({ height: 0, overflow: "hidden" });
                });

                $(this).addClass("loaded");
            });

            $(".faq-container").each(function () {
                if ($(this).hasClass("active")) {
                    $(this).find(".answer").css({ height: $(this).find(".answer")[0].originalHeight, padding: "" });
                } else {
                    $(this).find(".answer").css({ height: 0, padding: 0 });
                }
            });
        }, 1000);
    }); // On render.

    function renderSectionBlock(block, blockIdx) {
        switch (block.type) {
            case "image":
                return <div className="block image" key={"block-" + blockIdx} style={{ backgroundImage: "url(" + block.image_url + ")" }} />;

            case "faq":
                return <div className="block faq" key={"block-" + blockIdx}>
                    <h2>{block.title}</h2>
                    {block.faq.map((faq, faqIdx) => {
                        return <div className={"faq-container" + (activeQuestions[blockIdx] && activeQuestions[blockIdx][faqIdx] ? " active" : "")} key={blockIdx + "faq" + faqIdx}>
                            <div className="question" onClick={e => {
                                const newActiveQuestions = { ...activeQuestions };
                                if (!newActiveQuestions[blockIdx]) newActiveQuestions[blockIdx] = {};
                                newActiveQuestions[blockIdx][faqIdx] = !newActiveQuestions[blockIdx][faqIdx];
                                setActiveQuestions(newActiveQuestions);
                            }}>{faq.question} <img src="/static/icons/desplegable-preguntas.png" /></div>
                            <div className="answer">{faq.answer}</div>
                        </div>;
                    })}
                </div>;

            case "text":
                return <div className="block text" key={"block-" + blockIdx}>
                    {block.supertitle?.length > 0 && <div className="supertitle">{block.supertitle}</div>}
                    {block.title?.length > 0 && <h2>{block.title}</h2>}
                    {block.content?.length > 0 && <div dangerouslySetInnerHTML={{__html: block.content.replaceAll("\n", "<br/>") }}></div>}
                    {block.icons?.length > 0 && <div className="icons">
                        {block.icons.map((icon, iconIdx) => {
                            return <div key={"icon-" + blockIdx + "-" + iconIdx} style={{ backgroundImage: "url(" + icon + ")" }} />;
                        })}
                    </div>}
                    {!isNonPackType && (!noCalendar || !subscribed) && block.showButton && <a className="btn btn-brown" onClick={e => {
                        e.preventDefault();
                        if (subscribed) {
                            if (params.slugNegocio && subscriptionType) {
                                navigate(BASENAME + "/suscripcion/" + subscriptionType.slug + "/" + params.slugNegocio + "#" + params.eventID);
                            } else if (params.slugTipo) {
                                navigate(BASENAME + "/mis-suscripciones/" + params.slugTipo);
                            }
                        } else {
                            SubscriptionsService.SetCartData({
                                param: isTypeSubscriptionCart ? params.slugTipo : params.eventID,
                            });

                            navigate(BASENAME + "/completa-tu-suscripcion/" + (isTypeSubscriptionCart ? (params.slugTipo || subscriptionType.slug) : params.eventID));
                        }
                    }}>{subscribed ? L10n.__("Reservar") : (contents.subscriptionInfoSubscribers?.buttonText || contents.subscriptionInfo?.buttonText)}</a>}
                </div>;

            default: return null;
        }
    }

    useEffect(() => {
        let contentsPromise;
        if (params.slugTipo) {
            contentsPromise = SubscriptionsService.GetPageContents(params.eventID, params.slugTipo, SubscriptionsService.SUBSCRIPTION_MODE_TIPO);
        } else if (params.eventID) {
            contentsPromise = SubscriptionsService.GetPageContents(params.eventID, SubscriptionsService.GetCartData().typeSlug, SubscriptionsService.SUBSCRIPTION_MODE_EVENTO);
        }

        Promise.all([
            contentsPromise
        ]).then(([contents]) => {
            if (params.slugTipo) {
                APIRequest("subscriptions/get-events-for-type-slug", { subscription_type_slug: params.slugTipo }).then(response => {

                    if (isTypeSubscriptionCart) {
                        let noCalendar = true;
                        for (let negocio of response.data) {
                            if (negocio.events.filter(ev => ev.no_calendar != 1).length > 0) {
                                noCalendar = false;
                                break;
                            }
                        }

                        setNoCalendar(noCalendar);
                    }

                    setNegocios(response.data);
                });
            }

            setContents(contents);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        $("header").addClass("simple");

        return () => {
            $("header").removeClass("simple");
        };
    }); // On render

    if (loading) {
        return <LoadingIndicator />;
    }

    return <div className="suscribete">
        {contents.sections?.map((section, idx) => {
            return <div className={"section" + (section.length == 1 ? " single-block" : "")} key={"section-" + idx}>
                {section.map((block, blockIdx) => {
                    if (block.type == "offer" && negocios.length > 0 && !isTypeSubscriptionCart) return <div className="block offer" key={"block-" + blockIdx}>
                        <h2>Maecenas dapibus</h2>
                        <div className="negocios">
                            <div className="negocios-inner">
                                {negocios.map((negocio, negocioIdx) => {
                                    return <div className="negocio cover-zoom-listener" key={"negocio" + negocioIdx}>
                                        <div className="image-container cover-zoom" style={{ height: 235, backgroundImage: "url(" + (negocio.image_url[0] == "/" ? negocio.image_url : "/static/images/eventos/" + negocio.image_url) + ")" }}>
                                            <div className="icons">
                                                {negocio.iconos.map((icono, iconoIdx) => {
                                                    return <div
                                                        style={{ position: "relative" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            return false;
                                                        }}
                                                        key={"icono" + negocioIdx + "-" + iconoIdx}
                                                        className="has-tooltip"
                                                        data-tooltip={icono.description}>
                                                        <img src={icono.image_url} className="icon-image" />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="details">
                                            <div className="negocio-name">{negocio.name}</div>
                                            <div className="negocio-description" dangerouslySetInnerHTML={{ __html: negocio.description }} />
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                    </div>;
                    else return renderSectionBlock(block, blockIdx);
                })}
            </div>;
        })}
    </div>;
}
