import React, { Component } from 'react';
import ContentHelper from '../services/ContentHelper';
import { Content } from './Content';
import Footer from './Footer';
import $ from 'jquery';
import L10n from '../services/Locale';

export default class PreguntasFrecuentes extends Component {
    constructor(props) {
        super(props);

		this.firstLoad = {};

        this.state = {
            header_background_url: "",
            faqCount: 0
        };

        ContentHelper.GetString("faq-url-fondo-cabecera").then(header_background_url => {
            this.setState({
				header_background_url: header_background_url
            });
        });

        ContentHelper.GetString("faq-count").then(count => {
            this.setState({
                faqCount: parseInt(count)
            });
        });
    }

    onDesplegableLoaded(slug) {
		if (this.firstLoad[slug]) return;
		this.firstLoad[slug] = true;
        setTimeout(() => {
            const $desplegable = $(".desplegable[data-slug=" + slug + "] .contenido");
            $desplegable.attr('data-height', $desplegable.outerHeight() + 'px');
            setTimeout(() => { $desplegable.parent().css('opacity', 1); }, 500);

            $desplegable.addClass('collapsed');
            $desplegable.css({
                height: 0,
                overflow: 'hidden',
                paddingTop: 0,
                paddingBottom: 0
            });

            $desplegable.parent().find('.titulo').off('click').on('click', function(e) {
                const $contenido = $desplegable.parent().find('.contenido');
				$contenido.toggleClass("collapsed");

                $('.desplegable .contenido').each(function() {
                    if($(this).hasClass('collapsed')) {
                        $(this).css({
                            height: 0,
                            overflow: 'hidden',
                            paddingTop: 0,
                            paddingBottom: 0
                        });
                        $(this).parent().find('.titulo img').css('transform', '');
                    } else {
                        $(this).css({
                            height: $(this).attr('data-height'),
                            padding: ''
                        });
                        $(this).parent().find('.titulo img').css('transform', 'rotate(-180deg)');
                    }
                });
            });
        }, 500);
    }

    render() {
        return (
            <div className="preguntas-frecuentes">
                <div style={{backgroundImage: "url(" + this.state.header_background_url + ")"}} className="header-preguntas-frecuentes"><h1>{L10n.__("Preguntas frecuentes")}</h1></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            {[...Array(this.state.faqCount).keys()].map(idx => {
								const index = ("000" + (idx + 1)).slice(-3);
								return <DesplegablePreguntas key={index} onLoad={() => this.onDesplegableLoaded("faq-" + index)} slug={"faq-" + index} />;
							})}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

class DesplegablePreguntas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    render() {
        if(this.state.hidden) return null;

        return (
            <div className="desplegable" data-slug={this.props.slug}>
                <div className="titulo" onClick={e => { this.setState({ open: !this.state.open }) }}><Content slug={this.props.slug + '-titulo'} /><img alt="" src="/static/icons/desplegable-preguntas.png" /></div>
                <div className="contenido"><Content onLoad={content => { if(this.props.onLoad) this.props.onLoad(); }} onEmpty={() => { this.setState({ hidden: true }) }} slug={this.props.slug + '-contenido'} /></div>
            </div>
        );
    }
}
