import { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "./FormInput.tsx";
import Helper from "./../services/Helper";
import Session from "./../services/Session";
import L10n from "../services/Locale.tsx";

import AsyncButton from "./AsyncButton.tsx";
import { SessionContext } from "../context/SessionContext.tsx";
import SubscriptionsService from "../services/Subscriptions.tsx";
import Settings from "../services/Settings.ts";

export default function Login(props) {
	const [redirectClear, setRedirectClear] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [login, setLogin] = useState();
	const [password, setPassword] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const [persistentSession, setPersistentSession] = useState(true);
	const [brandName, setBrandName] = useState("");

	const buttonRef = useRef();
	const sessionContext = useContext(SessionContext);
	const navigate = useNavigate();

	function redirect() {
		/** DEBUG -- **/
		let subscribe = localStorage.getItem("subscribe_after_login");
		if (subscribe) {
			subscribe = subscribe.split(",");
			SubscriptionsService.DebugCreateSubscription(subscribe[0], subscribe[1]).then(() => {
				localStorage.removeItem("subscribe_after_login");
				const login_redirect = sessionStorage.getItem("login_redirect");
				if (login_redirect) {
					sessionStorage.removeItem("login_redirect");
					location.href = (login_redirect);
				} else {
					location.href = (BASENAME + "/");
				}
			});
			return;
		}
		/** -- DEBUG **/

		const login_redirect = sessionStorage.getItem("login_redirect");
		if (login_redirect) {
			sessionStorage.removeItem("login_redirect");
			location.href = (login_redirect);
		} else {
			location.href = (BASENAME + "/");
		}
	}

	function onSubmit(e) {
		if (e && e.preventDefault) e.preventDefault();

		if (inProgress) return false;
		setInProgress(true);

		Session.Login(login, password, persistentSession).then((response) => {
			if (response.status) {
				sessionStorage.removeItem("last_session_check");
				sessionStorage.setItem("is_logged_in", "1");

				Session.GetSession().then((session) => {
					sessionContext.setSession(session);
					sessionStorage.setItem("user_data", JSON.stringify(session));

					if (persistentSession) {
						localStorage.setItem("user_id", response.data.id);
						localStorage.setItem(
							"session_id",
							document.cookie
								?.split(";")
								.find((s) => s.trim().startsWith("PHPSESSID="))
								?.trim()
								.split("=")[1]
						);
					}

					redirect();
				});
			} else {
				buttonRef.current.reset();
				setInProgress(false);
				setErrorMessage(response.data);
			}
		});

		return false;
	}

	useEffect(() => {
		setInProgress(false);

		Settings.Get("BRAND_NAME").then((brandName) => {
			setBrandName(brandName);
		});

		Session.CheckIsLoggedIn().then(session => {
			if (session) {
				sessionContext.setSession(session);
				sessionStorage.setItem("user_data", JSON.stringify(session));
				redirect();
			} else {
				setRedirectClear(true);
			}
		});
	}, []);

	if (!redirectClear || brandName == "") return null;

	return (
		<div className="login">
			<div className="container">
				<div className="row">
					<div className="col-md-12 form-container">
						<h1>Entra en {brandName}</h1>
						<form autoComplete="off" onSubmit={onSubmit}>
							<input type="hidden" autoComplete="off" />
							<FormInput
								autoFocus={true}
								type="email"
								id="email"
								label={L10n.__("Email")}
								onValidate={(value) => {
									if (!Helper.ValidateEmail(value)) return L10n.__("Por favor introduce una dirección de email válida.");
								}}
								onChange={(value) => {
									setLogin(value);
								}}
							/>
							<FormInput
								type="password"
								id="password"
								label={L10n.__("Contraseña")}
								onChange={(value) => {
									setPassword(value);
								}}
							/>

							<FormInput
								type="checkbox"
								style={{ justifyContent: "flex-end" }}
								initialValue={true}
								label={L10n.__("No cerrar sesión")}
								onChange={(value) => {
									setPersistentSession(value);
								}}
							/>

							{errorMessage ? <div className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} /> : null}

							<AsyncButton className="btn btn-brown" ref={(ref) => (buttonRef.current = ref)} onClick={onSubmit} caption={L10n.__("Entrar")} />
							<div className="center">
								{L10n.__("¿Todavía no tienes una cuenta?")} <Link to={BASENAME + "/registro"}>{L10n.__("Regístrate")}</Link>.
							</div>
							<div className="center">
								<Link to={BASENAME + "/cambiar-contrasena"}>{L10n.__("¿Has olvidado tu contraseña?")}</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
