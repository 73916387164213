import React, { useRef, useState, useEffect, useCallback, useContext } from "react";
import { Link } from 'react-router-dom';
import Modal from "./Modal.tsx";
import HTMLReactParser from "html-react-parser";

import { StarMeter } from "./Encuesta";
import EventInfo from "./EventInfo.tsx";
import Calendar from "./Calendar";
import Icon from "./Icon.tsx";
import Events from "../services/Events.tsx";
import Session from "../services/Session.tsx";
import Helper from "../services/Helper.tsx";
import LoadingIndicator from "./LoadingIndicator.tsx";
import L10n from "../services/Locale.tsx";
import Slider from "./Slider.tsx";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { APIRequest } from "../services/API.ts";
import SubscriptionsService from "../services/Subscriptions.tsx";
import { SubscriptionsContext } from "../context/SubscriptionsContext.tsx";

function CalendarModal(props) {
	const onRef = props.onRef;
	const modalRef = useRef(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedDates, setSelectedDates] = useState([]);
	const [pax, setPax] = useState(1);
	const [slots, setSlots] = useState({});
	const [selectionLength, setSelectionLength] = useState(1);

	const subscriptionsContext = useContext(SubscriptionsContext);

	useEffect(() => {
		if (onRef) {
			onRef(modalRef.current);
		}
	}, [onRef]);

	useEffect(() => {
		return () => {
			$("body").removeClass("modal-open, scroll-lock");
		};
	}, []);

	if (!Array.isArray(subscriptionsContext.userSubscriptions)) return null;

	let maxPax = SubscriptionsService.GetEventoMaxPax(subscriptionsContext.userSubscriptions, props.availabilityObject.id);

	return (
		<Modal
			title={props.title}
			ref={(ref) => (modalRef.current = ref)}
			onOpen={props.onOpen}
			onClose={(modal) => {
				setSelectedDate(null);
				setSelectionLength(1);
				props.onClose();
			}}
			onCancel={(modal) => {}}
			renderContent={(modal) => {
				return (
					<div className="modal-calendario">
						<div className="top-bar">
							<div className="pax-selector">
								<div className="pax">{pax + " " + (pax == 1 ? L10n.__("persona") : L10n.__("personas"))}</div>
								<div
									className="pax-button"
									onClick={(e) => {
										setPax(Math.max(1, pax - 1));
									}}>
									<Icon name="minus" size={1.5} />
								</div>
								<div
									className="pax-button"
									onClick={(e) => {
										if (subscriptionsContext.userSubscriptions?.filter((s) => s.events?.filter((ev) => ev.id == props.availabilityObject.id).length > 0 && s.allow_extra_pax)?.length > 0) {
											setPax(pax + 1);
										} else {
											setPax(Math.min(pax + 1, maxPax));
										}
									}}>
									<Icon name="plus" size={1.5} />
								</div>
							</div>
							{props.multiple == 1 && (
								<div className="selection-length-selector">
									<div className="selection-length">{selectionLength + " " + (selectionLength == 1 ? L10n.__("noche") : L10n.__("noches"))}</div>
									<div
										className="selection-length-button"
										style={{ opacity: selectedDate ? 1.0 : 0.5 }}
										onClick={(e) => {
											if (!selectedDate) {
												return;
											}
											setSelectionLength(Math.max(1, selectionLength - 1));
										}}>
										<Icon name="minus" size={1.5} />
									</div>
									<div
										className="selection-length-button"
										style={{ opacity: selectedDate ? 1.0 : 0.5 }}
										onClick={(e) => {
											if (!selectedDate) {
												return;
											}
											let newSelectionLength = selectionLength;
											for (let i = 0; i < selectionLength + 1; i++) {
												const isoDate = Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + i));
												if (!props.calendar[isoDate]) break;
												newSelectionLength = i + 1;
											}
											setSelectionLength(newSelectionLength);
										}}>
										<Icon name="plus" size={1.5} />
									</div>
								</div>
							)}
							<div className="date-display">{selectedDate ? Helper.FormatDate(selectedDate, props.multiple ? Helper.DATE_VERBOSE_SHORTEST : Helper.DATE_VERBOSE_LONGEST) : L10n.__("Selecciona una fecha")}</div>
						</div>
						{pax > maxPax && <div className="pax-alert">{L10n.__("El número de personas excede tu suscripción y se cargará un suplemento de ") + Helper.FormatAmount(((pax - maxPax) * (props.availabilityObject.regular_price || props.availabilityObject.price)) / 100, false, true)}.</div>}
						<Calendar
							months={!Helper.IsResponsive() ? 2 : 1}
							className="calendario-evento"
							availabilityCalendar={props.calendar}
							selectionLength={selectionLength}
							onSelectionChanged={(dates) => {
								setSlots(props.calendar[Helper.GetISODate(dates[0])] || {});
								setSelectionLength(1);
								setSelectedDate(dates[0]);
								setSelectedDates(dates);

								setTimeout(() => {
									$(".modal-content-area").animate({ scrollTop: $(".modal-content-area .slots").offset().top }, 500);
								}, 100);
							}}
						/>
						{selectedDate && slots && (
							<div className="slots">
								{Object.keys(slots).map((slot, idx) => {
									const availablePax = slots[slot];
									const disabled = pax > availablePax;
									const availabilityRow = props.availabilityObject.availability.rows.find((row) => row.slot == Helper.GetISODate(selectedDate) + " " + slot);
									let slotPrice = 0;

									if (props.multiple) {
										selectedDates.forEach((date) => {
											const availabilityRow = props.availabilityObject.availability.rows.find((row) => row.slot == Helper.GetISODate(date) + " " + slot);
											slotPrice += parseFloat(availabilityRow.price != -1 ? availabilityRow.price : props.availabilityObject?.price);
										});
									} else {
										slotPrice = parseFloat(availabilityRow.price != -1 ? availabilityRow.price : props.availabilityObject?.price);
									}

									return (
										<div
											className={"slot-row" + (disabled ? " disabled" : "")}
											key={idx}
											onClick={(e) => {
												if (disabled) return;
												props.onSelectionConfirmed({ availabilityRow, pax, selectionLength });
											}}>
											<div className="slot-time">{Helper.FormatTimeWithDuration(slot, availabilityRow.duration || 0)}</div>
											<div className="available-pax">
												{availablePax} {availablePax == 1 ? L10n.__("plaza") : L10n.__("plazas")}
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				);
			}}
		/>
	);
}

export default function FichaGrupoSuscripcion(props) {
	const params = useParams();
	const navigate = useNavigate();
	const setOriginalDatesOffset = useRef();
	const [similar, setSimilar] = useState([]);
	const [selectedAvailability, setSelectedAvailability] = useState(null);
	const [negocio, setNegocio] = useState(null);
	const [subscriptionType, setSubscriptionType] = useState(null);
	const [surveyData, setSurveyData] = useState(null);
	const [loading, setLoading] = useState(false);
	const subscriptionsContext = useContext(SubscriptionsContext);
	const datesInfoModal = useRef({});

	useEffect(() => {
		if (!negocio) return;

		APIRequest("events/get-negocio-surveys", { negocio_id: negocio.id }).then((response) => {
			setSurveyData(response.data);
		});
	}, [negocio]);

	const calendarModalRef = useRef();
	const scrollOffset = useRef(0);

	const updateData = useCallback(() => {
		setLoading(true);
		return new Promise(async (resolve, reject) => {
			$("header").addClass("simple");

			const subscriptionTypeResponse = await APIRequest("subscriptions/get-type-by-slug", { slug: params.type_slug });
			setSubscriptionType(subscriptionTypeResponse.data);
			const subscriptionType = subscriptionTypeResponse.data;

			const negocio = await Events.GetBySlug(params.negocio_slug);
			negocio.events = negocio.events?.filter((e) => e.enabled && e.subscription_types.filter && e.subscription_types?.filter((t) => t.subscription_type_id == subscriptionType.id).length > 0);
			negocio.events = negocio.events.sort((a, b) => {
				const subscribedA = subscriptionsContext.userSubscriptions?.filter((s) => s.events?.filter((e) => e.id == a.id).length > 0).length > 0;
				const subscribedB = subscriptionsContext.userSubscriptions?.filter((s) => s.events?.filter((e) => e.id == b.id).length > 0).length > 0;
				if (subscribedA && !subscribedB) return -1;
				if (!subscribedA && subscribedB) return -1;
				return 0;
			});
			setNegocio(negocio);

			setLoading(false);

			Events.GetSimilar(negocio.id).then(async (similar) => {
				setSimilar(similar);
			});
		});
	}, [params]);

	function openModalDeferred() {
		if (selectedAvailability && calendarModalRef.current?.open) {
			setLoading(true);
			Session.CheckIsLoggedIn()
				.then((loggedIn) => {
					if (loggedIn) {
						if (subscriptionsContext.userSubscriptions?.filter((s) => s.events?.filter((e) => e.id == selectedAvailability.id).length > 0).length > 0) {
							setLoading(false);
							calendarModalRef.current.open();
						} else {
							setLoading(false);
							if (SubscriptionsService.GetCartData().typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO) {
								SubscriptionsService.RequireSubscriptionForType(SubscriptionsService.GetCartData().typeSlug, window.location.pathname + "#" + selectedAvailability.id);
							} else {
								SubscriptionsService.RequireSubscriptionForEvent(selectedAvailability.id, window.location.pathname + "#" + selectedAvailability.id);
							}
						}
					} else {
						setLoading(false);
						Session.RequireAccount(window.location.pathname + "#" + selectedAvailability.id, navigate);
					}
				})
				.catch((e) => {
					setLoading(false);
					Session.RequireAccount(window.location.pathname + "#" + selectedAvailability.id, navigate);
				});
		} else {
			setTimeout(openModalDeferred, 100);
		}
	}

	function renderDates(dateBoxWidth) {
		return negocio.events.map((event, idx) => {
			// Event included in subscription.
			const includesEvent = subscriptionsContext.userSubscriptions?.filter((s) => s.events?.filter((e) => e.id == event.id).length > 0).length > 0;
			if (!includesEvent && showSubscriptionsOnly) return null;

			// const totalPax = event.availability.rows.reduce((pax, v) => pax + v.pax, 0);
			// if (!event.no_calendar && totalPax == 0) return null;

			let minimumPrice = -1;
			event.availability.rows.forEach((row) => {
				if (row.price != -1) {
					if (minimumPrice == -1 || row.price < minimumPrice) {
						minimumPrice = row.price;
					}
				}
			});

			if (minimumPrice == -1) {
				if (event.price == -1) {
					if (minimumPrice == -1 || (negocio.price != -1 && negocio.price < minimumPrice)) {
						minimumPrice = negocio.price;
					}
				} else {
					if (minimumPrice == -1 || (event.price != -1 && event.price < minimumPrice)) {
						minimumPrice = event.price;
					}
				}
			}

			let minDuration = 0;
			for (let row of event.availability.rows) {
				if (row.duration > 0 && (row.duration < minDuration || minDuration == 0)) {
					minDuration = row.duration;
				}
			}

			const image_url = location.hostname == "localhost" ? "http://localhost:8081" + (event.image_url[0] == "/" ? event.image_url : "/" + event.image_url) : event.image_url;

			// No more subscriptions allowed.
			let outOfStock = false;
			if (subscriptionType && SubscriptionsService.GetCartData().typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO) {
				outOfStock = subscriptionType.stock == 0;
			} else {
				outOfStock = event.subscription_data.stock == 0;
			}

			// No more orders allowed.
			let outOfVisits = false;
			// Allow orders with an extra fee.
			let allowOverPax = false;

			if (Array.isArray(subscriptionsContext.userSubscriptions)) for (let subscription of subscriptionsContext.userSubscriptions) {
				if ((subscription.pack && subscriptionType.pack && subscription.subscription_type_id == subscriptionType.id) || (!subscriptionType.pack && subscription.events?.filter((ev) => ev.id == event.id).length > 0)) {
					outOfVisits = outOfVisits || subscription.remaining_pax === 0 || !subscription.remaining_visits;
						allowOverPax = allowOverPax || subscription.allow_extra_pax;
					}
				}

			let remainingVisits = 0;
			let totalVisits = 0;
			let renewable = true;

			subscriptionsContext.userSubscriptions?.forEach((userSubscription) => {
				if (userSubscription.remaining_visits <= remainingVisits) {
					return;
				}

				userSubscription.events?.forEach(se => {
					if (se.id == event.id) {
						if (userSubscription.cancel_at_period_end) renewable = false;
						remainingVisits = userSubscription.remaining_visits * userSubscription.pax;
						totalVisits = userSubscription.visits * userSubscription.pax;
					}
				});
			});

			const availabilityLeft = Object.values(SubscriptionsService.FilterCalendar(event?.availability?.calendar, event?.id, subscriptionsContext.userSubscriptions)).length;

			return (
				<div key={idx} style={{ display: "flex", flexDirection: "column" }}>
					<div
						className={"date cover-zoom-listener" + (!includesEvent && outOfStock ? " out-of-stock" : "")}
						style={{
							opacity: calendarModalRef?.current?.open ? 1 : 0,
							width: dateBoxWidth || "auto"
						}}
						onClick={(e) => {
							e.preventDefault();
							if (!event.no_calendar && outOfStock) return;
							
							if (!includesEvent && !outOfStock) {
								SubscriptionsService.SetCartData({
									param: SubscriptionsService.GetCartData().typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO ? SubscriptionsService.GetCartData().typeSlug : event.id
								});
								navigate(BASENAME + "/completa-tu-suscripcion/" + (SubscriptionsService.GetCartData().typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO ? SubscriptionsService.GetCartData().typeSlug : event.id));
							} else {
								if (event.no_calendar) {
									navigate(BASENAME + "/suscribete/n/" + negocio.slug + "/" + event.id);
									return;
								}

								if ((!includesEvent && outOfStock) || (includesEvent && outOfVisits && !allowOverPax)) return;
								if (!event.no_calendar && !availabilityLeft) return;
								
								setSelectedAvailability(event);
								openModalDeferred();
							}
						}}>
						{!event.no_calendar && !outOfStock && !outOfVisits && includesEvent && <div className="event-type inhibit-clicks">{L10n.__("Reservar")}</div>}
						{!includesEvent && !outOfStock && <div className="event-type inhibit-clicks">{L10n.__("Suscribirte")}</div>}
						{!event.no_calendar && !!availabilityLeft && ((includesEvent && outOfVisits) || (!includesEvent && outOfStock)) && (
							<div className="event-type out-of-stock inhibit-clicks has-tooltip" data-tooltip={outOfVisits ? (allowOverPax ? L10n.__("El número de personas seleccionado supera tu suscripción. Reserva solo disponible con recargo.") : L10n.__("El número de personas seleccionado supera tu suscripción.")) : L10n.__("No hay suscripciones disponibles en este momento.")}>
								{outOfVisits ? (allowOverPax ? L10n.__("Disponible con recargo") : L10n.__("Límite superado")) : L10n.__("Suscripciones completas")}
							</div>
						)}
						{!event.no_calendar && !availabilityLeft && includesEvent && (
							<div className="event-type out-of-stock inhibit-clicks has-tooltip" data-tooltip={L10n.__("No quedan plazas disponibles.")}>
								{L10n.__("Sin disponibilidad")}
							</div>
						)}
						<div className="event-info-image cover-zoom" style={{ backgroundImage: "url(" + (image_url[0] == "/" ? image_url : "/static/images/eventos/" + image_url) + ")" }}>
							{event.no_calendar == 1 && <div className="event-type has-tooltip no-calendar" data-tooltip={L10n.__('Suscripción a producto físico')}>{L10n.__("Producto")}</div>}
							{!event.no_calendar && (!outOfStock && includesEvent) === true && totalVisits > 0 && (
								<div className="remaining-visits inhibit-clicks has-tooltip" data-tooltip={(remainingVisits == 1 ? L10n.__("Te queda") : L10n.__("Te quedan")) + " " + remainingVisits + " " + (remainingVisits == 1 ? L10n.__("visita") : L10n.__("visitas"))}>
									{remainingVisits}/{totalVisits} visitas
								</div>
							)}
						</div>

						<div className="details">
							<div className="event-info-name">{event.nombre}</div>
							{!includesEvent && (
								<div className="event-info-tag">
									{L10n.__("Desde")} {Helper.FormatAmount((subscriptionType.pack ? subscriptionType?.price : event.subscription_data?.price) / 100.0)}{!!subscriptionType.renewable && ("/"+Helper.GetIntervalLabel(event.subscription_data?.interval || subscriptionType?.interval, event.subscription_data?.interval_type || subscriptionType?.interval_type))}
								</div>
							)}
							{includesEvent && <div className={"event-info-tag" + (!renewable ? " not-renewable" : "")}>{L10n.__(renewable ? "Suscripción activa" : "No se renovará")}</div>}
							{minDuration > 0 && <div className="event-info-tag">{Helper.FormatDuration(minDuration, true)}</div>}
						</div>
					</div>
					{Helper.IsResponsive() && (
						<div className="mas-info date" key={idx}>
							<div className="details">
								<div className="event-info-name">{event.nombre}</div>
								<div className="event-info-description">{Helper.Ellipsis(event.description ? event.description : negocio.description, 100)}</div>
								<Link className="read-more-link" to={"/suscribete/n/" + negocio.slug + "/" + event.id}>
									{L10n.__("+ Info")}
								</Link>
							</div>
						</div>
					)}
				</div>
			);
		});
	}

	function renderInlineDates() {
		return negocio.events
			.filter((event) => event.no_calendar || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0)
			.map((event, idx) => {
				return (
					<div className="date" key={idx}>
						<div className="details">
							<Link className="btn btn-small more-info" to={"/suscribete/n/" + negocio.slug + "/" + event.id}>
								{L10n.__("+ Info")}
							</Link>
							<div className="event-info-name">{event.nombre}</div>
							<div className="event-info-description">{Helper.Ellipsis(event.description ? event.description : negocio.description, 200)}</div>
						</div>
					</div>
				);
			});
	}

	function onScroll(e) {
		if (!$(".col-dates").is(":visible") || !$(".col-dates").length || !$(".col-dates")[0].originalOffset) {
			return;
		}

		const $eventInfo = $(".container.evento > .row + .row > .event-info");

		if ($eventInfo.height() < $(".col-dates").outerHeight(true)) {
			$eventInfo.css({ paddingBottom: $(".col-dates").outerHeight(true) - $eventInfo.height() });
		}
		$eventInfo.css({ minHeight: $(".col-dates").outerHeight(true) });

		requestAnimationFrame(() => {
			const scrollTop = $(window).scrollTop();
			const scrollDelta = scrollTop - scrollOffset.current;
			scrollOffset.current = scrollTop;

			const bottomElement = $(".encuestas-clientes").length ? $(".encuestas-clientes")[0] : $(".related-events-h3")[0];

			if (scrollDelta > 0 && scrollTop + $("header").height() + 20 >= $(".col-dates")[0].originalOffset.top) {
				if ($(".col-dates")[0]?.getBoundingClientRect().bottom < window.innerHeight - 10) {
					$(".col-dates").css({
						top: window.innerHeight - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0].getBoundingClientRect().top
					});
				}

				if ($(".col-dates").offset().top > $(".col-dates")[0].originalOffset.top && $(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
					$(".col-dates").css({
						top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}

				if ($(".col-dates")[0]?.getBoundingClientRect().bottom > bottomElement?.getBoundingClientRect().top) {
					$(".col-dates").css({
						top: bottomElement?.getBoundingClientRect().top - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}
			} else if (scrollDelta < 0) {
				if ($(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
					$(".col-dates").css({
						top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}

				if (scrollTop + $("header").height() + 20 < $(".col-dates")[0].originalOffset.top) {
					$(".col-dates").css({
						top: 0
					});
				}
			}
		});
	}

	setOriginalDatesOffset.current = () => {
		if (!$(".col-dates").length) {
			setTimeout(setOriginalDatesOffset.current, 500);
			return;
		}

		$(".col-dates")[0].originalOffset = $(".col-dates").offset();
		$(".col-dates")[0].originalOffset.boundingClientRect = $(".col-dates")[0].getBoundingClientRect();
		$(".col-dates").parent().css("position", "relative");
		$(".col-dates")
			.addClass("floating")
			.css({
				top: 0,
				width: $(".col-dates")[0].originalOffset.boundingClientRect.width,
				right: 0
			});
	};

	useEffect(() => {
		updateData();

		setOriginalDatesOffset.current();
		$(window).on("scroll", onScroll);

		return () => {
			$(window).off("scroll", onScroll);
			$("header").removeClass("simple");
		};
	}, [updateData]);

	useEffect(() => {
		if (selectedAvailability?.id) {
			openModalDeferred();
		}
	}, [selectedAvailability]);

	useEffect(() => {
		if (negocio) Helper.SetDocumentTitle(negocio.name);
		if (negocio?.events && location.hash.substring(1) == parseInt(location.hash.substring(1))) {
			setSelectedAvailability(negocio.events.find((e) => e.id == window.location.hash.substring(1)));
		}
	}, [negocio]);

	useEffect(() => {
		const onHashChange = (e) => {
			if (location.hash == "") calendarModalRef.current?.close();
			else updateData();
		};

		onHashChange();
		window.addEventListener("hashchange", onHashChange);

		SubscriptionsService.SetCartData({ typeSlug: params.type_slug });

		return () => {
			window.removeEventListener("hashchange", onHashChange);
		};
	}, []);

	if (!negocio) return <LoadingIndicator />;

	const sliderHeight = !Helper.IsResponsive() ? 456 : 375;
	const eventsLength = negocio.events?.filter((event) => event.no_calendar || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0);
	const showSubscriptionsOnly = location.pathname.split("/").pop() == "suscripciones";

	return (
		<React.Fragment>
			{loading && <LoadingIndicator />}
			<div className="heading-ficha-evento">
				{negocio.slider_enabled == 1 && negocio.slider && (
					<Slider
						style={{
							opacity: negocio.slider.length ? 1 : 0,
							height: sliderHeight,
							minHeight: sliderHeight
						}}
						height={sliderHeight}
						className="slider-ficha-evento"
						effect="fade"
						delay={5000}
						slides={negocio.slider}
					/>
				)}
				{!(negocio.slider_enabled == 1 && negocio.slider) && negocio.header_image_url != null && (
					<div className="row">
						<div className="event-cover" style={{ backgroundImage: "url(" + (negocio.header_image_url[0] == "/" ? negocio.header_image_url : "/static/images/eventos/" + negocio.header_image_url) + ")" }}></div>
					</div>
				)}
			</div>
			<div className={"evento container" + (showSubscriptionsOnly ? " subscribed-only" : "")}>
				<div className="row">
					<div className="col-md-8 event-info">
						<h1>{negocio.name}</h1>
						<div className="event-address">
							<div className="address-name">{negocio.city}</div>
							<div className="address">{negocio.address}</div>
						</div>
						<div className="tags"></div>
						<div className="info-negocio">
							<EventInfo negocio={negocio} />
						</div>
						{negocio.description != undefined && (
							<div className="event-description">
								<div className="event-text">{HTMLReactParser(negocio.description)}</div>
							</div>
						)}
						<div className="event-dates-responsive">
							<div className="container" style={{ width: "calc(" + eventsLength * 100 + "vw - " + eventsLength * 75 + "px)" }}>
								{renderDates("calc(100vw - 75px)")}
							</div>
						</div>
						{negocio.events?.filter((event) => event.no_calendar || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0).length > 0 && (
							<div className="desktop-only experiencias">
								<div className="inner">{renderInlineDates()}</div>
							</div>
						)}
						{negocio.iconos_detalle != undefined && JSON.parse(negocio.iconos_detalle)?.length > 0 && (
							<div className="facts">
								{JSON.parse(negocio.iconos_detalle).map((detalle, idx) => {
									return (
										<div key={detalle.image_url}>
											<div className="icon-image" style={{ backgroundImage: "url(" + detalle.image_url + ")" }} />
											<div>
												<div>
													<b>{detalle.titulo}</b>
												</div>
												<div dangerouslySetInnerHTML={{ __html: detalle.lista }} />
											</div>
										</div>
									);
								})}
							</div>
						)}
						{negocio.show_ratings == 1 && surveyData && surveyData.map && (
							<div className="encuestas-clientes">
								<h3>{L10n.__("Valoración")}</h3>
								<div className="inner">
									<div className="columna" style={{ width: window.innerWidth > 992 ? null : surveyData.length * window.innerWidth * 0.63 - window.innerWidth + 300 }}>
										{surveyData.map((item, index) => {
											return (
												<div className="category" key={"survey-data-category" + index}>
													<h2>{item.text}</h2>
													<StarMeter defaultValue={item.value} />
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="col-md-3 col-dates">{renderDates()}</div>
				</div>
			</div>
			{selectedAvailability?.availability?.calendar && (
				<CalendarModal
					multiple={negocio.disponibilidad_multiple || selectedAvailability.disponibilidad_multiple}
					availabilityObject={selectedAvailability}
					calendar={SubscriptionsService.FilterCalendar(selectedAvailability.availability.calendar, selectedAvailability.id, subscriptionsContext.userSubscriptions)}
					onRef={(ref) => (calendarModalRef.current = ref)}
					title={selectedAvailability.nombre || ""}
					onOpen={(_) => {
						history.pushState({}, "", window.location.pathname + "#" + selectedAvailability.id);
					}}
					onClose={(_) => {
						history.pushState({}, "", window.location.pathname);
					}}
					onSelectionConfirmed={(selection) => {
						Session.SetBookingData("selected_availability_id", selection.availabilityRow.id);
						Session.SetBookingData("selected_slot", selection.availabilityRow.slot.split(" ")[1]);
						Session.SetBookingData("pax", selection.pax);
						Session.SetBookingData("selected_date", selection.availabilityRow.slot.split(" ")[0]);
						Session.SetBookingData("selected_full_date", selection.availabilityRow.slot);
						Session.SetBookingData("selected_event", JSON.stringify(negocio));
						Session.SetBookingData("preorder_selection", null);
						Session.SetBookingData("extra_events", null);
						Session.SetBookingData("selection_length", selection.selectionLength);
						Session.SetBookingData(
							"user_subscription_id",
							subscriptionsContext.userSubscriptions?.filter((s) => {
								return s.availabilityIDs.filter((availabilityID) => availabilityID == selection.availabilityRow.id).length > 0;
							})[0].id
						);
						document.body.classList.remove("modal-open");

						const event = negocio.events.find((v) => v.id == selection.availabilityRow.event_id);

						if (event.offers_preorder == 1 && event.extras_menu) {
							navigate(BASENAME + "/venue/" + negocio.slug + "/extras");
						} else {
							navigate(`${BASENAME}/pago`);
						}
					}}
				/>
			)}
			{negocio.events
				.filter((event) => event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0)
				.map((event, idx) => {
					return (
						<Modal
							key={"event_" + event.id}
							onRef={(ref) => {
								datesInfoModal.current[event.id] = ref;
							}}
							title={event.nombre}
							className="event-info-modal"
							renderContent={(modal) => {
								return (
									<>
										<div
											className="event-image"
											style={{
												backgroundImage: "url(" + (event.image_url[0] == "/" ? event.image_url : "/static/images/eventos/" + event.image_url) + ")"
											}}>
											&nbsp;
										</div>
										<p />
										<div dangerouslySetInnerHTML={{ __html: event.description }} />
									</>
								);
							}}
						/>
					);
				})}
		</React.Fragment>
	);
}
