import React, { useRef, useState, useEffect, useCallback, useContext } from "react";
import Modal from "./Modal.tsx";
import HTMLReactParser from "html-react-parser";

import MaterialIcon from "./MaterialIcon.tsx";
import { StarMeter } from "./Encuesta";
import EventInfo from "./EventInfo.tsx";
import Calendar from "./Calendar";
import Icon from "./Icon.tsx";
import Events from "../services/Events.tsx";
import Session from "../services/Session.tsx";
import Helper from "../services/Helper.tsx";
import LoadingIndicator from "./LoadingIndicator.tsx";
import L10n from "../services/Locale.tsx";
import Slider from "./Slider.tsx";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { APIRequest } from "../services/API.ts";

function NoCalendarModal(props) {
	const onRef = props.onRef;
	const modalRef = useRef(null);
	const [quantity, setQuantity] = useState(1);

	useEffect(() => {
		if (onRef) {
			onRef(modalRef.current);
		}
	}, [onRef]);

	useEffect(() => {
		return () => {
			$("body").removeClass("modal-open");
		}
	}, []);

	return <Modal
		title={props.title}
		className="no-calendar-modal"
		ref={(ref) => (modalRef.current = ref)}
		onOpen={(modal) => { }}
		onClose={(modal) => {
			window.history.replaceState({}, "", window.location.pathname);
			setQuantity(1);
		}}
		onCancel={(modal) => { }}
		renderContent={(modal) => {
			return <div className="inner">
				<div className="image" style={{ backgroundImage: "url(" + props.evento.image_url + ")" }} />
				<div className="description" dangerouslySetInnerHTML={{ __html: props.evento.description }} />
				<div className="controls">
					<div className="quantity-selector">
						<MaterialIcon
							name="remove"
							className="quantity-button minus"
							onClick={(e) => {
								e.preventDefault();
								setQuantity(Math.max(quantity - 1, 1));
							}}
						/>
						<div className="quantity">{quantity}</div>
						<MaterialIcon
							name="add"
							className="quantity-button plus"
							onClick={(e) => {
								e.preventDefault();
								setQuantity(Math.min(quantity + 1, props.evento.no_calendar_stock ?? 0));
							}}
						/>
						<div className="button-confirm-container">
							<a
								href="#"
								className="btn btn-brown confirm"
								onClick={(e) => {
									e.preventDefault();
									if (props.onConfirm) props.onConfirm(quantity);
								}}>
								{L10n.__("Confirmar")} {<span>{Helper.FormatAmount((quantity * (props.evento.price == -1 ? 0 : props.evento.price)) / 100.0)}</span>}
							</a>
						</div>
					</div>
				</div>
			</div>
		}} />
}

function CalendarModal(props) {
	const onRef = props.onRef;
	const modalRef = useRef(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedDates, setSelectedDates] = useState([]);
	const [pax, setPax] = useState(1);
	const [slots, setSlots] = useState({});
	const [selectionLength, setSelectionLength] = useState(1);

	useEffect(() => {
		if (onRef) {
			onRef(modalRef.current);
		}
	}, [onRef]);

	useEffect(() => {
		return () => {
			$("body").removeClass("modal-open");
		}
	}, []);

	return (
		<Modal
			title={props.title}
			ref={(ref) => (modalRef.current = ref)}
			onOpen={(modal) => { }}
			onClose={(modal) => {
				window.history.replaceState({}, "", window.location.pathname);
				setSelectedDate(null);
				setSelectionLength(1);
			}}
			onCancel={(modal) => { }}
			renderContent={(modal) => {
				return (
					<div className="modal-calendario">
						<div className="top-bar">
							<div className="pax-selector">
								<div className="pax">{pax + " " + (pax == 1 ? L10n.__("persona") : L10n.__("personas"))}</div>
								<div
									className="pax-button"
									onClick={(e) => {
										setPax(Math.max(1, pax - 1));
									}}>
									<Icon name="minus" size={1.5} />
								</div>
								<div
									className="pax-button"
									onClick={(e) => {
										setPax(pax + 1);
									}}>
									<Icon name="plus" size={1.5} />
								</div>
							</div>
							{props.multiple == 1 && (
								<div className="selection-length-selector">
									<div className="selection-length">{selectionLength + " " + (selectionLength == 1 ? L10n.__("noche") : L10n.__("noches"))}</div>
									<div
										className="selection-length-button"
										style={{ opacity: selectedDate ? 1.0 : 0.5 }}
										onClick={(e) => {
											if (!selectedDate) {
												return;
											}
											setSelectionLength(Math.max(1, selectionLength - 1));
										}}>
										<Icon name="minus" size={1.5} />
									</div>
									<div
										className="selection-length-button"
										style={{ opacity: selectedDate ? 1.0 : 0.5 }}
										onClick={(e) => {
											if (!selectedDate) {
												return;
											}
											let newSelectionLength = selectionLength;
											for (let i = 0; i < selectionLength + 1; i++) {
												const isoDate = Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + i));
												if (!props.calendar[isoDate]) break;
												newSelectionLength = i + 1;
											}
											setSelectionLength(newSelectionLength);
										}}>
										<Icon name="plus" size={1.5} />
									</div>
								</div>
							)}
							<div className="date-display">{selectedDate ? Helper.FormatDate(selectedDate, props.multiple ? Helper.DATE_VERBOSE_SHORTEST : Helper.DATE_VERBOSE_LONGEST) : L10n.__("Selecciona una fecha")}</div>
						</div>
						<Calendar
							months={!Helper.IsResponsive() ? 2 : 1}
							className="calendario-evento"
							availabilityCalendar={props.calendar}
							selectionLength={selectionLength}
							onSelectionChanged={(dates) => {
								setSlots(props.calendar[Helper.GetISODate(dates[0])] || {});
								setSelectionLength(1);
								setSelectedDate(dates[0]);
								setSelectedDates(dates);

								setTimeout(() => {
									$(".modal-content-area").animate({ scrollTop: $(".modal-content-area .slots").offset().top }, 500);
								}, 100);
							}}
						/>
						{selectedDate && slots && (
							<div className="slots">
								{Object.keys(slots).map((slot, idx) => {
									const availablePax = slots[slot];
									const disabled = pax > availablePax;
									const availabilityRow = props.availabilityObject.availability.rows.find((row) => row.slot == Helper.GetISODate(selectedDate) + " " + slot);
									let slotPrice = 0;

									if (props.multiple) {
										selectedDates.forEach((date) => {
											const availabilityRow = props.availabilityObject.availability.rows.find((row) => row.slot == Helper.GetISODate(date) + " " + slot);
											slotPrice += parseFloat(availabilityRow.price != -1 ? availabilityRow.price : props.availabilityObject?.price);
										});
									} else {
										slotPrice = parseFloat(availabilityRow.price != -1 ? availabilityRow.price : props.availabilityObject?.price);
									}

									return (
										<div
											className={"slot-row" + (disabled ? " disabled" : "")}
											key={idx}
											onClick={(e) => {
												if (disabled) return;
												props.onSelectionConfirmed({ availabilityRow, pax, selectionLength });
											}}>
											<div className="slot-time">{Helper.FormatTimeWithDuration(slot, availabilityRow.duration || 0)}</div>
											<div className="available-pax">
												{availablePax} {availablePax == 1 ? L10n.__("plaza") : L10n.__("plazas")}
											</div>
											{props.availabilityObject.full_booking != 1 && (
												<div className="slot-amount">
													{Math.min(availablePax, pax)} &times; {Helper.FormatAmount(slotPrice / 100.0)}
												</div>
											)}
											{props.availabilityObject.full_booking == 1 && <div className="slot-amount">{Helper.FormatAmount(slotPrice / 100.0)}</div>}
										</div>
									);
								})}
							</div>
						)}
					</div>
				);
			}}
		/>
	);
}

export default function FichaEvento(props) {
	const params = useParams();
	const navigate = useNavigate();
	const setOriginalDatesOffset = useRef();
	const [similar, setSimilar] = useState([]);
	const [selectedAvailability, setSelectedAvailability] = useState(null);
	const [negocio, setNegocio] = useState(null);
	const [surveyData, setSurveyData] = useState(null);
	const [loading, setLoading] = useState(true);
	const datesInfoModal = useRef({});

	useEffect(() => {
		if (!negocio) return;

		APIRequest("events/get-negocio-surveys", { negocio_id: negocio.id }).then((response) => {
			setSurveyData(response.data);
		});
	}, [negocio]);

	const noCalendarModalRef = useRef();
	const calendarModalRef = useRef();
	const scrollOffset = useRef(0);

	const updateData = useCallback(() => {
		return new Promise(async (resolve, reject) => {
			$("header").addClass("simple");
			const negocio = await Events.GetBySlug(params.slug);
			negocio.events = negocio.events?.filter((e) => e.enabled);
			setNegocio(negocio);

			setLoading(false);

			if (!isNaN(window.location.hash.substring(1)) && window.location.hash.substring(1) > 0) {
				setSelectedAvailability(negocio.events.find((e) => e.id == window.location.hash.substring(1)));
			}

			Events.GetSimilar(negocio.id).then(async (similar) => {
				setSimilar(similar);
			});
		});
	}, [params]);

	function openModalDeferred() {
		if (selectedAvailability && calendarModalRef.current?.open) {
			setLoading(false);
			calendarModalRef.current.open();
			history.pushState({}, "", window.location.pathname + "#" + selectedAvailability.id);
		} else {
			setTimeout(openModalDeferred, 100);
		}
	}

	function renderDates(dateBoxWidth) {
		return negocio.events.map((event, idx) => {
			const totalPax = event.availability.rows.reduce((pax, v) => pax + v.pax, 0);
			if (totalPax == 0 && event.no_calendar != 1) return null;

			let minimumPrice = -1;
			event.availability.rows.forEach((row) => {
				if (row.price != -1) {
					if (minimumPrice == -1 || row.price < minimumPrice) {
						minimumPrice = row.price;
					}
				}
			});

			if (minimumPrice == -1) {
				if (event.price == -1) {
					if (minimumPrice == -1 || (negocio.price != -1 && negocio.price < minimumPrice)) {
						minimumPrice = negocio.price;
					}
				} else {
					if (minimumPrice == -1 || (event.price != -1 && event.price < minimumPrice)) {
						minimumPrice = event.price;
					}
				}
			}

			if (minimumPrice == -1) minimumPrice = 0;

			let minDuration = 0;
			for (let row of event.availability.rows) {
				if (row.duration > 0 && (row.duration < minDuration || minDuration == 0)) {
					minDuration = row.duration;
				}
			}

			const image_url = location.hostname == "localhost" ? "http://localhost:8081" + (event.image_url[0] == "/" ? event.image_url : "/" + event.image_url) : event.image_url;

			return (
				<div key={"date-" + idx} style={{ display: "flex", flexDirection: "column" }}>
					<div
						className={"date cover-zoom-listener"}
						style={{
							opacity: (event.no_calendar == 1 ? noCalendarModalRef : calendarModalRef)?.current?.open ? 1 : 0,
							width: dateBoxWidth || "auto"
						}}
						onClick={(e) => {
							e.preventDefault();
							if (event.no_calendar == 1 && event.no_calendar_stock == 0) return;
							
							setSelectedAvailability(event);
							openModalDeferred();
						}}>
						<div className="event-info-image cover-zoom" style={{ backgroundImage: "url(" + (image_url[0] == "/" ? image_url : "/static/images/eventos/" + image_url) + ")" }}>
							{event.no_calendar == 1 && <div className="event-type has-tooltip no-calendar" data-tooltip={L10n.__('Producto físico')}>{L10n.__("Producto")}</div>}
							{event.no_calendar == 1 && event.no_calendar_stock == 0 && <div className="event-type has-tooltip out-of-stock" data-tooltip={L10n.__('Este producto está agotado')}>{L10n.__("Agotado")}</div>}
						</div>
						<div className="details">
							<div className="event-info-name">{event.nombre}</div>
							<div className="event-info-tag">
								{L10n.__("Desde")} {Helper.FormatAmount(minimumPrice / 100.0)}
							</div>
							{minDuration > 0 && <div className="event-info-tag">{Helper.FormatDuration(minDuration, true)}</div>}
						</div>
					</div>
					{Helper.IsResponsive() && (
						<div className="mas-info date" key={idx}>
							<div className="details">
								<div className="event-info-name">{event.nombre}</div>
								<div className="event-info-description">
									{Helper.Ellipsis(
										event.description ? event.description : negocio.description,
										100,
										() =>
										(
											<a
												href="#"
												className="read-more-link"
												onClick={(e) => {
													e.preventDefault();
													datesInfoModal.current[event.id].open();
												}}>
												{L10n.__("+ Info")}
											</a>
										)
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			);
		});
	}

	function renderInlineDates() {
		return negocio.events
			.filter((event) => event.no_calendar == 1 || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0)
			.map((event, idx) => {
				return (
					<div className="date" key={idx}>
						<div className="details">
							<div className="event-info-name">{event.nombre}</div>
							<div className="event-info-description">
								{Helper.Ellipsis(
									event.description ? event.description : negocio.description,
									200,
									() =>
									(
										<a
											href="#"
											className="read-more-link"
											onClick={(e) => {
												e.preventDefault();
												datesInfoModal.current[event.id].open();
											}}>
											{L10n.__("+ Info")}
										</a>
									)
								)}
							</div>
						</div>
					</div>
				);
			});
	}

	function onScroll(e) {
		if (!$(".col-dates").is(":visible") || !$(".col-dates").length || !$(".col-dates")[0].originalOffset) {
			return;
		}

		const $eventInfo = $(".container.evento > .row + .row > .event-info");

		if ($eventInfo.height() < $(".col-dates").outerHeight(true)) {
			$eventInfo.css({ paddingBottom: $(".col-dates").outerHeight(true) - $eventInfo.height() });
		}
		$eventInfo.css({ minHeight: $(".col-dates").outerHeight(true) });

		requestAnimationFrame(() => {
			const scrollTop = $(window).scrollTop();
			const scrollDelta = scrollTop - scrollOffset.current;
			scrollOffset.current = scrollTop;

			const bottomElement = $(".encuestas-clientes").length ? $(".encuestas-clientes")[0] : $(".related-events-h3")[0];

			if (scrollDelta > 0 && scrollTop + $("header").height() + 20 >= $(".col-dates")[0].originalOffset.top) {
				if ($(".col-dates")[0]?.getBoundingClientRect().bottom < window.innerHeight - 10) {
					$(".col-dates").css({
						top: window.innerHeight - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0].getBoundingClientRect().top
					});
				}

				if ($(".col-dates").offset().top > $(".col-dates")[0].originalOffset.top && $(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
					$(".col-dates").css({
						top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}

				if ($(".col-dates")[0]?.getBoundingClientRect().bottom > bottomElement?.getBoundingClientRect().top) {
					$(".col-dates").css({
						top: bottomElement?.getBoundingClientRect().top - $(".col-dates").outerHeight() - 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}
			} else if (scrollDelta < 0) {
				if ($(".col-dates")[0]?.getBoundingClientRect().top > $("header").height() + 10) {
					$(".col-dates").css({
						top: $("header").height() + 10 - $(".col-dates").parent()[0]?.getBoundingClientRect().top
					});
				}

				if (scrollTop + $("header").height() + 20 < $(".col-dates")[0].originalOffset.top) {
					$(".col-dates").css({
						top: 0
					});
				}
			}
		});
	}

	setOriginalDatesOffset.current = () => {
		if (!$(".col-dates").length) {
			setTimeout(setOriginalDatesOffset.current, 500);
			return;
		}

		$(".col-dates")[0].originalOffset = $(".col-dates").offset();
		$(".col-dates")[0].originalOffset.boundingClientRect = $(".col-dates")[0].getBoundingClientRect();
		$(".col-dates").parent().css("position", "relative");
		$(".col-dates")
			.addClass("floating")
			.css({
				top: 0,
				width: $(".col-dates")[0].originalOffset.boundingClientRect.width,
				right: 0
			});
	};

	useEffect(() => {
		updateData();

		setOriginalDatesOffset.current();
		$(window).on("scroll", onScroll);

		return () => {
			$(window).off("scroll", onScroll);
			$("header").removeClass("simple");
		};
	}, [updateData]);

	useEffect(() => {
		if (selectedAvailability?.id) {
			openModalDeferred();
		}
	}, [selectedAvailability]);

	useEffect(() => {
		if (negocio) Helper.SetDocumentTitle(negocio.name);
	}, [negocio]);

	if (!negocio) return <LoadingIndicator />;

	const sliderHeight = !Helper.IsResponsive() ? 456 : 375;
	const eventsLength = negocio.events?.filter((event) => event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0);

	return (
		<React.Fragment>
			{loading && <LoadingIndicator />}
			<div className="heading-ficha-evento">
				{negocio.slider_enabled == 1 && negocio.slider && (
					<Slider
						style={{
							opacity: negocio.slider.length ? 1 : 0,
							height: sliderHeight,
							minHeight: sliderHeight
						}}
						height={sliderHeight}
						className="slider-ficha-evento"
						effect="fade"
						delay={5000}
						slides={negocio.slider}
					/>
				)}
				{!(negocio.slider_enabled == 1 && negocio.slider) && negocio.header_image_url != null && (
					<div className="row">
						<div className="event-cover" style={{ backgroundImage: "url(" + (negocio.header_image_url[0] == "/" ? negocio.header_image_url : "/static/images/eventos/" + negocio.header_image_url) + ")" }}></div>
					</div>
				)}
			</div>
			<div className={"evento container"}>
				<div className="row">
					<div className="col-md-8 event-info">
						<h1>{negocio.name}</h1>
						<div className="event-address">
							<div className="address-name">{negocio.city}</div>
							<div className="address">{negocio.address}</div>
						</div>
						<div className="tags"></div>
						<div className="info-negocio">
							<EventInfo negocio={negocio} />
						</div>
						{negocio.description != undefined && (
							<div className="event-description">
								<div className="event-text">{HTMLReactParser(negocio.description)}</div>
							</div>
						)}
						<div className="event-dates-responsive">
							<div className="container" style={{ width: "calc(" + eventsLength * 100 + "vw - " + eventsLength * 75 + "px)" }}>
								{renderDates("calc(100vw - 75px)")}
							</div>
						</div>
						{negocio.events?.filter((event) => event.no_calendar == 1 || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0).length > 0 && (
							<div className="desktop-only experiencias">
								<div className="inner">{renderInlineDates()}</div>
							</div>
						)}
						{negocio.iconos_detalle != undefined && JSON.parse(negocio.iconos_detalle)?.length > 0 && (
							<div className="facts">
								{JSON.parse(negocio.iconos_detalle).map((detalle, idx) => {
									return (
										<div key={"detalle-" + idx}>
											<div className="icon-image" style={{ backgroundImage: "url(" + detalle.image_url + ")" }} />
											<div>
												<div>
													<b>{detalle.titulo}</b>
												</div>
												<div dangerouslySetInnerHTML={{ __html: detalle.lista }} />
											</div>
										</div>
									);
								})}
							</div>
						)}
						{negocio.show_ratings == 1 && surveyData && surveyData.map && (
							<div className="encuestas-clientes">
								<h3>{L10n.__("Valoración")}</h3>
								<div className="inner">
									<div className="columna" style={{ width: window.innerWidth > 992 ? null : surveyData.length * window.innerWidth * 0.63 - window.innerWidth + 300 }}>
										{surveyData.map((item, index) => {
											return (
												<div className="category" key={"survey-data-category" + index}>
													<h2>{item.text}</h2>
													<StarMeter defaultValue={item.value} />
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="col-md-3 col-dates">{renderDates()}</div>
				</div>
				{similar?.length > 0 && (
					<div>
						<h3 className="related-events-h3">{L10n.__("Similares")}</h3>
						<div className="related-events desktop-only">
							{similar?.slice(0, 3).map((event) => (
								<div key={"event-" + event.id} onClick={() => (location.href = "/venue/" + event.slug)}>
									<div
										className="event-image"
										style={{
											backgroundImage: "url(" + (event.header_image_url[0] == "/" ? event.header_image_url : "/static/images/eventos/" + event.header_image_url) + ")"
										}}>
										&nbsp;
									</div>
									<div className="event-name">{event.name}</div>
									<div className="event-city">{event.city}</div>
								</div>
							))}
						</div>

						<div className="related-events mobile-only">
							<div className="horizontal-scroll-container" style={{ width: similar.length * (window.innerWidth - 75) + 130 }}>
								{similar.map((event) => (
									<div key={"similar-" + event.id} className="cover-zoom-listener">
										<a
											onClick={() => (location.href = "/venue/" + event.slug)}
											className="event-image cover-zoom"
											style={{
												backgroundImage: "url(" + (event.header_image_url[0] == "/" ? event.header_image_url : "/static/images/eventos/" + event.header_image_url) + ")"
											}}>
											&nbsp;
										</a>
										<div className="event-name">{event.name}</div>
										<div className="event-city">{event.city}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
			{selectedAvailability?.no_calendar != 1 && selectedAvailability?.availability?.calendar && (
				<CalendarModal
					multiple={negocio.disponibilidad_multiple || selectedAvailability.disponibilidad_multiple}
					availabilityObject={selectedAvailability}
					calendar={selectedAvailability.availability.calendar}
					onRef={(ref) => (calendarModalRef.current = ref)}
					title={selectedAvailability.nombre || ""}
					onSelectionConfirmed={(selection) => {
						Session.SetBookingData("selected_availability_id", selection.availabilityRow.id);
						Session.SetBookingData("selected_slot", selection.availabilityRow.slot.split(" ")[1]);
						Session.SetBookingData("pax", selection.pax);
						Session.SetBookingData("selected_date", selection.availabilityRow.slot.split(" ")[0]);
						Session.SetBookingData("selected_full_date", selection.availabilityRow.slot);
						Session.SetBookingData("selected_event", JSON.stringify(negocio));
						Session.SetBookingData("preorder_selection", null);
						Session.SetBookingData("extra_events", null);
						Session.SetBookingData("selection_length", selection.selectionLength);
						document.body.classList.remove("modal-open");

						const event = negocio.events.find((v) => v.id == selection.availabilityRow.event_id);

						if (event.offers_preorder == 1 && event.extras_menu) {
							navigate(BASENAME + "/venue/" + negocio.slug + "/extras");
						} else if (event.offers_upselling == 1) {
							navigate(BASENAME + "/venue/" + negocio.slug + "/extras/otros");
						} else {
							navigate(`${BASENAME}/pago`);
						}
					}}
				/>
			)}
			{selectedAvailability?.no_calendar == 1 && selectedAvailability?.availability?.calendar && (
				<NoCalendarModal
					evento={selectedAvailability}
					onRef={(ref) => (calendarModalRef.current = ref)}
					title={selectedAvailability.nombre || ""}
					onConfirm={(quantity) => {
						Session.SetBookingData("selected_availability_id", null);
						Session.SetBookingData("selected_slot", null);
						Session.SetBookingData("pax", quantity);
						Session.SetBookingData("selected_date", null);
						Session.SetBookingData("selected_full_date", null);
						Session.SetBookingData("selected_event", JSON.stringify(negocio));
						Session.SetBookingData("selected_event_id", selectedAvailability.id);
						Session.SetBookingData("preorder_selection", null);
						Session.SetBookingData("extra_events", null);
						Session.SetBookingData("selection_length", 1);

						if (selectedAvailability.offers_upselling == 1) {
							navigate(BASENAME + "/venue/" + negocio.slug + "/extras/otros");
						} else {
							navigate(`${BASENAME}/pago`);
						}
					}}
				/>
			)}
			{negocio.events
				.filter((event) => event.no_calendar == 1 || event.availability.rows.reduce((pax, v) => pax + v.pax, 0) > 0)
				.map((event, idx) => {
					return (
						<Modal
							key={"modal-" + idx}
							onRef={(ref) => {
								datesInfoModal.current[event.id] = ref;
							}}
							title={event.nombre}
							className="event-info-modal"
							renderContent={(modal) => {
								return (
									<>
										<div
											className="event-image"
											style={{
												backgroundImage: "url(" + (event.image_url[0] == "/" ? event.image_url : "/static/images/eventos/" + event.image_url) + ")"
											}}>
											&nbsp;
										</div>
										<p />
										<div dangerouslySetInnerHTML={{ __html: event.description }} />
									</>
								);
							}}
						/>
					);
				})}
		</React.Fragment>
	);
}
