import { useEffect, useState, useContext, useRef } from "react";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";
import Helper from "../services/Helper";

export default function SubscriptionTypesBar(props) {
	const [activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex] = useState((props.initialValue != -1 ? props.initialValue : 0) ?? 0);
	const [elementID, _setElementID] = useState("subt-" + Math.floor(Math.random() * 10000 + 10000));
	const [floating, setFloating] = useState(true);
	const [rightEnd, setRightEnd] = useState(false);

	const scrollOffset = useRef();

	const [currentBarIndex, setCurrentBarIndex] = useState(0);

	const subscriptionsContext = useContext(SubscriptionsContext);
	const subscriptionTypes = props.subscriptionTypes || subscriptionsContext.subscriptionTypes;

	useEffect(() => {

		const paddingTopInterval = setInterval(() => {
			const headerHeight = window.innerWidth <= 992 ? $("header.mobile").outerHeight(true) : $("header.desktop").outerHeight(true);
			
			$(".subscription-description").css({ opacity: 1 });
			$("body").css({ transition: "300ms", paddingTop: headerHeight + $("#" + elementID).outerHeight(true) + ($("#" + elementID + " .with-filter").length && window.innerWidth <= 992 ? $(".widget-filtro").outerHeight(true) : 0) });
			
			$("#" + elementID).css({
				top: headerHeight
			});
		}, 300);
		
		return () => {
			clearInterval(paddingTopInterval);
			$("body").css({ paddingTop: "" });
		};
	}, []);

	const changeCurrentBarIndex = (index) => {
		setCurrentBarIndex(Math.max(0, Math.min(subscriptionTypes.length - 1, index)));
	};

	useEffect(() => {
		const element = document.getElementById(elementID);
		const $container = $(element).find(".subscription-types-container");
		const $bar = $(element).find(".subscription-types-bar");
		$(element).css({ transition: "300ms", height: $container.outerHeight(true) + (window.innerWidth < 992 ? 19 : 35) });
		$bar.css({ transition: "300ms", height: $container.outerHeight(true) });
		$container.css({ transition: "300ms", position: "absolute" });

		// TODO: This should not need an else branch.
		if (props.jumpToFirstSubscribed && !(subscriptionTypes[activeSubscriptionTypeIndex]?.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex].id).length > 0)) {
			let firstSubscribed = -1;
			subscriptionTypes.forEach((subscriptionType, idx) => {
				if (firstSubscribed != -1) return;

				const subscribed = subscriptionType.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == subscriptionType.id).length > 0;
				if (subscribed) {
					firstSubscribed = idx;
				}
			});

			if (firstSubscribed != -1) {
				if (props.onChange) props.onChange(firstSubscribed);
				setActiveSubscriptionTypeIndex(firstSubscribed);
			}
		} else if (!(subscriptionTypes[activeSubscriptionTypeIndex]?.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex].id).length > 0)) {
			let firstAvailable = -1;
			subscriptionTypes.forEach((subscriptionType, idx) => {
				if (firstAvailable != -1) return;

				if (subscriptionType.available) {
					firstAvailable = idx;
				}
			});

			if (firstAvailable != -1) {
				if (props.onChange) props.onChange(firstAvailable);
				setActiveSubscriptionTypeIndex(firstAvailable);
			}
		}
	}, [subscriptionTypes]);

	useEffect(() => {
		let elementsWidth = 0;
		$("#" + elementID + " .subscription-types-container > div").each(function (idx) {
			elementsWidth += $(this).outerWidth(true);
		});

		const maxOffset =
			$("#" + elementID + " .subscription-types-container > div")
				.last()[0]
				.getBoundingClientRect().left -
			$("#" + elementID + " .subscription-types-container")[0].getBoundingClientRect().left +
			$("#" + elementID + " .subscription-types-container > div")
				.last()[0]
				.getBoundingClientRect().width -
			$("#" + elementID + " .subscription-types-bar")[0].getBoundingClientRect().width;

		let offset = 0;
		$("#" + elementID + " .subscription-types-container > div").each(function (idx) {
			if (idx + 1 < currentBarIndex) {
				offset += $(this).outerWidth(true);
			}
		});

		scrollOffset.current = Math.min(0, -Math.min(offset, maxOffset));
		setRightEnd(scrollOffset.current == -maxOffset);


		if (window.innerWidth > 992) {
			$("#" + elementID + " .subscription-types-container").css({ left: scrollOffset.current });
		}
	}, [currentBarIndex]);

	useEffect(() => {
		$("html, body").animate({ scrollTop: 0 }, 500);

		setTimeout(() => {
			if ($('.subscription-types-container .subscription-type.active')[0]?.getBoundingClientRect().left < $('.subscription-types-bar')[0]?.getBoundingClientRect().left) {
				if (window.innerWidth <= 992) {
					$("#" + elementID + " .subscription-types-bar").animate({
						scrollLeft: activeSubscriptionTypeIndex * ($("#" + elementID + " .subscription-types-container").outerWidth() / $("#" + elementID + " .subscription-types-container").children().length)}, 300);
				} else {
					changeCurrentBarIndex(activeSubscriptionTypeIndex);	
				}
			}
		}, 300);
	}, [activeSubscriptionTypeIndex, subscriptionTypes]);

	useEffect(() => {
		document.body.classList.add("subscriptions-bar-visible");

		$("#subscription-types-bar-injected-css").remove();

		if (Helper.IsResponsive()) {
			$("head").append(`
                <style id="subscription-types-bar-injected-css">
                .subscription-types-bar-container.floating {
                    top: ${$("header.mobile").outerHeight(true)}px;
                }
                </style>
            `);
		}

		return () => {
			$("#subscription-types-bar-injected-css").remove();
			document.body.classList.remove("subscriptions-bar-visible");
		};
	}); // On render.

	return (
		<div id={elementID} style={props.style} className={"subscription-types-bar-container " + (floating ? " floating" : "") + (currentBarIndex == 0 ? " left-end" : "") + (rightEnd ? " right-end" : "")}>
			<div className={"subscription-types-bar" + (props.className ? ' ' + props.className : '')}>
				<MaterialIcon
					name="expand_circle_right"
					className="move-left"
					onClick={(e) => {
						e.preventDefault();
						changeCurrentBarIndex(currentBarIndex - 6);
					}}
				/>
				<div className="subscription-types-container">
					{subscriptionTypes?.map((type, idx) => {
						let subscribed = type.available && subscriptionsContext.userSubscriptions?.filter((t) => t.subscription_type_id == type.id).length > 0;

						return (
							<div
								className={"subscription-type" + (type.available ? " available" : "") + (subscribed ? " subscribed" : "") + (activeSubscriptionTypeIndex == idx ? " active" : "")}
								key={"subscription-type-" + idx}
								onClick={(e) => {
									if (activeSubscriptionTypeIndex == idx) return;
									if (props.onChange) props.onChange(idx);
									setActiveSubscriptionTypeIndex(idx);
								}}>
								<div className="image" style={{ backgroundImage: "url(" + type.image_url + ")" }} />
								<div className="name">{type.name}</div>
								{type.pack == 1 && (
									<div className="pack-tag inhibit-clicks has-tooltip" data-tooltip={L10n.__("Esta suscripción te permite disfrutar de toda la oferta de") + " " + type.name}>
										{L10n.__("Pack")}
									</div>
								)}
							</div>
						);
					})}
				</div>
				<MaterialIcon
					name="expand_circle_right"
					className="move-right"
					onClick={(e) => {
						e.preventDefault();
						changeCurrentBarIndex(currentBarIndex + 6);
					}}
				/>
			</div>
		</div>
	);
}
