import { useState, useEffect, useCallback, useContext } from "react";

import AdminService from "../services/Admin";
import ContentHelper from "../services/ContentHelper";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import Field from "./Field";
import { DialogContext } from "../context/DialogContext";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import LoadingIndicator from "./LoadingIndicator";
import L10n from "../services/Locale";

export default function AdminContacto(props) {
	const [contactoEmpresasTitulo, setContactoEmpresasTitulo] = useState(null);
	const [contactoEmpresasSubtitulo, setContactoEmpresasSubtitulo] = useState(null);
	const [contactoTitulo, setContactoTitulo] = useState(null);
	const [contactoSubtitulo, setContactoSubtitulo] = useState(null);
	const [contactEmail, setContactEmail] = useState(null);
	const [contactEmpresasEmail, setContactEmpresasEmail] = useState(null);
	const [loadingInProgress, setLoadingInProgress] = useState(false);

	const subscriptionsContext = useContext(SubscriptionsContext);
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		setLoadingInProgress(true);
		Promise.all([ContentHelper.GetString("contacto-empresas-titulo"), ContentHelper.GetString("contacto-empresas-subtitulo"), ContentHelper.GetString("contacto-titulo"), ContentHelper.GetString("contacto-subtitulo"), AdminService.GetSettings("CONTACT_EMAIL"), AdminService.GetSettings("CONTACT_ALT_EMAIL")]).then(([tituloEmpresas, subtituloEmpresas, titulo, subtitulo, email, altEmail]) => {
			setContactoEmpresasTitulo(tituloEmpresas);
			setContactoEmpresasSubtitulo(subtituloEmpresas);
			setContactoTitulo(titulo);
			setContactoSubtitulo(subtitulo);
			setContactEmail(email);
			setContactEmpresasEmail(altEmail);
			setLoadingInProgress(false);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(
		(notifySaved) => {
			dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
				if (!confirmed) {
					notifySaved(false);
					return;
				}

				Promise.all([AdminService.SaveContent("contacto-empresas-titulo", contactoEmpresasTitulo), AdminService.SaveContent("contacto-empresas-subtitulo", contactoEmpresasSubtitulo), AdminService.SaveContent("contacto-titulo", contactoTitulo), AdminService.SaveContent("contacto-subtitulo", contactoSubtitulo), AdminService.SetSettings("CONTACT_EMAIL", contactEmail), AdminService.SetSettings("CONTACT_ALT_EMAIL", contactEmpresasEmail)]).then(() => {
					notifySaved(true);
				});
			});
		},
		[contactoEmpresasTitulo, contactoEmpresasSubtitulo, contactoTitulo, contactoSubtitulo, contactEmail, contactEmpresasEmail]
	);

	if (loadingInProgress) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-contacto">
			<AdminBar onSave={save} />
			<Bloque className="columnas">
				<div className="columna">
					<h2>Formulario de contacto general</h2>
					{contactoTitulo !== null && <Field placeholder={L10n.__("Título")} type="string" onChange={(value) => setContactoTitulo(value)} defaultValue={contactoTitulo} />}
					{contactoSubtitulo !== null && <Field placeholder={L10n.__("Subtítulo")} type="text" onChange={(value) => setContactoSubtitulo(value)} defaultValue={contactoSubtitulo} />}
					{contactEmail !== null && <Field placeholder={L10n.__("Email de contacto")} type="string" onChange={(value) => setContactEmail(value)} defaultValue={contactEmail} />}
				</div>

				{!subscriptionsContext?.subscriptionsEnabled && (
					<div className="columna">
						<h2>Formulario para empresas</h2>
						{contactoTitulo !== null && <Field placeholder={L10n.__("Título")} type="string" onChange={(value) => setContactoEmpresasTitulo(value)} defaultValue={contactoEmpresasTitulo} />}
						{contactoSubtitulo !== null && <Field placeholder={L10n.__("Subtítulo")} type="text" onChange={(value) => setContactoEmpresasSubtitulo(value)} defaultValue={contactoEmpresasSubtitulo} />}
						{contactEmpresasEmail !== null && <Field placeholder={L10n.__("Email de contacto")} type="string" onChange={(value) => setContactEmpresasEmail(value)} defaultValue={contactEmpresasEmail} />}
					</div>
				)}
			</Bloque>
		</div>
	);
}
