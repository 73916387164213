import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import AdminBar from "./AdminBar";
import { APIAdminRequest } from "../services/API";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import Bloque from "./AdminBloque";
import { DialogContext } from "../context/DialogContext";
import { PermissionsContext } from "../context/PermissionsContext";
import L10n from "../services/Locale";
import FormToggle from "./FormToggle";
import Modal from "./Modal";
import Helper from "../services/Helper";
import { StarMeter } from "./Encuesta";
import DotMenu from "./DotMenu";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import React from "react";

export default function AdminEmpleadores(props) {
	const [empleadorTypes, setEmpleadorTypes] = useState([]);
	const [empleadores, setEmpleadores] = useState([]);
    const modalEncuesta = useRef();
    const [ modalEncuestaContents, setModalEncuestaContents ] = useState([]);
    const [ editedEncuestaTypeIndex, setEditedEncuestaTypeIndex ] = useState(-1);
	const dialogContext = useContext(DialogContext);
	const permissionsContext = useContext(PermissionsContext);
    const [ draggedEmpleador, setDraggedEmpleador ] = useState(null);
    const [ dragStarted, setDragStarted ] = useState(false);
	const [ sortBy, setSortBy ] = useState([]);

	const navigate = useNavigate();

	function changeEmpleadorType(idx, type) {
		const newTypes = [...empleadorTypes];
		newTypes[idx] = type;
		newTypes.sort((a, b) => a.order - b.order);
		setEmpleadorTypes(newTypes);
	}

	useEffect(() => {
		sortBy.forEach((x, idx) => {
			if (x?.by) {
				const n = [...empleadores];
				empleadores?.filter(e => e.empleador_type_id == empleadorTypes[idx].id).sort((a, b) => {
					if (x.order == "asc") {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return 1;
							if (a.mean_rating < b.mean_rating) return -1;
							return 0;
						}
						
						if (x.by == "name") {
							return a.name.localeCompare(b.name);
						}
					} else {
						if (x.by == "rating") {
							if (a.mean_rating > b.mean_rating) return -1;
							if (a.mean_rating < b.mean_rating) return 1;
							return 0;
						}
						if (x.by == "name") {
							return b.name.localeCompare(a.name);
						}
					}
				}).forEach((e, idx) => {
					n[n.findIndex(e2 => e2.id == e.id)].order = idx;
				});
				setEmpleadores(n);
			}
		});
	}, [ sortBy ]);

	function getEmpleadores() {
		APIAdminRequest("get-empleadores", { partner_id: permissionsContext.user.id }).then(response => {
            for (let i=0; i<response.data?.length; ++i) {
                if (response.data[i].iconos) response.data[i].iconos = JSON.parse(response.data[i].iconos) || [];;
                if (response.data[i].iconos_detalle) response.data[i].iconos_detalle = JSON.parse(response.data[i].iconos_detalle) || [];
                if (response.data[i].info) response.data[i].info = JSON.parse(response.data[i].info) || {};
                if (response.data[i].encuesta) response.data[i].encuesta = JSON.parse(response.data[i].encuesta) || [];
            }
			response.data.forEach((_, idx) => {
				response.data[idx].order = idx;
			});
            setEmpleadores(response.data);
        });
	}

	useEffect(() => {
		getEmpleadores();
	}, [empleadorTypes]);

	useEffect(() => {
		APIAdminRequest("get-empleador-types").then((response) => {
			setEmpleadorTypes(response.data);
		});

		document.body.classList.add("has-header");
		document.body.classList.add("bg-grey");

        function onMouseUp() {
            setTimeout(() => {
                setDragStarted(false);
                setDraggedEmpleador(null);
            }, 150);
        }

        document.addEventListener("mouseup", onMouseUp);

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.classList.remove("has-header");
            document.removeEventListener("mouseup", onMouseUp);
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog(L10n.__("¿Quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}
            APIAdminRequest("save-empleadores-order", { empleadores: empleadores }).then(() => {
                APIAdminRequest("save-empleador-types", { types: empleadorTypes.map(type => { type.id = Math.max(0, type.id); return type; }), locale: L10n.GetLanguage() }).then((response) => {
                    notifySaved(true);
                });
            });
		});
	}, [empleadorTypes, empleadores]);

	return (
		<div className="admin-page admin-negocios">
			{permissionsContext.user.is_superuser == 1 && <>
			<AdminBar onSave={save} />
			<div className="header">
				<a
					href="#"
					className="btn"
					onClick={(e) => {
						e.preventDefault();
						let newTypes = [...empleadorTypes];
						if (newTypes.find((v) => v.order == 0)) {
							for (let i = 0; i < newTypes.length; i++) {
								newTypes[i].order++;
							}
						}
						newTypes.push({
							id: -Math.random(),
							type: "",
							plural: "",
							order: newTypes.length == 0 ? 0 : newTypes[newTypes.length - 1].order + 1,
							main: 0,
							description: ""
						});
						setEmpleadorTypes(newTypes);

                        setTimeout(() => {
                            $("html, body").animate({ scrollTop: $(".venue-type.fila:last-child").offset().top - $("header.desktop").outerHeight() + $(".header").outerHeight() }, 500);
                        }, 150);
					}}>
					{L10n.__("Nueva categoría")}
				</a>
			</div></>}
			<Bloque className="filas">
				{empleadorTypes.map((type, type_idx) => {
					return (
						<div className={"fila venue-type" + (type.enabled ? "" : " disabled")} key={"type"+type_idx+"-"+type.id}>
							{permissionsContext.user.is_superuser == 1 && <div className="row-actions">
								<div>
									<a
										className="highlight-type"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											if (type.main == 0) type.main = 1;
											else type.main = 0;
											changeEmpleadorType(type_idx, type);
										}}>
									</a>
									<div className="icon-tooltip">{L10n.__("Destacado")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-up"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order = Math.max(0, type.order - 1);
											for (let i = 0; i < empleadorTypes.length; i++) {
												if (i == type_idx) continue;
												if (empleadorTypes[i].order == type.order) empleadorTypes[i].order = previousOrder;
											}
											changeEmpleadorType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_up" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia arriba")}</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-down"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order++;
											for (let i = 0; i < empleadorTypes.length; i++) {
												if (i == type_idx) continue;
												if (empleadorTypes[i].order == type.order) empleadorTypes[i].order = previousOrder;
											}
											changeEmpleadorType(type_idx, type);
										}}>
										<MaterialIcon name="arrow_circle_down" />
									</a>
									<div className="icon-tooltip">{L10n.__("Mover hacia abajo")}</div>
                                </div>
								<Field
									type="string"
									placeholder={L10n.__("Nombre de la categoría")}
									defaultValue={type.plural}
									onChange={(value) => {
										type.plural = value;
										type.type = value;
										changeEmpleadorType(type_idx, type);
									}}
								/>
								<div>
									<MaterialIcon name="abc" onClick={e => {
										const n = [...sortBy];
										if (n[type_idx]?.by == "name") {
											n[type_idx].order = n[type_idx].order == "asc" ? "desc" : "asc";
										} else {
											n[type_idx] = { by: "name", order: "asc" };
										}
										setSortBy(n);
									}} className={"sort-by-name sort-button" + (sortBy[type_idx]?.by == "name" ? " active" : "")} />
									<div className="icon-tooltip">{L10n.__("Ordenar alfabéticamente")}</div>
								</div>
                                <div className="toggle-container">
                                    <FormToggle defaultValue={type.enabled == 1} onChange={value => {
                                        const newTypes = [...empleadorTypes];
                                        newTypes[type_idx].enabled = value ? 1 : 0;
                                        setEmpleadorTypes(newTypes);
                                    }} />
                                    <div className="icon-tooltip">{L10n.__((type.enabled == 1 ? "Desactivar" : "Activar")  + " categoría")}</div>
                                </div>
								<div className="clone-container">
									<a
										className="clone"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											APIAdminRequest("clone-empleador-type", { id: type.id }).then(response => {
												if (response.status) {
													APIAdminRequest("get-empleador-types", { class: props.negocioClass || 0 }).then((response) => {
														setEmpleadorTypes(response.data);
													});
												} else {
													alert(L10n.__(response.message));
												}
											})
										}}>
										<MaterialIcon name="copy_all" />
									</a>
									<div className="icon-tooltip">{L10n.__("Clonar categoría")}</div>
								</div>
								<div className="delete-container">
									<a
										className="delete"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											const newTypes = [...empleadorTypes];
											newTypes.splice(type_idx, 1);
											setEmpleadorTypes(newTypes);
										}}>
										<MaterialIcon name="delete" />
									</a>
									<div className="icon-tooltip">{L10n.__("Borrar categoría")}</div>
								</div>
							 </div>}
							{permissionsContext.user.is_superuser != 1 &&
							 <div className="row-actions">
								 <h3>{Helper.UCFirst(type.plural)}</h3>
							</div>}
							<div className={"evento-grid" + ((dragStarted && draggedEmpleador) ? " dragging" : "")}>
								{empleadores?.filter((v) => v.empleador_type_id == type.id).sort((a, b) => a.order - b.order).map((empleador, idx) => {
										let averagePrice = 0;
										let count = 0;

										for (let oferta_trabajo of empleador.ofertas_trabajo) {
											if (oferta_trabajo.price != -1) {
												averagePrice += oferta_trabajo.price;
												count++;
											}
										}

										if (count > 0) {
											averagePrice = (averagePrice / count) / 100;
										} else {
											averagePrice = -1;
										}

										return (
											<div
                                                className={"carrusel-main-evento" + (dragStarted && draggedEmpleador && draggedEmpleador == empleador ? " dragged-negocio" : "") + (empleador.enabled ? "" : " disabled")}
                                                key={"mainevento"+empleador.id+"-"+idx}
                                                onMouseMove={e => {
                                                    if (dragStarted && !draggedEmpleador) {
                                                        setDraggedEmpleador(empleador);
                                                    }
                                                }}
                                                onMouseDown={e => {
                                                    setDragStarted(true);
                                                }}
                                                onMouseUp={e => {
                                                    if (draggedEmpleador && empleador != draggedEmpleador) {
                                                        const n = [...empleadores];
														n.find(e => e.id == draggedEmpleador.id).order = empleador.order;
														n.find(e => e.id == empleador.id).order = empleador.order + 1;
														n.sort((a, b) => a.order - b.order);
														for (let i=n.findIndex(e => e.id == draggedEmpleador.id)+1; i<n.length; ++i)
														{
															if (i < 0 || i >= n.length) continue;
															n[i].order = i;
														}
                                                        setEmpleadores(n);

														const s = [...sortBy];
														s[type_idx] = { by: "", order: "" };
														setSortBy(s);
														setDragStarted(false);
														setDraggedEmpleador(null);
                                                    }

                                                    if ((!dragStarted || !draggedEmpleador) && !$(e.target).closest('.dot-menu').length) {
                                                        navigate(BASENAME + "/admin/trabajo/" + empleador.id);
                                                    }
                                                }}
                                            >
												<DotMenu align={"left"} options={[
													{ caption: L10n.__("Clonar empleador"), action: () => {
														APIAdminRequest("clone-empleador", { id: empleador.id }).then(response => {
															if (response.status) {
																navigate(BASENAME + "/admin/trabajo/" + response.data);
															} else {
																console.error(response.error);
															}
														})
													} },
													{ caption: L10n.__("Eliminar"), action: () => {
														dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este empleador y todas sus ofertas?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
															if (confirmed) {
																APIAdminRequest("delete-empleador", { id: empleador.id }).then(() => {
																	const n = [...empleadores];
																	n.splice(idx, 1);
																	setEmpleadores(n);
																});
															}
														});
													} }
												]} />
												{!empleador.enabled && <div className="disabled-tag">{L10n.__("Oculto")}</div>}
												<div className="image-container" style={{ backgroundImage: "url(" + empleador.image_url + ")" }}>
													{empleador.iconos && (
														<div className="icons">
															{empleador.iconos.map((icono, idx) => {
																return (
																	<div
																		style={{ position: "relative" }}
																		onClick={(e) => {
																			e.preventDefault();
																			e.stopPropagation();
																			return false;
																		}}
																		key={"icono"+empleador.id+"-"+idx}>
																		<img src={icono.image_url} className="icon-image" />
																		<div className="icon-tooltip">{icono.description}</div>
																	</div>
																);
															})}
														</div>
													)}
												</div>
												<div className="details">
													<div className="name">{empleador.name}</div>
													<div className="city">{empleador.city}</div>
													<div style={{display:"flex"}}>
														{empleador.ofertas_trabajo.length > 0 && averagePrice != -1 && <div className="average-price">
															<div>
																{L10n.__("Precio medio")} <span>{Helper.FormatAmount(averagePrice)}</span>
															</div>
														</div>}
														{empleador.ofertas_trabajo.filter(ev => ev.in_pack).length > 0 && <div className="pack-indicator">{L10n.__("Pack")}</div>}
													</div>
													{!!empleador.mean_rating && <div className="rating-container">
														<StarMeter defaultValue={empleador.mean_rating} />
														<div className="numeric-value">{parseFloat(empleador.mean_rating?.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
													</div>}
												</div>
											</div>
										);
									})}
								<div
                                    className="carrusel-main-evento add-new"
                                    key={type_idx}
                                    onMouseUp={e => {
                                        if (draggedEmpleador) {
                                            const n = [...empleadores];
                                            n.splice(n.findIndex(e => e.id == draggedEmpleador.id), 1);
                                            n.push(draggedEmpleador);
                                            setEmpleadores(n);
											const s = [...sortBy];
											s[type_idx] = { by: "", order: "" };
											setSortBy(s);
											setDragStarted(false);
											setDraggedEmpleador(null);
                                        }

                                        if (!dragStarted || !draggedEmpleador) {
                                            navigate(BASENAME + "/admin/trabajo/0");
                                        }
                                    }}
                                >
									<MaterialIcon name="add_circle" />
								</div>
							</div>
						</div>
					);
				})}
			</Bloque>
		</div>
	);
}
