import React, { Component } from 'react';
import L10n from '../services/Locale.tsx';
import { Link } from 'react-router-dom';
import Footer from './Footer.tsx';
import $ from 'jquery';

class RegistroGracias extends Component {
	componentDidMount() {
		$('body').addClass('bg-offwhite');
	}

	componentWillUnmount() {
		$('body').removeClass('bg-offwhite');
	}

    render() {
        return (
            <div className="registro-gracias formulario-contacto contacto-gracias bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{L10n.__("¡Has creado tu cuenta con éxito!")}</h1>
                            <div className="subtitle">{L10n.__("Comprueba tu buzón de entrada y carpeta de spam para verificar tu cuenta de correo.")}</div>
                            <Link to={BASENAME + "/"} className="btn btn-brown">{L10n.__("Volver")}</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default RegistroGracias;
